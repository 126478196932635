/* eslint-disable react/jsx-key */
import { Box, List, ListItemButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sidebar_style } from './style';

export function SideBar(props) {
  const { navItems = [], rootStyle = {}, className = '', ...rest } = props;
  const navigate = useNavigate();

  const gotoPage = (route) => {
    navigate(route);
  };

  return (
    <Box
      sx={{ ...sidebar_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={{ pt: { lg: 2, md: 2, sm: 0 }, pb: { sm: 10 } }}>
        {navItems.map((val, i) => (
          <List key={i}>
            <ListItemButton
              id={`sidebar_item_${i}`}
              onClick={() => gotoPage(val.path)}
              selected={val?.path === window.location.pathname}
              sx={{
                ...sidebar_style.ListItemButtonSx,
                '&.Mui-selected': {
                  backgroundColor: '#EEEEEE',
                  '&:hover': {
                    backgroundColor: '#EEEEEE',
                  },
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '#EEEEEE',
                },
                '&:hover': {
                  backgroundColor: '#EEEEEE',
                },
                '& .MuiButtonBase-root-MuiListItemButton-root.Mui-selected': {
                  backgroundColor: '#EEEEEE',
                },
              }}
            >
              {/* <ListItemButton sx={sidebar_style.ListItemBu ttonSx}> */}
              <Box sx={sidebar_style.iconSx}>{val?.icon}</Box>
              <Typography sx={sidebar_style.menuSx}>{val?.navItem}</Typography>
              {/* </ListItemButton> */}
            </ListItemButton>
          </List>
        ))}
      </Box>
    </Box>
  );
}
SideBar.propTypes = {
  navItems: PropTypes.array,
  rootStyle: PropTypes.object,
  className: PropTypes.string,
};
