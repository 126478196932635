export const addPlanDrawer_style = {
  TitileSx: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'text.label',
  },
  togglebuttonactiveSX: {
    color: 'primary.main',
    backgroundColor: '#E7FCF9',
    borderColor: 'primary.main',
    border: '1px solid ',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    padding: '5px 10px',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: '#E7FCF9',
    },
  },
  togglebuttoninactiveSX: {
    color: '#5C6266',
    border: '1px solid ',
    borderColor: 'transparent',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '8px',
    padding: '5px 10px',
    backgroundColor: '#E7FCF9',
    '&:hover': {
      color: '#5C6266',
      backgroundColor: '#E7FCF9',
    },
  },

  headerSx: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#101010',
    textTransform: 'capitalize',
  },
  inputFieldSx: {
    marginTop: '4px',
  },
  boxRootSx: {
    display: 'grid',
    gap: 2,
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },

  rootchildrenSx: {
    textAlign: 'center',
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: '8px',
    mr: 1,
  },
  genderSx: {
    display: 'flex',
    justifyContent: 'start',
  },
  selectAvailabilityButtonSx: {
    backgroundColor: 'primary.main',
    color: '#fff',
  },
  unSelectAvailabilityButtonSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'primaryTints.A100',
  },
  buttonSx: {
    color: 'primary.main',
    border: '2px solid',
    fontSize: '14px',
    fontWeight: 'medium',
    borderColor: 'primary.main',
    height: '40px',
    width: '128px',
    borderRadius: '4px',
    textTransform: 'capitalize',
  },
  addMemberSx: {
    textTransform: 'capitalize',
    height: '40px',
    width: '124px',
    borderRadius: '4px',
  },
  labelSx: {
    color: 'text.label',
  },
  activeUploadFileSx: {
    border: '2px dashed',
    borderColor: 'primary.main',
    // opacity: '48%',
    height: '132px',
    // marginTop: 2,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  browseTextSx: {
    fontSize: '14px',
    color: ' PrimaryTints.A100',
    opacity: '48%',
    textAlign: 'center',
    marginTop: '12px',
    cursor: 'pointer',
  },
  uploadIconSx: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  measurableSx: {
    background: ' #FFFFFF',
    border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
    p: 2,
  },
  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: 'text.hint',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#007965',
      opacity: 1,
    },
  },
  measurableContentSx: {
    display: 'flex',
  },
  buttonactivenumSX: {
    color: 'primary.main',
    backgroundColor: '#E7FCF9',
    borderColor: 'primary.main',
    border: '1px solid ',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    padding: '5px 10px',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: '#E7FCF9',
    },
  },
  buttoninactivenumSX: {
    color: '#5C6266',
    border: '1px solid ',
    borderColor: 'transparent',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '8px',
    padding: '5px 10px',
    backgroundColor: '#E7FCF9',
    '&:hover': {
      color: '#5C6266',
      backgroundColor: '#E7FCF9',
    },
  },
  containerBoxSx: {
    background: ' #FFFFFF',
    border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
    p: 1,
  },
  notesContainerBoxSx: {
    background: ' #FFFFFF',
    border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
    p: 1,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '10vh',
  },

  SaveButtonSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleButtonSx: {
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  textFieldStyle: {
    opacity: 1,
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 600,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: 'none',
      },
    },
    '& .MuiFormHelperText-root': {
      mx: 0,
    },
  },
  dropdownSX: {
    '.MuiPaper-root': {
      left: 4,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  activityunitsSX: {
    display: 'flex',
    background: ' #FFFFFF',
    border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
  },
  searchFieldSx: {
    width: '360px',
    '& .MuiOutlinedInput-notchedOutline': {
      px: 2,
      height: '44px',
      border: 'none',
    },

    '&.MuiFormControl-root': {
      backgroundColor: '#fff',
      pb: '4px',
    },
    ' & .MuiOutlinedInput-input': {
      color: 'text.primary',
      backgroundColor: '#fff',
      pt: 0.5,
    },
    '& .MuiOutlinedInput-root': {
      px: 0,
      pt: 0.7,
      backgroundColor: 'tranparent',
      color: '#025140',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: '#C5C5C5',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },

  listItemTitileSx: {
    textAlign: 'left',
    font: '12px/18px Poppins',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#000',
  },
  listItemclearSx: {
    textAlign: 'left',
    font: '12px/18px Poppins',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#F44F5A',
    cursor: 'pointer',
  },
  listItemclearSxx: {
    opacity: 0.5,
    textAlign: 'left',
    font: '12px/18px Poppins',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#F44F5A',
    cursor: 'pointer',
  },
  QtyTypoSx: {
    width: '100px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    '&:hover': {
      borderColor: '#E3E3E3',
    },
    '& .MuiOutlinedInput-root': {
      width: '100px',
      borderRadius: '10px',
      '& fieldset': {
        borderRadius: '10px',
        borderColor: '#E3E3E3',
      },
      '&:hover fieldset': {
        borderColor: '#E3E3E3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E3E3E3',
      },
    },
  },

  header: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#0E1824',
    textAlign: 'center',
    pb: 1,
  },
  description: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#0E1824',
    textAlign: 'center',
  },
  subDescription: {
    fontSize: '16px',
    color: '#0000004D',
    textAlign: 'center',
  },
};
