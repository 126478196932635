import { Box } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import React from 'react';
import { pagination_style } from './style';

function Pagination(props) {
  const {
    rootStyle = {},
    offset = 0,
    rowsPerPage = 10,
    totalCount='',
    onLimitChange = () => {},
    onOffsetchange = () => {},
    rowsPerPageOptions = [5, 10, 25, 50],
  } = props;

  const handleChangePage = (event, newPage) => {
    onOffsetchange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    onLimitChange(event.target.value, 0);
  };

  return (
    <Box
      sx={{
        ...pagination_style.rootSx,
        ...rootStyle,
      }}
    >
      <TablePagination
        component="div"
        count={parseInt(totalCount, 10)}
        page={offset}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </Box>
  );
}

Pagination.propTypes = {
  rootStyle: PropTypes.object,
  offset: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalCount: PropTypes.string,
  onLimitChange: PropTypes.func,
  onOffsetchange: PropTypes.func,
  rowsPerPageOptions: PropTypes.array,
};

export { Pagination };
