import {
  Box,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { NotificationCardIcon } from '@atoms/icons';
import { notification_card_style } from './style';
import { Button } from '../../atoms';

export function NotificationCard(props) {
  const {
    className = '',
    rootStyle = {},
    prescription = '',
    notificationTime = '',
    url = '',
    isExpert,
    redirectFnc = () => {},
    data = {},
    is_read = false,
    notificationDeleteFun,
    notificationUndoFun,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseBackDrop = () => setAnchorEl(null);

  const handleClose = async (notificationId) => {
    setAnchorEl(null);
    const res = await notificationDeleteFun(notificationId);
    toast((t) => (
      <span>
        {res?.message}

        <Button
          buttonStyle={{
            width: 'auto',
            p: 0,
            textTransform: 'capitalize',
            mx: 0.5,
          }}
          onClick={async () => {
            await notificationUndoFun(notificationId);
            toast.dismiss(t?.id);
          }}
        >
          Undo
        </Button>
      </span>
    ));
  };

  return (
    <Box
      sx={{
        ...notification_card_style.rootSx,
        ...rootStyle,
        backgroundColor: is_read === false ? 'primary.lighter' : '#FFFFFF',
        boxShadow: is_read === false ? '' : '0px 1px #dfe2e3',
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container>
        <Grid item xs={1.5}>
          <Box
            sx={
              isExpert
                ? { ...notification_card_style.iconExpertBackgroundSx }
                : { ...notification_card_style.iconBackgroundSx }
            }
          >
            <Box sx={{ pt: '6px', ml: '6px' }}>
              <NotificationCardIcon
                rootStyle={isExpert ? { color: '#6552CC' } : '#007965'}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={9}
          sx={{ cursor: 'pointer' }}
          onClick={() => redirectFnc(url, data)}
        >
          <Typography
            variant="body2"
            style={{ fontWeight: is_read === false ? '600' : '100' }}
          >
            {prescription}
          </Typography>
        </Grid>
        <Grid item xs={1.5} sm={1.5} md={1.5}>
          <Grid container justifyContent="end">
            <Grid item>
              <Typography
                variant="caption"
                color="text.disabled"
                style={{ fontWeight: is_read === false ? '600' : '100' }}
              >
                {notificationTime}
              </Typography>

              {is_read === false ? (
                ''
              ) : (
                <>
                  <IconButton
                    aria-label="delete"
                    onClick={handleClick}
                    disableFocusRipple
                    disableRipple
                  >
                    <BiDotsVerticalRounded />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={handleCloseBackDrop}
                  >
                    <MenuItem onClick={() => handleClose(data?.id)}>
                      Delete
                    </MenuItem>
                  </Menu>{' '}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

NotificationCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  prescription: PropTypes.string,
  notificationTime: PropTypes.string,
  url: PropTypes.string,
  isExpert: PropTypes.bool,
  redirectFnc: PropTypes.func,
  data: PropTypes.object,
  is_read: PropTypes.bool,
};
