import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { parameter_style } from './style';
import ChatBotParameter from './chatBot';

export default function ParameterSetup(props) {
  const { className = '' } = props;
  const [isActive, setActive] = useState(0);
  let component;

  const listData = ['Chatbot'];

  switch (isActive) {
    case 0:
      component = <ChatBotParameter />;
      break;

    default:
      component = 0;
      break;
  }

  const handleSelect = (value) => {
    setActive(value);
  };

  return (
    <Box className={`${className}`} sx={parameter_style.rootSx}>
      <Grid container>
        <Grid item xs={3} p={1}>
          <Stack sx={parameter_style.leftSideBarSx}>
            <Typography fontWeight={600} sx={{ p: 2 }}>
              Parameter
            </Typography>
            <Stack sx={parameter_style.ListContainerSx}>
              {listData.map((data, i) => (
                <Typography
                  key={i}
                  onClick={() => handleSelect(i)}
                  sx={{
                    p: 1,
                    borderRadius: '8px',
                    background: isActive === i ? '#d4d8dc' : '',
                    '&:hover': { background: isActive === i ? '' : '#EDEFF2' },
                  }}
                >
                  {data}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={9} p={1}>
          <Box sx={parameter_style.rightComponentSX}>{component}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ParameterSetup.propTypes = {
  className: PropTypes.string,
};
