export const toggleButton_style = {
  activeSx: {
    opacity: 1,
    color: 'border.focus',
    fontSize: '12px',
  },
  nameActiveSx: {
    fontWeight: 600,
    color: '#fff',
  },
  nameInactiveSx: {
    // mr: '0.5px',
    fontWeight: 500,
    color: 'black',
    '&:hover': {
      color: '#fff',
    },
  },
  radioSx: {
    color: 'text.secondary',
    fontSize: '12px',
  },
  buttonactiveSx: {
    //   border: '2px solid',
    borderColor: 'primary.main',
    color: '#fff',
    textTransform: 'capitalize',
    padding: '10px',
    '&:hover': {
      color: '#fff',
    },
  },
  buttoninactiveSx: {
    backgroundColor: '#fff',
    border: '2px solid primaryGreyishTints.100',
    // boxShadow: '5px 10px',
    textTransform: 'capitalize',
    borderColor: 'primaryGreyishTints.100',
    fontSize: '12px',
    padding: '10px',
    color: '#000',
    '&:hover': {
      color: '#fff',
    },
  },
};
