import { log } from '@hc/logger';
import { useRouteError } from 'react-router-dom';

export default function ErrorBoundary() {
  const error = useRouteError();

  if (
    error?.message &&
    error.message.includes('Failed to fetch dynamically imported module')
  ) {
    log('info', 'Error Occured - Failed to fetch dynamically imported module');
    return window.location.reload();
  }

  log('error', error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
