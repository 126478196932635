/* eslint-disable no-sparse-arrays */
// import { envConfig } from '@hc/config';
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, localStorageKeys, queryClient } from '@hc/utils';
import axios from 'axios';
import toast from 'react-hot-toast';
import create from 'zustand';

const activityInitialData = {
  id: 0,
  activity_name: '',
  activity_image: '',
  category_id: 0,
  goal_id: 0,
  is_measurable: true,
  quantity: '',
  unit: 1,
  activity_time: 0,
  specific_time: {
    from: '',
    to: '',
  },
  activity_days: {},
  activity_notes: {},
  activity_dont: {},
  activity_references: [],
  activity_days_data: 0,
  request_Status: {},
  error: {
    activity_name: '',
    category_id: '',
    goal_id: '',
    is_measurable: '',
    activity_time: '',
  },
};

const LinkData = {
  id: 0,
  linkName: '',
  url: '',
  imageUrl: '',
};

export const constructExpertData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((val) => {
      return {
        value: val,
        id: val?.id ?? 0,
        activity_name: val?.activity_name ?? '',
        status: val?.status ?? false,
        category_name: val?.category_name ?? '',
        created_at: val?.created_at ?? '',
        updated_at: val?.updated_at ?? '',
        expert_name: val?.expert_name ?? '',
      };
    });
    return arr;
  }
};

export const useExpertTemplate = create((set, get) => ({
  activity: activityInitialData,
  expertTemplateListData: [],
  expertTemplateListCount: 0,
  loading: null,
  error: null,
  Link: [{ ...LinkData }],
  linkloading: false,

  addLink: () => {
    const { Link } = get();
    set({ Link: [...Link, { ...LinkData, id: Link.length }] });
  },
  handelLinkChange: async (index, key, value) => {
    const { Link, getLinkPreview } = get();
    Link[index][key] = value;
    if (key === 'url') {
      const image = await getLinkPreview(value);
      Link[index].imageUrl = image;
    }
    set({ Link });
  },

  getLinkPreview: async (link) => {
    try {
      return axios
        .post('https://api.linkpreview.net', {
          q: link,
          key: '02030c9c3f7d8a9a5df14c65dbca79ff',
        })
        .then((resp) => {
          set(() => ({
            linkloading: false,
          }));
          return resp.data.image;
        });
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  // GET ALL ACTIVITY TEMPLATE DATA
  getAllExpertTemplateData: async (search = '', limit = 10, offset = 0) => {
    try {
      set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getAllExpertTemplateData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplateRequest/get-all-activity-request`,
            {
              search,
              offset,
              limit,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        set(() => ({
          loading: false,
          expertTemplateListData:
            Array.isArray(data?.data) && data?.data?.length > 0
              ? constructExpertData(data?.data)
              : [],
          expertTemplateListCount: data?.count ?? 0,
        }));
      }

      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  activityStatusUpdate: async (payload) => {
    try {
      set({ loading: true });
      const payloadData = {
        activity_id: payload?.id ?? 0,
        status: payload?.status ?? true,
      };
      queryClient.invalidateQueries({
        queryKey: ['activity_status_update'],
      });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['activity_status_update', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplate/upsert-activity-status`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'Activity Status Updated Successfully'
        );
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  initialExpertActivityData: async (activityId) => {
    try {
      queryClient.invalidateQueries({
        queryKey: ['initialExpertData'],
      });

      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['initialExpertData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplateRequest/get-activity-request`,
            {
              id: activityId,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        set(() => ({
          loading: false,
          activity:
            Object.keys(data.activity_request_data).length > 0
              ? {
                  id: data?.activity_request_data.id ?? null,
                  activity_name:
                    data?.activity_request_data?.activity_name ?? null,
                  activity_image:
                    data?.activity_request_data?.activity_image ?? null,
                  category_id: data?.activity_request_data?.category_id ?? null,
                  goal_id: data?.activity_request_data?.goal_id ?? null,
                  is_measurable:
                    data?.activity_request_data?.is_measurable ?? null,
                  quantity: data?.activity_request_data?.quantity ?? null,
                  unit: data?.activity_request_data?.unit ?? null,
                  activity_time:
                    data?.activity_request_data?.activity_time ?? null,
                  specific_time:
                    {
                      from:
                        data?.activity_request_data?.specific_time?.from ??
                        null,
                      to:
                        data?.activity_request_data?.specific_time?.to ?? null,
                    } ?? null,
                  activity_days:
                    data?.activity_request_data?.activity_days?.map(Number) ??
                    null,
                  activity_notes:
                    data?.activity_request_data?.activity_notes ?? {},
                  activity_dont:
                    data?.activity_request_data?.activity_dont ?? {},
                  activity_references:
                    data?.activity_request_data?.activity_references ?? {},
                  activity_days_data: data?.activity_days_data ?? null,
                  request_Status:
                    data?.activity_request_data
                      ?.master_mbf_activity_template_request_status ?? {},
                }
              : [],
          Link: data?.activity_request_data?.activity_references ?? [],
        }));

        return data?.activity_request_data;
      }

      return set(() => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  handleActivityFormChange: (key, value) => {
    const { activity } = get();

    set({
      activity: {
        ...activity,
        [key]: value,
      },
    });
  },
  handleActivitySpecificChange: (key, value) => {
    const { activity } = get();
    const { specific_time } = activity;
    set({
      activity: {
        ...activity,
        specific_time: {
          ...specific_time,
          [key]: value,
        },
      },
    });
  },

  handleActivityNotesChange: (Key, editor) => {
    const { activity } = get();
    set({
      activity: {
        ...activity,
        [Key]: editor,
      },
    });
  },

  approveActivityData: async () => {
    const { Link } = get();
    try {
      const { activity } = get();
      set({ loading: true });
      const payloadData = {
        id: activity.id || '',
        activity_name: activity?.activity_name ?? '',
        activity_image: activity?.activity_image ?? '',
        category_id: activity?.category_id ?? '',
        goal_id: activity?.goal_id ?? '',
        is_measurable: activity?.is_measurable ?? '',
        quantity: activity?.quantity ?? '',
        unit: activity?.unit ?? 1,
        activity_time: activity?.activity_time ?? '',
        specific_time:
          {
            from: activity?.specific_time.from ?? '',
            to: activity?.specific_time.to ?? '',
          } ?? null,
        activity_days: activity?.activity_days?.map(Number) ?? [],
        activity_notes: activity?.activity_notes ?? [],
        activity_dont: activity?.activity_dont ?? [],
        activity_references: Link ?? [],
      };
      queryClient.invalidateQueries({
        queryKey: ['approve-activity'],
      });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['approve-activity'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplateRequest/approve-activity-request`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        if (data?.status?.message === 'Activity Name Already Exist') {
          toast.error(data?.status?.message);
          set({ loading: false });
          return 201;
        }
      }
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ??
            `Activity ${activity.id ? 'approve' : 'approve'} Successfully`
        );
        set({ loading: false });

        return 200;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  rejectActivityData: async () => {
    try {
      const { activity } = get();
      set({ loading: true });
      const payloadData = {
        id: activity.id || '',
      };
      queryClient.invalidateQueries({
        queryKey: ['reject-activity'],
      });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['reject-activity'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplateRequest/reject-activity-request`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        toast.success(data?.status?.message ?? `Activity rejected`);
        set({ loading: false });
        return 200;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  expertPictureUpsert: async (event, user_id) => {
    const { updateExpertProfilePicture } = get();

    try {
      event.preventDefault();
      const formdata = new FormData();
      const files = event.target.files?.[0];
      formdata.append('file', files);
      formdata.append('id', user_id);
      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(localStorageKeys?.authToken) ?? ''
          }`,
        },
      };
      const uploads = await fetch(
        `${envConfig.api_url}/userprofiles/upsert-profile-picture`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => result);
      set({ loading: false });

      if (uploads?.status?.code === 200) {
        await updateExpertProfilePicture(uploads?.data?.profile_pic);
      } else updateExpertProfilePicture('');

      // return uploads;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  // UPDATE PROFILE PICTURE
  updateExpertProfilePicture: (profile_pic_url) => {
    const { activity } = get();
    set({
      activity: {
        ...activity,
        activity_image: profile_pic_url,
      },
    });
  },
  deleteProfileImage: async (id) => {
    try {
      set({ loading: true });
      const response = await httpRequest(
        'post',
        `${envConfig.api_url}/userprofiles/delete-profile-picture`,
        {
          user_id: id,
        },
        true
      );
      if (response.data?.status?.code === 200) {
        set({
          activity: {
            ...get().activity,
            activity_image: '',
          },
          loading: false,
        });
        toast.success(response?.data?.status?.message ?? '');
        return response;
      }
    } catch (error) {
      set({ loading: false });
      // eslint-disable-next-line no-undef
      log('error', err);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  clearActivity: () => {
    set({
      activity: JSON.parse(JSON.stringify(activityInitialData)),
      Link: [{ ...LinkData }],
    });
  },
  clearReference: () => {
    set({
      Link: [{ ...LinkData }],
    });
  },
  clearNotes: () => {
    set({
      activity: {
        ...get().activity,
        activity_notes: [],
      },
    });
  },
  clearDonts: () => {
    set({
      activity: {
        ...get().activity,
        activity_dont: [],
      },
    });
  },
  clearUnits: () => {
    set({
      activity: {
        ...get().activity,
        quantity: '',
        unit: 1,
      },
    });
  },
}));
