import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from '../../../atoms';
import { addNotes_style } from './style';

function AddNotes(props) {
  const {
    rootStyle = {},
    value,
    handleChanges = () => {},
    HandleSubmit = () => {},
    closeNotesDrawer = () => {},
  } = props;
  return (
    <Box
      sx={{
        ...addNotes_style.rootSx,
        ...rootStyle,
      }}
    >
      <Box sx={addNotes_style.boxRootSx} height="68vh" pb={4}>
        <ReactQuill theme="snow" value={value} onChange={handleChanges} />
      </Box>
      <Grid
        container
        columnSpacing={1}
        sx={{
          mt: 8,
          position: 'fixed',
          inset: 'auto',
          width: '400px',
        }}
      >
        <Grid item xs={6} pt={2}>
          <Button
            buttonStyle={addNotes_style.SaveButtonSx}
            onClick={HandleSubmit}
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={6} pt={2}>
          {' '}
          <Button
            buttonStyle={addNotes_style.cancleButtonSx}
            onClick={closeNotesDrawer}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

AddNotes.propTypes = {
  rootStyle: PropTypes.object,
};

export { AddNotes };
