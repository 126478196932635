/* eslint-disable react/no-unstable-nested-components */
import { getDateFormat } from '@hc/dayjs';
import { UseOrganizationData } from '@hc/store/backoffice/organizationMaster';
import { UsePlanStatus } from '@hc/store/backoffice/planStatus';
import { UsePlanStatusMasterData } from '@hc/store/backoffice/planStatusMaster';
import {
  EditIcon,
  Input,
  Label,
  SearchField,
  SearchIcon,
  TransactionFilterIcon,
} from '@hc/ui/atoms';
import { ConfigurationModal, TagsToggleButton } from '@hc/ui/components';
import { MasterTable } from '@hc/ui/components/backoffice';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { PlanChangeRequestDrawer } from '@hc/ui/components/backoffice/planChangeRequestDrawer';
import { PlanStatusFilterDrawer } from '@hc/ui/components/backoffice/planStatusFilterDrawer';
import { ProfileCard } from '@hc/ui/components/backoffice/profileCard';
import { StepperDrawer } from '@hc/ui/components/backoffice/stepperDrawer';
import { TransactionsCard } from '@hc/ui/components/backoffice/transactionsCard';
import { getFirstLetterOfName, queryClient } from '@hc/utils';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Popper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BiChevronDown, BiSearch } from 'react-icons/bi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PlanStatus_style } from './style';

export default function PlanStatusPage(props) {
  const { className = '', ...rest } = props;

  const expertTypeData = [
    {
      value: 1,
      name: 'Mind',
      selected: false,
    },

    {
      value: 2,
      name: 'Body',
      selected: false,
    },
    {
      value: 3,
      name: 'Food',
      selected: false,
    },
  ];

  const [buttons, setButtons] = useState(expertTypeData);

  //   ------------------------LOCAL STATE---------------------------------
  const [drugDrawerOpen, setDrugDrawerOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [orgValue, setorgValue] = useState(null);
  const [dropDownOpen, setdropDownOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [stepperDrawerOpen, setStepperOpen] = useState(false);
  const [planChangeRequestDrawerOpen, setPlanChangeRequestDrawerOpen] =
    useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const [showAssignExpert, setShowAssignExpert] = useState(false);

  const [selectExpertData, setSelectExpertData] = useState({
    categoryId: null,
    error: '',
    expertData: null,
  });

  const location = useLocation();

  //   -------------------------STORE---------------------------------------

  const {
    getAllPlanStatusData,
    planStatusListData,
    planCardData,
    handlePlanChange,
    planStatusCount,
    getPlanChangeRequestStatusTrackData,
    planChangeRequestStatusTrackData,
    updatePlanChangeRequestData,
    ClosePlanChangeRequest,
    GetPlanChangeRequestData,
    planChangeRequestData,
    planChangeRequestStatusData,
    planStatusChangeRequestHistoryData,
  } = UsePlanStatus((state) => ({
    getAllPlanStatusData: state.getAllPlanStatusData,
    planStatusListData: state.planStatusListData,
    planCardData: state.planCardData,
    handlePlanChange: state.handlePlanChange,
    planStatusCount: state.planStatusCount,
    getPlanChangeRequestStatusTrackData:
      state.getPlanChangeRequestStatusTrackData,
    planChangeRequestStatusTrackData: state.planChangeRequestStatusTrackData,
    updatePlanChangeRequestData: state.updatePlanChangeRequestData,
    ClosePlanChangeRequest: state.ClosePlanChangeRequest,
    GetPlanChangeRequestData: state.GetPlanChangeRequestData,
    planChangeRequestData: state.planChangeRequestData,
    planChangeRequestStatusData: state.planChangeRequestStatusData,
    planStatusChangeRequestHistoryData:
      state.planStatusChangeRequestHistoryData,
  }));

  const { getOrganizationData, OrganizationData } = UseOrganizationData(
    (state) => ({
      getOrganizationData: state.getOrganizationData,
      OrganizationData: state.OrganizationData,
    }),
  );

  const { planFilterStatus, planFilterType } = UsePlanStatus((state) => ({
    planFilterStatus: state.planFilterStatus,
    planFilterType: state.planFilterType,
  }));

  const { getPlanStatusData } = UsePlanStatusMasterData((state) => ({
    getPlanStatusData: state.getPlanStatusData,
  }));

  const [select, setSelect] = React.useState(OrganizationData[0]?.value);
  const [selectedLabel, setSelectedLabel] = useState(
    OrganizationData[0]?.label,
  );
  // const handleChange = async (value) => {
  //   if (value.value === 'user') {
  //     handlePlanChange('orgSearch', value.value);
  //     setSelect(value.value);
  //     setdropDownOpen(false);
  //   } else if (value.value === 'All') {
  //     setSelect(value.value);
  //     handlePlanChange('orgSearch', '');
  //   }
  //   queryClient.invalidateQueries({
  //     queryKey: ['planstatusData'],
  //   });
  //   await getAllPlanStatusData('');
  // };
  const handleOnSelectClose = (e) => {
    if (e.target.classList[0] === 'MuiBackdrop-root') {
      setdropDownOpen(false);
    }
  };
  // -----------------------------Pagination State ---------------------------

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });
    await getAllPlanStatusData(search, '', limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });
    await getAllPlanStatusData(search, '', rowsPerPage, offset);
  };

  // -----------------------------AutoComplete State Management-----------------------------------

  const handleAutoComplete = async (value) => {
    if (value.value === 'user') {
      handlePlanChange('orgSearch', value.value);
      setSelect(value.value);
      setSelectedLabel(value.label);
      setdropDownOpen(false);
    } else if (value.value === 'All') {
      setSelect(value.value);
      setSelectedLabel(value.label);
      handlePlanChange('orgSearch', '');
      setdropDownOpen(false);
    } else {
      setSelect('All');
      setSelectedLabel(value.label);
      handlePlanChange('orgSearch', value.value);
      setorgValue(value);
      setdropDownOpen(false);
      setorgValue(null);
    }

    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });

    await getAllPlanStatusData('');
  };

  const handlerRenderOrg = (value) => {
    setorgValue(value);
  };

  // ----------------------------------------Drawer Cl;ose-----------------------------
  const closeDrugMasterDrawer = () => {
    setDrugDrawerOpen(false);
  };

  // ----------------------------TABLE DATA AND HIGHLIGHTER--------------------------------------------
  const statusHighLighter = (value) => {
    return {
      backgroundColor:
        value === 'Publish'
          ? '#E8FCF0'
          : value === 'Draft'
          ? '#FCF8E8 '
          : value === 'pending'
          ? '#F5F5F5   '
          : '#fff',
      color:
        value === 'Publish'
          ? '#4CAF50'
          : value === 'Draft'
          ? '#FACC15'
          : value === 'pending'
          ? '#5C6266 '
          : '#0E1824',
    };
  };

  const logClick = async (mbf_user_plan_id) => {
    queryClient.invalidateQueries({
      queryKey: ['getPlanChangeRequestStatusTrackData'],
    });
    const resCode = await getPlanChangeRequestStatusTrackData(mbf_user_plan_id);
    if (resCode === 200) {
      setStepperOpen(true);
    }
  };

  // ---------------------------------------- Stepper Drawer Close-----------------------------
  const closeStepperDrawer = () => {
    setStepperOpen(false);
  };

  // ----------------------------------------Drug Audit Stepper Drawer Close-----------------------------
  const closePlanChangeRequestDrawer = () => {
    setSelectExpertData({ categoryId: null, error: '', expertData: null });
    setPlanChangeRequestDrawerOpen(false);
    //
    setShowAssignExpert(false);
    setIsModalOpen(false);
    setIsAssignModalOpen(false);
  };

  const closeCRFnc = () => {
    setIsModalOpen(true);
  };

  const updatePlanChangeRequest = () => {
    if (selectExpertData?.expertData?.userProfileId) {
      setIsAssignModalOpen(true);
    } else {
      setSelectExpertData({
        ...selectExpertData,
        error: 'Please assign expert!',
      });
    }
  };

  const closeCRFunction = async () => {
    queryClient.invalidateQueries({
      queryKey: ['closePlanChangeRequest'],
    });
    const payload = {
      mbf_user_plan_id: planChangeRequestData?.[0]?.id ?? '',
      plan_change_request_history_id:
        planStatusChangeRequestHistoryData?.[
          planStatusChangeRequestHistoryData?.length - 1
        ]?.id ?? '',
    };
    const resStatus = await ClosePlanChangeRequest(payload);

    if (resStatus?.code === 200) {
      toast.success(resStatus?.message);
      closePlanChangeRequestDrawer();
    } else {
      toast.error('Something went wrong please try again!');
    }
  };

  const closeCRModal = () => {
    setIsModalOpen(false);
  };

  const closeAssignModal = () => {
    setIsAssignModalOpen(false);
  };

  const assignToOtherExpert = () => {
    setShowAssignExpert(true);
  };

  const onEdit = async (mbf_user_plan_id) => {
    queryClient.invalidateQueries({
      queryKey: ['getPlanChangeRequestData'],
    });
    const resCode = await GetPlanChangeRequestData(mbf_user_plan_id);
    if (resCode === 200) {
      setPlanChangeRequestDrawerOpen(true);
    }
  };

  const handleButton = (buttonId) => {
    const obj = planChangeRequestStatusData?.find(
      (el) => el?.categoryId === buttonId,
    );
    setSelectExpertData({
      ...selectExpertData,
      categoryId: buttonId,
      expertData: obj,
      error: '',
    });
  };
  const closeAssignFunction = async () => {
    queryClient.invalidateQueries({
      queryKey: ['updatePlanChangeRequestData'],
    });
    const payload = {
      // user_id = "",
      mbf_user_plan_id: planChangeRequestData?.[0]?.id ?? '',
      plan_change_request_history_id:
        planStatusChangeRequestHistoryData?.[
          planStatusChangeRequestHistoryData?.length - 1
        ]?.id ?? '',
      expert_profile_id: selectExpertData?.expertData?.userProfileId ?? '',
      expert_category_name: `${selectExpertData?.expertData?.categoryName} Expert)`,
    };
    const resStatus = await updatePlanChangeRequestData(payload);
    if (resStatus?.code === 200) {
      toast.success(resStatus?.message);
      closePlanChangeRequestDrawer();
    } else {
      toast.error('Something went wrong please try again!');
    }
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            gap: 1.8,
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <Link
            id={`${params?.row?.id}`}
            onClick={() => logClick(params?.row?.id)}
            style={{ fontSize: '14px', color: '#007965', fontWeight: 500 }}
          >
            Log
          </Link>
          {params?.row?.change_requested_date && (
            <EditIcon id="editIcon" onClick={() => onEdit(params?.row?.id)} />
          )}
        </Box>
      ),
    },
    {
      field: 'user_name',
      headerName: 'User Name',
      width: 200,
    },
    {
      field: 'requested_date',
      headerName: 'Plan Request Date',
      width: 180,
      valueGetter: (params) =>
        getDateFormat(params?.row?.requested_date, 'DD/MM/YYYY'),
    },
    {
      field: 'plan_start_date',
      headerName: 'Plan Start Date',
      width: 180,
      valueGetter: (params) =>
        params?.row?.value?.plan_start_date
          ? getDateFormat(params?.row?.value?.plan_start_date, 'DD/MM/YYYY')
          : null,
    },
    {
      field: 'plan_end_date',
      headerName: 'Plan End Date',
      width: 180,
      valueGetter: (params) =>
        params?.row?.value?.plan_end_date
          ? getDateFormat(params?.row?.value?.plan_end_date, 'DD/MM/YYYY')
          : null,
    },

    {
      field: 'change_requested_date',
      headerName: 'Change Request Date',
      width: 180,
      valueGetter: (params) =>
        params?.row?.change_requested_date
          ? getDateFormat(params?.row?.change_requested_date, 'DD/MM/YYYY')
          : null,
    },

    {
      field: 'plan_type',
      headerName: 'Plan Type',
      width: 180,
    },

    {
      field: 'plan_status_name',
      headerName: 'Plan Status',
      width: 180,
    },
    {
      field: 'mind',
      headerName: 'Mind Status',
      width: 180,
      renderCell: (params) => (
        <Box sx={{ cursor: 'pointer' }}>
          <Typography sx={PlanStatus_style.expertfontSx}>
            {params?.row?.mindExpertName}
          </Typography>
          <Tooltip
            placement="bottom-start"
            title={`Last updated ${getDateFormat(
              params?.row?.mindLastUpdate,
              'lll',
            )}`}
            arrow
          >
            <Typography
              sx={{
                ...PlanStatus_style.statusTypo,
                ...statusHighLighter(params?.row?.mind),
              }}
            >
              {params?.row?.mind}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'body',
      headerName: 'Body Status',
      width: 180,
      renderCell: (params) => (
        <Box sx={{ cursor: 'pointer' }}>
          <Typography sx={PlanStatus_style.expertfontSx}>
            {params?.row?.bodyExpertName}
          </Typography>
          <Tooltip
            placement="bottom-start"
            title={`Last updated ${getDateFormat(
              params?.row?.bodyLastUpdate,
              'lll',
            )}`}
            arrow
          >
            <Typography
              sx={{
                ...PlanStatus_style.statusTypo,
                ...statusHighLighter(params?.row?.body),
              }}
            >
              {params?.row?.body}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'food',
      headerName: 'Food Status',
      width: 170,
      renderCell: (params) => (
        <Box sx={{ cursor: 'pointer' }}>
          <Typography sx={PlanStatus_style.expertfontSx}>
            {params?.row?.foodExpertName}
          </Typography>
          <Tooltip
            placement="bottom-start"
            title={`Last updated ${getDateFormat(
              params?.row?.foodLastUpdate,
              'lll',
            )}`}
            arrow
          >
            <Typography
              sx={{
                ...PlanStatus_style.statusTypo,
                ...statusHighLighter(params?.row?.food),
              }}
            >
              {params?.row?.food}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
  ];

  // -------------------TABLE DATA SEARCH------------------------------------

  const handleSearchFnc = async (newData) => {
    setSearch(newData);
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });

    await getAllPlanStatusData(newData);
  };

  const filterDrawerOpen = () => {
    UsePlanStatus.setState({
      planStatus: planFilterStatus,
      planType: planFilterType,
    });
    setDrugDrawerOpen(true);
  };

  //   --------------------------------INITIAL FETCH-------------------------------

  const initialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });
    await getAllPlanStatusData('');
    getOrganizationData();
    getPlanStatusData();
  };

  const requestDta = async () => {
    queryClient.invalidateQueries({
      queryKey: ['planstatusData'],
    });

    if (location.state.id) {
      await getAllPlanStatusData(search, location.state.id);
      onEdit(location.state.id);
      window.history.replaceState({}, '');
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    requestDta();
  }, [location]);

  console.log(planStatusListData);
  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{
        overflow: 'scroll',
        backgroundColor: '#F8F8F9',
        height: '92vh',
      }}
    >
      <Box>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {' '}
          <Typography sx={PlanStatus_style.statsFont}>Stats</Typography>
          <Box display="flex">
            <Typography
              mt={2}
              ml={4}
              fontSize={14}
              fontWeight={500}
              sx={{ cursor: 'pointer' }}
              color="#007965"
              onClick
            >
              {selectedLabel}
            </Typography>
            <Select
              id="select"
              value={select}
              onClose={(e) => handleOnSelectClose(e)}
              open={dropDownOpen}
              onOpen={() => setdropDownOpen(true)}
              MenuProps={{
                PaperProps: {
                  sx: { ...PlanStatus_style.selectPaperPropsSx },
                },
              }}
              IconComponent={BiChevronDown}
              sx={PlanStatus_style.selectMenuSx}
            >
              {/* {selection?.map((val, i) => (
              <MenuItem
                key={i}
                onClick={() => handleChange(val)}
                value={val?.value}
                sx={PlanStatus_style.menuListSx}
              >
                {select === val?.value ? <TickIcon /> : null}
                &nbsp; {val?.label}
              </MenuItem>
            ))} */}
              <Stack sx={{ height: '250px', overflow: 'hidden' }}>
                {/* <Divider sx={{ my: 1 }} /> */}
                <Box px={2}>
                  <Autocomplete
                    // data-testId='search2'
                    getOptionLabel={(option) => option?.label}
                    value={orgValue}
                    onChange={(e, value) => handleAutoComplete(value)}
                    open
                    popupIcon=""
                    sx={{ width: 300 }}
                    disablePortal
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          height: '10px',
                          ...PlanStatus_style.textFieldSx,
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <Typography
                          data-testId={`plan-${option?.label.replaceAll(
                            ' ',
                            '-',
                          )}`}
                          {...props}
                          sx={PlanStatus_style.dropdownTypoSx}
                          onChange={(e, option) => handlerRenderOrg(option)}
                        >
                          {' '}
                          {option?.label}
                        </Typography>
                      );
                    }}
                    PopperComponent={({ style, ...props }) => (
                      <Popper
                        popperOptions={{
                          placement: 'bottom-start',
                        }}
                        {...props}
                        sx={{
                          ...style,
                          '& ::-webkit-scrollbar': {
                            display: 'none',
                          },
                          '& .MuiPaper-root': {
                            mt: 0.5,
                            pb: 0.5,
                            height: '190px',
                            overflow: 'scroll',
                          },
                        }}
                      />
                    )}
                    options={OrganizationData}
                  />
                </Box>
              </Stack>
            </Select>
          </Box>
        </Stack>
        <Grid container spacing={2}>
          {planCardData.map((data, i) => (
            <Grid item xs={3} key={i} height="100%">
              {' '}
              <TransactionsCard
                percentage={data?.percentage?.slice(1)}
                description={data?.description}
                amount={data?.amount}
                positive={
                  Number(data?.percentage?.slice(0, -1)) > 0 ? true : false
                }
                showPercentage={data.percentage ? true : false}
                colorCode={data?.color ?? '#007965'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={4}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box>
            <Typography sx={PlanStatus_style.statsFont}>Plan Status</Typography>
          </Box>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <SearchField
              searchFieldStyle={PlanStatus_style.searchFieldSx}
              placeholder="Search by User or Expert Name"
              onSearch={search}
              setOnSearch={handleSearchFnc}
              startAdornment={
                <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                  <BiSearch sx={{ fontSize: '14px', width: '14px' }} />
                </IconButton>
              }
            />

            <Box
              data-testId="filter"
              sx={PlanStatus_style.filterContainerSx}
              onClick={() => filterDrawerOpen()}
            >
              <TransactionFilterIcon />
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Box mt={3}>
        <MasterTable
          columns={columns}
          data={planStatusListData}
          customTableStyle={PlanStatus_style.dataTableCustomSx}
          hideFooter
          rootStyle={{ height: '68vh' }}
          rowHeight={60}
        />
        <Stack flexDirection="row" justifyContent="end" sx={{ width: '100%' }}>
          <Pagination
            totalCount={planStatusCount}
            onLimitChange={onLimitChange}
            onOffsetchange={onOffsetchange}
            offset={offset}
            rowsPerPage={rowsPerPage}
          />
        </Stack>
      </Box>
      <PlanStatusFilterDrawer
        drugDrawerOpen={drugDrawerOpen}
        closeDrugMasterDrawer={closeDrugMasterDrawer}
      />

      <StepperDrawer
        headerName="MBF User Plan Status Log"
        stepperDrawerOpen={stepperDrawerOpen}
        closeStepperDrawer={closeStepperDrawer}
        stepperData={
          Array.isArray(planChangeRequestStatusTrackData) &&
          planChangeRequestStatusTrackData?.length > 0
            ? planChangeRequestStatusTrackData
            : []
        }
      />

      <PlanChangeRequestDrawer
        assignCR={
          planStatusChangeRequestHistoryData?.[
            planStatusChangeRequestHistoryData?.length - 1
          ]?.changeRequestStatus
            ? planStatusChangeRequestHistoryData?.[
                planStatusChangeRequestHistoryData?.length - 1
              ]?.changeRequestStatus
            : ''
        }
        showAssignExpert={showAssignExpert}
        drawerOpen={planChangeRequestDrawerOpen}
        loading={false}
        header="Plan Change Request"
        closeDrawer={closePlanChangeRequestDrawer}
        updatePlanChangeRequest={updatePlanChangeRequest}
        closeCRFnc={closeCRFnc}
        // eslint-disable-next-line react/no-children-prop
        children={
          <Box width={328}>
            <Grid>
              <Grid pb={1.5} item md={12} lg={12} xl={12}>
                <ProfileCard
                  name={planChangeRequestData?.[0]?.userName}
                  gender={planChangeRequestData?.[0]?.gender}
                  age={planChangeRequestData?.[0]?.age}
                  src={planChangeRequestData?.[0]?.userProfilePic}
                  profileName={getFirstLetterOfName(
                    planChangeRequestData?.[0]?.userName,
                  )}
                  mobileNumber={`${planChangeRequestData?.[0]?.mobileNoCountryCode} ${planChangeRequestData?.[0]?.mobileNo}`}
                  emailId={planChangeRequestData?.[0]?.emailId}
                  rootStyle={{ my: 1.5 }}
                />
              </Grid>

              {planStatusChangeRequestHistoryData?.[
                planStatusChangeRequestHistoryData?.length - 1
              ]?.changeRequestStatus ===
                'Plan change request raised by the user' ||
              planStatusChangeRequestHistoryData?.[
                planStatusChangeRequestHistoryData?.length - 1
              ]?.changeRequestStatus ===
                'Plan updated by the expert based on the MBF user change request' ? (
                <Grid>
                  <Grid pb={1.5} item md={12} lg={12} xl={12}>
                    <Label htmlFor="Description">
                      Change Request Description
                    </Label>
                    <Input
                      id="Description"
                      multiline
                      textFieldStyle={{ marginTop: '4px' }}
                      value={
                        planStatusChangeRequestHistoryData?.[
                          planStatusChangeRequestHistoryData?.length - 1
                        ]?.description ?? ''
                      }
                      fullWidth
                      isReadOnly
                    />
                  </Grid>

                  <Grid pb={1.5} item md={12} lg={12} xl={12}>
                    {planStatusChangeRequestHistoryData?.[
                      planStatusChangeRequestHistoryData?.length - 1
                    ]?.changeRequestStatus ===
                      'Plan updated by the expert based on the MBF user change request' &&
                      !showAssignExpert && (
                        <>
                          <Typography
                            sx={{
                              ...PlanStatus_style.stepPointsHeadingSx,
                            }}
                            backgroundColor="#E8FCF0"
                            color="#4CAF50"
                          >
                            {
                              planStatusChangeRequestHistoryData?.[
                                planStatusChangeRequestHistoryData?.length - 1
                              ]?.changeRequestStatus
                            }
                          </Typography>
                          <Typography
                            sx={PlanStatus_style.showAssignExpertTextSx}
                            onClick={() => assignToOtherExpert()}
                          >
                            {' '}
                            Assign to other experts?
                          </Typography>
                        </>
                      )}
                  </Grid>

                  {planStatusChangeRequestHistoryData?.[
                    planStatusChangeRequestHistoryData?.length - 1
                  ]?.changeRequestStatus ===
                    'Plan change request raised by the user' ||
                  showAssignExpert ? (
                    <>
                      <Grid pb={1.5} item md={12} lg={12} xl={12}>
                        <Label isRequired>Assign to Expert</Label>
                        <TagsToggleButton
                          dataTestId="goal"
                          RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                          options={buttons}
                          value={selectExpertData?.categoryId}
                          isError={selectExpertData?.error?.length > 0}
                          errorMessage={selectExpertData?.error}
                          buttonactiveStyle={
                            PlanStatus_style.togglebuttonactiveSX
                          }
                          buttoninactiveStyle={
                            PlanStatus_style.togglebuttoninactiveSX
                          }
                          valueChange={(e) => handleButton(e)}
                        />
                      </Grid>

                      {typeof selectExpertData?.expertData === 'object' &&
                        !Array.isArray(selectExpertData?.expertData) &&
                        selectExpertData?.expertData !== null && (
                          <Grid pb={1.5} item md={12} lg={12} xl={12}>
                            <ProfileCard
                              name={selectExpertData?.expertData?.userName}
                              src={
                                selectExpertData?.expertData?.userProfilePicture
                              }
                              profileName={getFirstLetterOfName(
                                selectExpertData?.expertData?.userName,
                              )}
                              mobileNumber={`${selectExpertData?.expertData?.mobileNoCountryCode} ${selectExpertData?.expertData?.mobileNo}`}
                              emailId={selectExpertData?.expertData?.emailId}
                              rootStyle={{ my: 1.5 }}
                              expertCategory={
                                selectExpertData?.expertData?.categoryName
                              }
                            />
                          </Grid>
                        )}
                    </>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </Box>
        }
      />

      <ConfigurationModal
        isModalOpen={isModalOpen}
        handleClose={closeCRModal}
        handleDelete={closeCRFunction}
        title="Close Change Request"
        message="Are you sure you want to close this Change Request?"
        cancleLable="No"
        acceptLable="Yes"
        DeletebuttonStyle={PlanStatus_style.configurationButtonSx}
      />

      <ConfigurationModal
        isModalOpen={isAssignModalOpen}
        handleClose={closeAssignModal}
        handleDelete={closeAssignFunction}
        title="Assign Change Request"
        message="Are you sure you want to assign this Change Request to "
        cancleLable="No"
        acceptLable="Yes"
        name={`${selectExpertData?.expertData?.userName} (${selectExpertData?.expertData?.categoryName} Expert)`}
        DeletebuttonStyle={PlanStatus_style.configurationButtonSx}
      />
    </Box>
  );
}

PlanStatusPage.propTypes = {
  className: PropTypes.string,
};
