/* eslint-disable camelcase */
export const patient_report_card_style = {
  rootSx: {},
  outlinedBox: {
    border: '2px solid #DBEAE8',
    borderRadius: '8px',
    mb: 1,
  },
  activeOutlinedBox: {
    border: '2px solid ',
    borderColor: 'border.focus',
    borderRadius: '8px',
    mb: 1,
  },
  expandIconSx: {
    position: 'absolute',
  },
  ImageBoxSx: {
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  resImageBoxSx: {
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: '#F44F5A',
  },

  adSx: {
    mt: 3,
    height: '48vh',
  },
  editIconSx: {
    pr: 2,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
    mt: 2,
  },
  reportName: {
    fontSize: '14px',
    fontWeight: '600',
    width: '214px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    mt: 0.6,
  },
};
