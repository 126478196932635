import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from '../../atoms';
import { configurationModal_style } from './style';

function ConfigurationModal(props) {
  const {
    isModalOpen,
    handleClose = () => {},
    handleDelete = () => {},
    title = 'title',
    message = ' Are you sure you want to Logout?',
    cancleLable = 'CANCEL',
    acceptLable = 'LOGOUT',
    name = '',
    DeletebuttonStyle,
  } = props;

  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <Box sx={configurationModal_style.totalModalSx}>
        <Box sx={configurationModal_style.totalBoxSx}>
          <Box mt={2}>
            <Typography sx={configurationModal_style.titleSx}>
              {' '}
              {title}
            </Typography>

            <Typography sx={configurationModal_style.description}>
              {' '}
              {message} <span> {name}</span>
            </Typography>
            <Box
              mt={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <Button
                sx={configurationModal_style.closebuttonSx}
                onClick={handleClose}
              >
                {cancleLable}
              </Button>
              <Button
                sx={{
                  ...configurationModal_style.DeletebuttonSx,
                  ...DeletebuttonStyle,
                }}
                onClick={handleDelete}
              >
                {acceptLable}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

ConfigurationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  DeletebuttonStyle: PropTypes.object,
};

export { ConfigurationModal };
