import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructDrugData = (data) => {
  const allergiesTableData = data.map((val) => ({
    value: val,
    id: val?.id ?? 0,
    appointment_status: val?.appointment_status ?? '',
    updated_at: val?.updated_at ?? '',
    created_at: val?.created_at ?? '',
  }));
  return allergiesTableData;
};

export const useAppointmentStatuses = create((set) => ({
  appointmentStatusData: [],
  appointmentStatusCount: 0,
  loading: false,
  // ------------- STORE METHODS ------------------
  getAppointmentStatus: async (search = '', limit = 10, offset = 0) => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getAppointmentStatus', search, offset],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backofficeMaster/get-all-appointment-status`,
            {
              search,
              limit,
              offset,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        set(() => ({
          appointmentStatusData:
            Array.isArray(data?.data?.appointment_status_data) &&
            data?.data?.appointment_status_data?.length > 0
              ? constructDrugData(data?.data?.appointment_status_data)
              : [],
          appointmentStatusCount: data?.data?.appointment_status_count,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!'
      );
    }
  },
}));
