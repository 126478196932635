export const awarenessManagementTopContainer_style = {
  masterTextSx: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: 'medium',
    ml: 1.5,
  },
  uploadButtonSx: {
    display: 'flex',
    width: 128,
    height: 40,
    color: 'primary.main',
    border: '2px solid ',
    boderColor: 'primary.main',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  unSelectAvailabilityButtonSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'primaryTints.A100',
  },
  buttonSx: {
    color: 'primary.main',
    border: '2px solid',
    fontSize: '14px',
    fontWeight: 'medium',
    borderColor: 'primary.main',
    height: '40px',
    width: '128px',
    borderRadius: '4px',
    textTransform: 'capitalize',
  },
  addMasterSx: {
    textTransform: 'capitalize',
    height: '40px',
    width: '124px',
    borderRadius: '10px',
  },
  searchFieldSx: {
    width: '360px',
    borderRadius: '10px',
    '& .MuiOutlinedInput-notchedOutline': {
      px: 2,
      height: '44px',
      border: 'none',
    },

    '&.MuiFormControl-root': {
      backgroundColor: '#fff',
      pb: '4px',
    },
    ' & .MuiOutlinedInput-input': {
      color: 'text.primary',
      backgroundColor: '#fff',
      pt: 0.5,
    },
    '& .MuiOutlinedInput-root': {
      px: 0,
      pt: 0.7,
      backgroundColor: 'tranparent',
      color: '#025140',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: '#C5C5C5',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },
};
