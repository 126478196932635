import { getDateFormatToString } from '@hc/dayjs';
import { useActivityDayMaster } from '@hc/store/backoffice/activityDayMaster';
import { useActivityGoalMaster } from '@hc/store/backoffice/activityGoalMaster';
import { useActivityTemplate } from '@hc/store/backoffice/activityTemplate';
import { useActivityTimeMaster } from '@hc/store/backoffice/activityTimeMaster';
import { useActivityUnitMaster } from '@hc/store/backoffice/activityUnitMaster';
import { useCategoryMaster } from '@hc/store/backoffice/categoryMaster';
import { useExpertTemplate } from '@hc/store/backoffice/expertTemplate';
import { EditIcon, Switch } from '@hc/ui/atoms';
import { TableSkeletonLoader } from '@hc/ui/components';
import {
  AddActivityDrawer,
  MasterTable,
  MasterTopContainer,
} from '@hc/ui/components/backoffice';
import { AddExpertDrawer } from '@hc/ui/components/backoffice/addExpertDrawer';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ActivityTemplate_style } from './style';

export default function ActivityTemplates(props) {
  const { className = '', ...rest } = props;
  const [drugDrawerOpen, setDrugDrawerOpen] = useState(false);
  const [expertDrugDrawerOpen, setexpertDrugDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState(0);
  const [tab, setTab] = useState(0);
  const [showBtn, setshowBtn] = useState(true);
  const [ShowSearch, setShowSearch] = useState(true);
  const [adminSearch, setAdminSearch] = useState('');
  const [expertSearch, setExpertSearch] = useState('');

  const [adminOffset, setAdminOffset] = useState(0);
  const [adminRowsPerPage, setAdminRowsPerPage] = React.useState(10);
  const [expertOffset, setExpertOffset] = useState(0);
  const [expertRowsPerPage, setExpertRowsPerPage] = React.useState(10);

  const {
    getAllActivityTemplateData,
    activityTemplateListData,
    loading,
    activityStatusUpdate,
    initialEditActivityData,
    updateActivityPicture,
    clearActivity,
    activityTemplateListCount,
  } = useActivityTemplate((state) => ({
    getAllActivityTemplateData: state.getAllActivityTemplateData,
    loading: state.loading,
    activityTemplateListData: state.activityTemplateListData,
    activityStatusUpdate: state.activityStatusUpdate,
    initialEditActivityData: state.initialEditActivityData,
    updateActivityPicture: state.updateActivityPicture,
    clearActivity: state.clearActivity,
    activityTemplateListCount: state.activityTemplateListCount,
  }));

  const {
    getAllExpertTemplateData,
    expertTemplateListData,
    expertTemplateListCount,
    initialExpertActivityData,
    updateExpertProfilePicture,
  } = useExpertTemplate((state) => ({
    getAllExpertTemplateData: state.getAllExpertTemplateData,
    updateExpertProfilePicture: state.updateExpertProfilePicture,
    expertTemplateListData: state.expertTemplateListData,
    expertTemplateListCount: state.expertTemplateListCount,
    initialExpertActivityData: state.initialExpertActivityData,
  }));

  const { getCategoryData } = useCategoryMaster((state) => ({
    getCategoryData: state.getCategoryData,
  }));

  const { getActivityUnit } = useActivityUnitMaster((state) => ({
    getActivityUnit: state.getActivityUnit,
  }));
  const { getActivityDayData } = useActivityDayMaster((state) => ({
    getActivityDayData: state.getActivityDayData,
  }));
  const { getActivityTimeData } = useActivityTimeMaster((state) => ({
    getActivityTimeData: state.getActivityTimeData,
  }));
  const { getActivityGoalTagData } = useActivityGoalMaster((state) => ({
    getActivityGoalTagData: state.getActivityGoalTagData,
  }));

  const closeDrugMasterDrawer = () => {
    setDrugDrawerOpen(false);
  };
  const closeExpertDrawer = () => {
    setexpertDrugDrawerOpen(false);
  };

  // -------------------TABLE DATA SEARCH----------------
  const handleSearch = async (search) => {
    setAdminSearch(search);
    queryClient.invalidateQueries({
      queryKey: ['getAllActivityTemplateData'],
    });

    await getAllActivityTemplateData(search);
    setAdminOffset(0);
  };

  const handleExpertSearch = async (search) => {
    setExpertSearch(search);
    queryClient.invalidateQueries({
      queryKey: ['getAllExpertTemplateData'],
    });

    await getAllExpertTemplateData(search);
  };

  // -----------------ADD AND EDIT ACTIVITY -----------------------

  const handleTabChange = (value) => {
    setTab(value);
    if (value === 1) {
      setshowBtn(false);
    } else {
      setshowBtn(true);
    }

    if (value === 1) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  };

  const handleAddClick = () => {
    clearActivity();
    setDrawerType(0);
    setDrugDrawerOpen(true);
  };

  const onEdit = async (id) => {
    const activity = await initialEditActivityData(id);
    setDrugDrawerOpen(true);
    updateActivityPicture(activity.activity_image);
    setDrawerType(1);
  };

  const onExpertEdit = async (id) => {
    const activity = await initialExpertActivityData(id);
    setexpertDrugDrawerOpen(true);
    updateExpertProfilePicture(activity.activity_image);
    setDrawerType(1);
  };

  const onStatusChange = async (id, status) => {
    const payload = {
      id,
      status: !status,
    };
    const response = await activityStatusUpdate(payload);
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllActivityTemplateData'],
      });

      await getAllActivityTemplateData(
        adminSearch,
        adminRowsPerPage,
        adminOffset,
      );
    }
  };
  // ----------------------PAGINATION--------------------------------
  const onAdminLimitChange = async (limit, offset) => {
    setAdminRowsPerPage(limit);
    setAdminOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllActivityTemplateData'],
    });

    await getAllActivityTemplateData(adminSearch, limit, offset);
  };

  const onAdminOffsetchange = async (offset) => {
    setAdminOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllActivityTemplateData'],
    });

    await getAllActivityTemplateData(adminSearch, adminRowsPerPage, offset);
  };

  // -----------Expert Pagination ------------------------
  const onExpertLimitChange = async (limit, offset) => {
    setExpertRowsPerPage(limit);
    setExpertOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllExpertTemplateData'],
    });
    await getAllExpertTemplateData(expertSearch, limit, offset);
  };

  const onExpertOffsetchange = async (offset) => {
    setExpertOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllExpertTemplateData'],
    });
    await getAllExpertTemplateData(expertSearch, expertRowsPerPage, offset);
  };
  // ----------------------TABLE COLUMN HEAD DATA ---------------------
  const columns = [
    {
      field: 'activity_name',
      headerName: 'Activity Name',
      width: 300,
    },
    {
      field: 'category_name',
      headerName: 'Type',
      width: 110,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 270,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },

    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
    {
      field: 'is_active',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => (
        <Switch
          id="status5"
          checked={params?.row?.is_active}
          onChange={() =>
            onStatusChange(params?.row?.id, params?.row?.is_active)
          }
          rootStyle={ActivityTemplate_style.switchSx}
        />
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <Box sx={{ pl: 1 }} onClick={() => onEdit(params?.row?.id)}>
          <EditIcon id='edit5' style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];
  const expertcolumns = [
    {
      field: 'activity_name',
      headerName: 'Activity Name',
      width: 200,
    },
    {
      field: 'category_name',
      headerName: 'Type',
      width: 110,
    },
    {
      field: 'expert_name',
      headerName: 'Expert Name',
      width: 110,
    },

    {
      field: 'created_at',
      headerName: 'Created At',
      width: 270,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },

    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => (
        <Typography
          sx={{
            ...ActivityTemplate_style.transactionStatusSx,
            color:
              params?.row?.status === 'Approved'
                ? '#4CAF50'
                : params?.row?.status === 'Pending'
                ? '#FACC15'
                : params?.row?.status === 'Rejected'
                ? '#F44F5A '
                : '#0E1824',
          }}
        >
          {' '}
          {params?.row?.status}
        </Typography>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <Box sx={{ pl: 1 }} onClick={() => onExpertEdit(params?.row?.id)}>
          <EditIcon id='expertPlanEdit' style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];

  // --------------------INITAIL API CALL -------------------------
  const InitialData = async () => {
    await getAllActivityTemplateData();
  };

  useEffect(() => {
    InitialData();
    getCategoryData();
    getActivityUnit();
    getActivityDayData();
    getActivityTimeData();
    getActivityGoalTagData();
    getAllExpertTemplateData('');
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MasterTopContainer
              addMasterStyle={ActivityTemplate_style.addMasterSx}
              handleSearch={handleSearch}
              handleAddClick={handleAddClick}
              handleSecondSearch={handleExpertSearch}
              isTitileShow={false}
              isTabShow
              currentTab={handleTabChange}
              showSearchField={ShowSearch}
              showSecondSearchField={!ShowSearch}
              searchFieldPlaceholder="Search Activity name, type"
              SecondsearchFieldPlaceholder="Search Activity name, type"
              showAddButton={showBtn}
              addButtonName="Add new activity"
              count={
                tab === 0
                  ? activityTemplateListCount
                  : tab === 1
                  ? expertTemplateListCount
                  : 0
              }
            />
          </Grid>
          {tab === 0 && (
            <Grid item xs={12}>
              {loading ? (
                <Box>
                  <TableSkeletonLoader />
                </Box>
              ) : (
                <MasterTable
                  columns={columns}
                  data={activityTemplateListData ?? []}
                  customTableStyle={ActivityTemplate_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              )}
              <Stack
                flexDirection="row"
                justifyContent="end"
                sx={{ width: '100%' }}
              >
                <Pagination
                  totalCount={activityTemplateListCount ?? 0}
                  onLimitChange={onAdminLimitChange}
                  onOffsetchange={onAdminOffsetchange}
                  offset={adminOffset}
                  rowsPerPage={adminRowsPerPage}
                />
              </Stack>
            </Grid>
          )}

          {tab === 1 && (
            <Grid item xs={12}>
              {loading ? (
                <TableSkeletonLoader />
              ) : (
                <MasterTable
                  columns={expertcolumns}
                  data={expertTemplateListData ?? []}
                  customTableStyle={ActivityTemplate_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              )}
              <Stack
                flexDirection="row"
                justifyContent="end"
                sx={{ width: '100%' }}
              >
                <Pagination
                  totalCount={expertTemplateListCount ?? 0}
                  onLimitChange={onExpertLimitChange}
                  onOffsetchange={onExpertOffsetchange}
                  offset={expertOffset}
                  rowsPerPage={expertRowsPerPage}
                />
              </Stack>
            </Grid>
          )}
        </Grid>

        {/* Add Drug Drawer */}
        <Box>
          <AddActivityDrawer
            drugDrawerOpen={drugDrawerOpen}
            closeDrugMasterDrawer={closeDrugMasterDrawer}
            type={drawerType}
          />
        </Box>
        <Box>
          <AddExpertDrawer
            drugDrawerOpen={expertDrugDrawerOpen}
            closeDrugMasterDrawer={closeExpertDrawer}
            type={drawerType}
          />
        </Box>
      </Box>
    </Box>
  );
}

ActivityTemplates.propTypes = {
  className: PropTypes.string,
};
