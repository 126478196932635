/* eslint-disable import/order */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { backofficeRoutes } from '@hc/routes';
import { httpRequest } from '@hc/utils/axios';
import { localStorageKeys } from '@hc/utils/constants';
import { routeTo } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import create from 'zustand';
import { useRouting } from '../common/routing';

export const useBackofficeProfile = create((set, get) => ({
  user: {
    email: '',
    password: '',
    error: {
      email: '',
      password: '',
    },
  },
  loading: false,
  error: null,

  // SignIn
  signIn: async (payload) => {
    try {
      set({ loading: true });
      // Hitting the signin API

      const response = await httpRequest(
        'post',
        `${envConfig.auth_url_v1}/login/email`,
        {
          email: payload?.email ?? '',
          password: payload?.password ?? '',
        },
        false
      );
      // If the user is exists
      if (
        response?.data?.statusCode === '200' &&
        response?.data?.data?.auth_token
      ) {
        const token = response?.data?.data?.auth_token;

        localStorage.setItem(localStorageKeys.authToken, token);
        toast.success('Signed in successfully');
        set({ loading: false });
        return routeTo(useRouting, backofficeRoutes.userManagement);
      }
      toast.error(response?.data?.message);

      return set({ loading: false });
    } catch (err) {
      set({ loading: false });
      set({
        user: payload,
      });
      log('error', err);
      return toast.error(
        err?.response?.data?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  // updateOnboarding
  updateOnboarding: (key, value) => {
    const { user } = get();
    set({
      user: {
        ...user,
        [key]: value,
        error: {
          ...user.error,
          [key]: '',
        },
      },
    });
  },

  // updateErrorOnboarding
  updateErrorOnboarding: (error) => {
    const { user } = get();
    set({
      user: {
        ...user,
        error: {
          ...user.error,
          error,
        },
      },
    });
  },

  // updateState
  updateStateOnBoarding: () =>
    set(() => ({
      user: {
        email: '',
        password: '',
        error: {
          email: '',
          password: '',
        },
      },
      loading: false,
    })),
}));
