import { Box, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { tableSkeletonLoader_style } from './style';

function TableSkeletonLoader(props) {
  const { className = '', rootStyle = {}, ...rest } = props;

  return (
    <Box
      sx={{
        ...tableSkeletonLoader_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={tableSkeletonLoader_style.cardRootSx}>
        {Array(9)
          .fill(1)
          .map((val, i) => (
            <Box key={i} sx={tableSkeletonLoader_style.tableContentSx}>
              <Skeleton
                width={100}
                height={20}
                animation="wave"
                sx={{
                  ...tableSkeletonLoader_style.skeletonRoot,
                }}
              />
              <Skeleton
                width={60}
                height={20}
                animation="wave"
                sx={{
                  ...tableSkeletonLoader_style.skeletonRoot,
                }}
              />
              <Skeleton
                width={160}
                height={20}
                animation="wave"
                sx={{
                  ...tableSkeletonLoader_style.skeletonRoot,
                }}
              />
              <Skeleton
                width={160}
                height={20}
                animation="wave"
                sx={{
                  ...tableSkeletonLoader_style.skeletonRoot,
                }}
              />
              <Skeleton
                width={60}
                height={20}
                animation="wave"
                sx={{
                  ...tableSkeletonLoader_style.skeletonRoot,
                }}
              />
              <Skeleton
                width={60}
                height={20}
                animation="wave"
                sx={{
                  ...tableSkeletonLoader_style.skeletonRoot,
                }}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
}

TableSkeletonLoader.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { TableSkeletonLoader };
