export const backofficeRoutes = {
  login: '/login',
  drugMaster: '/drugMaster',
  activity: '/ActivityTemplates',
  plan: '/PlanTemplates',
  paymentSetup: '/paymentSetup',
  transactions: '/transactions',
  settlements: '/settlements',
  userManagement: '/usermanagement',
  planStatus: '/planStatus',
  organization: '/organization',
  organizationMember: '/organizationMember',
  contractDetails: '/contractDetails',
  planManagement: '/PlanManagement',
  awarenessManagement: '/awarenessManagement',
  awarenessCategoryManagement: '/awarenessCategoryManagement',
  patient: '/patient',
  masterManagement: '/masterManagement',
  feedsandBugs: '/feedsandBugs',
  parameter: '/parameter',
  drugAudit: '/drugAudit',
  seekHelp: '/seekHelp',
};
