export const drawer_style = {
  rootSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
  },
  headerSx: { p: 2 },
  childrenSx: { p: 2, pb: 3, overflow: 'overlay' },
  footerSx: {
    p: 3,
    bgcolor: 'common.white',
    borderTop: '1.3px dashed',
    borderColor: 'border.main',
    position: 'absolute',
    inset: 'auto 0 0 0',
  },
  closesSx: {
    width: '15px',
    cursor: 'pointer',
    height: '14px',
  },
  closeSx: {
    position: 'fixed',
    top: '2%',
    backgroundColor: '#fff',
    marginLeft: '-49px',
    padding: '8px',
    height: '36px',
    borderRadius: '50%',
    width: '36px',
    cursor: 'pointer',
  },
  rightClose: {
    padding: '12px',
    borderRadius: '50%',
    width: '40px',
    cursor: 'pointer',
    backgroundColor: '#ffe8e8',
    color: '#ff4b4b',
    height: '40px',
    margin: '10px 10px',
  },
  totalHeaderSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid',
    borderColor: 'border.main',
  },

  drawerCenterBoxSx: {
    bgcolor: '#FCF8E8',
    borderRadius: '50%',
    height: '56px',
    width: '56px',
    border: '2px solid #FBE27F',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    marginTop: '-30px',
    // marginLeft: '13px',
  },

  drawerCenterIconBoxSx: {
    height: '16px',
    width: '16px',
    borderRadius: '4px',
    bgcolor: '#FACC15',
    transform: 'rotate(45deg)',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  iconTextSx: {
    transform: 'rotate(-45deg)',
    mt: '-4px',
    mr: '4px',
  },
  drawerBoxSx: {
    bgcolor: '#FCF8E8',
    borderBottom: '2px solid #FBE27F',
    display: 'flex',
    justifyContent: 'center',
    height: '74px',
  },
};
