/* eslint-disable default-param-last */
/* eslint-disable consistent-return */
import axios from 'axios';
import { localStorageKeys } from './constants';

export function httpRequest(method, url, request = {}, includeToken) {
  const options = {
    ...request,
  };
  if (method === 'get') {
    const promise = axios[method](url, {
      ...(includeToken && {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(localStorageKeys?.authToken) ?? ''
          }`,
        },
      }),
    });
    return promise;
  }
  const promise = axios[method](
    url,
    {
      ...options,
    },
    {
      ...(includeToken && {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem(localStorageKeys?.authToken) ?? ''
          }`,
        },
      }),
    }
  );
  return promise;
}
