/* eslint-disable no-use-before-define */
import { Button, SearchField, Switch, Table } from '@hc/ui/atoms';
import { Box, IconButton, Stack, Typography, capitalize } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import { patient_style } from './style';
import { useAddPatients } from '@hc/store';
import { TableSkeletonLoader } from '@hc/ui/components';
import { AddPatientDrawer } from '@hc/ui/components/backoffice/addPatientDrawer';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { BiSearch } from 'react-icons/bi';
import { patient_style } from './style';

export default function Patient(props) {
  const { className = '', ...rest } = props;
  const [patientDrawerOpen, setPatientDrawerOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const {
    patientState,
    getPatientData,
    organizationPatientStatusUpdate,
    loading,
  } = useAddPatients((state) => ({
    organizationPatientStatusUpdate: state.organizationPatientStatusUpdate,
    patientState: state.patientState,
    getPatientData: state.getPatientData,
    loading: state.loading,
  }));

  const columns = [
    {
      field: 'first_name',
      headerName: 'Full Name',
      width: 250,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            {capitalize(params.row?.first_name ?? 'null')}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'email_id',
      headerName: 'Email',
      width: 280,
    },
    {
      field: 'mobile',
      headerName: 'Phone',
      width: 200,
    },

    {
      field: 'genderAge',
      headerName: 'Gender/Age',
      width: 220,
    },
    {
      field: 'user_status_id',
      headerName: 'Unblock/Block',
      // type: 'number',
      width: 200,

      renderCell: (params) => (
        <Box>
          <Switch
            id="toggle2"
            checked={params?.row?.user_status_id !== 1}
            onChange={() => onStatusChange(params)}
          />
        </Box>
      ),
    },
    // {
    //   field: 'consultationReports',
    //   headerName: 'Consultation Reports',
    //   width: 200,
    //   headerAlign: 'right',
    //   align: 'right',
    // },
  ];

  const closeDrugMasterDrawer = () => {
    setPatientDrawerOpen(false);
  };

  const initialData = async (search, rowsPerPage = 10, offset = 0) => {
    queryClient.invalidateQueries({
      queryKey: ['patients-get-all'],
    });
    await getPatientData(search, rowsPerPage, offset);
  };

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    initialData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    initialData(search, rowsPerPage, offset);
  };

  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    setOffset(0);
    initialData(searchValue);
  };

  const onStatusChange = async (data) => {
    queryClient.invalidateQueries({
      queryKey: ['backoffice-patients-status-update'],
    });
    const response = await organizationPatientStatusUpdate(data);
    if (response === 200) {
      initialData(search, rowsPerPage, offset);
    }
  };

  useEffect(() => {
    initialData(search, rowsPerPage, offset);
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Stack>
        <Stack flexDirection="row" justifyContent="space-between" my={3}>
          <Box>
            <Typography sx={patient_style.titleSx}>
              {`Patient Management (${patientState?.patientCount ?? 0})`}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto', marginRight: '20px' }}>
            <SearchField
              id="search"
              searchFieldStyle={patient_style.searchFieldSx}
              placeholder="Search by Name, Phone & Email"
              onSearch={search}
              setOnSearch={handleSearch}
              startAdornment={
                <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                  <BiSearch />
                </IconButton>
              }
            />
          </Box>
          <Box>
            <Button
              sx={patient_style.addtireBtnSx}
              onClick={() => setPatientDrawerOpen(true)}
            >
              Add patient
            </Button>
          </Box>
        </Stack>
        {loading ? (
          <Box>
            <TableSkeletonLoader />
          </Box>
        ) : (
          <>
            <Table
              customTableStyle={patient_style.dataTableCustomSx}
              rows={patientState?.patientList ?? []}
              columns={columns}
              rootStyle={{ height: '65vh', width: '100%' }}
              emptyState
              // pageSize={5}
              hideFooter
              disableColumnMenu
              autoHeight={patientState?.patientList?.length >= 0 ? false : true}
            />
            <Stack
              flexDirection="row"
              justifyContent="end"
              sx={{ width: '100%' }}
            >
              <Pagination
                totalCount={patientState?.patientCount ?? ''}
                onLimitChange={onLimitChange}
                onOffsetchange={onOffsetchange}
                offset={offset}
                rowsPerPage={rowsPerPage}
              />
            </Stack>
          </>
        )}
      </Stack>

      <AddPatientDrawer
        patientDrawerOpen={patientDrawerOpen}
        closePatientDrawer={closeDrugMasterDrawer}
      />
    </Box>
  );
}

Patient.propTypes = {
  className: PropTypes.string,
};
