export const searchField_style = {
  searchBoxSx: {
    position: 'relative',
    borderRadius: '0px',
    marginLeft: 0,
    display: 'inline-flex',
    p: 2,
  },
  searchFieldSx: {
    '&.MuiFormControl-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
      px: 0,
      pt: 0.7,
      backgroundColor: 'tranparent',
      color: '#025140',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '0px 4px 4px 0px',
      fontSize: '14px',
    },
  },
  searchInputSx: {
    padding: '0px 8px 4px 4px',
  },
};
