export const addContractDrawer_style = {
  rootSx: { p: 1 },
  inputGroupSx: { display: 'grid', gap: 0.5 },
  inputGroupSingleSx: { width: '300px' },
  contractContainer: {
    backgroundColor: '#FFFFFF',
    p: 3,
    borderRadius: '4px',

    overflow: 'scroll',
  },
  topbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexSX: { display: 'flex', gap: 1.5, alignItems: 'center' },
  labelSx: {
    color: 'text.label',
  },
  inputFieldSx: {
    marginTop: '4px',
  },
  AlignStraigntSX: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },

  linkWrapSx: {
    display: 'flex',
    gap: 0.8,
    alignItems: 'center',
  },
  linkSx: {
    color: '#2675D2',
    fontSize: '14px',
    fontWeight: 'normal',
  },
  UploadbtnSx: {
    bgcolor: 'primary.main',
    whiteSpace: 'nowrap',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    cursor: 'pointer',
    width: '150px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  SaveButtonSx: {
    bgcolor: 'primary.main',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleButtonSx: {
    paddingLeft: '41px',
    paddingRight: '37px',
    whiteSpace: 'nowrap',
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
};
