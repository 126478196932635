import { MenuItem, Select, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiArrowDownSFill } from 'react-icons/ri';
import { dropdown_style } from './style';

export function Dropdown(props) {
  const {
    rootStyle = {},
    value,
    optionListStyle = {},
    selectOption = [],
    isError,
    placeholder = '',
    onChange = () => {},
    className = '',
    disabled ,
    helperText = '',
    addDoctor = false,
    datatestid,
    ...rest
  } = props;

  return (
    <>
      <Select
        className={`${className}`}
        {...rest}
        sx={{ ...dropdown_style.rootSx, ...rootStyle }}
        labelId="demo-simple-select-label"
        id={`${datatestid}`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        // label={placeholder}
        fullWidth
        size="small"
        disabled={disabled}
        displayEmpty
        variant="outlined"
        error={isError}
        inputProps={{ 'aria-label': 'Without label' }}
        MenuProps={{
          sx: { ...dropdown_style.optionListSx, ...optionListStyle },
        }}
        IconComponent={RiArrowDownSFill}
      >
        {selectOption?.map((option, i) => (
          <MenuItem key={i} value={option?.value} id={`item${i}`}>
            {option?.label}
          </MenuItem>
        ))}
        {addDoctor && (
          <MenuItem key="addDoctor" value="addDoctor">
            <Typography sx={dropdown_style.text}>+ Add Doctor</Typography>
            <Typography sx={dropdown_style.subText}>
              Invite Doctor to the circle by providing their mobile nummber
            </Typography>
          </MenuItem>
        )}
      </Select>
      {/* Field required Message */}
      {helperText?.length > 0 && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {helperText}
        </Typography>
      )}
    </>
  );
}

Dropdown.propTypes = {
  rootStyle: PropTypes.object,
  optionListStyle: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  datatestid: PropTypes.string,
  selectOption: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  value: PropTypes.number,
  isError: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.object,
  sx: PropTypes.object,
  addDoctor: PropTypes.bool,
};
