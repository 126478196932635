export const feedbackDrawer_style = {
  boxRootSx: {
    display: 'grid',
    gap: 2,
    pt: 1,
    width: '350px',
  },
  drawerHeadSx: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#101010',
    textTransform: 'capitalize',
  },
  saveBtnSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  emojiContainer: {
    padding: 1,
    borderRadius: '100%',
    display: 'flex',
    placeItems: 'center',
  },
  title: { fontSize: '16px', fontWeight: 500 },
  content: {
    fontSize: '16px',
    fontWeight: 400,
    pl: 2,
  },
  customtextSx: {
    '& .MuiOutlinedInput-input': {
      height: '100px!important',
      fontSize: '16px',
      fontWeight: 500,
      color: '#0E1824',
      lineHeight: '25px',
      p: 0,
    },
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },
  labelSx: {
    color: 'text.label',
  },
  inputFieldSx: {
    marginTop: '4px',
  },
};
