import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { SearchSelect_style } from './style';

export function SearchSelect(props) {
  const {
    label = '',
    value = '',
    options = [],
    onChange = () => false,
    optionViewKey,
    textValue = '',
    autocompeleteSx = {},
    autocompeleteStyle = {},
    renderOption,
    isError,
    helperText,
    startAdornment,
    endAdornment,
    disableCloseOnSelect,
    onClick = () => {},
    filterOptions,
    placeholder = '',
    noOptionsText,
    onChangeText = () => {},
    TestId = 'autocomplete',
    loading,
  } = props;

  return (
    <Autocomplete
      id={TestId}
      size="small"
      options={options}
      value={value}
      onChange={onChange}
      autoComplete
      disableCloseOnSelect={disableCloseOnSelect}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option[optionViewKey]}
      sx={autocompeleteSx}
      noOptionsText={noOptionsText}
      ListboxProps={{
        sx: { ...SearchSelect_style.optionListSx },
      }}
      loading={loading}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          value={textValue}
          variant="outlined"
          onChange={onChangeText}
          onClick={onClick}
          error={isError}
          helperText={helperText}
          placeholder={placeholder}
          id={TestId}
          sx={{
            ...SearchSelect_style.autoListSx,
            ...autocompeleteStyle,
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="end" sx={{ ml: 0 }}>
                {startAdornment}
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    sx={SearchSelect_style.circularSx}
                    size={20}
                  />
                ) : null}
                <InputAdornment position="end" sx={{ ml: 0 }}>
                  {endAdornment}
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
}

SearchSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  textValue: PropTypes.string,
  autocompeleteSx: PropTypes.object,
  autocompeleteStyle: PropTypes.object,
  renderOption: PropTypes.element,
  isError: PropTypes.bool,
  helperText: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  optionViewKey: PropTypes.string,
  noOptionsText: PropTypes.node,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.node,
  placeholder: PropTypes.string,
  classes: PropTypes.object,
  sx: PropTypes.object,
  disableCloseOnSelect: PropTypes.bool,
  onClick: PropTypes.func,
  filterOptions: PropTypes.any,
  onChangeText: PropTypes.func,
  TestId: PropTypes.string,
  loading: PropTypes.bool,
};
