import { Button } from '@atoms';
import { Box, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { proceedModal_style } from './style';

function ProceedModal(props) {
  const {
    className = '',
    isModalOpen = false,
    handleClose = () => false,
    handleDelete = () => false,
    content = '',
    header = '',
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...proceedModal_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Modal open={isModalOpen} onClose={handleClose} disableBackdropClick>
        <Box sx={proceedModal_style.totalModalSx}>
          <Box sx={proceedModal_style.totalBoxSx}>
            <Box mt={2}>
              {header ?? ''}
              {content ?? ''}

              <Box
                mt={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end',
                  pb: 3,
                }}
              >
                <Button
                  sx={proceedModal_style.closebuttonSx}
                  onClick={() => handleClose()}
                >
                  CANCEL
                </Button>
                <Button
                  sx={proceedModal_style.DeletebuttonSx}
                  onClick={() => handleDelete()}
                >
                  DELETE
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

ProceedModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ProceedModal };

