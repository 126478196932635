export const addDrugDrawer_style = {
  inputFieldSx: {
    marginTop: '4px',
  },
  boxRootSx: {
    display: 'grid',
    gap: 2,
    pt: 1,
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },

  rootchildrenSx: {
    textAlign: 'center',
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: '8px',
    mr: 1,
  },
  genderSx: {
    display: 'flex',
    justifyContent: 'start',
  },
  selectAvailabilityButtonSx: {
    backgroundColor: 'primary.main',
    color: '#fff',
  },
  unSelectAvailabilityButtonSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'primaryTints.A100',
  },
  buttonSx: {
    color: 'primary.main',
    border: '2px solid',
    fontSize: '14px',
    fontWeight: 'medium',
    borderColor: 'primary.main',
    height: '40px',
    width: '128px',
    borderRadius: '4px',
    textTransform: 'capitalize',
  },
  addMemberSx: {
    textTransform: 'capitalize',
    height: '40px',
    width: '124px',
    borderRadius: '4px',
  },
  labelSx: {
    color: 'text.label',
  },
};
