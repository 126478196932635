import styled from '@emotion/styled';
import { Box } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { daySelector_style } from './style';

function DaySelector(props) {
  const {
    className = '',
    rootStyle = {},
    buttonContent = [],
    selectbuttonStyle = {},
    buttonStyle = {},
    getDay = () => {},
    value = [],
    onTimeChange = () => {},
    ...rest
  } = props;
  const [formats, setFormats] = useState(value);
  const handleFormat = (newValue) => {
    setFormats(newValue);
    getDay(newValue);
    onTimeChange(newValue);
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    display: 'flex',
    gap: '10px',
    borderRadius: '4px',
    '& .MuiButtonBase-root': {
      width: '42px',
      borderRadius: '100px !important',
      border: '1px solid #EBEBEB !important',
    },

    '& .MuiToggleButtonGroup-grouped': {
      border: '1px solid #EBEBEB',
      color: '#353448',
      cursor: 'pointer',
      padding: '8px',
      textTransform: 'capitalize',
      borderRadius: '100px',
      '&:hover': {
        background: '#EEFBF6',
        border: '1px solid #EBEBEB',
      },
      '&:focus': {
        outline: 'none',
      },
      '&.Mui-selected': {
        background: '#EEFBF6',
        border: '1px solid #49C792 !important',
        '&:hover': {
          background: '#EEFBF6',
        },
      },
      ...buttonStyle,
      ...selectbuttonStyle,
    },
  }));

  return (
    <Box
      sx={{
        ...daySelector_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <StyledToggleButtonGroup
        selectbuttonStyle={selectbuttonStyle}
        buttonStyle={buttonStyle}
        value={formats}
        onChange={(e, newValue) => handleFormat(newValue)}
      >
        {buttonContent.map((option, id) => (
          <ToggleButton
            id={option.label}
            disabled={option.disabled}
            value={option?.value}
            key={id}
            sx={{ fontSize: '14px' }}
          >
            {option.label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </Box>
  );
}

DaySelector.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  buttonContent: PropTypes.array,
  selectbuttonStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  getDay: PropTypes.func,
  value: PropTypes.array,
  onTimeChange: PropTypes.func,
};

export { DaySelector };
