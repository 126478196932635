export const transactionsCard_style = {
  rootSx: {
    background: '#FFFFFF',
    // border: '1px solid #E3E3E3',
    // borderRadius: '8px',
    py: 2.4,
    px: 2,
    // height: '100px',
    border: '1px',
    borderRadius: '12px',
    boxShadow:
      'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  },
  amountFontSx: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0px',
    color: '#007965',
    opacity: 1,
  },
  percentageFontSx: {
    textAlign: 'left',
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '0px',

    opacity: 1,
  },
  percentageContainerSx: {
    background: '#E8FCF0',
    borderRadius: '4px',
    px: 1,
    gap: 0.2,
    height: '22px',
    display: 'flex',
    alignItems: 'center',
  },
  cardDescriptionSx: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0px',
    color: '#808080',
    opacity: 1,
  },
};
