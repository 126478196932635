import { NotificationCard } from '@components';
import { getDate } from '@hc/dayjs';
import { localStorageKeys, queryClient } from '@hc/utils';
import { diffYMDHMS, parseJwt } from '@hc/utils/helperFunctions';
import { Avatar, Box, Dialog, Grid, Typography } from '@mui/material';
import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import notificationEmptyState from '../../../assets/notificationEmptyState.svg';
import { CancelIcon } from '../../../atoms';
import { useDoctorNotification, useOrganizationNotification } from '@hc/store';
import { isEqual } from 'date-fns';

export function OrganizationNotificationPopup(props) {
  const {
    notificationData,
    notificationOpenCloseFnc = () => {},
    openPop,
    redirectFnc,
    userId,
    title = '',
    roleID,
  } = props;

  const {
    organizationNotificationUndo,
    organizationNotificationDelete,
    getOrganizationNotification,
  } = useOrganizationNotification(
    (state) => ({
      organizationNotificationUndo: state.organizationNotificationUndo,
      organizationNotificationDelete: state.organizationNotificationDelete,
      getOrganizationNotification: state.getOrganizationNotification,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    doctorNotificationUndo,
    doctorNotificationDelete,
    getDoctorNotification,
  } = useDoctorNotification(
    (state) => ({
      doctorNotificationUndo: state.doctorNotificationUndo,
      doctorNotificationDelete: state.doctorNotificationDelete,
      getDoctorNotification: state.getDoctorNotification,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const notificationDeleteFun = async (notificationID) => {
    let res;
    if (roleID === 4) {
      res = await organizationNotificationDelete(notificationID);
      queryClient?.invalidateQueries({
        queryKey: ['/notifications/getAll'],
      });
      await getOrganizationNotification(userId);
    } else {
      res = await doctorNotificationDelete(notificationID);
      await getDoctorNotification(userId);
    }
    return res;
  };
  const notificationUndoFun = async (notificationID) => {
    if (roleID === 4) {
      await organizationNotificationUndo(notificationID);
      queryClient?.invalidateQueries({
        queryKey: ['/notifications/getAll'],
      });
      await getOrganizationNotification(userId);
    } else {
      await doctorNotificationUndo(notificationID);
      await getDoctorNotification(userId);
    }
  };

  // Intializing the firebase
  useEffect(() => {
    import('@hc/store').then((modules) => {
      const { initialize, enableNotification } = modules.useFirebase.getState();
      initialize();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const data = parseJwt(authToken);

      enableNotification(data?.user_id);
    });
  }, []);

  return (
    <Dialog
      open={openPop}
      onClose={() => notificationOpenCloseFnc(false)}
      PaperProps={{
        maxHeight: { sx: '100%', md: 600 },
      }}
    >
      <Box
        sx={{
          cursor: 'pointer',
          px: 2,
          pt: 1.5,
          display: 'flex',
          justifyContent: title ? 'space-between' : 'end',
          alignItems: 'center',
        }}
        onClick={() => notificationOpenCloseFnc(false)}
      >
        {title && (
          <Typography
            sx={{ fontSize: '18px', fontWeight: 600, color: '#0E1824' }}
          >
            {title}
            {}
          </Typography>
        )}
        <Avatar
          sx={{
            backgroundColor: '#fff',
            '&:hover': { backgroundColor: 'grey.300' },
          }}
        >
          <CancelIcon rootStyle={{ color: 'grey' }} />
        </Avatar>
      </Box>
      <Box
        sx={{
          width: { sm: '90%', md: '460px' },
          height: '100%',
          boxShadow: '5px 8px 16px #00000014',
        }}
      >
        {notificationData?.length > 0 ? (
          notificationData.map((data, i) => (
            <Box key={i}>
              <NotificationCard
                prescription={JSON.parse(data?.content)?.message ?? ''}
                notificationTime={diffYMDHMS(getDate(data?.createdAt))}
                redirectFnc={redirectFnc}
                notificationDeleteFun={notificationDeleteFun}
                notificationUndoFun={notificationUndoFun}
                data={data}
                is_read={data?.is_read}
                url={JSON.parse(data?.content)?.actionUrl ?? ''}
              />
            </Box>
          ))
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ px: 5, pb: 5, pt: 0 }}
          >
            <Grid item mt={5}>
              <Box>
                <img src={notificationEmptyState} alt="" />
              </Box>
            </Grid>
            <Grid item mt={1.6}>
              <Typography fontSize={16} fontWeight={600}>
                No Notifications Yet
              </Typography>
            </Grid>
            <Grid item mt={1}>
              <Box width={250} textAlign="center">
                <Typography fontSize={12} color="#888888">
                  {/* Come back later! */}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Dialog>
  );
}
OrganizationNotificationPopup.propTypes = {
  notificationData: PropTypes.array,
  notificationOpenCloseFnc: PropTypes.func,
  openPop: PropTypes.bool,
  redirectFnc: PropTypes.func,
  title: PropTypes.string,
};
