import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Drawer, Input, Label } from '@hc/ui/atoms';
import { addNewTierDrawer_style } from './style';

function AddNewTierDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    drugDrawerOpen = false,
    closeDrugMasterDrawer = () => false,
    ...rest
  } = props;

  const [input, setInput] = useState('');
  const handleChange = (e) => {
    setInput(e.traget.value);
  };

  const HandleSubmit = () => {};

  const HandleDrawerClose = () => {
    closeDrugMasterDrawer();
  };

  return (
    <Box
      sx={{
        ...addNewTierDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {drugDrawerOpen && (
        <Drawer
          show={drugDrawerOpen}
          onCloseDrawer={HandleDrawerClose}
          anchor="right"
          isCloseIconRequired
          header="Add new tier"
          headerStyle={addNewTierDrawer_style.headerSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                position: 'absolute',
                inset: 'auto 0 0 0',
                mb: 3,
                px: 2,
              }}
            >
              <Button
                buttonStyle={addNewTierDrawer_style.SaveButtonSx}
                // onClick={() => upsertDrugMaster()}
                onClick={() => HandleSubmit()}
              >
                Apply
              </Button>
              <Button
                buttonStyle={addNewTierDrawer_style.cancleButtonSx}
                onClick={closeDrugMasterDrawer}
              >
                Reset
              </Button>
            </Box>
          }
        >
          <Box sx={addNewTierDrawer_style.boxRootSx}>
            {/* ------------ACTIVITY NAME INPUT----------- */}
            <Box sx={addNewTierDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addNewTierDrawer_style.labelSx}
                htmlFor="Tier"
                isRequired
              >
                Tier Name
              </Label>
              <Input
                id="Tier"
                textFieldStyle={addNewTierDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('Tier', e.target.value, 20);
                }}
                value={input}
                fullWidth
              />
            </Box>
            {/*  ----------------ACTIVITY TYPE DROPDOWN ------------- */}
            <Box sx={addNewTierDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addNewTierDrawer_style.labelSx}
                htmlFor="Consultation_Fee"
                isRequired
              >
                Consultation Fee
              </Label>
              <Input
                id="Consultation_Fee"
                textFieldStyle={addNewTierDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('Consultation_Fee', e.target.value, 20);
                }}
                value={input}
                fullWidth
                endAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    ₹
                  </Typography>
                }
              />
            </Box>
            {/* ----------TIME SELCTION TOGGLE------------------ */}
            <Box sx={addNewTierDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addNewTierDrawer_style.labelSx}
                htmlFor="Follow_Up_Fee"
                isRequired
              >
                Follow-Up Fee
              </Label>
              <Input
                id="Follow_Up_Fee"
                textFieldStyle={addNewTierDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('Follow_Up_Fee', e.target.value, 20);
                }}
                value={input}
                endAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    ₹
                  </Typography>
                }
              />
            </Box>

            <Box sx={addNewTierDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addNewTierDrawer_style.labelSx}
                htmlFor="Platform_Fee"
                isRequired
              >
                Platform Fee
              </Label>
              <Input
                id="Platform_Fee"
                textFieldStyle={addNewTierDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('Platform_Fee', e.target.value, 20);
                }}
                Value={input}
                endAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    %
                  </Typography>
                }
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

AddNewTierDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddNewTierDrawer };
