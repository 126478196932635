import { getDateFormatToString } from '@hc/dayjs';
import { useBugReportMaster } from '@hc/store/backoffice/feeds/bugReportMaster';
import { useFeedBackMaster } from '@hc/store/backoffice/feeds/feedbackMaster';
import {
  AngryFaceEmoji,
  EditIcon,
  HappyFaceEmoji,
  HeartFaceEmoji,
  NeutralFaceEmoji,
  NotOkEmoji,
} from '@hc/ui/atoms';
import { BugreportDrawer, TableSkeletonLoader } from '@hc/ui/components';
import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { FeedBackDrawer } from '@hc/ui/components/backoffice/feedbackDrawer';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ActivityTemplate_style } from './style';

export default function FeedsandBugs(props) {
  const { className = '', ...rest } = props;
  const [drugDrawerOpen, setFeedsDrawerOpen] = useState(false);
  const [expertDrugDrawerOpen, setexpertDrugDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState(0);
  const [tab, setTab] = useState(0);
  const [showBtn, setshowBtn] = useState(true);
  const [ShowSearch, setShowSearch] = useState(true);
  const [adminSearch, setAdminSearch] = useState('');
  const [expertSearch, setExpertSearch] = useState('');
  const [adminOffset, setAdminOffset] = useState(0);
  const [adminRowsPerPage, setAdminRowsPerPage] = React.useState(10);
  const [expertOffset, setExpertOffset] = useState(0);
  const [expertRowsPerPage, setExpertRowsPerPage] = React.useState(10);

  const { getFeedbacks, feedBackData, feedBackCount, initialFeeds, loading } =
    useFeedBackMaster((state) => ({
      getFeedbacks: state.getFeedbacks,
      loading: state.loading,
      feedBackData: state.feedBackData,
      feedBackCount: state.feedBackCount,
      initialFeeds: state.initialFeeds,
    }));

  const {
    getbugList,
    bugReportData,
    bugReportCount,
    initialBugs,
    updateBugStatus,
  } = useBugReportMaster((state) => ({
    getbugList: state.getbugList,
    bugReportData: state.bugReportData,
    bugReportCount: state.bugReportCount,
    initialBugs: state.initialBugs,
    updateBugStatus: state.updateBugStatus,
  }));

  const closeDrugMasterDrawer = () => {
    setFeedsDrawerOpen(false);
  };
  const closeExpertDrawer = () => {
    setexpertDrugDrawerOpen(false);
  };

  // -------------------TABLE DATA SEARCH----------------
  const handleSearch = async (search) => {
    setAdminSearch(search);
    queryClient.invalidateQueries({
      queryKey: ['getFeedbacks'],
    });

    await getFeedbacks(search);
    setAdminOffset(0);
  };

  const handleBugSearch = async (search) => {
    setExpertSearch(search);
    queryClient.invalidateQueries({
      queryKey: ['getbugList'],
    });

    await getbugList(search);
  };

  const upsertMaster = async () => {
    const response = await updateBugStatus();
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getbugList'],
      });
      getbugList(expertSearch);
      setexpertDrugDrawerOpen(false);
    }
  };

  // -----------------ADD AND EDIT ACTIVITY -----------------------

  const handleTabChange = (value) => {
    setTab(value);
    if (value === 1) {
      setshowBtn(false);
    } else {
      setshowBtn(true);
    }

    if (value === 1) {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  };

  const onEdit = (id) => {
    setFeedsDrawerOpen(true);
    initialFeeds(id);
  };

  const onExpertEdit = async (id) => {
    initialBugs(id);
    setexpertDrugDrawerOpen(true);
  };

  // ----------------------PAGINATION--------------------------------
  const onAdminLimitChange = async (limit, offset) => {
    setAdminRowsPerPage(limit);
    setAdminOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getFeedbacks'],
    });

    await getFeedbacks(adminSearch, limit, offset);
  };

  const onAdminOffsetchange = async (offset) => {
    setAdminOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getFeedbacks'],
    });

    await getFeedbacks(adminSearch, adminRowsPerPage, offset);
  };

  // -----------Expert Pagination ------------------------
  const onExpertLimitChange = async (limit, offset) => {
    setExpertRowsPerPage(limit);
    setExpertOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getbugList'],
    });
    await getbugList(expertSearch, limit, offset);
  };

  const onExpertOffsetchange = async (offset) => {
    setExpertOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getbugList'],
    });
    await getbugList(expertSearch, expertRowsPerPage, offset);
  };
  // ----------------------TABLE COLUMN HEAD DATA ---------------------
  const FeedEmoji = [
    { id: 0, icon: <AngryFaceEmoji />, percentage: 0 },
    { id: 1, icon: <NotOkEmoji />, percentage: 25 },
    { id: 2, icon: <NeutralFaceEmoji />, percentage: 50 },
    { id: 3, icon: <HappyFaceEmoji />, percentage: 75 },
    { id: 4, icon: <HeartFaceEmoji />, percentage: 100 },
  ];

  const colorStatus = [
    { status: 'Pending', color: '#F44F5A', value: 1 },
    { status: 'Inprogress', color: '#FACC15', value: 2 },
    { status: 'Completed', color: '#25C460', value: 3 },
    { status: 'Deployed', color: '#f00', value: 4 },
  ];
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 120,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
    },
    {
      field: 'content',
      headerName: 'Content',
      width: 200,
    },
    {
      field: 'rating',
      headerName: 'Rating',
      width: 110,
      renderCell: (params) => (
        <Box id={params?.id}>
          {FeedEmoji?.find((val) => val.percentage === params.row.rating).icon}
        </Box>
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 270,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },

    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },

    {
      field: 'Action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <Box sx={{ pl: 1 }} onClick={() => onEdit(params?.row?.id)}>
          <EditIcon id="edit1" style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];
  const bugColumns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 120,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 180,
    },
    {
      field: 'content',
      headerName: 'Content',
      width: 230,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => (
        <Typography
          sx={{
            pl: 1,
            color: colorStatus.find((val) => val.value === params.row.status)
              .color,
          }}
        >
          {params.row.statusValue}
        </Typography>
      ),
    },

    {
      field: 'created_at',
      headerName: 'Created At',
      width: 270,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },

    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },

    {
      field: 'Action',
      headerName: 'Action',
      width: 200,
      renderCell: (params) => (
        <Box sx={{ pl: 1 }} onClick={() => onExpertEdit(params?.row?.id)}>
          <EditIcon id='editOption1' style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];

  const filterList = [
    { id: 'feedback', name: 'FeedBack', count: tab === 0 ? feedBackCount : -1 },
    { id: 'bugs', name: 'Bugs', count: tab === 1 ? bugReportCount : -1 },
  ];
  // --------------------INITAIL API CALL -------------------------
  const InitialData = async () => {
    await getFeedbacks();
  };

  useEffect(() => {
    InitialData();
    getbugList('');
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MasterTopContainer
              // addMasterStyle={ActivityTemplate_style.addMasterSx}
              handleSearch={handleSearch}
              handleSecondSearch={handleBugSearch}
              showSearchField={ShowSearch}
              showSecondSearchField={!ShowSearch}
              showAddButton={false}
              // handleAddClick={handleAddClick}
              isTitileShow={false}
              isTabShow
              currentTab={handleTabChange}
              searchFieldPlaceholder="Search"
              SecondsearchFieldPlaceholder="Search"
              filterData={filterList}
            />
          </Grid>
          {tab === 0 && (
            <Grid item xs={12}>
              {loading ? (
                <Box>
                  <TableSkeletonLoader />
                </Box>
              ) : (
                <MasterTable
                  columns={columns}
                  data={feedBackData ?? []}
                  customTableStyle={ActivityTemplate_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              )}
              <Stack
                flexDirection="row"
                justifyContent="end"
                sx={{ width: '100%' }}
              >
                <Pagination
                  totalCount={feedBackCount ?? 0}
                  onLimitChange={onAdminLimitChange}
                  onOffsetchange={onAdminOffsetchange}
                  offset={adminOffset}
                  rowsPerPage={adminRowsPerPage}
                />
              </Stack>
            </Grid>
          )}

          {tab === 1 && (
            <Grid item xs={12}>
              {loading ? (
                <TableSkeletonLoader />
              ) : (
                <MasterTable
                  columns={bugColumns}
                  data={bugReportData ?? []}
                  customTableStyle={ActivityTemplate_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              )}
              <Stack
                flexDirection="row"
                justifyContent="end"
                sx={{ width: '100%' }}
              >
                <Pagination
                  totalCount={bugReportCount ?? 0}
                  onLimitChange={onExpertLimitChange}
                  onOffsetchange={onExpertOffsetchange}
                  offset={expertOffset}
                  rowsPerPage={expertRowsPerPage}
                />
              </Stack>
            </Grid>
          )}
        </Grid>

        {/* Add Drug Drawer */}
        <Box>
          <FeedBackDrawer
            drawerOpen={drugDrawerOpen}
            closeMasterDrawer={closeDrugMasterDrawer}
          />
        </Box>
        <Box>
          <BugreportDrawer
            drawerOpen={expertDrugDrawerOpen}
            closeMasterDrawer={closeExpertDrawer}
            upsertMaster={upsertMaster}
          />
        </Box>
      </Box>
    </Box>
  );
}

FeedsandBugs.propTypes = {
  className: PropTypes.string,
};
