import { getDateFormatToString } from '@hc/dayjs';
import { backofficeRoutes } from '@hc/routes';
import { useAwarenessManagement } from '@hc/store/backoffice/awarenessManagement';
import {
  BackOfficeAddReferenceIcon,
  DeleteIcon,
  EditIcon,
  Input,
  Label,
  Switch,
  ToggleButton,
  UploadIcon
} from '@hc/ui/atoms';
import { TableSkeletonLoader } from '@hc/ui/components';
import { MasterTable } from '@hc/ui/components/backoffice';
import { AwarenessManagementTopContainer } from '@hc/ui/components/backoffice/awarenessManagementTopContainer';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { PlanManagementDrawer } from '@hc/ui/components/backoffice/planManagementDrawer';
import { camelCaseToWords, handleUploadFile, queryClient } from '@hc/utils';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { CategoryManagement_style } from './style';

export default function AwarenessCategoryManagement(props) {
  const { className = '', ...rest } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [categoryManagementDrawerOpen, setCategoryManagementDrawerOpen] =
    useState(false);

  const categoryTypeData = [
    {
      value: 'content',
      name: 'Content',
    },

    {
      value: 'video',
      name: 'Video',
    },
  ];

  // Store Data

  const {
    awarenessManagementState,
    getAllCategoryData,
    clearCategoryManagementData,
    categoryManagemenStatusUpdate,
    isIamValidToCategorySave,
    upsertCategoryManagement,
    handleCategoryManagementFormChange,
    updateCategoryPicture,
    handleContentDelete,
    addContentFnc,
    handelTitleContentChange,
    getNoteDataByCategoryId,
    loading,
  } = useAwarenessManagement((state) => ({
    awarenessManagementState: state.awarenessManagementState,
    getAllCategoryData: state.getAllCategoryData,
    clearCategoryManagementData: state.clearCategoryManagementData,
    categoryManagemenStatusUpdate: state.categoryManagemenStatusUpdate,
    isIamValidToCategorySave: state.isIamValidToCategorySave,
    upsertCategoryManagement: state.upsertCategoryManagement,
    handleCategoryManagementFormChange:
      state.handleCategoryManagementFormChange,
    updateCategoryPicture: state.updateCategoryPicture,
    handleContentDelete: state.handleContentDelete,
    addContentFnc: state.addContentFnc,
    handelTitleContentChange: state.handelTitleContentChange,
    getNoteDataByCategoryId: state.getNoteDataByCategoryId,
    loading: state.loading,
  }));

  const { categoryManagementData, categoryManagement } =
    awarenessManagementState;

  // General Hooks

  const closeCategoryManagementDrawer = () => {
    setCategoryManagementDrawerOpen(false);
  };

  const onToggleButtonClick = (value) => {
    handleCategoryManagementFormChange('note_type', value);
  };

  // searchValue IS THE PARAM
  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    if (location?.state?.id) {
      await getAllCategoryData(location?.state?.id, searchValue);
    } else {
      navigate(backofficeRoutes?.awarenessManagement);
    }
  };
  const handleAddClick = () => {
    clearCategoryManagementData();
    setCategoryManagementDrawerOpen(true);
  };

  const onStatusChange = async (id, status) => {
    const payload = {
      id,
      status: !status,
    };
    const response = await categoryManagemenStatusUpdate(payload);
    if (response === 200) {
      if (location?.state?.id) {
        await getAllCategoryData(location?.state?.id, search);
      } else {
        navigate(backofficeRoutes?.awarenessManagement);
      }
    }
  };

  const onContentNameChange = (key, value, limit) => {
    if (limit) {
      if (value.length <= limit) {
        handleCategoryManagementFormChange(key, value);
      }
    }
  };

  const onEdit = async (data) => {
    queryClient.invalidateQueries({
      queryKey: ['getNoteDataByCategoryId'],
    });
    const res = await getNoteDataByCategoryId(data?.id);
    if (res === 200) {
      setCategoryManagementDrawerOpen(true);
    }
  };

  const handleCategoryPicUpload = async (e) => {
    const res = await handleUploadFile(e.target.files);
    if (
      res?.[0]?.status === 'ok' &&
      res?.[0]?.message === 'Upload Successfully!'
    ) {
      // UPDATE PROFILE PICTURE
      updateCategoryPicture(res?.[0]?.downloadUrl);
      return toast.success('Category Picture Updated Successfully');
    }
    toast.error('Something went wrong please try again!');
  };

  const deleteNotePicture = (i, type) => {
    handelTitleContentChange('awareness_category_note_image', '', i);
    return toast.success(`Note ${type} Deleted Successfully`);
  };

  const handleNotePicUpload = async (e, i, type) => {
    const res = await handleUploadFile(e.target.files);
    if (
      res?.[0]?.status === 'ok' &&
      res?.[0]?.message === 'Upload Successfully!'
    ) {
      // UPDATE PROFILE PICTURE
      // updateCategoryPicture(res?.[0]?.downloadUrl);
      handelTitleContentChange(
        'awareness_category_note_image',
        res?.[0]?.downloadUrl,
        i,
      );
      return toast.success(`Note ${type} Updated Successfully`);
    }
    toast.error('Something went wrong please try again!');
  };

  const columns = [
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <Box sx={{ pl: 1 }} onClick={() => onEdit(params?.row)}>
          <EditIcon style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
    {
      field: 'awareness_category_name',
      headerName: 'Category name',
      width: 320,
    },
    {
      field: 'note_type',
      headerName: 'Notes Type',
      width: 200,
      valueGetter: (params) => camelCaseToWords(params?.row?.note_type),
    },
    {
      field: 'category_notes_count',
      headerName: 'Notes Mapped',
      width: 200,
    },
    {
      field: 'active_status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => (
        <Switch
          checked={params?.row?.active_status}
          onChange={() =>
            onStatusChange(
              params?.row?.awareness_category_id,
              params?.row?.active_status,
            )
          }
          rootStyle={CategoryManagement_style.switchSx}
        />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
  ];

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    if (location?.state?.id) {
      await getAllCategoryData(location?.state?.id, search, limit, offset);
    } else {
      navigate(backofficeRoutes?.awarenessManagement);
    }
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    if (location?.state?.id) {
      await getAllCategoryData(
        location?.state?.id,
        search,
        rowsPerPage,
        offset,
      );
    } else {
      navigate(backofficeRoutes?.awarenessManagement);
    }
  };

  const initialData = async () => {
    if (location?.state?.id) {
      await getAllCategoryData(location?.state?.id, '');
    } else {
      navigate(backofficeRoutes?.awarenessManagement);
    }
  };

  // UPSERT EXPERT FUNCTION
  const upsertCategoryManagementFnc = async () => {
    const result = isIamValidToCategorySave();
    if (result) {
      if (categoryManagement?.note_type === 'content') {
        const result = categoryManagement?.contentData.map((v) =>
          Object.values(v).some((x) => x === null || x === ''),
        );
        if (!result?.includes(true)) {
          const reponse = await upsertCategoryManagement();
          if (reponse === 200) {
            initialData();
            setCategoryManagementDrawerOpen(false);
            clearCategoryManagementData();
          }
        } else {
          toast.error(
            'Please Upload Note Image & Fill All the Required Fields!',
          );
        }
      } else if (categoryManagement?.note_type === 'video') {
        if (
          categoryManagement?.contentData?.[0]
            ?.awareness_category_note_image !== null &&
          categoryManagement?.contentData?.[0]
            ?.awareness_category_note_image !== '' &&
          categoryManagement?.contentData?.[0]?.awareness_category_note_image
            ?.length > 0
        ) {
          const reponse = await upsertCategoryManagement();
          if (reponse === 200) {
            initialData();
            setCategoryManagementDrawerOpen(false);
            clearCategoryManagementData();
          }
        } else {
          toast.error('Please Upload Note Video!');
        }
      }
    }
  };

  const addContent = () => {
    const result = categoryManagement?.contentData.map((v) =>
      Object.values(v).some((x) => x === null || x === ''),
    );
    if (!result?.includes(true)) {
      addContentFnc();
    } else {
      toast.error('Please Upload Note Image & Fill All the Required Fields!');
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      initialData();
    }
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AwarenessManagementTopContainer
              addMasterStyle={CategoryManagement_style.addMasterSx}
              handleSearch={handleSearch}
              handleAddClick={handleAddClick}
              title="Category Management"
              count={categoryManagementData?.count ?? '0'}
              showSearchField
              searchFieldPlaceholder="Search Category Name..."
              showAddButton
              addButtonName="Add Category"
              searchValue={search}
              isBackButton
            />
          </Grid>

          <Grid item xs={12}>
            <Stack sx={CategoryManagement_style.topbarCardSx}>
              <Typography sx={CategoryManagement_style.topbarheadingSx}>
                {`Awareness Name: ${location?.state?.name}`}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Box>
                <TableSkeletonLoader />
              </Box>
            ) : (
              <MasterTable
                columns={columns}
                data={
                  Array.isArray(categoryManagementData?.data) &&
                  categoryManagementData?.data?.length
                    ? categoryManagementData?.data
                    : []
                }
                customTableStyle={CategoryManagement_style.dataTableCustomSx}
                hideFooter
                rootStyle={{ height: '68vh' }}
              />
            )}
            <Stack
              flexDirection="row"
              justifyContent="end"
              sx={{ width: '100%' }}
            >
              <Pagination
                totalCount={categoryManagementData?.count ?? 0}
                onLimitChange={onLimitChange}
                onOffsetchange={onOffsetchange}
                offset={offset}
                rowsPerPage={rowsPerPage}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* Add Plan Management Drawer */}
        <Box>
          <PlanManagementDrawer
            isUpdate={categoryManagement?.id?.length > 0 ? true : false}
            drawerOpen={categoryManagementDrawerOpen}
            loading={loading}
            header="Category Management"
            closeDrawer={closeCategoryManagementDrawer}
            upsertUser={upsertCategoryManagementFnc}
            // eslint-disable-next-line react/no-children-prop
            children={
              <Box width={400}>
                <Grid>
                  <Grid pb={1.5} item md={12} lg={12} xl={12}>
                    <Label isRequired htmlFor="category_name">
                      Name
                    </Label>
                    <Input
                      id="category_name"
                      placeholder="Category Name"
                      isError={categoryManagement?.error?.name?.length > 0}
                      errorMessage={categoryManagement?.error?.name}
                      onChange={(event) =>
                        onContentNameChange('name', event?.target.value, 30)
                      }
                      value={categoryManagement?.name}
                    />
                  </Grid>

                  {/*  ------------- CATEGORY IMAGE UPLOAD FIELD ----------- */}
                  <Box sx={CategoryManagement_style.inputGroupSx}>
                    <Box sx={CategoryManagement_style.LablewithBtnSx}>
                      <Label
                        rootStyle={CategoryManagement_style.labelSx}
                        htmlFor="categoryImage"
                        isRequired
                      >
                        Image
                      </Label>
                      {categoryManagement?.image?.length > 0 && (
                        <Box
                          sx={CategoryManagement_style.FlexSx}
                          onClick={() => updateCategoryPicture('')}
                        >
                          <DeleteIcon />
                          <Typography
                            sx={CategoryManagement_style.ImgaeRemoveSx}
                          >
                            &nbsp; Delete
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box sx={CategoryManagement_style.uploadFileRootSx}>
                      {!categoryManagement?.image?.length > 0 && (
                        <label htmlFor="uplodebtn">
                          <Box sx={CategoryManagement_style.activeUploadFileSx}>
                            <Box
                              component="label"
                              sx={{
                                ...CategoryManagement_style.uploadIconSx,
                              }}
                            >
                              <UploadIcon />
                              <input
                                hidden
                                name="img"
                                accept="image/*"
                                multiple="multiple"
                                type="file"
                                id="uplodebtn"
                                onChange={handleCategoryPicUpload}
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={CategoryManagement_style.browseTextSx}
                              >
                                Click to browse and upload
                              </Typography>
                            </Box>
                          </Box>
                        </label>
                      )}

                      {categoryManagement?.image?.length > 0 && (
                        <Box>
                          <img
                            src={categoryManagement?.image}
                            alt="category_image"
                            style={{
                              width: '400px',
                              height: '160px',
                              objectFit: 'cover',
                              borderRadius: '10px',
                            }}
                          />
                        </Box>
                      )}
                    </Box>

                    {categoryManagement?.error?.image && (
                      <Typography color="#F44F5A" mt={0.5} fontSize={12}>
                        {categoryManagement?.error?.image}
                      </Typography>
                    )}
                  </Box>

                  <Grid pb={0.5} item md={12} lg={12} xl={12} pt={1.5}>
                    <Label
                      rootStyle={CategoryManagement_style.labelSx}
                      htmlFor="noteType"
                      isRequired
                    >
                      Note type
                    </Label>
                    <ToggleButton
                      RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                      isError={categoryManagement?.error?.note_type?.length > 0}
                      errorMessage={categoryManagement?.error?.note_type}
                      options={categoryTypeData ?? []}
                      value={categoryManagement?.note_type}
                      buttonactiveStyle={
                        CategoryManagement_style.togglebuttonactiveSX
                      }
                      buttoninactiveStyle={
                        CategoryManagement_style.togglebuttoninactiveSX
                      }
                      onChange={(value) => onToggleButtonClick(value)}
                    />
                  </Grid>
                  <Divider sx={{ pb: 2 }} />
                  {categoryManagement?.note_type === 'content' && (
                    <>
                      {categoryManagement?.contentData.map((val, i) => (
                        <Stack key={i} mt={1}>
                          <Box sx={CategoryManagement_style.LablewithBtnSx}>
                            <Typography
                              sx={{
                                color: '#262C33',
                                fontWeight: 600,
                                fontSize: '18px',
                                mb: 0.5,
                                pt: 1,
                              }}
                            >
                              {` Content ${i + 1} `}
                            </Typography>

                            <Box
                              sx={{
                                ...CategoryManagement_style.FlexSx,
                                opacity:
                                  categoryManagement?.contentData?.length === 1
                                    ? 0.5
                                    : 1,
                                height: '25px',
                              }}
                              onClick={() =>
                                categoryManagement?.contentData?.length === 1
                                  ? null
                                  : handleContentDelete(i)
                              }
                            >
                              <DeleteIcon />
                              <Typography
                                sx={CategoryManagement_style.ImgaeRemoveSx}
                              >
                                &nbsp; Delete
                              </Typography>
                            </Box>
                          </Box>

                          <Box sx={CategoryManagement_style.inputGroupSx}>
                            <Label
                              rootStyle={CategoryManagement_style.labelSx}
                              htmlFor="title"
                              isRequired
                            >
                              Title
                            </Label>

                            <Input
                              id="Title"
                              placeholder="Enter title"
                              textFieldStyle={
                                CategoryManagement_style.inputFieldSx
                              }
                              onChange={(e) => {
                                handelTitleContentChange(
                                  'title',
                                  e.target.value,
                                  i,
                                );
                              }}
                              value={val?.title}
                              fullWidth
                            />
                          </Box>

                          <Box sx={CategoryManagement_style.inputGroupSx}>
                            <Label
                              rootStyle={CategoryManagement_style.labelSx}
                              htmlFor="title_color_code"
                              isRequired
                            >
                              Title Color Code
                            </Label>

                            <Input
                              id="title_color_code"
                              placeholder="Enter title color code"
                              textFieldStyle={
                                CategoryManagement_style.inputFieldSx
                              }
                              onChange={(e) => {
                                handelTitleContentChange(
                                  'title_color_code',
                                  e.target.value,
                                  i,
                                );
                              }}
                              value={val?.title_color_code}
                              fullWidth
                            />
                          </Box>

                          <Box sx={CategoryManagement_style.inputGroupSx}>
                            <Label
                              rootStyle={CategoryManagement_style.labelSx}
                              htmlFor="content"
                              isRequired
                            >
                              Content
                            </Label>
                            <Input
                              id="content"
                              multiline
                              placeholder="Enter content"
                              textFieldStyle={
                                CategoryManagement_style.inputFieldSx
                              }
                              onChange={(e) => {
                                handelTitleContentChange(
                                  'content',
                                  e.target.value,
                                  i,
                                );
                              }}
                              value={val?.content}
                              fullWidth
                            />
                          </Box>

                          <Box sx={CategoryManagement_style.inputGroupSx}>
                            <Label
                              rootStyle={CategoryManagement_style.labelSx}
                              htmlFor="Content_color_code"
                              isRequired
                            >
                              Content Color Code
                            </Label>
                            <Input
                              id="Content_color_code"
                              placeholder="Enter content color code"
                              textFieldStyle={
                                CategoryManagement_style.inputFieldSx
                              }
                              onChange={(e) => {
                                handelTitleContentChange(
                                  'content_color_code',
                                  e.target.value,
                                  i,
                                );
                              }}
                              value={val?.content_color_code}
                              fullWidth
                            />
                          </Box>

                          <Box sx={CategoryManagement_style.inputGroupSx}>
                            <Label
                              rootStyle={CategoryManagement_style.labelSx}
                              htmlFor="gradient_left_color_code"
                              isRequired
                            >
                              Gradient Left Color Code
                            </Label>
                            <Input
                              id="gradient_left_color_code"
                              placeholder="Enter gradient left color code"
                              textFieldStyle={
                                CategoryManagement_style.inputFieldSx
                              }
                              onChange={(e) => {
                                handelTitleContentChange(
                                  'gradient_left_color_code',
                                  e.target.value,
                                  i,
                                );
                              }}
                              value={val?.gradient_left_color_code}
                              fullWidth
                            />
                          </Box>

                          <Box sx={CategoryManagement_style.inputGroupSx}>
                            <Label
                              rootStyle={CategoryManagement_style.labelSx}
                              htmlFor="gradient_right_color_code"
                              isRequired
                            >
                              Gradient Right Color Code
                            </Label>
                            <Input
                              id="gradient_right_color_code"
                              placeholder="Enter gradient right color code"
                              textFieldStyle={
                                CategoryManagement_style.inputFieldSx
                              }
                              onChange={(e) => {
                                handelTitleContentChange(
                                  'gradient_right_color_code',
                                  e.target.value,
                                  i,
                                );
                              }}
                              value={val?.gradient_right_color_code}
                              fullWidth
                            />
                          </Box>

                          {val?.gradient_left_color_code &&
                            val?.gradient_right_color_code && (
                              <Box sx={CategoryManagement_style.inputGroupSx}>
                                <Label
                                  rootStyle={CategoryManagement_style.labelSx}
                                  htmlFor="gradient_right_color_code"
                                  isRequired
                                >
                                  Content Background Sample
                                </Label>
                                <Box
                                  style={{
                                    height: '200px',
                                    borderRadius: '10px',
                                    background: `linear-gradient(to bottom,  ${val?.gradient_left_color_code}, ${val?.gradient_right_color_code})`,
                                  }}
                                />
                              </Box>
                            )}

                          {/*  ------------- NOTE IMAGE UPLOAD FIELD ----------- */}
                          <Box sx={CategoryManagement_style.inputGroupSx}>
                            <Box sx={CategoryManagement_style.LablewithBtnSx}>
                              <Label
                                rootStyle={CategoryManagement_style.labelSx}
                                htmlFor="noteImage"
                                isRequired
                              >
                                Image
                              </Label>
                              {val?.awareness_category_note_image?.length >
                                0 && (
                                <Box
                                  sx={CategoryManagement_style.FlexSx}
                                  onClick={() =>
                                    deleteNotePicture(i, 'Picture')
                                  }
                                >
                                  <DeleteIcon />
                                  <Typography
                                    sx={CategoryManagement_style.ImgaeRemoveSx}
                                  >
                                    &nbsp; Delete
                                  </Typography>
                                </Box>
                              )}
                            </Box>

                            <Box sx={CategoryManagement_style.uploadFileRootSx}>
                              {!val?.awareness_category_note_image?.length >
                                0 && (
                                <label htmlFor={`uplodebtnn_${i}`}>
                                  <Box
                                    sx={
                                      CategoryManagement_style.activeUploadFileSx
                                    }
                                  >
                                    <Box
                                      component="label"
                                      sx={{
                                        ...CategoryManagement_style.uploadIconSx,
                                      }}
                                    >
                                      <UploadIcon />
                                      <input
                                        hidden
                                        name="img"
                                        accept="image/*"
                                        multiple="multiple"
                                        type="file"
                                        id={`uplodebtnn_${i}`}
                                        onChange={(e) =>
                                          handleNotePicUpload(e, i, 'Picture')
                                        }
                                      />
                                    </Box>
                                    <Box>
                                      <Typography
                                        sx={
                                          CategoryManagement_style.browseTextSx
                                        }
                                      >
                                        Click to browse and upload
                                      </Typography>
                                    </Box>
                                  </Box>
                                </label>
                              )}

                              {val?.awareness_category_note_image?.length >
                                0 && (
                                <Box>
                                  <img
                                    src={val?.awareness_category_note_image}
                                    alt={`category_image_${i}`}
                                    style={{
                                      width: '400px',
                                      height: '160px',
                                      objectFit: 'cover',
                                      borderRadius: '10px',
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                          {categoryManagement?.contentData?.length !==
                            i + 1 && <Divider sx={{ pb: 2 }} />}
                        </Stack>
                      ))}
                      <Stack alignItems="center">
                        <Box
                          sx={{
                            border: '1px solid #007965',
                            backgroundColor: '',
                            px: '6px',
                            py: '3px',
                            mt: 2,
                            borderRadius: '8px',
                            display: 'flex',
                            gap: 0.4,
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => addContent()}
                        >
                          <BackOfficeAddReferenceIcon
                            rootStyle={{ color: '#007965' }}
                          />
                          <Typography sx={CategoryManagement_style.getlinkSx}>
                            Add Content
                          </Typography>
                        </Box>
                      </Stack>
                    </>
                  )}

                  {categoryManagement?.note_type === 'video' &&
                    categoryManagement?.contentData.map((val, i) => (
                      <Stack key={i} mt={1}>
                        {/*  ------------- NOTE VIDEO UPLOAD FIELD ----------- */}
                        <Box sx={CategoryManagement_style.inputGroupSx}>
                          <Box sx={CategoryManagement_style.LablewithBtnSx}>
                            <Label
                              rootStyle={CategoryManagement_style.labelSx}
                              htmlFor="noteVideo"
                              isRequired
                            >
                              {val?.awareness_category_note_image?.length > 0
                                ? 'Video URL'
                                : 'Video'}
                            </Label>
                            {val?.awareness_category_note_image?.length > 0 && (
                              <Box
                                sx={CategoryManagement_style.FlexSx}
                                onClick={() => deleteNotePicture(i, 'Video')}
                              >
                                <DeleteIcon />
                                <Typography
                                  sx={CategoryManagement_style.ImgaeRemoveSx}
                                >
                                  &nbsp; Delete
                                </Typography>
                              </Box>
                            )}
                          </Box>

                          <Box sx={CategoryManagement_style.uploadFileRootSx}>
                            {!val?.awareness_category_note_image?.length >
                              0 && (
                              <label htmlFor={`uplodebtnn_${i}`}>
                                <Box
                                  sx={
                                    CategoryManagement_style.activeUploadFileSx
                                  }
                                >
                                  <Box
                                    component="label"
                                    sx={{
                                      ...CategoryManagement_style.uploadIconSx,
                                    }}
                                  >
                                    <UploadIcon />
                                    <input
                                      hidden
                                      name="video"
                                      accept="video/*"
                                      type="file"
                                      id={`uplodebtnn_${i}`}
                                      onChange={(e) =>
                                        handleNotePicUpload(e, i, 'Video')
                                      }
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      sx={CategoryManagement_style.browseTextSx}
                                    >
                                      Click to browse and upload video
                                    </Typography>
                                  </Box>
                                </Box>
                              </label>
                            )}

                            {val?.awareness_category_note_image?.length > 0 && (
                              <Box sx={CategoryManagement_style.inputGroupSx}>
                                <Input
                                  id="awareness_category_note_image"
                                  placeholder="Enter gradient right color code"
                                  textFieldStyle={
                                    CategoryManagement_style.inputFieldSx
                                  }
                                  value={val?.awareness_category_note_image}
                                  fullWidth
                                  disabled
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Stack>
                    ))}
                </Grid>
              </Box>
            }
          />
        </Box>
      </Box>
    </Box>
  );
}

AwarenessCategoryManagement.propTypes = {
  className: PropTypes.string,
};
