/* eslint-disable no-useless-escape */
import { useAddPatients } from '@hc/store';
import {
  Button,
  CustomRadio,
  Drawer,
  Input,
  Label,
  MobileInput,
} from '@hc/ui/atoms';
import { useState } from 'react';
import { queryClient } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { addPatientDrawer_style } from './style';

function AddPatientDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    patientDrawerOpen = false,
    closePatientDrawer = () => false,
    ...rest
  } = props;
  const [saveBTn, setsaveBTn] = useState(false);
  const {
    patientState,
    updateErrorData,
    updatePatientState,
    upsertPatientData,
    getPatientData,
    clearPatientDetail,
    loading,
  } = useAddPatients((state) => ({
    clearPatientDetail: state.clearPatientDetail,
    updateErrorData: state.updateErrorData,
    getPatientData: state.getPatientData,
    patientState: state.patientState,
    updatePatientState: state.updatePatientState,
    upsertPatientData: state.upsertPatientData,
    loading: state.loading,
  }));

  const { patientDetail, errorValue } = patientState;

  const isIamValidToSave = () => {
    const patientCopy = JSON.parse(JSON.stringify(patientDetail));

    let isValid = true;
    const error = JSON.parse(JSON.stringify(errorValue));

    // Checking name
    if (!patientCopy?.name) {
      isValid = false;
      error.name = 'Please enter your name.';
    } else {
      error.name = '';
    }

    // Checking gender
    if (!patientCopy?.gender) {
      isValid = false;
      error.gender = 'Please select your gender';
    } else {
      error.gender = '';
    }

    // Checking age
    if (!patientCopy?.age) {
      isValid = false;
      error.age = 'Please enter your age';
    } else {
      error.age = '';
    }
    // Checking email_id

    const filter = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (patientCopy?.email?.length > 0 && filter.test(patientCopy?.email)) {
      error.email = '';
    } else {
      isValid = false;
      error.email = 'Please enter the valid mail';
    }

    // Checking phone number

    if (patientCopy?.phone?.mobile?.length === 0) {
      isValid = false;
      error.phone = 'Enter a valid 10 digit mobile number';
    } else if (patientCopy?.phone?.mobile?.length < 9) {
      isValid = false;
      error.phone = 'Enter a valid 10 digit mobile number';
    } else {
      error.phone = '';
    }

    // UPDATE PROFILE ERROR
    updateErrorData('errorValue', error);
    return isValid;
  };
  const handleChange = (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    updatePatientState(key, value);
    if (saveBTn) isIamValidToSave();
  };

  const handleSubmit = async () => {
    const result = isIamValidToSave();
    setsaveBTn(true);
    if (result) {
      setsaveBTn(false);
      queryClient?.invalidateQueries({
        queryKey: ['user-patient-invite'],
      });

      const status = await upsertPatientData();
      if (status?.code === 200) {
        closePatientDrawer();
        clearPatientDetail();
        queryClient.invalidateQueries({
          queryKey: ['patients-get-all'],
        });
        await getPatientData('', 10, 0);
      }
    }
  };

  const handleDrawerClose = () => {
    clearPatientDetail();
    closePatientDrawer();
    setsaveBTn(false);
  };

  return (
    <Box
      sx={{
        ...addPatientDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {patientDrawerOpen && (
        <Drawer
          show={patientDrawerOpen}
          onCloseDrawer={handleDrawerClose}
          anchor="right"
          closeStyle={addPatientDrawer_style.closeIconSx}
          isCloseIconRequired
          header="Patient"
          headerStyle={addPatientDrawer_style.headerSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                position: 'absolute',
                inset: 'auto 0 0 0',
                mb: 3,
                px: 2,
              }}
            >
              <Button
                buttonStyle={addPatientDrawer_style.SaveButtonSx}
                onClick={() => handleSubmit()}
                loading={loading}
              >
                Save
              </Button>
              <Button
                buttonStyle={addPatientDrawer_style.cancleButtonSx}
                onClick={handleDrawerClose}
                disabled={loading}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <Box sx={addPatientDrawer_style.boxRootSx}>
            {/* ------------ACTIVITY NAME INPUT----------- */}
            <Box sx={addPatientDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addPatientDrawer_style.labelSx}
                htmlFor="patientName"
                isRequired
              >
                Patient Name
              </Label>
              <Input
                id="patientName"
                textFieldStyle={addPatientDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('name', e.target.value, 100);
                }}
                isError={errorValue?.name.length > 0}
                errorMessage={errorValue?.name}
                value={patientDetail?.name ?? ''}
                fullWidth
              />
            </Box>
            {/*  ----------------ACTIVITY TYPE DROPDOWN ------------- */}
            <Box sx={addPatientDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addPatientDrawer_style.labelSx}
                htmlFor="gender"
                isRequired
              >
                Choose Patient Gender
              </Label>
              <CustomRadio
                id="gender"
                value={patientDetail?.gender ?? ''}
                onChange={(value) => {
                  handleChange('gender', value, 100);
                }}
                isError={errorValue?.gender.length > 0}
                errorMessage={errorValue?.gender}
                avataractiveStyle={{ border: '1px solid' }}
                avatarinactiveStyle={{ border: '1px solid' }}
              />
            </Box>
            {/* ----------TIME SELCTION TOGGLE------------------ */}
            <Box sx={addPatientDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addPatientDrawer_style.labelSx}
                htmlFor="age"
                isRequired
              >
                Age
              </Label>
              <Input
                id="age"
                textFieldStyle={addPatientDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('age', e.target.value, 3);
                }}
                type="number"
                isError={errorValue?.age.length > 0}
                errorMessage={errorValue?.age}
                value={patientDetail?.age ?? ''}
                endAdornment={
                  <Typography
                    sx={{ color: '#B7B7B7', fontSize: '14px', fontWeight: 400 }}
                  >
                    Yrs
                  </Typography>
                }
              />
            </Box>

            <Box sx={addPatientDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addPatientDrawer_style.labelSx}
                htmlFor="phoneNumber"
                isRequired
              >
                Phone Number
              </Label>
              <MobileInput
                id="phoneNumber"
                textFieldStyle={addPatientDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('phone', e, 100);
                }}
                helperText={errorValue?.phone}
                isError={errorValue?.phone.length > 0}
                value={{
                  mobile_code: patientDetail?.phone?.mobileCode ?? '+91',
                  mobile: patientDetail?.phone?.mobile ?? '',
                }}
              />
            </Box>
            <Box sx={addPatientDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addPatientDrawer_style.labelSx}
                htmlFor="email"
                isRequired
              >
                Email
              </Label>
              <Input
                id="email"
                helperText={errorValue?.email}
                isError={errorValue?.email.length > 0}
                textFieldStyle={addPatientDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleChange('email', e.target.value, 100);
                }}
                value={patientDetail?.email ?? ''}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

AddPatientDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  patientDrawerOpen: PropTypes.bool,
  closePatientDrawer: PropTypes.func,
};

export { AddPatientDrawer };
