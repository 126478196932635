export const bugreportDrawer_style = {
  boxRootSx: {
    display: 'grid',
    gap: 2,
    pt: 1,
    width: '350px',
  },
  drawerHeadSx: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#101010',
    textTransform: 'capitalize',
  },
  saveBtnSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleBtnSx: {
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  emojiContainer: {
    padding: 1,
    borderRadius: '100%',
    display: 'flex',
    placeItems: 'center',
  },
  title: { fontSize: '16px', fontWeight: 500 },
  content: {
    fontSize: '16px',
    fontWeight: 400,
    pl: 2,
  },
};
