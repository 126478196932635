import { useCategoryMaster } from '@hc/store/backoffice/categoryMaster';
import { useDurationMaster } from '@hc/store/backoffice/durationTypeMaster';
import { usePlanTemplate } from '@hc/store/backoffice/planTemplate';
import {
  Button,
  Drawer,
  Input,
  Label,
  SearchIcon,
  SearchSelect,
  ToggleButton,
} from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import { Box, Divider, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { ActivitySelectCard } from '../activitySelectCard';
import { MultiSelectToggle } from '../multiSelectToggle';
import { ProceedModal } from '../proceedModal';
import { addPlanDrawer_style } from './style';

function AddPlanDrawer(props) {
  const {
    className = '',
    planTemplateDrawerOpen = false,
    closePlanTemplateDrawer = () => false,

    rootStyle,
    ...rest
  } = props;

  const [reset, setReset] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState({
    activityCount: 0,
    activityArray: [],
    event: [],
  });
  const [saveBtn, setsaveBtn] = useState(false);

  const {
    handlePlanFormChange,
    handlePlanCategoryChange,
    getActivityList,
    addCardData,
    clearActivityCardData,
    removeCardItem,
    addPlanTemplate,
    getAllPlanTemplateData,
    clearPlanTemplateData,
    planTemplateState,
    isIamValidToSave,
    deleteActivityData,
    loading,
  } = usePlanTemplate(
    (state) => ({
      clearPlanTemplateData: state.clearPlanTemplateData,
      getAllPlanTemplateData: state.getAllPlanTemplateData,
      initialEditPlanData: state.initialEditPlanData,
      addPlanTemplate: state.addPlanTemplate,
      removeCardItem: state.removeCardItem,
      handlePlanFormChange: state.handlePlanFormChange,
      handlePlanCategoryChange: state.handlePlanCategoryChange,
      getActivityList: state.getActivityList,
      addCardData: state.addCardData,
      clearActivityCardData: state.clearActivityCardData,
      planTemplateState: state.planTemplateState,
      isIamValidToSave: state.isIamValidToSave,
      deleteActivityData: state.deleteActivityData,
      loading: state.loading,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    },
  );

  const { ActivityDropDownData, activityCardData, plan } = planTemplateState;
  const { error, id } = plan;

  const { durationData } = useDurationMaster((state) => ({
    durationData: state.durationData,
  }));

  const { categoryData } = useCategoryMaster((state) => ({
    categoryData: state.categoryData,
  }));

  // Drawer data clear function
  const drawrDataClear = () => {
    closePlanTemplateDrawer();
    clearActivityCardData();
    clearPlanTemplateData();
    setsaveBtn(false);
  };

  // Upser plan template API call
  const upsertPlan = async () => {
    const result = isIamValidToSave();
    setsaveBtn(true);
    if (result) {
      setsaveBtn(false);
      if (activityCardData?.length > 0) {
        const responseStatusCode = await addPlanTemplate();
        if (responseStatusCode === 200) {
          drawrDataClear();
          queryClient.invalidateQueries({
            queryKey: ['getAllPlanTemplateData'],
          });
          await getAllPlanTemplateData('');
        }
      } else {
        toast.error('Please select atleast one activity to proceed!');
      }
    }
  };

  const handleCancle = () => {
    drawrDataClear();
    setsaveBtn(false);
  };

  // DELETE MODEL OPEN FUNCTION
  const DeleteModalOpenFun = (deselectedActivitys, activityArr, even) => {
    setDeleteData({
      activityCount:
        deselectedActivitys?.length > 0 ? deselectedActivitys?.length : 0,
      activityArray: activityArr,
      event: even,
    });
    setDeleteModal(true);
  };
  // DELETE MODEL CLOSE FUNCTION
  const DeleteModalCloseFun = async () => {
    setDeleteModal(false);
    setDeleteData({
      activityCount: 0,
      activityArray: [],
      event: [],
    });
    await handlePlanCategoryChange(plan?.category_id);
  };

  const handleCategory = async (e) => {
    if (plan?.category_id?.length > e?.length) {
      const difference = plan?.category_id.filter((x) => !e.includes(x));

      const deselectedActivitys = activityCardData.filter(
        (y) =>
          // eslint-disable-next-line radix
          y.category_id === parseInt(difference.toString()),
      );
      if (
        Array.isArray(deselectedActivitys) &&
        deselectedActivitys?.length > 0
      ) {
        DeleteModalOpenFun(deselectedActivitys, difference, e);
      } else {
        handlePlanCategoryChange(e);
        if (e?.length > 0) {
          queryClient.invalidateQueries({
            queryKey: ['getActivityList'],
          });
          await getActivityList(e);
        }
      }
    } else {
      handlePlanCategoryChange(e);
      if (e?.length > 0) {
        queryClient.invalidateQueries({
          queryKey: ['getActivityList'],
        });
        await getActivityList(e);
      }
    }
    if (saveBtn) isIamValidToSave();
  };

  const handlePlanChange = (key, value) => {
    handlePlanFormChange(key, value);
    if (saveBtn) isIamValidToSave();
  };

  const handleAdd = (e, option) => {
    if (option?.value) {
      // ADD CARD DATA
      addCardData(option);
      setReset({ value: '', label: '', labelShow: '' });
    }
  };

  // DELETE DOSE
  const handleDelete = async () => {
    await deleteActivityData(
      parseInt(deleteData?.activityArray?.toString(), 10),
    );
    DeleteModalCloseFun();
    handlePlanCategoryChange(deleteData?.event);
    if (deleteData?.event?.length > 0) {
      queryClient.invalidateQueries({
        queryKey: ['getActivityList'],
      });
      await getActivityList(deleteData?.event);
    }
  };

  return (
    <>
      <Box
        sx={{
          ...addPlanDrawer_style.rootSx,
          ...rootStyle,
        }}
        className={`${className}`}
        {...rest}
      >
        <Box className={`${className}`} {...rest}>
          <Box className={`${className}`} {...rest}>
            <Box>
              {planTemplateDrawerOpen && (
                <Drawer
                  show={planTemplateDrawerOpen}
                  onCloseDrawer={drawrDataClear}
                  anchor="right"
                  isCloseIconRequired
                  header="Plan Template"
                  headerStyle={addPlanDrawer_style.headerSx}
                  footerStyle={{
                    borderTop: '0px',
                    p: 2,
                    bgcolor: 'transparent',
                  }}
                  footer={
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        buttonStyle={addPlanDrawer_style.SaveButtonSx}
                        onClick={() => upsertPlan()}
                        loading={loading}
                      >
                        {id ? 'Update' : 'Save'}
                      </Button>
                      <Button
                        buttonStyle={addPlanDrawer_style.cancleButtonSx}
                        onClick={() => handleCancle()}
                        disabled={loading}
                      >
                        Cancel
                      </Button>
                    </Box>
                  }
                >
                  <Box
                    sx={{
                      ...addPlanDrawer_style.boxRootSx,
                      width: { xs: '45vw', md: '35vw', lg: '25vw' },
                    }}
                  >
                    {/* ------------PLAN NAME INPUT----------- */}
                    <Box sx={addPlanDrawer_style.inputGroupSx}>
                      <Label
                        rootStyle={addPlanDrawer_style.labelSx}
                        htmlFor="PlanName"
                        isRequired
                      >
                        Plan Name
                      </Label>
                      <Input
                        id="PlanName"
                        textFieldStyle={addPlanDrawer_style.inputFieldSx}
                        onChange={(e) => {
                          handlePlanChange('plan_name', e.target.value);
                        }}
                        value={plan?.plan_name}
                        fullWidth
                        isError={error?.plan_name?.length > 0}
                        errorMessage={error?.plan_name}
                      />
                    </Box>
                    <Box>
                      <Label
                        rootStyle={addPlanDrawer_style.labelSx}
                        htmlFor="ActivityName"
                        isRequired
                      >
                        Choose Plan Category
                      </Label>
                      <MultiSelectToggle
                        RootStyle={{
                          flexwrap: 'wrap',
                          flexFlow: 'wrap',
                          marginTop: '4px',
                        }}
                        buttonContent={categoryData}
                        value={plan?.category_id}
                        plan={plan}
                        isError={error?.category_id?.length > 0}
                        errorMessage={error?.category_id}
                        handleValueChange={handleCategory}
                      />
                    </Box>
                    <Box>
                      <Label
                        rootStyle={addPlanDrawer_style.labelSx}
                        htmlFor="choosePlanDuration"
                        isRequired
                      >
                        Choose Plan Duration
                      </Label>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mt: 0.5,
                        }}
                      >
                        <Input
                          id="duration"
                          size="small"
                          fullwidth={false}
                          type="number"
                          placeholder={0}
                          textFieldStyle={addPlanDrawer_style.QtyTypoSx}
                          onChange={(e) => {
                            handlePlanChange('duration', e.target.value, 2);
                          }}
                          value={plan?.duration}
                        />
                        <ToggleButton
                          RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                          options={durationData}
                          value={plan?.duration_type}
                          buttonactiveStyle={
                            addPlanDrawer_style.buttonactivenumSX
                          }
                          buttoninactiveStyle={
                            addPlanDrawer_style.buttoninactivenumSX
                          }
                          onChange={(e) => {
                            handlePlanChange('duration_type', e);
                          }}
                        />
                      </Box>
                      {error?.duration?.length > 0 ||
                      error?.duration_type?.length > 0 ? (
                        <Typography
                          sx={{ mt: 0.5 }}
                          variant="caption"
                          color="error"
                        >
                          {error?.duration?.length > 0
                            ? error?.duration
                            : error?.duration_type}
                        </Typography>
                      ) : null}
                    </Box>
                    <Divider />
                    <Box>
                      <Label
                        rootStyle={addPlanDrawer_style.labelSx}
                        htmlFor=" searchAndSelectActivity"
                        isRequired
                      >
                        Search and Select Activity
                      </Label>
                      <SearchSelect
                        value={reset}
                        onChange={handleAdd}
                        searchFieldStyle={addPlanDrawer_style.searchFieldSx}
                        placeholder="Search Activity Name"
                        optionViewKey="label"
                        renderOption={(props, option) => {
                          return (
                            <Box
                              {...props}
                              id={option?.activity_name.replaceAll(' ', '-')}
                              sx={{ cursor: 'pointer' }}
                              height="40px"
                              onClick={(e) => handleAdd(e, option)}
                              px={2}
                              display="flex"
                            >
                              <Box display="flex" alignSelf="center">
                                <Typography>
                                  {option?.activity_name} -{' '}
                                  {option?.category_name}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }}
                        startAdornment={
                          <SearchIcon rootStyle={{ color: 'grey.500' }} />
                        }
                        fullWidth
                        options={
                          Array.isArray(ActivityDropDownData) &&
                          ActivityDropDownData?.length > 0
                            ? ActivityDropDownData.filter(
                                (da) =>
                                  !activityCardData.some(
                                    (eachDa) => eachDa?.activity_id === da?.id,
                                  ),
                              )
                            : []
                        }
                      />
                    </Box>

                    <Box display="flex" justifyContent="space-between">
                      <Typography sx={addPlanDrawer_style.listItemTitileSx}>
                        {`Activities selected (${
                          activityCardData?.length > 0
                            ? activityCardData?.length
                            : 0
                        })`}
                      </Typography>
                      <Typography
                        sx={
                          activityCardData?.length > 0
                            ? addPlanDrawer_style.listItemclearSx
                            : addPlanDrawer_style.listItemclearSxx
                        }
                        onClick={() =>
                          activityCardData?.length > 0
                            ? clearActivityCardData()
                            : null
                        }
                      >
                        {' '}
                        Clear all
                      </Typography>
                    </Box>

                    <Stack gap={1} sx={{ height: '25vh', overflow: 'scroll' }}>
                      {Array.isArray(activityCardData) &&
                        activityCardData.length > 0 &&
                        activityCardData.map((data, i) => (
                          <Box key={i}>
                            <ActivitySelectCard
                              onClose={() => removeCardItem(data.activity_id)}
                              image={data?.activity_image ?? ''}
                              activityName={data?.activity_name ?? ''}
                              activityType={data?.category_name ?? ''}
                            />
                          </Box>
                        ))}
                    </Stack>
                  </Box>
                </Drawer>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <ProceedModal
        isModalOpen={deleteModal}
        handleClose={DeleteModalCloseFun}
        handleDelete={handleDelete}
        drugName={deleteData?.drugName}
        header={
          <Typography sx={addPlanDrawer_style.header}>Are you sure?</Typography>
        }
        content={
          <Typography sx={addPlanDrawer_style.subDescription}>
            {' '}
            Deselecting plan category will remove <br />
            <span style={addPlanDrawer_style.description}>
              {`${deleteData?.activityCount} activities`}
            </span>{' '}
            from the selected list{' '}
          </Typography>
        }
      />
    </>
  );
}

AddPlanDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddPlanDrawer };
