/* eslint-disable array-callback-return */
import toast from 'react-hot-toast';
import { queryClient } from '@hc/utils';
import create from 'zustand';
import { envConfig } from '@hc/config';

export const useGender = create((set) => ({
  genderData: [],
  loading: null,
  error: null,
  getGenderDetails: async () => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery([
        '/masterGender/getAll',
        'get',
        `${envConfig.api_url}`,
      ]);
      const genderDataCopy = [];
      if (Array.isArray(data) && data?.length > 0) {
        data.map((val) => {
          genderDataCopy.push({
            value: val?.id ?? '',
            label: val?.gender_name ?? '',
          });
        });
      }
      return set(() => ({
        loading: false,
        genderData: genderDataCopy,
      }));
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },
}));
