import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const useOrgProfile = create((set) => ({
  profileView: {},
  loading: null,
  error: null,
  getProfileView: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      if (tokenData?.organization_id) {
        const { data } = await queryClient.fetchQuery({
          queryKey: ['getProfile'],
          queryFn: async () => {
            const { data } = await httpRequest(
              'post',
              `${envConfig.api_url}/organization/getProfile`,
              {
                organization_id: tokenData?.organization_id ?? '',
              },
              true
            );
            return data;
          },
          staleTime: Infinity,
        });
        return set({
          loading: false,
          profileView: data,
        });
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
