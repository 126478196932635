/* eslint-disable camelcase */
export const CategoryManagement_style = {
  headerSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleSx: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: 'medium',
  },
  daterangeSx: {
    width: '250px',
  },
  dataTableCustomSx: {
    '& .MuiDataGrid-row': {
      borderBottom: '2px solid',
      borderColor: 'grey.400',
    },

    '& .MuiDataGrid-main': {
      border: '1px solid',
      borderColor: 'grey.400',
      bgcolor: '#FFFFFF',
      borderRadius: '6px',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid',
      borderColor: 'grey.400',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
  },
  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: 'text.hint',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#007965',
      opacity: 1,
    },
  },
  addMasterSx: {
    width: 'fit-content',
  },

  togglebuttoninactiveSX: {
    color: '#5C6266',
    border: '1px solid ',
    borderColor: 'transparent',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '8px',
    // padding: '5px 10px',
    backgroundColor: '#E7FCF9',
    '&:hover': {
      color: '#5C6266',
      backgroundColor: '#E7FCF9',
    },
  },
  togglebuttonactiveSX: {
    color: 'primary.main',
    backgroundColor: '#E7FCF9',
    borderColor: 'primary.main',
    border: '1px solid ',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    // padding: '5px 10px',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: '#E7FCF9',
    },
  },
  buttonactiveSx: {
    border: '2px solid ',
    color: '#fff',
    textTransform: 'capitalize',
    padding: '10px',
    borderRadius: '8px',
    bgcolor: 'primary.main',
    cursor: 'pointer',
  },
  buttoninactiveSx: {
    backgroundColor: '#fff',
    border: '2px solid #E3E3E3',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: '12px',
    padding: '10px',
    color: '#5C6266',
    cursor: 'pointer',
  },
  inputGroupSx: { display: 'grid', gap: 0.5, pt: 1.5 },
  LablewithBtnSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pr: 0.8,
    pt: 0.5,
    pb: 2,
  },
  labelSx: {
    color: 'text.label',
  },
  ImgaeRemoveSx: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#F44F5A',
    cursor: 'pointer',
  },
  FlexSx: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FEEAEA ',
    borderRadius: '4px',
    px: 0.8,
    cursor: 'pointer',
  },
  activeUploadFileSx: {
    border: '2px dashed',
    borderColor: 'primary.main',
    // opacity: '48%',
    height: '132px',
    // marginTop: 2,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  browseTextSx: {
    fontSize: '14px',
    color: ' PrimaryTints.A100',
    opacity: '48%',
    textAlign: 'center',
    marginTop: '12px',
    cursor: 'pointer',
  },
  uploadIconSx: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  boxRootSx: {
    pt: 1,
    width: '25vw',
  },
  SaveButtonSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    width: '180px',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleButtonSx: {
    border: '1px solid',
    width: '180px',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  // inputGroupSx: { mb: 1.5 },
  imginputGroupSx: { mb: 1.5 },
  // labelSx: {
  //   color: 'text.label',
  //   mb: 1,
  // },
  inputFieldSx: {
    marginTop: '4px',
  },
  getlinkSx: {
    textAlign: 'center',

    color: 'primary.main',
  },
  expertField: {
    backgroundColor: '#F7F7F7',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: 'none',
      },
    },
  },
  topbarTitleSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#0E1824',
    pb: 0.4,
  },
  topbarCardSx: {
    backgroundColor: '#fff',
    p: 2,
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
  },
  topbarheadingSx: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0E1824',
    pb: 0.4,
  },
};
