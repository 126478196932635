import { EditIcon, Switch } from '@hc/ui/atoms';
import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { useHealthProblems } from '@hc/store/backoffice/masters/healthProblems';
import { HealthProblemsDrawer, TableSkeletonLoader } from '@hc/ui/components';
import { getDateFormatToString } from '@hc/dayjs';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { Box, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { healthProblems_Style } from './style';

export default function HealthProblems(props) {
  const { ...rest } = props;

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Store Data

  const {
    healthProblemData,
    healthProblemCount,
    getAllHealthProblems,
    healthProblemsStatusUpdate,
    addEditHealthProblems,
    isIamValidToSave,
    initialEditHealthProblems,
    clearHealthProblems,
    loading,
  } = useHealthProblems((state) => ({
    healthProblemData: state.healthProblemData,
    healthProblemCount: state.healthProblemCount,
    getAllHealthProblems: state.getAllHealthProblems,
    healthProblemsStatusUpdate: state.healthProblemsStatusUpdate,
    addEditHealthProblems: state.addEditHealthProblems,
    isIamValidToSave: state.isIamValidToSave,
    initialEditHealthProblems: state.initialEditHealthProblems,
    clearHealthProblems: state.clearHealthProblems,
    loading: state.loading,
  }));

  //  ----- TABLE DATA SEARCH ---------
  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    await getAllHealthProblems(searchValue, rowsPerPage);
    setOffset(0);
  };

  // ------TABLE DATA SWITCH --------
  const onStatusChange = async (val) => {
    const payload = {
      id: val.id ?? 0,
      active_status: !val?.is_active ?? true,
    };
    const response = await healthProblemsStatusUpdate(payload);
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllHealthProblems', search, offset],
      });
      await getAllHealthProblems(search, rowsPerPage, offset);
    }
  };

  // ------PAGINATION ------------
  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllHealthProblems', search, offset],
    });
    await getAllHealthProblems(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    await getAllHealthProblems(search, rowsPerPage, offset);
  };

  // ADD
  const upsertMaster = async () => {
    const result = isIamValidToSave();
    if (result) {
      const response = await addEditHealthProblems();
      if (response?.status?.code === 200) {
        setDrawerOpen(false);
        clearHealthProblems();
        queryClient.invalidateQueries({
          queryKey: ['getAllHealthProblems', search, offset],
        });
        await getAllHealthProblems(search, rowsPerPage, offset);
      }
    }
  };

  const handleAddClick = () => {
    setDrawerOpen(true);
  };
  const closeDrugMasterDrawer = () => {
    setDrawerOpen(false);
    clearHealthProblems();
  };
  const onEdit = (val) => {
    initialEditHealthProblems(val);
    setDrawerOpen(true);
  };

  //  TABLE COLUMNS META DATA
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 60,
    },
    {
      field: 'health_problem',
      headerName: 'Health Problems',
      width: 180,
    },
    {
      field: 'status',
      headerName: 'Active/Inactive',
      width: 150,
      renderCell: (params) => (
        <Switch
          id="healthPrblmStatus"
          checked={params?.row?.status}
          onChange={() => onStatusChange(params?.row?.value)}
          rootStyle={healthProblems_Style.switchSx}
        />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ pl: 1 }} onClick={() => onEdit(params?.row?.value)}>
          <EditIcon id='editHealthProblem' style={{ cursor: 'pointer', color: '#007965' }} />
        </Box>
      ),
    },
  ];

  //  INITIAL DATA FETCH

  const initialData = async () => {
    await getAllHealthProblems('');
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      {...rest}
      p={2}
      sx={{
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: '#fff',
        borderRadius: '10px',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MasterTopContainer
            isMasterTitleShow
            isTitileShow={false}
            handleSearch={handleSearch}
            handleAddClick={handleAddClick}
            title="Health Problems"
            count={healthProblemCount}
            showSearchField
            searchFieldPlaceholder="Search Health Problems by name"
            showAddButton
            addButtonName="Add Health Problems"
            addMasterStyle={{
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 3px 3px 0px rgba(0,0,0,0.14),0px 1px 2px 0px rgba(0,0,0,0.12)',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <TableSkeletonLoader />
          ) : (
            <Box>
              <MasterTable
                columns={columns}
                data={healthProblemData ?? []}
                customTableStyle={healthProblems_Style.dataTableCustomSx}
                hideFooter
                rootStyle={{ height: '68vh' }}
              />
            </Box>
          )}
          <Stack
            flexDirection="row"
            justifyContent="end"
            sx={{ width: '100%' }}
          >
            <Pagination
              totalCount={healthProblemCount}
              onLimitChange={onLimitChange}
              onOffsetchange={onOffsetchange}
              offset={offset}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Grid>
      </Grid>
      <HealthProblemsDrawer
        drawerOpen={drawerOpen}
        closeMasterDrawer={closeDrugMasterDrawer}
        upsertMaster={upsertMaster}
      />
    </Box>
  );
}

HealthProblems.propTypes = {
  className: PropTypes.string,
};
