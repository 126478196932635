/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import {
  endOfFnc,
  getDateFormatToString,
  startOfFnc,
  subtractFnc,
} from '@hc/dayjs';
import { UseSeekHelp } from '@hc/store/backoffice/seekHelp';
import {
  EditIcon,
  Input,
  Label,
  MobileInput,
  SearchField,
  ToggleButton,
  TransactionFilterIcon,
} from '@hc/ui/atoms';
import {
  PatientReportUploadCard,
  TableSkeletonLoader,
} from '@hc/ui/components';
import { MasterTable } from '@hc/ui/components/backoffice';
import { FilterTab } from '@hc/ui/components/backoffice/filterTab';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { SeekHelpDrawer } from '@hc/ui/components/backoffice/seekHelpDrawer';
import { SeekHelpFilterDrawer } from '@hc/ui/components/backoffice/seekHelpFilterDrawer';
import { StepperDrawer } from '@hc/ui/components/backoffice/stepperDrawer';
import { TransactionsCard } from '@hc/ui/components/backoffice/transactionsCard';
import { queryClient } from '@hc/utils';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { SeekHelp_style } from './style';

export default function SeekHelp(props) {
  const { className = '', ...rest } = props;

  //   ------------------------LOCAL STATE---------------------------------

  const statusData = [
    { value: 1, name: 'Open' },
    { value: 2, name: 'Closed' },
  ];
  const [seekHelpFilterDrawerOpen, setSeekHelpFilterDrawerOpen] =
    useState(false);
  const [seekHelpDrawerOpen, setSeekHelpDrawerOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [stepperDrawerOpen, setStepperOpen] = useState(false);

  //   -------------------------STORE---------------------------------------

  const {
    getAllSeekHelpData,
    seekHelpState,
    handleChange,
    getSeekHelpData,
    updateFromAndToDateAndTab,
    updateSeekHelpData,
    clearDrawerData,
    getSeekHelpStatusTrackData,
    loading,
  } = UseSeekHelp((state) => ({
    getAllSeekHelpData: state.getAllSeekHelpData,
    seekHelpState: state.seekHelpState,
    handleChange: state.handleChange,
    getSeekHelpData: state.getSeekHelpData,
    getSeekHelpStatusTrackData: state.getSeekHelpStatusTrackData,
    updateFromAndToDateAndTab: state.updateFromAndToDateAndTab,
    updateSeekHelpData: state.updateSeekHelpData,
    clearDrawerData: state.clearDrawerData,
    loading: state.loading,
  }));

  const {
    seekHelpListData,
    seekHelpCount,
    search,
    tabIndex,
    seekHelpStatsCardData,
    name,
    email,
    mobile,
    countryCode,
    content,
    attachmentURL,
    status,
    seekHelpStatus,
    seekHelpStatusTrackData,
    error,
  } = seekHelpState;

  const filterList = [
    'Today',
    'This week',
    'This month',
    'Last week',
    'Last month',
  ];

  const handleChangeFilter = async (event, newValue) => {
    let fromDat = '';
    let toDat = '';
    if (newValue === 0) {
      fromDat = startOfFnc(new Date(), 'day');
      toDat = endOfFnc(new Date(), 'day');
    } else if (newValue === 1) {
      fromDat = startOfFnc(new Date(), 'week');
      toDat = endOfFnc(new Date(), 'week');
    } else if (newValue === 2) {
      fromDat = startOfFnc(new Date(), 'month');
      toDat = endOfFnc(new Date(), 'month');
    } else if (newValue === 3) {
      const lastWeekDate = subtractFnc(new Date(), 1, 'week');
      fromDat = startOfFnc(new Date(lastWeekDate), 'week');
      toDat = endOfFnc(new Date(lastWeekDate), 'week');
    } else if (newValue === 4) {
      const lastMonthDate = subtractFnc(new Date(), 1, 'month');
      fromDat = startOfFnc(new Date(lastMonthDate), 'month');
      toDat = endOfFnc(new Date(lastMonthDate), 'month');
    }
    await updateFromAndToDateAndTab(
      new Date(fromDat),
      new Date(toDat),
      newValue,
    );
    queryClient.invalidateQueries({
      queryKey: ['getAllSeekHelpData'],
    });

    await getAllSeekHelpData(search);
  };

  // -----------------------------Pagination State ---------------------------
  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllSeekHelpData'],
    });
    await getAllSeekHelpData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllSeekHelpData'],
    });
    await getAllSeekHelpData(search, rowsPerPage, offset);
  };

  // ----------------------------------------Drawer Cl;ose-----------------------------
  const closeSeekHelpFilterDrawer = () => {
    setSeekHelpFilterDrawerOpen(false);
  };

  // ----------------------------------------Drug Audit Stepper Drawer Close-----------------------------
  const closeSeekHelpDrawer = () => {
    if (status === 2) {
      clearDrawerData();
    }
    setSeekHelpDrawerOpen(false);
  };

  const onEdit = async (seek_help_id) => {
    queryClient.invalidateQueries({
      queryKey: ['getSeekHelpData'],
    });
    const resCode = await getSeekHelpData(seek_help_id);
    if (resCode === 200) {
      setSeekHelpDrawerOpen(true);
    }
  };

  const logClick = async (seek_help_id) => {
    queryClient.invalidateQueries({
      queryKey: ['getSeekHelpStatusTrackData'],
    });
    const resCode = await getSeekHelpStatusTrackData(seek_help_id);
    if (resCode === 200) {
      setStepperOpen(true);
    }
  };

  // ---------------------------------------- Stepper Drawer Close-----------------------------
  const closeStepperDrawer = () => {
    setStepperOpen(false);
  };

  const columns = [
    {
      field: 'userName',
      headerName: 'User Name',
      width: 160,
    },
    {
      field: 'mobileNumber',
      headerName: 'Contact Number',
      width: 160,
    },
    {
      field: 'emailId',
      headerName: 'Email ID',
      width: 200,
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params?.row?.createdAt, 'lll'),
    },
    {
      field: 'updatedAt',
      headerName: 'Closed On',
      width: 180,
      valueGetter: (params) =>
        params?.row?.status === 'Closed'
          ? getDateFormatToString(params?.row?.updatedAt, 'lll')
          : '-',
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: '12px',
            borderRadius: '6px',
            padding: '4px 8px',
          }}
          backgroundColor={`${
            params?.row?.status === 'Open' ? '#ffffe6' : '#E8FCF0'
          }`}
          color={`${params?.row?.status === 'Open' ? '#FACC15' : '#4CAF50'}`}
        >
          {params?.row?.status}
        </Typography>
      ),
    },

    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1.8, cursor: 'pointer' }}>
          <EditIcon id="editIcon" onClick={() => onEdit(params?.row?.id)} />
          {/* <AwarenessCategoryIcon
            id="logIcon"
            onClick={() => logClick(params?.row?.id)}
          /> */}
          <Link
            id={`${params?.row?.id}`}
            onClick={() => logClick(params?.row?.id)}
            style={{ fontSize: '14px', color: '#007965', fontWeight: 500 }}
          >
            Log
          </Link>
        </Box>
      ),
    },
  ];

  // -------------------TABLE DATA SEARCH------------------------------------
  const handleSearchFnc = async (newData) => {
    handleChange('search', newData);
    queryClient.invalidateQueries({
      queryKey: ['getAllSeekHelpData'],
    });

    await getAllSeekHelpData(newData);
  };

  const updateSeekHelp = async () => {
    if (status === 2) {
      queryClient.invalidateQueries({
        queryKey: ['updateSeekHelpData'],
      });
      const resStatus = await updateSeekHelpData();
      if (resStatus === 200) {
        queryClient.invalidateQueries({
          queryKey: ['getAllSeekHelpData'],
        });
        await getAllSeekHelpData(search);
        setSeekHelpDrawerOpen(false);
      }
    } else {
      setSeekHelpDrawerOpen(false);
    }
  };

  const filterDrawerOpen = () => {
    setSeekHelpFilterDrawerOpen(true);
  };

  //   --------------------------------INITIAL FETCH-------------------------------
  const initialData = async () => {
    const fromDatee = startOfFnc(new Date(), 'day');
    const toDatee = endOfFnc(new Date(), 'day');
    await updateFromAndToDateAndTab(new Date(fromDatee), new Date(toDatee), 0);
    queryClient.invalidateQueries({
      queryKey: ['getAllSeekHelpData'],
    });
    await getAllSeekHelpData('');
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{
        overflow: 'scroll',
        backgroundColor: '#F8F8F9',
        height: '92vh',
      }}
    >
      <Box>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {' '}
          <Typography sx={SeekHelp_style.statsFont}>Stats</Typography>
        </Stack>
        <Grid container spacing={2}>
          {seekHelpStatsCardData.map((data, i) => (
            <Grid item xs={3} key={i} height="100%">
              {' '}
              <TransactionsCard
                percentage={data?.percentage?.slice(1)}
                description={data.description}
                amount={data.count}
                positive={
                  Number(data?.percentage?.slice(0, -1)) > 0 ? true : false
                }
                showPercentage={data.percentage ? true : false}
                colorCode={data?.color ?? '#007965'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={4}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box>
            <Typography sx={SeekHelp_style.statsFont}>Seek Help</Typography>
          </Box>
        </Stack>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Box
            sx={{
              backgroundColor: '#EDEFF2',
              borderRadius: '10px',
            }}
          >
            <FilterTab
              filterList={filterList}
              handleChange={handleChangeFilter}
              value={tabIndex}
              toggleContainerSx={{
                display: '-webkit-box',
                width: '646px',
              }}
              filterTabRootStyle={SeekHelp_style.filterTabRootSx}
            />{' '}
          </Box>
          <Stack flexDirection="row" alignItems="center" gap={2}>
            <SearchField
              id="search"
              searchFieldStyle={SeekHelp_style.searchFieldSx}
              placeholder="Search by Email ID"
              onSearch={search}
              setOnSearch={handleSearchFnc}
              startAdornment={
                <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                  <BiSearch sx={{ fontSize: '14px', width: '14px' }} />
                </IconButton>
              }
            />
            <Box
              data-testId="filter"
              sx={SeekHelp_style.filterContainerSx}
              onClick={filterDrawerOpen}
            >
              <TransactionFilterIcon />
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Box mt={3}>
        {loading ? (
          <Box>
            <TableSkeletonLoader />
          </Box>
        ) : (
          <Box>
            <MasterTable
              columns={columns}
              data={seekHelpListData}
              customTableStyle={SeekHelp_style.dataTableCustomSx}
              hideFooter
              rootStyle={{ height: '68vh' }}
              rowHeight={60}
            />
            <Stack
              flexDirection="row"
              justifyContent="end"
              sx={{ width: '100%' }}
            >
              <Pagination
                totalCount={seekHelpCount}
                onLimitChange={onLimitChange}
                onOffsetchange={onOffsetchange}
                offset={offset}
                rowsPerPage={rowsPerPage}
              />
            </Stack>
          </Box>
        )}
      </Box>

      <SeekHelpFilterDrawer
        seekHelpFilterDrawerOpen={seekHelpFilterDrawerOpen}
        closeSeekHelpFilterDrawer={closeSeekHelpFilterDrawer}
      />

      <SeekHelpDrawer
        isUpdate={seekHelpStatus === 2 ? true : false}
        drawerOpen={seekHelpDrawerOpen}
        loading={loading}
        header="Seek Help"
        closeDrawer={closeSeekHelpDrawer}
        updateSeekHelp={updateSeekHelp}
        // eslint-disable-next-line react/no-children-prop
        children={
          <Box width={328}>
            <Grid>
              <Grid pb={1.5} item md={12} lg={12} xl={12}>
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  placeholder="User name"
                  value={name}
                  isReadOnly
                />
              </Grid>
              <Grid pb={1.5} item md={12} lg={12} xl={12}>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  placeholder="Email"
                  value={email}
                  isReadOnly
                />
              </Grid>
              <Grid pb={1.5} item md={12} lg={12} xl={12}>
                <Label>Mobile Number</Label>
                <MobileInput
                  value={{
                    mobile_code: countryCode ?? '+91',
                    mobile: mobile ?? '',
                  }}
                  disabled
                  isReadonlyCC
                />
              </Grid>

              <Grid pb={1.5} item md={12} lg={12} xl={12}>
                <Label htmlFor="Description">Description</Label>
                <Input
                  id="Description"
                  multiline
                  textFieldStyle={{ marginTop: '4px' }}
                  value={content}
                  fullWidth
                  isReadOnly
                />
              </Grid>
              {Array.isArray(attachmentURL) && attachmentURL?.length > 0 && (
                <Grid pb={1.5} item md={12} lg={12} xl={12}>
                  <>
                    {' '}
                    <Label>Attachment Files</Label>
                    <Box mb={1}>
                      {Array.isArray(attachmentURL) &&
                        attachmentURL.length > 0 &&
                        attachmentURL.map((val, i) => (
                          <PatientReportUploadCard
                            key={i}
                            showReportName
                            // icon={<UploadCloseIcon />}
                            isUploadCard
                            reportName={val?.filename ?? ''}
                            uploadedFor={val?.size ?? ''}
                            uploadedData={[
                              { file: `${val?.downloadUrl ?? ''}` },
                            ]}
                            showCheckBox
                          />
                        ))}
                    </Box>
                  </>
                </Grid>
              )}

              <Grid pb={1.5} item md={12} lg={12} xl={12}>
                <Label isRequired>Status</Label>
                <ToggleButton
                  RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                  isError={error?.status?.length > 0}
                  errorMessage={error?.status}
                  options={statusData}
                  value={status}
                  buttonactiveStyle={SeekHelp_style.togglebuttonactiveSX}
                  buttoninactiveStyle={SeekHelp_style.togglebuttoninactiveSX}
                  onChange={(value) => handleChange('status', value)}
                />
              </Grid>
            </Grid>
          </Box>
        }
      />

      <StepperDrawer
        headerName="Seek Help Log"
        stepperDrawerOpen={stepperDrawerOpen}
        closeStepperDrawer={closeStepperDrawer}
        stepperData={
          Array.isArray(seekHelpStatusTrackData) &&
          seekHelpStatusTrackData?.length > 0
            ? seekHelpStatusTrackData
            : []
        }
      />
    </Box>
  );
}
