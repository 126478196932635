import { useChatBotCount } from '@hc/store';
import { Button, Input, Label } from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { chat_bot_parameter_style } from './style';

export default function ChatBotParameter(props) {
  const { ...rest } = props;
  const [enabled, setEnabled] = useState(false);

  const {
    chatQuesCount,
    getChatCountData,
    upsertChatCount,
    updateCountState,
    error,
  } = useChatBotCount((state) => ({
    chatQuesCount: state.chatQuesCount,
    getChatCountData: state.getChatCountData,
    upsertChatCount: state.upsertChatCount,
    updateCountState: state.updateCountState,
    error: state.error,
  }));

  const getInitialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['question-count/get'],
    });
    await getChatCountData();
  };

  const isValidToSave = () => {
    let isValid = true;
    let errorVal = error;
    if (chatQuesCount <= 0) {
      isValid = false;
      errorVal = 'Please enter the valid count';
    } else {
      errorVal = '';
    }
    updateCountState('error', errorVal);
    return isValid;
  };

  const onUpdate = async () => {
    const result = isValidToSave();
    if (result && enabled) {
      queryClient.invalidateQueries({
        queryKey: ['question-count/update'],
      });
      await upsertChatCount();
      await getInitialData();
      setEnabled(false);
    }
  };

  const onEdit = () => {
    setEnabled(true);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <Box
      {...rest}
      px={2}
      py={2}
      sx={{
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: '#fff',
        borderRadius: '10px',
      }}
    >
      <Box sx={chat_bot_parameter_style.inputGroupSx}>
        <Label
          rootStyle={chat_bot_parameter_style.labelSx}
          htmlFor="chatBotChatCount"
          isRequired
        >
          Questions per month (Platform user)
        </Label>
        <Input
          id="chatBotChatCount"
          type="number"
          textFieldStyle={chat_bot_parameter_style.inputFieldSx}
          onChange={(event) => {
            updateCountState('chatQuesCount', event.target.value);
          }}
          isError={error?.length > 0}
          errorMessage={error}
          disabled={!enabled}
          value={chatQuesCount ?? 0}
          fullWidth
          endAdornment={
            <Button
              buttonStyle={{ height: '28px', width: '100px' }}
              onClick={() => (enabled ? onUpdate() : onEdit())}
            >
              {enabled ? 'Update' : 'Edit'}
            </Button>
          }
        />
      </Box>
    </Box>
  );
}
