export const TransactionsPages_style = {
  statsFont: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#0E1824',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  selectMenuSx: {
    fontSize: '14px',
    color: '#5C6266',
    fontWeight: 500,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .MuiSelect-select': {
      color: '#007965',
    },
    '& .MuiSelect-icon': {
      color: '#007965',
    },
  },
  filterTabRootSx: {
    fontSize: '14px',
    fontWeight: 500,
    height: '20px',
    position: 'relative',
    zIndex: 1,
    color: '#3D3D3D',
    width: '25%',
    textTransform: 'capitalize',
    opacity: 1,
    borderRadius: '8px',
    '&.Mui-selected': {
      color: '#fff',
    },
  },
  selectPaperPropsSx: { boxShadow: '0px 0px 4px 2px #E3E3E3' },
  menuListSx: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#0F0B11',
    textAlign: 'left',
    '&.MuiMenuItem-root.Mui-selected': {
      color: '#007965',
      backgroundColor: '#fff',
    },
    '&.MuiMenuItem-root:hover': {
      backgroundColor: '#fff',
    },
    '&.MuiMenuItem-root.Mui-selected:hover': {
      backgroundColor: '#fff',
    },
  },
  searchFieldSx: {
    width: '360px',
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      px: 2,
      height: '46px',
      border: 'none',
    },

    '&.MuiFormControl-root': {
      backgroundColor: '#fff',
      pb: '4px',
    },
    ' & .MuiOutlinedInput-input': {
      color: 'text.primary',
      backgroundColor: '#fff',
      pt: 0.5,
    },
    '& .MuiOutlinedInput-root': {
      px: 0,
      pt: 0.7,
      backgroundColor: 'tranparent',
      color: '#025140',
      '& fieldset': {
        borderRadius: '8px',
        border: '1px solid',
        borderColor: '#C5C5C5',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },
  // searchFieldSx: {
  //   width: '360px',

  //   '& .MuiOutlinedInput-root': {
  //     px: 0,
  //     pt: 0.7,
  //     backgroundColor: 'tranparent',
  //     '& fieldset': {
  //       borderRadius: '6px',
  //       border: '1.5px solid',
  //       borderColor: '#7B8894',
  //     },
  //     '&:hover fieldset': {
  //       borderColor: '#7B8894',
  //     },
  //     '&.Mui-focused fieldset': {
  //       borderColor: '#7B8894',
  //     },
  //   },
  //   ' & .MuiOutlinedInput-input': {
  //     color: 'text.primary',
  //     fontSize: '16px',
  //     pt: 0.5,
  //   },
  //   '& .MuiOutlinedInput-notchedOutline': {
  //     px: 2,
  //     height: '48px',
  //     border: 'none',
  //   },
  //   '&.MuiFormControl-root': {
  //     backgroundColor: '#fff',
  //     pb: '4px',
  //   },
  // },
  filterContainerSx: {
    background: ' #FFFFFF ',
    border: '1.5px solid #007965',
    borderRadius: '6px',
    width: '39px',
    height: '39px',
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
  },
};
