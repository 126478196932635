import { UseSeekHelp } from '@hc/store/backoffice/seekHelp';
import {
  Button,
  DateRangePicker,
  Drawer,
  Label,
  ToggleButton
} from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { seekHelpFilterDrawer_style } from './style';

function SeekHelpFilterDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    seekHelpFilterDrawerOpen = false,
    closeSeekHelpFilterDrawer = () => false,
    ...rest
  } = props;

  const {
    getAllSeekHelpData,
    seekHelpState,
    clearFilter,
    handleChange,
    updateTabIndex,
    loading,
  } = UseSeekHelp((state) => ({
    getAllSeekHelpData: state.getAllSeekHelpData,
    seekHelpState: state.seekHelpState,
    clearFilter: state.clearFilter,
    handleChange: state.handleChange,
    updateTabIndex: state.updateTabIndex,
    loading: state.loading,
  }));

  const statusData = [
    { value: 0, name: 'All' },
    { value: 1, name: 'Open' },
    { value: 2, name: 'Closed' },
  ];

  const { fromDate, toDate, search, statusType } = seekHelpState;

  const handleDateChange = (key, value) => {
    if (key === 'fromDate' || key === 'statusType') {
      handleChange(key, value);
    } else {
      const endDate = new Date(value);
      if (endDate > fromDate) {
        handleChange(key, value);
      } else {
        toast.error('Please select greater than from date');
      }
    }
  };

  const HandleSubmit = async () => {
    if (fromDate !== '' || toDate !== '') {
      if (
        (typeof fromDate === 'object' && typeof toDate === 'object') ||
        (typeof fromDate === 'object' && typeof toDate === 'object')
      ) {
        const dateFrom = new Date(fromDate);
        const dateTo = new Date(toDate);
        if (dateFrom < dateTo) {
          queryClient.invalidateQueries({
            queryKey: ['getAllSeekHelpData'],
          });

          await getAllSeekHelpData(search);
          updateTabIndex(dateFrom, dateTo);
          closeSeekHelpFilterDrawer();
        } else {
          toast.error('Please select greater than from date');
        }
      } else {
        toast.error('Please select from date / to date');
      }
    } else {
      queryClient.invalidateQueries({
        queryKey: ['getAllSeekHelpData'],
      });

      await getAllSeekHelpData(search);
      closeSeekHelpFilterDrawer();
    }
  };

  const HandleDrawerClose = () => {
    clearFilter();
  };

  return (
    <Box
      sx={{
        ...seekHelpFilterDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {seekHelpFilterDrawerOpen && (
        <Drawer
          show={seekHelpFilterDrawerOpen}
          onCloseDrawer={closeSeekHelpFilterDrawer}
          anchor="right"
          isCloseIconRequired
          header="Filter"
          headerStyle={seekHelpFilterDrawer_style.headerSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                position: 'absolute',
                inset: 'auto 0 0 0',
                mb: 3,
                px: 2,
              }}
            >
              <Button
                buttonStyle={seekHelpFilterDrawer_style.SaveButtonSx}
                loading={loading}
                onClick={() => HandleSubmit()}
              >
                Apply Filter
              </Button>
              <Button
                buttonStyle={seekHelpFilterDrawer_style.cancleButtonSx}
                onClick={() => HandleDrawerClose()}
                disabled={loading}
              >
                Reset
              </Button>
            </Box>
          }
        >
          <Box sx={seekHelpFilterDrawer_style.boxRootSx}>
            <Box sx={seekHelpFilterDrawer_style.inputGroupSx}>
              <Label isRequired>Status</Label>

              <ToggleButton
                RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                // isError={error?.status?.length > 0}
                // errorMessage={error?.status}
                options={statusData}
                value={statusType}
                buttonactiveStyle={
                  seekHelpFilterDrawer_style.togglebuttonactiveSX
                }
                buttoninactiveStyle={
                  seekHelpFilterDrawer_style.togglebuttoninactiveSX
                }
                onChange={(value) => handleDateChange('statusType', value)}
              />
            </Box>

            {/*  ---------------- Seek Help Start Date Field ------------- */}
            <Box sx={seekHelpFilterDrawer_style.inputGroupSx}>
              <Label
                rootStyle={seekHelpFilterDrawer_style.labelSx}
                htmlFor="From Date"
                // isRequired
              >
                From Date
              </Label>
              <DateRangePicker
                // datatestid="startDate"
                startDate={fromDate ?? ''}
                onChange={(val) => {
                  handleDateChange('fromDate', val);
                }}
                dateFormat="dd-MM-yyyy"
                customSx={{
                  backgroundColor: '#fff',
                  borderColor: '#c5c5c5',
                  width: '100%',
                }}
              />
            </Box>

            {/*  ---------------- Seek Help End Date Field ------------- */}
            <Box sx={seekHelpFilterDrawer_style.inputGroupSx}>
              <Label
                rootStyle={seekHelpFilterDrawer_style.labelSx}
                htmlFor="To Date"
                // isRequired
              >
                To Date
              </Label>

              <DateRangePicker
                // datatestid="endDate"
                startDate={toDate ?? ''}
                onChange={(val) => {
                  handleDateChange('toDate', val);
                }}
                dateFormat="dd-MM-yyyy"
                customSx={{
                  backgroundColor: '#fff',
                  borderColor: '#c5c5c5',
                }}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

SeekHelpFilterDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  seekHelpFilterDrawerOpen: PropTypes.func,
  closeSeekHelpFilterDrawer: PropTypes.func,
};

export { SeekHelpFilterDrawer };

