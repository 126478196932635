export const addDonts_style = {
  boxRootSx: {
    display: 'grid',
    gap: 2,
    pt: 1,
    width: '400px',
  },
  SaveButtonSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    width: '180px',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleButtonSx: {
    border: '1px solid',
    width: '180px',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
};
