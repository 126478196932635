/* eslint-disable no-restricted-syntax */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { toast } from 'react-hot-toast';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { create } from 'zustand';

export const useAddPatients = create((set, get) => ({
  patientState: {
    patientDetail: {
      name: '',
      gender: '',
      age: '',
      phone: {
        mobile: '',
      },
      email: '',
    },
    errorValue: {
      name: '',
      gender: '',
      age: '',
      phone: '',
      email: '',
    },
    patientList: [],
    patientCount: '',
  },
  loading: null,
  error: null,

  clearPatientDetail: () => {
    const { patientState } = get();
    const { patientDetail } = patientState;
    set({
      patientState: {
        ...patientState,
        patientDetail: {
          ...patientDetail,
          name: '',
          gender: '',
          age: '',
          phone: {
            mobile: '',
          },
          email: '',
        },
        errorValue: {
          name: '',
          gender: '',
          age: '',
          phone: '',
          email: '',
        },
      },
    });
  },

  getPatientData: async (search, limit, offset) => {
    try {
      const { patientState } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: [`patients-get-all`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/patients/get/all?user_id=${
              tokenData?.id ?? ''
            }&search=${search}&limit=${limit}&offset=${offset}`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });
      const resArr = [];
      for (const object of data?.results) {
        const obj = {
          ...object,
          genderAge: `${object?.gender}/${object?.age}`,
        };
        resArr.push(obj);
      }

      if (status?.code === 200) {
        set({
          loading: false,
          patientState: {
            ...patientState,
            patientList: resArr ?? [],
            patientCount: data?.patient_count ?? '',
          },
        });
      }

      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  organizationPatientStatusUpdate: async (payload) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      const payloadData = {
        admin_user_id: tokenData?.id ?? '',
        user_id: payload?.row?.user_id ?? '',
        status: payload?.row?.user_status_id === 3 ? false : true,
      };

      const { data } = await queryClient.fetchQuery({
        queryKey: ['backoffice-patients-status-update'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/patients/status/update`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'plan Status Updated Successfully'
        );
      }

      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  upsertPatientData: async () => {
    try {
      const { patientState } = get();
      const { patientDetail } = patientState;
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      const payload = {
        user_id: tokenData?.id ?? '',
        name: patientDetail?.name ?? '',
        email_id: patientDetail?.email ?? '',
        country_code: patientDetail?.phone?.mobileCode ?? '+91',
        mobile_no: patientDetail?.phone?.mobile ?? '',
        age: parseInt(patientDetail?.age, 10),
        gender: patientDetail?.gender ?? '',
      };

      const { status } = await queryClient.fetchQuery({
        queryKey: [`user-patient-invite`],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url}/user/patient/invite`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set({
          loading: false,
        });
        toast.success(status?.message);
      }

      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  updatePatientState: (key, value) => {
    const { patientState } = get();
    const { patientDetail } = patientState;

    set({
      patientState: {
        ...patientState,
        patientDetail: {
          ...patientDetail,
          [key]: value,
        },
      },
    });
  },
  updateErrorData: (key, value) => {
    const { patientState } = get();

    set({
      patientState: {
        ...patientState,
        [key]: value,
      },
    });
  },
}));
