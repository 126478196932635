import { CloseIcon } from '@atoms/icons';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import PropTypes from 'prop-types';
import { useState } from 'react';
import { dialog_style } from './style';
import { BackIcon } from '../icons';

function DialogModal(props) {
  const {
    className = '',
    rootStyle = {},
    isDialogOpened = {},
    handleCloseDialog = () => false,
    title = '',
    titleStyle = {},
    topDivider,
    bottomDivider,
    content,
    closeOutStyle = {},
    isCloseOut,
    contentRootStyle = {},
    maxModalWidth = '',
    actions,
    fullWidthscreen = 'sm',
    closeStyle = {},
    DialogActionsboxShadow = {},
    isBackRequired = false,
    dialogRootStyle = {},
    onCloseDialog,
    isMobileResponsive,
    fullWidth = false,
    ...rest
  } = props;

  const theme = useTheme();
  const [maxWidth] = useState(maxModalWidth);
  const fullscreen = useMediaQuery(theme.breakpoints.down(fullWidthscreen));

  const handleClose = () => {
    handleCloseDialog(false);
  };

  return (
    <Box
      sx={{ ...dialog_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Dialog
        fullScreen={fullscreen}
        maxWidth={maxWidth}
        open={isDialogOpened}
        onClose={!onCloseDialog && handleClose}
        PaperProps={{
          sx: { ...dialog_style.dialogRootSx, ...dialogRootStyle },
        }}
        fullWidth={fullWidth}
      >
        <DialogTitle sx={{ padding: '10px 8px' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              alignSelf="center"
              sm={10}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {isBackRequired && (
                <IconButton onClick={handleClose}>
                  <BackIcon />
                </IconButton>
              )}
              <Typography sx={{ ...dialog_style.titleRootSx, ...titleStyle }}>
                {title}
              </Typography>
            </Grid>
            {!isMobileResponsive && isCloseOut && (
              <Box>
                <IconButton
                  id="back"
                  sx={{ ...dialog_style.closeOutsx, ...closeOutStyle }}
                  onClick={handleClose}
                >
                  <CloseIcon
                    rootStyle={{
                      width: '12px',
                      height: '12px',
                      color: 'common.black',
                      ...closeStyle,
                    }}
                  />
                </IconButton>
              </Box>
            )}
            {!isMobileResponsive && !isCloseOut && (
              <Grid
                display="flex"
                justifyContent="end"
                alignSelf="start"
                item
                sm={2}
                md={2}
                lg={2}
                xl={2}
              >
                <IconButton id="close" onClick={handleClose}>
                  <CloseIcon rootStyle={{ ...closeStyle }} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        {topDivider && <Divider />}
        <DialogContent sx={{ ...dialog_style.contentSx, ...contentRootStyle }}>
          {content}
        </DialogContent>
        {bottomDivider && (
          <Box
            sx={{
              borderTop: '1px solid #ededed',
              boxShadow: ' 0px -3px 6px 3px #f5f5f5',
              ...DialogActionsboxShadow,
            }}
          >
            {/* <Divider /> */}
          </Box>
        )}

        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </Box>
  );
}

export { DialogModal };

DialogModal.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  isDialogOpened: PropTypes.object,
  handleCloseDialog: PropTypes.func,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
  content: PropTypes.element,
  actions: PropTypes.element,
  maxModalWidth: PropTypes.string,
  dialogRootStyle: PropTypes.object,
  topDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
  contentRootStyle: PropTypes.object,
  isCloseOut: PropTypes.bool,
  closeOutStyle: PropTypes.object,
  fullWidthscreen: PropTypes.string,
  closeStyle: PropTypes.object,
  DialogActionsboxShadow: PropTypes.object,
  isBackRequired: PropTypes.bool,
  onCloseDialog: PropTypes.bool,
  isMobileResponsive: PropTypes.bool,
  fullWidth: PropTypes.bool,
};
