import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { useRoles } from '@hc/store/backoffice/masters/Roles';
import { TableSkeletonLoader } from '@hc/ui/components';
import { getDateFormatToString } from '@hc/dayjs';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { Box, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { rolesMaster_Style } from './style';

export default function RolesMaster(props) {
  const { ...rest } = props;

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Store Data

  const { roleMasterData, roleCount, getAllRoles, loading } = useRoles(
    (state) => ({
      roleMasterData: state.roleMasterData,
      roleCount: state.roleCount,
      getAllRoles: state.getAllRoles,
      loading: state.loading,
    })
  );

  //  ----- TABLE DATA SEARCH ---------
  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    await getAllRoles(searchValue, rowsPerPage);
    setOffset(0);
  };

  // ------PAGINATION ------------
  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllRoles', search, offset],
    });
    await getAllRoles(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    await getAllRoles(search, rowsPerPage, offset);
  };

  //  TABLE COLUMNS META DATA
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
      field: 'role_name',
      headerName: 'Roles',
      width: 200,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 300,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 300,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
  ];

  //  INITIAL DATA FETCH

  const initialData = async () => {
    await getAllRoles('');
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      {...rest}
      p={2}
      sx={{
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: '#fff',
        borderRadius: '10px',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MasterTopContainer
            isMasterTitleShow
            isTitileShow={false}
            handleSearch={handleSearch}
            title="Roles"
            count={roleCount}
            showSearchField
            searchFieldPlaceholder="Search Roles by name"
            showAddButton={false}
          />
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <TableSkeletonLoader />
          ) : (
            <Box>
              <MasterTable
                columns={columns}
                data={roleMasterData ?? []}
                customTableStyle={rolesMaster_Style.dataTableCustomSx}
                hideFooter
                rootStyle={{ height: '68vh' }}
              />
            </Box>
          )}
          <Stack
            flexDirection="row"
            justifyContent="end"
            sx={{ width: '100%' }}
          >
            <Pagination
              totalCount={roleCount}
              onLimitChange={onLimitChange}
              onOffsetchange={onOffsetchange}
              offset={offset}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

RolesMaster.propTypes = {
  className: PropTypes.string,
};
