import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import toast from 'react-hot-toast';
import { create } from 'zustand';

export const useChatBotCount = create((set, get) => ({
  chatQuesCount: '',
  error: '',
  loading: null,

  // ------------- STORE METHODS ------------------
  getChatCountData: async () => {
    try {
      const token = localStorage.getItem(localStorageKeys.authToken);
      const authToken = parseJwt(token);

      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['question-count/get'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/chat/question-count/get?user_id=${
              authToken.id ?? ''
            }&id=1`,
            {},
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        set({
          loading: false,
          chatQuesCount: data?.data?.question_count ?? '',
        });
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!',
      );
    }
  },

  upsertChatCount: async () => {
    try {
      const { chatQuesCount } = get();
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['question-count/update'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/chat/question-count/update`,
            {
              id: 1,
              json_data: { question_count: chatQuesCount ?? 0 },
            },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(data?.status?.message);
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!',
      );
    }
  },

  updateCountState: (key, value) => {
    set({ [key]: value });
  },
}));
