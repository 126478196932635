export const labTests_Style = {
  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: 'text.hint',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#007965',
      opacity: 1,
    },
  },
  dataTableCustomSx: {
    '& .MuiDataGrid-row': {
      borderBottom: '2px solid',
      borderColor: 'grey.400',
    },
    '& .MuiDataGrid-main': {
      border: '1px solid',
      borderColor: 'grey.400',
      bgcolor: '#FFFFFF',
      borderRadius: '6px',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid',
      borderColor: 'grey.400',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
  },
};
