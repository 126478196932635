export const CustomTextArea_style = {
  wrapperSx: {
    '& .MuiTypography-gutterBottom': {
      marginBottom: '8px',
    },
    '& .MuiTextField-root': {
      backgroundColor: 'common.white',
      '&:hover': {
        borderColor: 'border.main',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: '1.5px solid',
      borderColor: 'border.main',
    },
  },
  expertTextField: {
    '& .MuiOutlinedInput-root': {
      p: 2,
      fontSize: '14px',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'expert.main',
      },
    },
    '& .MuiOutlinedInput-multiline ': {
      p: 0,
    },
    '& .MuiInputAdornment-root': {
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      bottom: 24,
      '& .MuiIconButton-root': {
        p: 0,
        mr: 0.7,
        mb: 0.4,
      },
    },
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      p: 2,
      backgroundColor: '#fff',
      fontSize: '14px',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
    '& .MuiOutlinedInput-multiline ': {
      p: 0,
    },
    '& .MuiInputAdornment-root': {
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      bottom: 24,
      '& .MuiIconButton-root': {
        p: 0,
        mr: 0.7,
        mb: 0.4,
      },
    },
  },
  addSx: {
    backgroundColor: 'orangeTints.600',
    color: 'common.white',
    borderRadius: '6px',
    fontSize: '12px',
    py: 0.5,
    px: 1.4,
  },
  addDisableSx: {
    backgroundColor: '#F5B588',
    color: 'common.white',
    borderRadius: '6px',
    fontSize: '12px',
    py: 0.5,
    px: 1.4,
  },
};
