import { SvgIcon } from '@mui/material';

export function DocumentIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 12.8,
    height: 16,
    color: '#0f0b11',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.8 16"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M9.8 4A1.809 1.809 0 008 5.8v12.4A1.809 1.809 0 009.8 20H19a1.809 1.809 0 001.8-1.8V9.8a.6.6 0 00-.176-.424l-.006-.006-5.194-5.194A.6.6 0 0015 4zm0 1.2h4.6v3.4a1.809 1.809 0 001.8 1.8h3.4v7.8a.591.591 0 01-.6.6H9.8a.591.591 0 01-.6-.6V5.8a.591.591 0 01.6-.6zm5.8.848L18.752 9.2H16.2a.591.591 0 01-.6-.6zM11.8 12.4a.6.6 0 100 1.2H17a.6.6 0 100-1.2zm0 2.8a.6.6 0 100 1.2h3.6a.6.6 0 100-1.2z"
        transform="translate(-8 -4)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function GoogleCalender(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 24,
    height: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g transform="translate(-6 -6)">
        <path
          data-name="Rectangle 55813"
          transform="translate(10.667 10.667)"
          fill="#fff"
          d="M0 0H14.667V14.667H0z"
        />
        <path
          data-name="Path 101051"
          d="M25.68 20.152l.672.96 1.056-.768v5.568h1.152v-7.3h-.96z"
          transform="translate(-6.56 -4.205)"
          fill="#1e88e5"
        />
        <path
          data-name="Path 101052"
          d="M20.962 21.939a2.032 2.032 0 00.675-1.5 2.2 2.2 0 00-2.278-2.112 2.237 2.237 0 00-2.22 1.635l1.1.281a1.116 1.116 0 011.115-.764.974.974 0 110 1.92h-.665v1.152h.665a1.245 1.245 0 011.329 1.093 1.2 1.2 0 01-1.283 1.095 1.242 1.242 0 01-1.276-.945L17 23.977a2.388 2.388 0 002.4 1.913 2.344 2.344 0 002.427-2.245 2.165 2.165 0 00-.865-1.706z"
          transform="translate(-3.667 -4.109)"
          fill="#1e88e5"
        />
        <path
          data-name="Path 101053"
          d="M27 39.333H13.667L13 36.667 13.667 34H27l.667 2.667z"
          transform="translate(-2.333 -9.333)"
          fill="#fbc02d"
        />
        <path
          data-name="Path 101054"
          d="M36.667 27.667L39.333 27V13.667L36.667 13 34 13.667V27z"
          transform="translate(-9.333 -2.333)"
          fill="#4caf50"
        />
        <path
          data-name="Path 101055"
          d="M24.667 11.333l.667-2.667L24.667 6H8a2 2 0 00-2 2v16.667l2.667.667 2.667-.667V11.333z"
          fill="#1e88e5"
        />
        <path
          data-name="Path 101056"
          d="M34 34v5.333L39.333 34z"
          transform="translate(-9.333 -9.333)"
          fill="#e53935"
        />
        <path
          data-name="Path 101057"
          d="M37.333 6H34v5.333h5.333V8a2 2 0 00-2-2z"
          transform="translate(-9.333)"
          fill="#1565c0"
        />
        <path
          data-name="Path 101058"
          d="M8 39.333h3.333V34H6v3.333a2 2 0 002 2z"
          transform="translate(0 -9.333)"
          fill="#1565c0"
        />
      </g>
    </SvgIcon>
  );
}
export function InfoIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 24,
    height: 24,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="Component 71 \u2013 1"
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <path data-name="Rectangle 55830" fill="none" d="M0 0H24V24H0z" />
      <path
        data-name="icons8-info (1)"
        d="M12 4a8 8 0 108 8 8.009 8.009 0 00-8-8zm0 1.2A6.8 6.8 0 115.2 12 6.791 6.791 0 0112 5.2zM12 8a.8.8 0 10.8.8.8.8 0 00-.8-.8zm-.009 2.791a.6.6 0 00-.591.609v4.4a.6.6 0 101.2 0v-4.4a.6.6 0 00-.609-.609z"
        fill={rootSx.color}
        stroke={rootSx.color}
        strokeWidth={0.25}
      />
    </SvgIcon>
  );
}

export function GreenTickIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '20px',
    height: '20px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 20 20"
      {...rest}
    >
      <g data-name="Group 5533" stroke="#f5faf9">
        <g
          data-name="Ellipse 39431"
          transform="translate(-66 -144) translate(68 146)"
          fill="#25c460"
          strokeWidth={2}
        >
          <circle cx={8} cy={8} r={8} stroke="none" />
          <circle cx={8} cy={8} r={9} fill="none" />
        </g>
        <path
          d="M79.156 151.5a.333.333 0 00-.229.1l-4.095 4.095-1.764-1.763a.333.333 0 10-.471.471l2 2a.333.333 0 00.471 0l4.332-4.331a.333.333 0 00-.242-.572z"
          fill="#fff"
          strokeWidth={0.5}
          transform="translate(-66 -144)"
        />
      </g>
    </SvgIcon>
  );
}

export function PainIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 11.951,
    height: 12.5,
    color: '#f58634',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 11.951 12.5"
      {...rest}
    >
      <path
        d="M6.521.25a.273.273 0 00-.271.272 1.239 1.239 0 00.211.633 1.9 1.9 0 001.388.732 3.606 3.606 0 011.313.358 1.933 1.933 0 01.94 1.407 4.11 4.11 0 00.62 1.406 2.5 2.5 0 01.437 1.22.273.273 0 00.543-.052 3.155 3.155 0 00-.515-1.453 3.5 3.5 0 01-.552-1.238 2.543 2.543 0 00-1.2-1.765 4.018 4.018 0 00-1.509-.423 1.311 1.311 0 01-1.009-.5.581.581 0 01-.118-.32.273.273 0 00-.272-.281zM4.335.523a.273.273 0 00-.268.276 1.776 1.776 0 01-.107.541 1.048 1.048 0 01-.69.7 4.905 4.905 0 00-1.22.488h0A2.58 2.58 0 00.795 4.855 1.774 1.774 0 01.55 5.864a2.371 2.371 0 00-.3 1.206.273.273 0 10.545 0 1.668 1.668 0 01.242-.958 2.484 2.484 0 00.3-1.257 1.955 1.955 0 01.993-1.86 4.436 4.436 0 011.1-.436 1.632 1.632 0 001.04-1.031A2.324 2.324 0 004.61.8a.273.273 0 00-.277-.276zm4.9 4.37a.273.273 0 00-.261.268c0 1.606-.273 2.363-.273 3 0 .242.007.724.007.852 0 .909.538 1.674.538 2.965a.273.273 0 10.545 0 21.073 21.073 0 00-.541-2.965v-.849c0-.27.084-.768.162-1.414a2.125 2.125 0 00.492.541.273.273 0 10.329-.435 2.069 2.069 0 01-.71-1.691.273.273 0 00-.284-.268zm-6.543.545a.273.273 0 00-.261.268 2.82 2.82 0 01-.742 1.992.273.273 0 10.393.378 3 3 0 00.555-.826c.028.1.051.227.08.314a3.924 3.924 0 01.259.868v.818a5.221 5.221 0 01-.387 1.051 4.312 4.312 0 00-.431 1.675.273.273 0 10.545 0 3.91 3.91 0 01.387-1.46 3.506 3.506 0 00.431-1.266v-.818a3.3 3.3 0 00-.286-1.041 5.028 5.028 0 01-.259-1.686.273.273 0 00-.284-.268zm3.283 2.177L4.612 9.524h1.091l-1.091 1.908 2.726-2.454H6.25l1.091-1.363z"
        fill="currentColor"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
export function StopIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 12,
    height: 12,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M7.833 6A1.841 1.841 0 006 7.833v8.333A1.841 1.841 0 007.833 18h8.333A1.841 1.841 0 0018 16.167V7.833A1.841 1.841 0 0016.167 6z"
        transform="translate(-6 -6)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function TrashIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 15.2,
    height: 16,
    color: '#f44f5a',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.2 16"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M12.6 4a2.809 2.809 0 00-2.768 2.4H7.1a.539.539 0 00-.192 0H5.6a.6.6 0 100 1.2h.855l1.007 10.412A2.208 2.208 0 009.652 20h5.895a2.207 2.207 0 002.19-1.988L18.744 7.6h.856a.6.6 0 100-1.2h-1.3a.6.6 0 00-.192 0h-2.74A2.809 2.809 0 0012.6 4zm0 1.2a1.585 1.585 0 011.545 1.2h-3.09A1.585 1.585 0 0112.6 5.2zM7.66 7.6h9.879l-1 10.3a.992.992 0 01-1 .9H9.652a.993.993 0 01-1-.9zm3.53 1.991a.6.6 0 00-.591.609v6a.6.6 0 101.2 0v-6a.6.6 0 00-.609-.609zm2.8 0a.6.6 0 00-.591.609v6a.6.6 0 101.2 0v-6a.6.6 0 00-.609-.609z"
        transform="translate(-5 -4)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function GoogleIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 22,
    height: 22,
    ...rootStyle,
  };

  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
      <path
        fill="#ffc107"
        d="M21.786 8.846H20.9V8.8H11v4.4h6.217a6.605 6.605 0 1 1-1.838-7.129L18.49 2.96A10.989 10.989 0 1 0 22 11a11.075 11.075 0 0 0-.214-2.154Z"
        data-name="Path 99440"
      />
      <path
        fill="#ff3d00"
        d="m1.268 5.88 3.614 2.65a6.587 6.587 0 0 1 10.5-2.459L18.49 2.96A10.978 10.978 0 0 0 1.268 5.88Z"
        data-name="Path 99441"
      />
      <path
        fill="#4caf50"
        d="M11 22a10.949 10.949 0 0 0 7.375-2.856l-3.4-2.881A6.583 6.583 0 0 1 4.794 13.23l-3.582 2.764A10.991 10.991 0 0 0 11 22Z"
        data-name="Path 99442"
      />
      <path
        fill="#1976d2"
        d="M21.786 8.846H20.9V8.8H11v4.4h6.217a6.622 6.622 0 0 1-2.248 3.064l3.4 2.881A10.641 10.641 0 0 0 22 11a11.075 11.075 0 0 0-.214-2.154Z"
        data-name="Path 99443"
      />
    </SvgIcon>
  );
}

export function WarningIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: 63.6,
    height: 63.6,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 63.6 63.6"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="Path 943"
        d="M50.033 25a25.033 25.033 0 1025.033 25.033A25.033 25.033 0 0050.033 25z"
        transform="translate(-14.5 -14.5) translate(-3.266 -3.466)"
        fill="#feeaea"
      />
      <path
        data-name="Path 944"
        d="M46.3 16a30.3 30.3 0 1030.3 30.3A30.3 30.3 0 0046.3 16z"
        fill="none"
        stroke="#444b54"
        strokeMiterlimit={10}
        strokeWidth={3}
        transform="translate(-14.5 -14.5)"
      />
      <path
        d="M16.02 5.053a2.675 2.675 0 00-2.372 1.367l-9.3 16.8a2.741 2.741 0 002.372 4.027h18.6a2.741 2.741 0 002.372-4.027l-9.3-16.8a2.675 2.675 0 00-2.372-1.367zm0 1.74a.911.911 0 01.8.5l9.3 16.8a.885.885 0 01-.8 1.353H6.723a.885.885 0 01-.8-1.353l9.3-16.8a.911.911 0 01.797-.501zm-.014 4.822a.9.9 0 00-.887.914v6.006a.9.9 0 101.8 0v-6.006a.9.9 0 00-.915-.914zm.014 9.623a1.2 1.2 0 101.2 1.2 1.2 1.2 0 00-1.2-1.201z"
        transform="translate(-14.5 -14.5) translate(30.279 30.152)"
        fill="#f44f5a"
      />
    </SvgIcon>
  );
}
export function CalenderColorIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 14,
    width: 14,
    color: 'secondary.main',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-calendar"
        d="M8.167,6A2.174,2.174,0,0,0,6,8.167v7.667A2.174,2.174,0,0,0,8.167,18h7.667A2.174,2.174,0,0,0,18,15.833V8.167A2.174,2.174,0,0,0,15.833,6Zm0,1h7.667A1.159,1.159,0,0,1,17,8.167v.5H7v-.5A1.159,1.159,0,0,1,8.167,7ZM7,9.667H17v6.167A1.159,1.159,0,0,1,15.833,17H8.167A1.159,1.159,0,0,1,7,15.833ZM9.167,11a.833.833,0,1,0,.833.833A.833.833,0,0,0,9.167,11ZM12,11a.833.833,0,1,0,.833.833A.833.833,0,0,0,12,11Zm2.833,0a.833.833,0,1,0,.833.833A.833.833,0,0,0,14.833,11ZM9.167,14a.833.833,0,1,0,.833.833A.833.833,0,0,0,9.167,14ZM12,14a.833.833,0,1,0,.833.833A.833.833,0,0,0,12,14Z"
        transform="translate(-6 -6)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function ForwardIcon(props) {
  const { rootStyle, opacity, ...rest } = props;
  const rootSx = {
    width: 20,
    height: 20,
    color: 'rgba(24,68,87,0.48)',
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="Group 5407"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Ellipse 39211" fill="#fff" stroke="#e2e2e2" strokeWidth={1}>
        <circle cx={10} cy={10} r={10} stroke="none" />
        <circle cx={10} cy={10} r={9.5} fill="none" />
      </g>
      <path
        d="M8.234 6.471l3.44 3.44-3.44 3.441a.391.391 0 00.553.553l3.717-3.717a.391.391 0 000-.553L8.787 5.92a.391.391 0 00-.553.551z"
        fill="currentColor"
        stroke="rgba(24,68,87,0.48)"
        strokeWidth={1}
        opacity={opacity ?? 1}
      />
    </SvgIcon>
  );
}
export function VideoIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 12,
    color: '#101010',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 12"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M6.6 9A2.609 2.609 0 004 11.6v6.8A2.609 2.609 0 006.6 21h6.8a2.609 2.609 0 002.6-2.6v-.54l3.091 1.855A.6.6 0 0020 19.2v-8.4a.6.6 0 00-.909-.515L16 12.14v-.54A2.609 2.609 0 0013.4 9zm0 1.2h6.8a1.391 1.391 0 011.4 1.4v6.8a1.391 1.391 0 01-1.4 1.4H6.6a1.391 1.391 0 01-1.4-1.4v-6.8a1.391 1.391 0 011.4-1.4zm12.2 1.66v6.28L16 16.46v-2.92z"
        transform="translate(-4 -9)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function CougeIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 12.064,
    height: 14.5,
    color: '#f58634',
    strokeWidth: 0.5,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 12.064 14.5"
      {...rest}
    >
      <path
        data-name="icons8-coughing (1)"
        d="M7.258.254a.3.3 0 00-.081.01 1.963 1.963 0 00-.735.407 2.008 2.008 0 00-.5.671 1.37 1.37 0 00-.389-.3A1.788 1.788 0 004.809.86a.304.304 0 100 .609 1.207 1.207 0 01.469.118.693.693 0 01.41.5 4.862 4.862 0 00-4.284 3.4 1.491 1.491 0 00-.007 2.908 4.86 4.86 0 003.338 3.231 1.443 1.443 0 002.577 0 4.862 4.862 0 003.34-3.232 1.491 1.491 0 00-.002-2.903 4.862 4.862 0 00-4.3-3.4 1.45 1.45 0 01.486-.965 1.268 1.268 0 01.48-.274.302.302 0 00-.064-.6zM5.995 2.688h.078a4.262 4.262 0 014.071 3.13.3.3 0 00.259.222.913.913 0 010 1.814.3.3 0 00-.259.222 4.274 4.274 0 01-3.06 3 .3.3 0 00-.206.178.913.913 0 01-1.685 0 .3.3 0 00-.206-.177 4.274 4.274 0 01-3.06-3 .3.3 0 00-.262-.223.913.913 0 010-1.813.3.3 0 00.259-.222 4.262 4.262 0 014.071-3.131zM3.909 6.793a.301.301 0 00-.1.594l.913.3a.305.305 0 10.193-.578l-.913-.3a.3.3 0 00-.093-.016zm4.268.152a.3.3 0 00-.111.017l-.913.3a.305.305 0 10.193.578l.913-.3a.3.3 0 00-.082-.594zM6.032 9.381a.609.609 0 10.609.609.609.609 0 00-.609-.609zm-1.53 3.04a.3.3 0 00-.246.141l-.609.927a.304.304 0 10.509.334l.609-.927a.3.3 0 00-.263-.475zm3.048 0a.3.3 0 00-.25.477l.609.913a.304.304 0 10.506-.338l-.609-.913a.3.3 0 00-.256-.139zm-1.523.3a.3.3 0 00-.3.308v.913a.304.304 0 10.609 0v-.913a.3.3 0 00-.309-.308z"
        fill="currentColor"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
export function PillIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 12.475,
    height: 12.25,
    color: '#f58634',
    strokeWidth: 0.25,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 12.475 12.25"
      {...rest}
    >
      <path
        d="M8.822.125a3.519 3.519 0 00-2.5 1.031L1.381 6.102a3.531 3.531 0 004.993 4.993l2.577-2.577a.468.468 0 00.1-.1l2.264-2.264A3.527 3.527 0 008.815.13zm0 .931a2.6 2.6 0 011.834 4.43L8.671 7.468 5.003 3.804l1.989-1.985a2.585 2.585 0 011.83-.763zM4.341 4.468l3.667 3.667-2.3 2.3a2.586 2.586 0 01-3.667 0h0a2.586 2.586 0 010-3.667zm-.667 1.637a.468.468 0 10.468.468.468.468 0 00-.468-.469zm1.562.625a.468.468 0 10.468.468.468.468 0 00-.469-.469zm-2.812.938a.468.468 0 10.468.468.468.468 0 00-.468-.468zm1.562.312a.468.468 0 10.468.468.468.468 0 00-.468-.47zm1.874.312a.468.468 0 10.468.468.468.468 0 00-.468-.47zm-2.811.937a.468.468 0 10.468.468.468.468 0 00-.468-.47zm1.562.312a.468.468 0 10.468.468.468.468 0 00-.468-.469z"
        fill="currentColor"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
export function HeartIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 13.583,
    height: 12.25,
    color: '#f58634',
    strokeWidth: 0.5,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 13.583 12.25"
      {...rest}
    >
      <path
        d="M1.958.125A1.841 1.841 0 00.125 1.958v8.333a1.841 1.841 0 001.833 1.834h9.667a1.841 1.841 0 001.833-1.833V1.958A1.841 1.841 0 0011.625.125zm0 1h9.667a.826.826 0 01.833.833v8.333a.826.826 0 01-.833.834H1.958a.826.826 0 01-.833-.833V7.125h.833a.5.5 0 00.428-.243l.506-.842.942 2.121a.5.5 0 00.455.3.512.512 0 00.457-.293l1.156-2.543 1.252 3.5a.5.5 0 00.44.33h.031a.5.5 0 00.447-.276l1.2-2.391h.353a.833.833 0 100-1h-.667a.5.5 0 00-.447.276l-.809 1.619-1.277-3.558a.5.5 0 00-.45-.331.494.494 0 00-.476.292L4.297 6.739l-.882-1.984a.5.5 0 00-.885-.054l-.855 1.424h-.55V1.958a.826.826 0 01.833-.833z"
        fill="currentColor"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
export function HeartPulse(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 14.25,
    height: 12.25,
    color: '#f58634',
    strokeWidth: 0.5,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 14.25 12.25"
      {...rest}
    >
      <path
        data-name="icons8-heart-with-pulse (1)"
        d="M3.958.125C2.21.125.125.848.125 4.292a3.158 3.158 0 00.083.833c0-.006 1 0 1 0a4.393 4.393 0 01-.083-.833c0-2.19.874-3.167 2.833-3.167a3.178 3.178 0 012.735 1.576.5.5 0 00.865 0 3.178 3.178 0 012.734-1.576c1.959 0 2.833.977 2.833 3.167 0 3.844-4.959 6.311-6 6.786a13.638 13.638 0 01-4.826-3.621H1.077c1.822 2.938 5.647 4.543 5.858 4.63a.5.5 0 00.379 0c.278-.114 6.811-2.851 6.811-7.8 0-3.439-2.085-4.162-3.833-4.162A4.181 4.181 0 007.125 1.59 4.181 4.181 0 003.958.125zm2.327 3.333a.494.494 0 00-.449.294L4.63 6.406l-.881-1.981a.5.5 0 00-.424-.3h-.031a.5.5 0 00-.431.242l-.855 1.424H.958a.5.5 0 000 1h1.334a.5.5 0 00.428-.243l.506-.842.942 2.119a.5.5 0 00.457.3.512.512 0 00.457-.293l1.156-2.543 1.252 3.5a.5.5 0 00.44.33h.031a.5.5 0 00.447-.276l1.2-2.391h.361a.833.833 0 100-1h-.67a.5.5 0 00-.447.276l-.809 1.619L6.762 3.79a.5.5 0 00-.45-.331z"
        fill="currentColor"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
export function MicroIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 12.365,
    height: 12.25,
    color: '#f58634',
    strokeWidth: 0.5,
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 12.365 12.25"
      {...rest}
    >
      <path
        d="M2.481.125A2.231 2.231 0 00.497 1.297a2.542 2.542 0 00-.261 1.078.75.75 0 101.5 0 1.214 1.214 0 01.1-.422c.1-.2.141-.328.641-.328a.75.75 0 100-1.5zm4.235 1.484a3.775 3.775 0 00-.391.016 3.517 3.517 0 00-2.813 4.828A1.912 1.912 0 013.528 8h.016a2.353 2.353 0 00-.308 1.281v.031a2.606 2.606 0 002.953 2.266h.016a4.017 4.017 0 002.859-1.875 8.189 8.189 0 001.172-4.578 3.52 3.52 0 00-3.52-3.516zm0 1a2.494 2.494 0 012.52 2.516 7.259 7.259 0 01-1 4.031 2.97 2.97 0 01-2.156 1.422A1.581 1.581 0 014.236 9.25a1.356 1.356 0 01.172-.75l.016-.031.012-.016a2.919 2.919 0 00.016-2.359 2.5 2.5 0 011.984-3.469 2.745 2.745 0 01.28-.016zm.02 1.516a1 1 0 101 1 1 1 0 00-1-1zm4.922 0a.5.5 0 00-.422.422 4 4 0 01-.266 1.016 1.385 1.385 0 01-.125.25.5.5 0 10.781.625 2.363 2.363 0 00.266-.469 4.947 4.947 0 00.344-1.266.5.5 0 00-.583-.578zM1.936 5.609a.751.751 0 00-.7.656 1.3 1.3 0 01-.594.953.75.75 0 10.687 1.312 2.919 2.919 0 001.407-2.046.75.75 0 00-.8-.875zm4.3 2.516a.5.5 0 10.5.5.5.5 0 00-.5-.5zm-4 2a.5.5 0 10.5.5.5.5 0 00-.5-.5zm9 0a1 1 0 101 1 1 1 0 00-1-1z"
        fill="currentColor"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
export function Document(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 9.85,
    height: 12.25,
    color: '#f58634',
    strokeWidth: 0.5,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 9.85 12.25"
      {...rest}
    >
      <path
        data-name="icons8-document (1)"
        d="M1.475.125a1.357 1.357 0 00-1.35 1.35v9.3a1.357 1.357 0 001.35 1.35h6.9a1.357 1.357 0 001.35-1.35v-6.3a.45.45 0 00-.132-.318h0l-3.9-3.9a.45.45 0 00-.318-.132zm0 .9h3.45v2.55a1.357 1.357 0 001.35 1.35h2.55v5.85a.443.443 0 01-.45.45h-6.9a.443.443 0 01-.45-.45v-9.3a.443.443 0 01.45-.45zm4.35.636l2.364 2.364H6.275a.443.443 0 01-.45-.45zm-2.85 4.764a.45.45 0 100 .9h3.9a.45.45 0 100-.9zm0 2.1a.45.45 0 100 .9h2.7a.45.45 0 100-.9z"
        fill="currentColor"
        stroke="currentColor"
      />
    </SvgIcon>
  );
}
export function ConfirmAppointmentIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 78.066,
    height: 78.066,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78.066 78.066"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="Path 942"
        d="M53.033 16a37.033 37.033 0 1037.033 37.033A37.033 37.033 0 0053.033 16z"
        fill="#fff"
        transform="translate(-14 -14)"
      />
      <path
        data-name="Path 943"
        d="M53.5 25A28.5 28.5 0 1082 53.5 28.5 28.5 0 0053.5 25z"
        transform="translate(-14 -14) translate(-.467 -.467)"
        fill="rgba(0,121,101,0.16)"
      />
      <path
        data-name="Path 944"
        d="M53.033 16a37.033 37.033 0 1037.033 37.033A37.033 37.033 0 0053.033 16z"
        fill="none"
        stroke="#444b54"
        strokeWidth={4}
        transform="translate(-14 -14)"
      />
      <path
        data-name="Path 945"
        d="M36.06 56.891l10.454 9.255 23.493-27"
        fill="none"
        stroke="#444b54"
        strokeLinecap="round"
        strokeWidth={4}
        transform="translate(-14 -14)"
      />
    </SvgIcon>
  );
}
export function SearchIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 16,
    height: 16,
    color: 'text.hint',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      sx={rootSx}
      {...rest}
    >
      <path
        d="M12.444 6a6.444 6.444 0 103.876 11.578l4.162 4.161a.889.889 0 101.257-1.257l-4.161-4.162A6.43 6.43 0 0012.444 6zm0 1.778a4.668 4.668 0 013.4 7.865.889.889 0 00-.2.2 4.667 4.667 0 11-3.2-8.064z"
        transform="translate(-6 -6)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function HeightNoBgIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 15.998,
    width: 16,
    color: 'rgba(24,87,76,0.48)',
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 15.998"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="icons8-length 1"
        d="M12.2 2.027a2.371 2.371 0 00-1.682.692l-7.805 7.818a2.346 2.346 0 00.038 3.4l3.388 3.393a2.392 2.392 0 003.364 0l7.807-7.813a2.4 2.4 0 000-3.368L13.884 2.72a2.371 2.371 0 00-1.684-.693zm0 1.56a.782.782 0 01.548.266l3.427 3.429a.7.7 0 010 1.1L8.369 16.2a.7.7 0 01-1.1 0l-3.442-3.448-.02-.019a.741.741 0 01.038-1.062l.979-.981 2.428 2.49L8.4 12.06l-2.441-2.5L7.216 8.3l1.26 1.355 1.173-1.097-1.3-1.4 1.29-1.288 2.48 2.483 1.133-1.136-2.478-2.483.879-.88a.785.785 0 01.547-.266z"
        transform="translate(-2 -2.027)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
