/* eslint-disable no-sparse-arrays */
// import { envConfig } from '@hc/config';
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructPlanCradData = (data) => {
  if (Object.keys(data).length > 0) {
    return [
      {
        amount: data?.Count,
        percentage: '',
        description: 'Number of Users',
        color: '#007965',
      },
      {
        amount: data?.renewal,
        percentage: '',
        description: 'Renewal plans',
        color: '#007965',
      },
      {
        amount: data?.newPlan,
        percentage: '',
        description: 'New plans',
        color: '#007965',
      },
      {
        amount: data?.underReview,
        percentage: '',
        description: 'Under expert review',
        color: '#FACC15',
      },
    ];
  }
};

export const constructPlanTableData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((val) => {
      return {
        value: val,
        id: val?.mbf_user_plan_id ?? 0,
        plan_id: val?.mbf_user_plan_id ?? 0,
        user_name: val?.mbf_user_name ?? '',
        requested_date: val?.plan_requested_date ?? '',
        change_requested_date: val?.plan_change_request_date ?? '',
        plan_status_name: val?.plan_status_name ?? '',
        plan_type: val?.plan_type ?? '',
        mind: val?.statusData[0]?.status_name ?? 'pending',
        mindExpertName: val?.statusData[0]?.expert_name ?? '',
        mindLastUpdate: val?.statusData[0]?.updated_at ?? '',
        body: val?.statusData[1]?.status_name ?? 'pending',
        bodyExpertName: val?.statusData[1]?.expert_name ?? '',
        bodyLastUpdate: val?.statusData[1]?.updated_at ?? '',
        food: val?.statusData[2]?.status_name ?? 'pending',
        foodExpertName: val?.statusData[2]?.expert_name ?? '',
        foodLastUpdate: val?.statusData[2]?.updated_at ?? '',
        statusData: val?.statusData,
      };
    });
    return arr;
  }
};

export const showCatogory = (arrData) => {
  let dataaa = [];
  if (arrData?.length === 1) {
    arrData?.[0] === '1'
      ? (dataaa = ['Mind'])
      : arrData?.[0] === '2'
      ? (dataaa = ['Body'])
      : arrData?.[0] === '3'
      ? (dataaa = ['Food'])
      : (dataaa = ['Mind']);
  } else if (arrData?.length === 2) {
    arrData?.map((val, i) => {
      i === 0
        ? dataaa.push(
            val === '1'
              ? 'Mind'
              : val === '2'
              ? 'Body'
              : val === '3'
              ? 'Food'
              : 'Mind',
          )
        : dataaa.push(
            val === '1'
              ? ' & Mind'
              : val === '2'
              ? ' & Body'
              : val === '3'
              ? ' & Food'
              : ' & Mind',
          );
    });
  } else if (arrData?.length === 3) {
    arrData?.map((val, i) => {
      i === 0
        ? dataaa.push(
            val === '1'
              ? 'Mind'
              : val === '2'
              ? 'Body'
              : val === '3'
              ? 'Food'
              : 'Mind',
          )
        : i === 1
        ? dataaa.push(
            val === '1'
              ? ', Mind'
              : val === '2'
              ? ', Body'
              : val === '3'
              ? ', Food'
              : ', Mind',
          )
        : dataaa.push(
            val === '1'
              ? ' & Mind'
              : val === '2'
              ? ' & Body'
              : val === '3'
              ? ' & Food'
              : ' & Mind',
          );
    });
  }
  return dataaa?.toString()?.replace(',,', ',');
};

export const ConstructStatusTrackData = (data) => {
  let arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val) => {
      arr.push({
        // light-yellow : #ffffe6 // "User requested to this plan"  "Expert is reviewing this plan"    "Expert kept this plan as a draft"   "User started & doing activities based on the plan" "Plan change request raised by the user"   "Change request assigned to Expert by Admin"    "Change request assigned:"
        // light-green : #E8FCF0 //    "Expert published & waiting for user to take up the plan"   "Expert published"   "Plan updated by the expert based on the MBF user change request"  "Plan updated by the expert"   "User completed this plan"
        // light-red : #FEEAEA // "Review cancelled by Expert"
        backgroundColor:
          val?.statusName === 'User requested to this plan' ||
          val?.statusName === 'Expert is reviewing this plan' ||
          val?.statusName === 'Expert kept this plan as a draft' ||
          val?.statusName ===
            'User started & doing activities based on the plan' ||
          val?.statusName === 'Plan change request raised by the user' ||
          val?.statusName === 'Change request assigned to Expert by Admin' ||
          val?.statusName?.startsWith('Change request assigned:')
            ? '#ffffe6'
            : val?.statusName ===
                'Expert published & waiting for user to take up the plan' ||
              val?.statusName === 'Expert published' ||
              val?.statusName ===
                'Plan updated by the expert based on the MBF user change request' ||
              val?.statusName === 'Plan updated by the expert' ||
              val?.statusName === 'User completed this plan'
            ? '#E8FCF0'
            : '#FEEAEA',
        // yellow : #FACC15
        // green : #4CAF50
        // red : #F44F5A
        color:
          val?.statusName === 'User requested to this plan' ||
          val?.statusName === 'Expert is reviewing this plan' ||
          val?.statusName === 'Expert kept this plan as a draft' ||
          val?.statusName ===
            'User started & doing activities based on the plan' ||
          val?.statusName === 'Plan change request raised by the user' ||
          val?.statusName === 'Change request assigned to Expert by Admin' ||
          val?.statusName?.startsWith('Change request assigned:')
            ? '#FACC15'
            : val?.statusName ===
                'Expert published & waiting for user to take up the plan' ||
              val?.statusName === 'Expert published' ||
              val?.statusName ===
                'Plan updated by the expert based on the MBF user change request' ||
              val?.statusName === 'Plan updated by the expert' ||
              val?.statusName === 'User completed this plan'
            ? '#4CAF50'
            : '#F44F5A',
        title: val?.statusName ?? '',
        role:
          val?.roleName === 'Expert'
            ? showCatogory(val?.expertCategory) + ' Expert'
            : val?.roleName,
        subTitle: val?.userProfileName
          ? val?.userProfileName
          : val?.userProfileFirstName
          ? val?.userProfileFirstName
          : '',
        dateTime: val?.createAt ?? '',
        dateTimeFormat: 'DD MMM YYYY, hh:mm A',
      });
    });
  }
  return arr;
};

export const UsePlanStatus = create((set, get) => ({
  planStatusListData: [],
  planCardData: [],
  planChangeRequestStatusTrackData: [],
  planChangeRequestData: [],
  planChangeRequestStatusData: [],
  planStatusChangeRequestHistoryData: [],
  planStatusCount: 0,
  planStatus: 0,
  planType: '',
  planFilterStatus: 0,
  planFilterType: '',
  orgSearch: '',
  loading: null,
  error: null,
  isIamValidToSave: () => {
    const { activity } = get();
    const activityCopy = JSON.parse(JSON.stringify(activity));
    let isValid = true;
    const error = activityCopy?.error;
    // checking drugName
    if (!activity?.activity_name) {
      isValid = false;
      error.activity_name = 'Please enter your activity name';
    } else {
      error.activity_name = '';
    }
    // checking time
    if (!activity?.category_id) {
      isValid = false;
      error.category_id = 'Please select category type';
    } else {
      error.category_id = '';
    }
    // checking when
    if (!activity?.goal_id) {
      isValid = false;
      error.goal_id = 'Please select goal type';
    } else {
      error.goal_id = '';
    }

    if (!activity?.activity_time) {
      isValid = false;
      error.activity_time = 'Please select activity time';
    } else {
      error.activity_time = '';
    }
    set({
      activity: {
        ...activity,
        error,
      },
    });

    return isValid;
  },

  // GET ALL ACTIVITY TEMPLATE DATA
  getAllPlanStatusData: async (
    search = '',
    mbfUserPlanId = '',
    limit = 10,
    offset = 0,
  ) => {
    const { planStatus, planType, orgSearch } = get();
    try {
      set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['planstatusData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/mbf-plan-status-detail`,
            {
              search,
              limit,
              offset,
              planStatus,
              planType,
              orgSearch,
              mbfUserPlanId,
            },
            true,
          );

          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          loading: false,
          planStatusListData:
            Array.isArray(data?.tableData) && data?.tableData?.length > 0
              ? constructPlanTableData(data?.tableData)
              : [],
          planCardData:
            Object.keys(data).length > 0
              ? constructPlanCradData({
                  Count: data?.totalCount,
                  renewal: data?.renewalPlanCount,
                  newPlan: data?.newPlanCount,
                  underReview: data?.underExpertReviewCount,
                })
              : [],
          planStatusCount: data?.totalCount,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  // ASSIGN CHANGE REQUEST TO EXPERT
  updatePlanChangeRequestData: async (payload) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      set({ loading: true });
      const response = await queryClient.fetchQuery({
        queryKey: ['updatePlanChangeRequestData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/plan-change-request-assign`,
            {
              ...payload,
              user_id: tokenData?.id,
            },
            true,
          );

          return response;
        },
        staleTime: Infinity,
      });

      set(() => ({ loading: false }));
      return response?.data?.status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  // CLOSE CHANGE REQUEST BY ADMIN
  ClosePlanChangeRequest: async (payload) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      set({ loading: true });
      const response = await queryClient.fetchQuery({
        queryKey: ['closePlanChangeRequest'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/plan-change-request-close`,
            {
              ...payload,
              user_id: tokenData?.id,
            },
            true,
          );

          return response;
        },
        staleTime: Infinity,
      });

      set(() => ({ loading: false }));
      return response?.data?.status;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  handlePlanChange: (key, value) => {
    set({
      [key]: value,
    });
  },

  clearPlanFilter: () => {
    set({
      planStatus: 0,
      planType: '',
    });
  },

  // GET MBF USER PLAN CHANGE REQUEST STATUS TRACH (LOG)
  getPlanChangeRequestStatusTrackData: async (mbf_user_plan_id) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getPlanChangeRequestStatusTrackData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/mbf-user-plan/track-status?user_id=${tokenData?.id}&mbf_user_plan_id=${mbf_user_plan_id}`,
            {},
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set({
          planChangeRequestStatusTrackData:
            Array.isArray(data) && data?.length > 0
              ? ConstructStatusTrackData(data)
              : [],
        });
      }
      return status?.code;
    } catch (error) {
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  // GET MBF USER PLAN CHANGE REQUEST DATA
  GetPlanChangeRequestData: async (mbf_user_plan_id) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getPlanChangeRequestData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/mbf-user-plan/get?user_id=${tokenData?.id}&mbf_user_plan_id=${mbf_user_plan_id}`,
            {},
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set({
          planChangeRequestData:
            Array.isArray(data?.planData) && data?.planData?.length > 0
              ? data?.planData
              : [],
          planChangeRequestStatusData:
            Array.isArray(data?.planStatusData) &&
            data?.planStatusData?.length > 0
              ? data?.planStatusData
              : [],
          planStatusChangeRequestHistoryData:
            Array.isArray(data?.planStatusChangeRequestHistoryData) &&
            data?.planStatusChangeRequestHistoryData?.length > 0
              ? data?.planStatusChangeRequestHistoryData
              : [],
        });
      }
      return status?.code;
    } catch (error) {
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },
}));
