/* eslint-disable camelcase */
export const PlanManagement_style = {
  headerSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleSx: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: 'medium',
  },
  daterangeSx: {
    width: '250px',
  },
  dataTableCustomSx: {
    '& .MuiDataGrid-row': {
      borderBottom: '2px solid',
      borderColor: 'grey.400',
    },

    '& .MuiDataGrid-main': {
      border: '1px solid',
      borderColor: 'grey.400',
      bgcolor: '#FFFFFF',
      borderRadius: '6px',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid',
      borderColor: 'grey.400',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
  },
  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: 'text.hint',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#007965',
      opacity: 1,
    },
  },
  addMasterSx: {
    width: 'fit-content',
  },

  togglebuttoninactiveSX: {
    color: '#5C6266',
    border: '1px solid ',
    borderColor: 'transparent',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '8px',
    // padding: '5px 10px',
    backgroundColor: '#E7FCF9',
    '&:hover': {
      color: '#5C6266',
      backgroundColor: '#E7FCF9',
    },
  },
  togglebuttonactiveSX: {
    color: 'primary.main',
    backgroundColor: '#E7FCF9',
    borderColor: 'primary.main',
    border: '1px solid ',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    // padding: '5px 10px',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: '#E7FCF9',
    },
  },
  buttonactiveSx: {
    border: '2px solid ',
    color: '#fff',
    textTransform: 'capitalize',
    padding: '10px',
    borderRadius: '8px',
    bgcolor: 'primary.main',
    cursor: 'pointer',
  },
  buttoninactiveSx: {
    backgroundColor: '#fff',
    border: '2px solid #E3E3E3',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: '12px',
    padding: '10px',
    color: '#5C6266',
    cursor: 'pointer',
  },
};
