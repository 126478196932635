import { Box, Divider, Grid, Stack, Switch, Typography } from '@mui/material';
import {
  ActivityDontsEditIcon,
  ActivityNotesEditIcon,
  ActivityReffernceEditIcon,
  AlarmClockIcon,
  Button,
  DeleteIcon,
  Drawer,
  Dropdown,
  Input,
  Label,
  ToggleButton,
  UploadIcon,
} from '@hc/ui/atoms';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { useCategoryMaster } from '@hc/store/backoffice/categoryMaster';
import { useExpertTemplate } from '@hc/store/backoffice/expertTemplate';
import { useActivityUnitMaster } from '@hc/store/backoffice/activityUnitMaster';
import { useActivityDayMaster } from '@hc/store/backoffice/activityDayMaster';
import { useActivityTimeMaster } from '@hc/store/backoffice/activityTimeMaster';
import { useActivityGoalMaster } from '@hc/store/backoffice/activityGoalMaster';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { DaySelector } from '../../daySelector';
import { AddNotes } from '../addNotes';
import { AddDonts } from '../addDonts';
import { AddReferences } from '../addReferences';
import { TagsToggleButton } from '../tagsToggleButton';
import { addExpertDrawer_style } from './style';

function AddExpertDrawer(props) {
  const {
    className = '',
    drugDrawerOpen = false,
    closeDrugMasterDrawer = () => false,
    rootStyle,
    type,
    ...rest
  } = props;

  const [component, setComponent] = useState(0);
  const [showUnits, setshowUnits] = useState(true);
  const [daysInString, setdaysInString] = useState('');

  // ------------------------STORE---------------------
  const {
    handleActivityFormChange,
    activity,
    handleActivityNotesChange,
    expertPictureUpsert,
    updateActivityPicture,
    clearActivity,
    approveActivityData,
    activity_references,
    handleActivitySpecificChange,
    getAllExpertTemplateData,
    clearNotes,
    clearDonts,
    clearReference,
    clearUnits,
    deleteProfileImage,
    rejectActivityData,
    loading,
  } = useExpertTemplate((state) => ({
    getAllExpertTemplateData: state.getAllExpertTemplateData,
    clearNotes: state.clearNotes,
    handleActivityFormChange: state.handleActivityFormChange,
    activity: state.activity,
    handleActivityNotesChange: state.handleActivityNotesChange,
    expertPictureUpsert: state.expertPictureUpsert,
    updateActivityPicture: state.updateActivityPicture,
    clearActivity: state.clearActivity,
    approveActivityData: state.approveActivityData,
    activity_references: state.activity_references,
    handleActivitySpecificChange: state.handleActivitySpecificChange,
    getAllActivityTemplateData: state.getAllActivityTemplateData,
    clearDonts: state.clearDonts,
    clearReference: state.clearReference,
    clearUnits: state.clearUnits,
    deleteProfileImage: state.deleteProfileImage,
    rejectActivityData: state.rejectActivityData,
  }));

  const { categoryData } = useCategoryMaster((state) => ({
    categoryData: state.categoryData,
  }));

  const { activityUnitData } = useActivityUnitMaster((state) => ({
    activityUnitData: state.activityUnitData,
  }));
  const { activityDayData } = useActivityDayMaster((state) => ({
    activityDayData: state.activityDayData,
  }));
  const { activityTimeData } = useActivityTimeMaster((state) => ({
    activityTimeData: state.activityTimeData,
  }));

  const { activityGoalTagData } = useActivityGoalMaster((state) => ({
    activityGoalTagData: state.activityGoalTagData,
  }));

  function handleNotesChanges(content, delta, source, editor) {
    handleActivityNotesChange('activity_notes', editor.getContents());
  }

  function handleDontsChanges(content, delta, source, editor) {
    handleActivityNotesChange('activity_dont', editor.getContents());
  }

  const handleProfilePicUpload = async (e) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    const res = await expertPictureUpsert(e, data?.id);

    if (res?.status?.code === 200) {
      // UPDATE PROFILE PICTURE
      updateActivityPicture(res?.data?.profile_pic);
      return toast.success(
        res?.status?.message ?? 'User Profile Picture Updated Successfully'
      );
    }
  };

  const handleProfileDelete = async () => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const data = parseJwt(authToken);
    await deleteProfileImage(data.id);
  };

  const handleUpdateday = (newValue) => {
    if (newValue.length < 7) {
      const days = activityDayData
        .filter((val) => newValue.includes(val.value))
        .map((val) => val.description.slice(0, 3))
        .join(',');

      setdaysInString(days);
    } else setdaysInString`day`;
  };

  const HandleSubmit = async () => {
    const responseStatusCode = await approveActivityData();
    if (responseStatusCode === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllExpertTemplateData'],
      });
      await getAllExpertTemplateData('');
      closeDrugMasterDrawer();
      clearReference();
      clearActivity();
    }
  };
  const handleMeasurable = (event) => {
    handleActivityFormChange('is_measurable', event.target.checked);
    setshowUnits(!showUnits);
    clearUnits();
  };
  const handleDrawerClose = () => {
    if (component !== 0) {
      setComponent(0);
      closeDrugMasterDrawer();
    } else {
      closeDrugMasterDrawer();
    }
  };

  const handleNotesDrawerClose = () => {
    setComponent(0);
  };
  const handleDontsDrawerClose = () => {
    setComponent(0);
  };

  const handleRefferenceDrawerClose = () => {
    setComponent(0);
  };

  const handleNotesDrawerCancle = (key) => {
    setComponent(0);
    if (key === 'Notes') clearNotes();
    if (key === 'Donts') clearDonts();
    if (key === 'Reference') clearReference();
  };

  const handleCancel = async () => {
    const responseStatusCode = await rejectActivityData();

    if (responseStatusCode === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllExpertTemplateData'],
      });
      await getAllExpertTemplateData('');
      closeDrugMasterDrawer();
      clearReference();
      clearActivity();
    }
  };

  const handleCloseDrawer = () => {
    closeDrugMasterDrawer();
    clearReference();
    clearActivity();
  };
  const initialMaterData = async () => {};

  useEffect(() => {
    initialMaterData();
  }, []);

  useEffect(() => {
    handleUpdateday(activity.activity_days);
  }, []);

  return (
    <Box
      sx={{
        ...addExpertDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        {drugDrawerOpen && (
          <Drawer
            show={drugDrawerOpen}
            onCloseDrawer={handleDrawerClose}
            anchor="right"
            isCloseIconRequired
            header={
              component === 0
                ? 'Plan Details'
                : component === 1
                ? 'add Notes'
                : component === 2
                ? ' add donts '
                : component === 3
                ? 'add reference'
                : ''
            }
            headerStyle={addExpertDrawer_style.headerSx}
            footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
            footer={
              component === 0 && (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {activity.request_Status.id === 1 && (
                    <>
                      {' '}
                      <Button
                        buttonStyle={addExpertDrawer_style.SaveButtonSx}
                        // onClick={() => upsertDrugMaster()}
                        loading={loading}
                        onClick={() => HandleSubmit()}
                      >
                        Approve activity
                      </Button>
                      <Button
                        buttonStyle={addExpertDrawer_style.cancleButtonSx}
                        onClick={handleCancel}
                        disabled={loading}
                      >
                        Reject activity
                      </Button>
                    </>
                  )}

                  {(activity.request_Status.id === 2 ||
                    activity.request_Status.id === 3) && (
                    <Button
                      buttonStyle={addExpertDrawer_style.cancleButtonSx}
                      onClick={handleCloseDrawer}
                    >
                      Close
                    </Button>
                  )}
                </Box>
              )
            }
          >
            {component === 0 && (
              <Box sx={addExpertDrawer_style.boxRootSx}>
                {/* ------------ACTIVITY NAME INPUT----------- */}
                <Box sx={addExpertDrawer_style.inputGroupSx}>
                  <Label
                    rootStyle={addExpertDrawer_style.labelSx}
                    htmlFor="ActivityName"
                    isRequired
                  >
                    Activity Name
                  </Label>
                  <Input
                    placeholder="Activity Name"
                    id="ActivityName"
                    isError={activity?.error?.activity_name?.length > 0}
                    errorMessage={activity?.error?.activity_name}
                    textFieldStyle={addExpertDrawer_style.inputFieldSx}
                    onChange={(e) => {
                      handleActivityFormChange(
                        'activity_name',
                        e.target.value,
                        20
                      );
                    }}
                    value={activity.activity_name}
                    fullWidth
                  />
                </Box>

                {/*  -------------ACTIVITY IMAGE UPLOAD FIELD ----------- */}
                <Box sx={addExpertDrawer_style.inputGroupSx}>
                  <Box sx={addExpertDrawer_style.LablewithBtnSx}>
                    <Label
                      rootStyle={addExpertDrawer_style.labelSx}
                      htmlFor="Activityimage"
                      isRequired
                    >
                      Activity image
                    </Label>
                    {activity.activity_image && (
                      <Box sx={addExpertDrawer_style.FlexSx}>
                        <DeleteIcon />
                        <Typography
                          sx={addExpertDrawer_style.ImgaeRemoveSx}
                          onClick={handleProfileDelete}
                        >
                          &nbsp; Delete
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box sx={addExpertDrawer_style.uploadFileRootSx}>
                    {!activity.activity_image && (
                      <Box sx={addExpertDrawer_style.activeUploadFileSx}>
                        <Box
                          component="label"
                          sx={{
                            ...addExpertDrawer_style.uploadIconSx,
                          }}
                        >
                          <UploadIcon />
                          <input
                            hidden
                            name="img"
                            accept="image/*"
                            multiple="multiple"
                            type="file"
                            id="uplodebtnv"
                            onChange={handleProfilePicUpload}
                          />
                        </Box>
                        <Box>
                          <Typography sx={addExpertDrawer_style.browseTextSx}>
                            Click to browse and upload
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {activity.activity_image ? (
                      <Box>
                        <img
                          src={activity.activity_image}
                          alt="activity_Image"
                          style={{
                            width: '400px',
                            height: '160px',
                            objectFit: 'cover',
                            borderRadius: '10px',
                          }}
                        />
                      </Box>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
                {/*  ----------------ACTIVITY TYPE  ------------- */}
                <Box sx={addExpertDrawer_style.inputGroupSx}>
                  <Label
                    rootStyle={addExpertDrawer_style.labelSx}
                    htmlFor="SelectType"
                    isRequired
                  >
                    Choose plan category
                  </Label>
                  <ToggleButton
                    RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                    isError={activity?.error?.category_id?.length > 0}
                    errorMessage={activity?.error?.category_id}
                    options={categoryData}
                    value={activity.category_id}
                    buttonactiveStyle={
                      addExpertDrawer_style.togglebuttonactiveSX
                    }
                    buttoninactiveStyle={
                      addExpertDrawer_style.togglebuttoninactiveSX
                    }
                    onChange={(value) =>
                      handleActivityFormChange('category_id', value)
                    }
                  />
                </Box>

                {/* ----------------MEASURABLE SWITCH-------------- */}
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  sx={addExpertDrawer_style.measurableSx}
                >
                  <Box sx={addExpertDrawer_style.measurableContentSx}>
                    <Box mt={0.4}>
                      {' '}
                      <AlarmClockIcon />
                    </Box>

                    <Typography pl={1}>
                      {' '}
                      Do you want to measure <br />
                      this activity?
                    </Typography>
                  </Box>
                  <Box>
                    {' '}
                    <Switch
                      checked={activity.is_measurable}
                      onChange={handleMeasurable}
                      rootStyle={addExpertDrawer_style.switchSx}
                    />
                  </Box>
                </Stack>

                {/* ----------------- ACTIVITY UNITS ------------- */}
                {showUnits && (
                  <Box sx={addExpertDrawer_style.inputGroupSx}>
                    <Label
                      rootStyle={addExpertDrawer_style.labelSx}
                      htmlFor="Activityunits"
                      isRequired
                    >
                      Activity units
                    </Label>

                    <Grid
                      container
                      sx={addExpertDrawer_style.activityunitsSX}
                      p={1}
                    >
                      <Grid item xs={6} height="60%">
                        <Input
                          placeholder="0"
                          sx={addExpertDrawer_style.textFieldStyle}
                          value={activity.quantity}
                          onChange={(e) =>
                            handleActivityFormChange('quantity', e.target.value)
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={3}
                        sx={{
                          borderLeft: '1px solid #E3E3E3',
                        }}
                      >
                        {' '}
                      </Grid>
                      <Grid item xs={3}>
                        <Dropdown
                          placeholder="min"
                          fullWidth={false}
                          rootStyle={addExpertDrawer_style.dropdownSX}
                          selectOption={activityUnitData}
                          value={activity.unit}
                          id="Activityunits"
                          onChange={(value) => {
                            handleActivityFormChange(
                              'unit',
                              value?.target?.value
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* --------------DAY SELECTION BUTTON-------------- */}
                <Box sx={addExpertDrawer_style.containerBoxSx}>
                  <Typography sx={addExpertDrawer_style.onTimeSx}>
                    Do this activity on
                  </Typography>

                  <DaySelector
                    buttonContent={activityDayData}
                    getDay={(newValue) =>
                      handleActivityFormChange('activity_days', newValue)
                    }
                    value={activity.activity_days}
                    onTimeChange={(newValue) => handleUpdateday(newValue)}
                  />

                  <Typography sx={addExpertDrawer_style.onTimeSx}>
                    {' '}
                    {`Every ${daysInString} `}
                  </Typography>
                </Box>

                {/* ----------TIME SELCTION TOGGLE------------------ */}

                <Box sx={addExpertDrawer_style.containerBoxSx}>
                  {' '}
                  <Typography sx={addExpertDrawer_style.onTimeSx}>
                    Time of activity
                  </Typography>
                  <ToggleButton
                    RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                    options={activityTimeData}
                    value={activity.activity_time}
                    isError={activity?.error?.activity_time?.length > 0}
                    errorMessage={activity?.error?.activity_time}
                    buttonactiveStyle={addExpertDrawer_style.buttonactivenumSX}
                    buttoninactiveStyle={
                      addExpertDrawer_style.buttoninactivenumSX
                    }
                    onChange={(value) => {
                      handleActivityFormChange('activity_time', value);
                    }}
                  />
                </Box>
                {activity.activity_time === 6 && (
                  <Stack
                    flexDirection="row"
                    sx={{ backgroundColor: '#F7F7F7 ', borderRadius: '10px' }}
                    pt={0.4}
                    justifyContent="space-around"
                  >
                    <Box p={0.8}>
                      {' '}
                      <Typography pl={1} sx={addExpertDrawer_style.timeInput}>
                        From
                      </Typography>
                      <Input
                        type="time"
                        textFieldStyle={addExpertDrawer_style.timeinputFieldSx}
                        value={activity.specific_time.from}
                        onChange={(e) => {
                          handleActivitySpecificChange('from', e.target.value);
                        }}
                      />
                    </Box>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ height: '50px', mt: 1.5, mr: 2 }}
                    />

                    <Box pt={0.8}>
                      <Typography sx={addExpertDrawer_style.timeInput}>
                        To
                      </Typography>
                      <Input
                        type="time"
                        textFieldStyle={addExpertDrawer_style.timeinputFieldSx}
                        value={activity.specific_time.to}
                        onChange={(e) => {
                          handleActivitySpecificChange('to', e.target.value);
                        }}
                      />
                    </Box>
                  </Stack>
                )}

                {/* -------------DRAWER BUTOONS--------------------- */}
                <Box sx={addExpertDrawer_style.notesContainerBoxSx}>
                  <Box
                    onClick={() => setComponent(1)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ActivityNotesEditIcon rootStyle={{ cursor: 'pointer' }} />
                    <Typography sx={addExpertDrawer_style.NotesDescSx}>
                      Notes
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => setComponent(2)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ActivityDontsEditIcon rootStyle={{ cursor: 'pointer' }} />
                    <Typography sx={addExpertDrawer_style.NotesDescSx}>
                      Dont&apos;s
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => setComponent(3)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ActivityReffernceEditIcon
                      rootStyle={{ cursor: 'pointer' }}
                    />
                    <Typography sx={addExpertDrawer_style.NotesDescSx}>
                      Refernece
                    </Typography>
                  </Box>
                </Box>

                {/* ------------------------------- */}
                <Box sx={addExpertDrawer_style.inputGroupSx}>
                  <Label
                    rootStyle={addExpertDrawer_style.labelSx}
                    htmlFor="SelectType"
                    isRequired
                  >
                    Choose plan category
                  </Label>
                  <TagsToggleButton
                    RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                    options={activityGoalTagData}
                    value={activity.goal_id}
                    isError={activity?.error?.goal_id?.length > 0}
                    errorMessage={activity?.error?.goal_id}
                    buttonactiveStyle={
                      addExpertDrawer_style.togglebuttonactiveSX
                    }
                    buttoninactiveStyle={
                      addExpertDrawer_style.togglebuttoninactiveSX
                    }
                    valueChange={(e) => {
                      handleActivityFormChange('goal_id', e);
                    }}
                  />
                </Box>
              </Box>
            )}
            {component === 1 && (
              <AddNotes
                value={activity.activity_notes}
                handleChanges={handleNotesChanges}
                HandleSubmit={handleNotesDrawerClose}
                closeNotesDrawer={() => handleNotesDrawerCancle('Notes')}
              />
            )}
            {component === 2 && (
              <AddDonts
                value={activity.activity_dont}
                handleChanges={handleDontsChanges}
                HandleSubmit={handleDontsDrawerClose}
                closeDontsDrawer={() => handleNotesDrawerCancle('Donts')}
              />
            )}
            {component === 3 && (
              <AddReferences
                reference={activity_references}
                HandleSubmit={handleRefferenceDrawerClose}
                handleChanges={handleDontsChanges}
                closeReferenceDrawer={() =>
                  handleNotesDrawerCancle('Reference')
                }
              />
            )}
          </Drawer>
        )}
      </Box>
    </Box>
  );
}

AddExpertDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddExpertDrawer };
