export const parameter_style = {
  rootSx: {
    height: '100vh',
    overflowY: 'scroll',
    backgroundColor: '#F8F8F9',
    py: 3,
    px: 1,
  },
  leftSideBarSx: {
    background: '#fff',
    borderRadius: '10px',
    height: '83vh',
    boxShadow:
      'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  },
  rightComponentSX: {
    background: '#fff',
    borderRadius: '10px',
    boxShadow:
      'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  },
  ListContainerSx: { px: 2, py: 0.5, gap: 1, cursor: 'pointer' },
};
