export const patient_style = {
  titleSx: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#0E1824',
  },
  addtireBtnSx: {
    border: '1px solid',
    borderColor: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
  },
  searchFieldSx: {
    width: '360px',
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      px: 2,
      height: '44px',
      border: 'none',
    },

    '&.MuiFormControl-root': {
      backgroundColor: '#fff',
      pb: '4px',
    },
    ' & .MuiOutlinedInput-input': {
      color: 'text.primary',
      backgroundColor: '#fff',

      pt: 0.5,
    },
    '& .MuiOutlinedInput-root': {
      px: 0,
      pt: 0.7,
      backgroundColor: 'tranparent',
      color: '#025140',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: '#C5C5C5',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },

  cancleButtonSx: {
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  saveButtonSx: {
    border: '1px solid',
    borderColor: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
  },
  TurnonFontSx: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#262C33',
  },
  disableFontSx: { fontSize: '12px', fontWeighyt: 400, color: '#5C6266' },
  gstFontSx: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#262C33',
    mb: 1,
  },
  tableCustomSx: {
    '& .MuiDataGrid-row': {
      borderTop: '1px solid ',
      borderColor: '#E3E3E3',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    '& .MuiDataGrid-main': {
      border: '1px solid',
      borderColor: '#E3E3E3',
      borderRadius: '6px',
      bgcolor: '#fff',
    },
  },

  dataTableCustomSx: {
    '& .MuiDataGrid-row': {
      borderBottom: '2px solid',
      borderColor: 'grey.400',
    },

    '& .MuiDataGrid-main': {
      border: '1px solid',
      borderColor: 'grey.400',
      bgcolor: '#FFFFFF',
      borderRadius: '6px',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid',
      borderColor: 'grey.400',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
  },

  ContainerCard: {
    border: '1px solid #E3E3E3',
    backgroundColor: '#fff',
    borderRadius: '8px',
    p: 3,
  },

  InputSx: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& fieldset': {
        borderRadius: '10px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: '',
      },
    },
  },
  totalgstInputSx: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#EDEFF2',
      borderRadius: '10px',
      '& fieldset': {
        borderRadius: '10px',
        border: '1.5px solid',
        borderColor: '#EDEFF2',
      },
      '&:hover fieldset': {
        borderColor: '#EDEFF2',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#EDEFF2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#EDEFF2',
      },
    },
  },
};
