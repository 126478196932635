/* eslint-disable prefer-const */
import { useOrganization } from '@hc/store';
import { useState } from 'react';
import {
  Button,
  Drawer,
  Input,
  Label,
  Loader,
  MobileInput,
  UploadCloseIcon,
} from '@hc/ui/atoms';
import { handleUploadFile, queryClient } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { addOrganizationDrawer_style } from './style';
import { PatientReportUploadCard } from '../../clinical';

function AddOrganizationDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    organizationDrawerOpen,
    closeOrganizationDrawer,
    ...rest
  } = props;

  const [saveBtn, setSaveBtn] = useState(false);
  const [imgLoader, setimgLoader] = useState(false);
  const {
    handleorganizationChange,
    getAllorganizationData,
    organizationState,
    upsertOrganization,
    saveLoading,
  } = useOrganization((state) => ({
    getAllorganizationData: state.getAllorganizationData,
    upsertOrganization: state.upsertOrganization,
    handleorganizationChange: state.handleorganizationChange,
    organizationState: state.organizationState,
    saveLoading: state.saveLoading,
  }));

  const { organization } = organizationState;
  const { error } = organization;

  const isIamValidToSave = () => {
    const organizationCopy = JSON.parse(JSON.stringify(organization));

    let isValid = true;
    const error = organizationCopy?.error;

    // checking plan_name
    if (!organizationCopy?.name) {
      isValid = false;
      error.name = 'Please the enter organization name';
    } else {
      error.name = '';
    }
    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (
      !organizationCopy?.email?.length > 0 &&
      !filter.test(organizationCopy?.email)
    ) {
      isValid = false;
      error.email = 'Please enter the valid mail';
    } else {
      error.email = '';
    }

    // Checking Mobile Number
    if (!('mobile' in organizationCopy?.mobileNumber)) {
      isValid = false;
      error.mobile = 'Enter the mobile number';
    } else if (organizationCopy?.mobileNumber?.mobile?.length < 10) {
      isValid = false;
      error.mobile = 'Enter a valid 10 digit mobile number';
    } else {
      error.mobile = '';
    }

    handleorganizationChange('error', error);

    return isValid;
  };

  const handleorganizationFormChange = (key, value) => {
    handleorganizationChange(key, value);
    if (saveBtn) isIamValidToSave();
  };

  const upsertOrganizationMaster = async () => {
    const result = isIamValidToSave();
    setSaveBtn(true);
    if (result) {
      setSaveBtn(false);
      queryClient.invalidateQueries({
        queryKey: ['organization/sendInvite'],
      });

      const response = await upsertOrganization();
      if (response === 200) {
        queryClient.invalidateQueries({
          queryKey: ['getAllorganizationData'],
        });
        await getAllorganizationData('', 10, 0);
        closeOrganizationDrawer();
      }
    }
  };

  const handleFileUpload = async (e) => {
    setimgLoader(true);
    let fileList = e?.target?.files;
    if (fileList.length > 0) {
      const data = await handleUploadFile({ 0: fileList[0], length: 1 });
      handleorganizationChange('logo', data[0]);
      setimgLoader(false);
    }
  };

  const handleCloseDrawer = () => {
    setSaveBtn(false);
    closeOrganizationDrawer();
  };

  return (
    <Box
      sx={{
        ...addOrganizationDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {organizationDrawerOpen && (
        <Drawer
          show={organizationDrawerOpen}
          onCloseDrawer={handleCloseDrawer}
          anchor="right"
          isCloseIconRequired
          header="Organization"
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
          }}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                buttonStyle={addOrganizationDrawer_style.SaveButtonSx}
                onClick={() => upsertOrganizationMaster()}
                loading={saveLoading === true}
              >
                {organization?.id ? 'Update' : 'Save'}
              </Button>
              <Button
                buttonStyle={addOrganizationDrawer_style.cancleButtonSx}
                onClick={handleCloseDrawer}
                disabled={saveLoading}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <Box sx={addOrganizationDrawer_style.boxRootSx}>
            <Box sx={addOrganizationDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addOrganizationDrawer_style.labelSx}
                htmlFor="organizationName"
                isRequired
              >
                Name
              </Label>
              <Input
                id="organizationName"
                textFieldStyle={addOrganizationDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleorganizationFormChange('name', e.target.value);
                }}
                isError={error?.name?.length > 0}
                errorMessage={error?.name}
                value={organization.name}
                fullWidth
              />
            </Box>
            <Box sx={addOrganizationDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addOrganizationDrawer_style.labelSx}
                htmlFor="organizationEmail"
                isRequired
              >
                Email
              </Label>
              <Input
                id="organizationEmail"
                textFieldStyle={addOrganizationDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleorganizationFormChange('email', e.target.value);
                }}
                disabled={!organization?.createOrganization}
                isError={error?.email?.length > 0}
                errorMessage={error?.email}
                value={organization.email}
                fullWidth
              />
            </Box>

            <Box sx={addOrganizationDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addOrganizationDrawer_style.labelSx}
                htmlFor="organizationEmail"
                isRequired
              >
                Phone Number
              </Label>
              <MobileInput
                value={{
                  mobile_code: organization?.mobileNumber?.mobileCode ?? '+91',
                  mobile: organization?.mobileNumber?.mobile ?? '',
                }}
                isReadonlyCC={!organization?.createOrganization}
                disabled={!organization?.createOrganization}
                isError={error?.mobile?.length > 0}
                helperText={error?.mobile}
                onChange={(value) =>
                  handleorganizationChange('mobileNumber', value)
                }
              />
            </Box>

            <Box sx={addOrganizationDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addOrganizationDrawer_style.labelSx}
                htmlFor="Address"
              >
                Address
              </Label>
              <Input
                id="Address"
                multiline
                textFieldStyle={addOrganizationDrawer_style.inputFieldSx}
                onChange={(e) => {
                  handleorganizationChange('address', e.target.value);
                }}
                isError={error?.address?.length > 0}
                errorMessage={error?.address}
                value={organization.address}
                fullWidth
              />
            </Box>

            <Box sx={addOrganizationDrawer_style.inputGroupSx}>
              <Label
                rootStyle={addOrganizationDrawer_style.labelSx}
                htmlFor="Address"
              >
                Upload Organization Logo (Only PNG)
              </Label>

              {!organization?.logo?.filename && (
                <Box
                  component="label"
                  sx={addOrganizationDrawer_style.UploadbtnSx}
                >
                  <input
                    hidden
                    name="img"
                    accept=".png"
                    multiple="multiple"
                    type="file"
                    id="uplodebtnv"
                    onChange={(e) => handleFileUpload(e)}
                  />

                  <Typography
                    color="#fff"
                    fontSize={14}
                    textAlign="center"
                    alignSelf="center"
                    fontWeight={500}
                  >
                    {imgLoader ? 'Loading...' : 'Upload'}
                  </Typography>
                </Box>
              )}
              {organization?.logo?.filename?.length > 0 && (
                <Box sx={addOrganizationDrawer_style.linkWrapSx}>
                  <PatientReportUploadCard
                    showReportName
                    icon={<UploadCloseIcon />}
                    isUploadCard
                    reportName={organization?.logo?.filename ?? ''}
                    uploadedData={[
                      { file: `${organization?.logo?.downloadUrl ?? ''}` },
                    ]}
                    IconOnClick={() => handleorganizationChange('logo', {})}
                    editIconStyle={{ alignItems: 'center', mt: 0 }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

AddOrganizationDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddOrganizationDrawer };
