import { Box } from '@mui/material';
import { useHealthProblems } from '@hc/store/backoffice/masters/healthProblems';
import PropTypes from 'prop-types';
import { Button, Drawer, Input, Label } from '@hc/ui/atoms';
import { healthProblemsDrawer_style } from './style';

function HealthProblemsDrawer(props) {
  const {
    drawerOpen = false,
    closeMasterDrawer = () => false,
    upsertMaster = () => {},
  } = props;

  const { handleChange, health_problem, health_problem_id } = useHealthProblems(
    (state) => ({
      handleChange: state.handleChange,
      health_problem: state.health_problem,
      health_problem_id: state.health_problem_id,
    })
  );

  return (
    <Box>
      {drawerOpen && (
        <Drawer
          show={drawerOpen}
          onCloseDrawer={closeMasterDrawer}
          anchor="right"
          isCloseIconRequired
          header={`${health_problem_id ? 'Update' : 'Add'} Health Problems`}
          headerStyle={healthProblemsDrawer_style.drawerHeadSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                buttonStyle={healthProblemsDrawer_style.saveBtnSx}
                onClick={upsertMaster}
              >
                {health_problem_id ? 'Update' : 'Add'}
              </Button>
              <Button
                buttonStyle={healthProblemsDrawer_style.cancleBtnSx}
                onClick={closeMasterDrawer}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <Box sx={healthProblemsDrawer_style.boxRootSx}>
            <Box sx={healthProblemsDrawer_style.inputGroupSx}>
              <Label
                rootStyle={healthProblemsDrawer_style.labelSx}
                htmlFor="HealthProblems"
                isRequired
              >
                Health Problems
              </Label>
              <Input
                id="HealthProblems"
                textFieldStyle={healthProblemsDrawer_style.inputFieldSx}
                onChange={(e) => handleChange(e.target.value)}
                fullWidth
                value={health_problem}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

HealthProblemsDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
  closeMasterDrawer: PropTypes.func,
};

export { HealthProblemsDrawer };
