import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AddSummaryIcon } from '../icons';
import { CustomTextArea_style } from './style';

export function CustomTextArea(props) {
  const {
    disable,
    type = 'text',
    data_testId = '',
    isrequired,
    value = '',
    onChange = () => {},
    enterKeyPress,
    add = () => {},
    placeholder = '',
    multiline = true,
    helperText = '',
    className = '',
    textFieldStyle = {},
    buttonRequired = false,
    plusButton = false,
    MbfVariant,
    rows,
    rowsMax,
    readonly = true,
    isError,
    customWrapperSx = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{ ...CustomTextArea_style.wrapperSx, ...customWrapperSx }}
      className={`${className}`}
      {...rest}
    >
      <TextField
        readOnly={readonly}
        fullWidth
        disabled={disable}
        type={type}
        sx={{ ...CustomTextArea_style.textField, ...textFieldStyle }}
        id={data_testId}
        required={isrequired}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => add()}
              position="start"
              id={`add${data_testId}`}
            >
              {plusButton && (
                <AddSummaryIcon
                  fill={
                    MbfVariant ? '#6552CC' : disable ? '#c0e1dc' : '#007965'
                  }
                />
              )}
              {buttonRequired && (
                <IconButton disabled={value === ''} sx={{ p: 0 }}>
                  {value !== '' ? (
                    <Typography sx={CustomTextArea_style.addSx}>Add</Typography>
                  ) : (
                    <Typography sx={CustomTextArea_style.addDisableSx}>
                      Add
                    </Typography>
                  )}
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        rows={rows}
        maxRows={rowsMax}
        value={value}
        placeholder={placeholder}
        multiline={multiline}
        onChange={onChange}
        error={isError}
        helperText={helperText}
      />
    </Box>
  );
}

CustomTextArea.propTypes = {
  data_testId: PropTypes.string,
  enterKeyPress: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disable: PropTypes.bool,
  isrequired: PropTypes.any,
  classes: PropTypes.object,
  textFieldStyle: PropTypes.object,
  placeholder: PropTypes.string,
  buttonRequired: PropTypes.bool,
  plusButton: PropTypes.bool,
  multiline: PropTypes.bool,
  id: PropTypes.string,
  add: PropTypes.func,
  onClick: PropTypes.func,
  helperText: PropTypes.string,
  className: PropTypes.string,
  MbfVariant: PropTypes.bool,
  rows: PropTypes.any,
  rowsMax: PropTypes.any,
  readonly: PropTypes.bool,
  isError: PropTypes.bool,
  customWrapperSx: PropTypes.object,
};
