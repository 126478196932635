import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AllergiesMaster from './Allergies';
import AppointmentStatusMaster from './Appointment';
import GoalCategoryMaster from './GoalCategory';
import HealthProblems from './HealthProblems';
import LabTestsMaster from './LabTests';
import RelationshipsMaster from './Relationships';
import RolesMaster from './Roles';
import SpecialitiesMaster from './Specialities';
import SymptomMaster from './Symptoms';
import DrugMaster from './drugMaster';
import { MasterManagement_Style } from './style';

export default function MasterManagement(props) {
  const { className = '' } = props;
  const [isActive, setActive] = useState(0);
  let component;

  const listData = [
    'Allergies',
    'Appointment Statuses',
    'Drugs',
    'Health Problems',
    // 'Lab Test Groups',
    'Lab Tests',
    'Relationships',
    'Roles',
    'Specialities',
    'Symptoms',
    'Goal Category',
  ];

  switch (isActive) {
    case 0:
      component = <AllergiesMaster />;
      break;
    case 1:
      component = <AppointmentStatusMaster />;
      break;
    case 2:
      component = <DrugMaster />;
      break;
    case 3:
      component = <HealthProblems />;
      break;
    // case 4:
    //   component = <LabTestGroups />;
    //   break;
    case 4:
      component = <LabTestsMaster />;
      break;
    case 5:
      component = <RelationshipsMaster />;
      break;
    case 6:
      component = <RolesMaster />;
      break;
    case 7:
      component = <SpecialitiesMaster />;
      break;
    case 8:
      component = <SymptomMaster />;
      break;
    case 9:
      component = <GoalCategoryMaster />;
      break;

    default:
      component = 0;
      break;
  }

  const handleSelect = (value) => {
    setActive(value);
  };

  return (
    <Box className={`${className}`} sx={MasterManagement_Style.rootSx}>
      <Grid container>
        <Grid item xs={3} p={1}>
          <Stack sx={MasterManagement_Style.leftSideBarSx}>
            <Typography fontWeight={600} sx={{ p: 2 }}>
              Masters
            </Typography>
            <Stack sx={MasterManagement_Style.ListContainerSx}>
              {listData.map((data, i) => (
                <Typography
                  id={`master_${i}`}
                  key={i}
                  onClick={() => handleSelect(i)}
                  sx={{
                    p: 1,
                    borderRadius: '8px',
                    background: isActive === i ? '#d4d8dc' : '',
                    '&:hover': { background: isActive === i ? '' : '#EDEFF2' },
                  }}
                >
                  {data}
                </Typography>
              ))}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={9} p={1}>
          <Box sx={MasterManagement_Style.rightComponentSX}>{component}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}

MasterManagement.propTypes = {
  className: PropTypes.string,
};
