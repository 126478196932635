import { init, setContext } from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const isProd = import.meta.env.PROD;

if (isProd) {
  init({
    dsn: 'https://f8266af9178c42f490be56bbb0d34880@o4504654558396416.ingest.sentry.io/4504693143306240',
  });
  setContext('extra', {
    product: 'backoffice',
    environment: import.meta.env.MODE,
  });
}
ReactDOM.createRoot(document.getElementById('root')).render(<App />);
