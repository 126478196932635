export const lightTheme = {
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      light: '#6EBDAB',
      main: '#007965',
      dark: '#239179',
      lighter: '#E2F7F4',
      contrastText: '#fff',
      bglighter:'#6EBDAB'
    },
    secondary: {
      light: '#FFF3EB',
      main: '#F58634',
      dark: '#EB7C2A',
      contrastText: '#fff',
    },
    mbf: {
      light: '#E5E0FF',
      main: '#6552CC',
      dark: '#5B48BD',
      contrastText: '#fff',
    },

    primaryTints: {
      50: '#FBFFFF',
      100: '#EBFAF7',
      200: '#E2F7F4',
      300: '#DCF5F1',
      400: '#CCF3EC',
      500: '#6EBDAB',
      700: '#40B4A1',
      800: '#239179',
      900: '#106C5C',
      A100: '#18574C',
      A200: '#269F8A',
    },
    primaryGreyishTints: {
      50: '#F8FAFA',
      100: '#F5FBFA',
      300: '#CBE2DE',
    },
    orangeTints: {
      50: '#FFF3EB',
      100: '#FFD8BE',
      200: '#FCF3E8',
      400: '#FF980E',
      600: '#EB6D13',
    },
    purple: {
      50: '#FCFBFF',
      100: '#F7F6FF',
      200: '#F4F2FF',
      300: '#F3F0FF',
      400: '#EEEBFF',
      500: '#EAE6FF',
      600: '#E5E0FF',
      700: '#DFD8FF',
      800: '#D5CCFF',
      A100: '#7A67D9',
      A200: '#9684EF',
      A400: '#6552CC',
      A700: '#5B48BD',
    },
    green: {
      50: '#E8FCF0',
      200: '#B7EECC',
      300: '#87E0A8',
      400: '#E8FCF0',
      500: '#56D284',
      600: '#25C460',
    },
    red: {
      50: '#FEEAEA',
      200: '#FFE0E0',
      400: '#FBB2B7',
      600: '#F8888F',
      800: '#F44F5A',
    },
    amber: {
      50: '#FCF3E8',
      200: '#FDDCB2',
      400: '#FEC67B',
      600: '#FEAF45',
      800: '#FF980E',
    },
    sky: {
      50: '#DEF5FC',
      200: '#AEDCEA',
      400: '#7FC3D8',
      600: '#4FAAC6',
      800: '#1F91B4',
    },
    lime: {
      50: '#EFFADD',
      200: '#D9EDB6',
      400: '#C3E08F',
      600: '#ADD267',
      800: '#97C540',
    },
    teal: {
      50: '#E1FFF3',
      200: '#B1ECD4',
      400: '#80DAB5',
      600: '#50C795',
      800: '#1FB476',
    },
    blue: {
      50: '#E9F4FC',
      200: '#BADFF7',
      400: '#8BC9F3',
      600: '#5BB4EE',
      800: '#2C9EE9',
    },
    indigo: {
      50: '#E9ECFC',
      200: '#C3CBF3',
      400: '#9CA9EA',
      600: '#7688E1',
      700: '#6377DD',
      800: '#4F66D8',
    },
    yellow: {
      50: '#FCF8E8',
      200: '#FCEDB3',
      400: '#FBE27F',
      600: '#FBD74A',
      800: '#FACC15',
    },
    error: {
      light: '#FFE0E0',
      main: '#F44F5A',
      dark: '#EB3434',
      contrastText: '#fff',
    },
    grey: {
      50: '#FAFAFA',
      100: '#F7F7F7',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#E3E3E3',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#8C8A8D',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A300: '#F5F5F5',
      A400: '#303030',
      A500: '#C5C5C5',
      A700: '#616161',
    },
    text: {
      primary: '#0E1824',
      secondary: '#5C6266',
      disabled: '#B7B7B7',
      label: '#262C33',
      hint: '#888888',
    },
    background: {
      paper: '#fff',
      shadow: '#FDFDFF',
      default: '#fafafa',
    },
    border: {
      main: '#DBEAE8',
      hover: '#DBEAE8',
      focus: '#007965',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    type: 'light',
    contrastThreshold: 3,
    tonalOffset: 0.2,
    divider: '#DBDBDB',
  },
  shadows: {
    md: '0px 0px 10px #00000029',
  },
};
