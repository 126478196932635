import { Box } from '@mui/material';
import { useAllergies } from '@hc/store/backoffice/allergies';
import PropTypes from 'prop-types';
import { Button, Drawer, Input, Label } from '@hc/ui/atoms';
import { allergiesDrawer_style } from './style';

function AllergiesDrawer(props) {
  const {
    drawerOpen = false,
    closeMasterDrawer = () => false,
    upsertMaster = () => {},
  } = props;

  const { handleChange, allergy_name, allergy_id } = useAllergies((state) => ({
    handleChange: state.handleChange,
    allergy_name: state.allergy_name,
    allergy_id: state.allergy_id,
  }));

  return (
    <Box>
      {drawerOpen && (
        <Drawer
          show={drawerOpen}
          onCloseDrawer={closeMasterDrawer}
          anchor="right"
          isCloseIconRequired
          header={`${allergy_id ? 'Update' : 'Add'} Allergies`}
          headerStyle={allergiesDrawer_style.drawerHeadSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                buttonStyle={allergiesDrawer_style.saveBtnSx}
                onClick={upsertMaster}
              >
                {allergy_id ? 'Update' : 'Add'}
              </Button>
              <Button
                buttonStyle={allergiesDrawer_style.cancleBtnSx}
                onClick={closeMasterDrawer}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <Box sx={allergiesDrawer_style.boxRootSx}>
            <Box sx={allergiesDrawer_style.inputGroupSx}>
              <Label
                rootStyle={allergiesDrawer_style.labelSx}
                htmlFor="Allergies"
                isRequired
              >
                Allergies
              </Label>
              <Input
                id="Allergies"
                textFieldStyle={allergiesDrawer_style.inputFieldSx}
                onChange={(e) => handleChange(e.target.value)}
                fullWidth
                value={allergy_name}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

AllergiesDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
  closeMasterDrawer: PropTypes.func,
};

export { AllergiesDrawer };
