import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Button, Drawer } from '../../../atoms';
import { planChangeRequestDrawer_style } from './style';

function PlanChangeRequestDrawer(props) {
  const {
    className = '',
    assignCR = '',
    showAssignExpert = false,
    header = '',
    drawerOpen,
    closeDrawer = () => {},
    closeCRFnc = () => {},
    updatePlanChangeRequest = () => {},
    children,
    loading,
    rootStyle = {},
    custom,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...planChangeRequestDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box>
        {drawerOpen && (
          <Drawer
            show={drawerOpen}
            onCloseDrawer={closeDrawer}
            anchor="right"
            isCloseIconRequired
            header={header}
            headerStyle={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#101010',
              textTransform: 'capitalize',
            }}
            footerStyle={{ py: 2.8, px: 2 }}
            footer={
              <Box sx={{ display: 'flex', gap: 2 }}>
                {assignCR === 'Plan change request raised by the user' ||
                showAssignExpert ? (
                  <Button
                    loading={loading}
                    buttonStyle={{
                      bgcolor: 'primary.main',
                      textTransform: 'capitalize',
                      color: '#fff',
                      borderRadius: '8px',
                      '&:hover': {
                        bgcolor: 'primary.main',
                      },
                    }}
                    onClick={() => updatePlanChangeRequest()}
                  >
                    Assign
                  </Button>
                ) : null}

                <Button
                  disabled={loading === true ? true : false}
                  buttonStyle={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    bgcolor: '#fff',
                    '&:hover': {
                      bgcolor: 'primaryTints.200',
                    },
                  }}
                  onClick={() =>
                    assignCR === 'Plan change request raised by the user' ||
                    (assignCR ===
                      'Plan updated by the expert based on the MBF user change request' &&
                      !showAssignExpert)
                      ? closeCRFnc()
                      : closeDrawer()
                  }
                >
                  {assignCR === 'Plan change request raised by the user' ||
                  (assignCR ===
                    'Plan updated by the expert based on the MBF user change request' &&
                    !showAssignExpert)
                    ? 'Close CR'
                    : 'Close'}
                </Button>
              </Box>
            }
          >
            {children}
          </Drawer>
        )}
      </Box>
    </Box>
  );
}

PlanChangeRequestDrawer.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  rootStyle: PropTypes.object,
  drawerOpen: PropTypes.bool,
  closeDrawer: PropTypes.func,
  closeCRFnc: PropTypes.func,
  updatePlanChangeRequest: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.element,
  assignCR: PropTypes.string,
  showAssignExpert: PropTypes.bool,
};

export { PlanChangeRequestDrawer };

