import { Box, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React from 'react';
import MemberdataEmptyState from '../../assets/MemberdataEmptyState.svg';
import { table_Style } from './style';

function CustomNoRowsOverlay() {
  return (
    <Stack alignItems="center" justifyContent="center" height="80vh">
      <Box>
        <img src={MemberdataEmptyState} alt="MemberdataEmptyState" />
      </Box>
      <Box sx={table_Style.emptystate}>No data found</Box>
    </Stack>
  );
}

export function Table(props) {
  const {
    rows = [],
    columns = [],
    pageSize = 10,
    onRowClick = () => {},
    onRowDoubleClick = () => {},
    autoHeight = true,
    disableSelectionOnClick = true,
    rootStyle = {},
    className = '',
    emptyState = false,
    customTableStyle = {},
    disableColumnMenu = false,
    hideFooter = false,
    rowHeight = 52,
    ...rest
  } = props;

  return (
    <Box
      className={`${className}`}
      {...rest}
      sx={{ ...table_Style.rootSx, ...rootStyle }}
    >
      <DataGrid
        sx={{
          '& .MuiDataGrid-row': {
            border: '1px solid',
            borderColor: 'grey.400',
          },
          '& .MuiDataGrid-main': {
            border: '1px solid',
            borderColor: 'grey.400',
            bgcolor: '#FFFFFF',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: ' none',
          },
          ...customTableStyle,
        }}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onRowClick={onRowClick}
        GridLinesVisibility="none"
        onRowDoubleClick={onRowDoubleClick}
        autoHeight={autoHeight}
        disableSelectionOnClick={disableSelectionOnClick}
        disableColumnMenu={disableColumnMenu}
        hideFooterPagination={hideFooter}
        rowHeight={rowHeight}
        components={{
          ...(emptyState && {
            NoRowsOverlay: CustomNoRowsOverlay,
            NoResultsOverlay: CustomNoRowsOverlay,
          }),
        }}
      />
    </Box>
  );
}

Table.propTypes = {
  pageSize: PropTypes.number,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      width: PropTypes.number,
      value: PropTypes.number,
      editable: PropTypes.bool,
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      age: PropTypes.number,
      id: PropTypes.string,
    })
  ),
  rootStyle: PropTypes.object,
  className: PropTypes.string,
  emptyState: PropTypes.bool,
  customTableStyle: PropTypes.object,
  disableColumnMenu: PropTypes.object,
  hideFooter: PropTypes.bool,
  rowHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  autoHeight: PropTypes.bool,
  disableSelectionOnClick: PropTypes.bool,
  classes: PropTypes.object,
  sx: PropTypes.object,
};
