import { Drawer } from '@hc/ui/atoms';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { BackofficeStepper } from '../backofficeStepper';
import { StepperDrawer_style } from './style';

function StepperDrawer(props) {
  const {
    className = '',
    headerName = '',
    rootStyle = {},
    stepperData = [],
    stepperDrawerOpen = false,
    closeStepperDrawer = () => false,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...StepperDrawer_style.rootSx,
        ...rootStyle,
        paddingTop: '100px',
      }}
      className={`${className}`}
      {...rest}
    >
      {stepperDrawerOpen && (
        <Drawer
          show={stepperDrawerOpen}
          onCloseDrawer={closeStepperDrawer}
          anchor="right"
          isCloseIconRequired
          header={headerName ?? 'Log'}
          headerStyle={StepperDrawer_style.headerSx}
        >
          <Box sx={StepperDrawer_style.boxRootSx}>
            <BackofficeStepper stepperData={stepperData} />
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

StepperDrawer.propTypes = {
  className: PropTypes.string,
  headerName: PropTypes.string,
  rootStyle: PropTypes.object,
  stepperDrawerOpen: PropTypes.func,
  closeStepperDrawer: PropTypes.func,
  stepperData: PropTypes.array,
};

export { StepperDrawer };
