import { Box } from '@mui/material';
import { useRelationships } from '@hc/store/backoffice/masters/Relationships';
import PropTypes from 'prop-types';
import { Button, Drawer, Input, Label } from '@hc/ui/atoms';
import { relationshipsDrawer_style } from './style';

function RelationshipsDrawer(props) {
  const {
    drawerOpen = false,
    closeMasterDrawer = () => false,
    upsertMaster = () => {},
  } = props;

  const { handleChange, relationship, relationship_id } = useRelationships(
    (state) => ({
      handleChange: state.handleChange,
      relationship: state.relationship,
      relationship_id: state.relationship_id,
    })
  );

  return (
    <Box>
      {drawerOpen && (
        <Drawer
          show={drawerOpen}
          onCloseDrawer={closeMasterDrawer}
          anchor="right"
          isCloseIconRequired
          header={`${relationship_id ? 'Update' : 'Add'} relationships`}
          headerStyle={relationshipsDrawer_style.drawerHeadSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                buttonStyle={relationshipsDrawer_style.saveBtnSx}
                onClick={upsertMaster}
              >
                {relationship_id ? 'Update' : 'Add'}
              </Button>
              <Button
                buttonStyle={relationshipsDrawer_style.cancleBtnSx}
                onClick={closeMasterDrawer}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <Box sx={relationshipsDrawer_style.boxRootSx}>
            <Box sx={relationshipsDrawer_style.inputGroupSx}>
              <Label
                rootStyle={relationshipsDrawer_style.labelSx}
                htmlFor="relationships"
                isRequired
              >
                relationships
              </Label>
              <Input
                id="relationships"
                textFieldStyle={relationshipsDrawer_style.inputFieldSx}
                onChange={(e) => handleChange(e.target.value)}
                fullWidth
                value={relationship}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

RelationshipsDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
  closeMasterDrawer: PropTypes.func,
};

export { RelationshipsDrawer };
