import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { useAppointmentStatuses } from '@hc/store/backoffice/masters/appointmentStatuses';
import { TableSkeletonLoader } from '@hc/ui/components';
import { getDateFormatToString } from '@hc/dayjs';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { Box, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AppointmentStatusMaster_Style } from './style';

export default function AppointmentStatusMaster(props) {
  const { ...rest } = props;

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Store Data

  const {
    appointmentStatusCount,
    getAppointmentStatus,
    appointmentStatusData,
    loading,
  } = useAppointmentStatuses((state) => ({
    appointmentStatusCount: state.appointmentStatusCount,
    appointmentStatusData: state.appointmentStatusData,
    getAppointmentStatus: state.getAppointmentStatus,
    loading: state.loading,
  }));

  //  ----- TABLE DATA SEARCH ---------
  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    await getAppointmentStatus(searchValue, rowsPerPage);
    setOffset(0);
  };

  // ------TABLE DATA SWITCH --------

  // ------PAGINATION ------------
  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAppointmentStatus', search, offset],
    });
    await getAppointmentStatus(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    await getAppointmentStatus(search, rowsPerPage, offset);
  };

  // ADD

  //  TABLE COLUMNS META DATA
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
      field: 'appointment_status',
      headerName: 'Appointment Status',
      width: 250,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 300,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 300,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
  ];

  //  INITIAL DATA FETCH

  const initialData = async () => {
    await getAppointmentStatus('');
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      {...rest}
      p={2}
      sx={{
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: '#fff',
        borderRadius: '10px',
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MasterTopContainer
            isMasterTitleShow
            isTitileShow={false}
            handleSearch={handleSearch}
            title="Appointment Statuses"
            count={appointmentStatusCount}
            showSearchField
            searchFieldPlaceholder="Search Appointment Statuses by name"
            showAddButton={false}
          />
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <TableSkeletonLoader />
          ) : (
            <Box>
              <MasterTable
                columns={columns}
                data={appointmentStatusData ?? []}
                customTableStyle={
                  AppointmentStatusMaster_Style.dataTableCustomSx
                }
                hideFooter
                rootStyle={{ height: '68vh' }}
              />
            </Box>
          )}
          <Stack
            flexDirection="row"
            justifyContent="end"
            sx={{ width: '100%' }}
          >
            <Pagination
              totalCount={appointmentStatusCount}
              onLimitChange={onLimitChange}
              onOffsetchange={onOffsetchange}
              offset={offset}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

AppointmentStatusMaster.propTypes = {
  className: PropTypes.string,
};
