export const transactionsTable_style = {
  paperContainer: {
    width: '100%',
    borderRadius: '10px',
    border: '1px solid #E3E3E3',
    maxWidth: '82vw',
  },
  tableRow: {
    cursor: 'pointer',
    overflowX: 'auto',
    '&:not(:last-child) > td, &:not(:last-child) > th': {
      borderBottom: '1px solid #E3E3E3', // Customize the border color and size
    },
  },
  tableCellSx: {
    minWidth: '200px',
    whiteSpace: 'nowrap',
  },
  tableCellBold: { minWidth: '150px', fontWeight: 500 },
  hightLightedCell: {
    minWidth: '150px',
    backgroundColor: '#EBFAF7',
    color: '#007965',
    fontWeight: 500,
  },
  StickyCell1: {
    minWidth: '150px',
    position: 'sticky',
    right: '182px',
    background: '#fff',
    zIndex: 100,
    boxShadow: '1px 0px 0px 0px #E3E3E3 inset',
  },
  StickyCell0: {
    minWidth: '150px',
    position: 'sticky',
    right: 0,
    background: '#fff',
    zIndex: 100,
  },
  transactionStatusSx: {
    px: 0.2,
    width: 'min-content',
    py: 0.1,
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 400,
  },
  IcontableCellSx: {
    minWidth: '30px',
    whiteSpace: 'nowrap',
    '& .MuiTableCell-root': {
      minWidth: '30px',
    },
  },
};
