export const tableSkeletonLoader_style = {
  cardRootSx: {
    borderRadius: '4px',
    // border: '1px solid #CCF3EC',
  },
  tableContentSx: {
    height: 50,
    border: '1px solid #CCF3EC',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    my: 1,
    borderRadius: '4px',
  },
  skeletonRoot: {
    backgroundColor: 'primaryTints.300',
    '&::after': {
      background:
        'linear-gradient( 90deg,transparent,rgba(255,255,255, 0.3),transparent)',
    },
  },
};
