/* eslint-disable no-sparse-arrays */
// import { envConfig } from '@hc/config';
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import axios from 'axios';
import toast from 'react-hot-toast';
import create from 'zustand';

const activityInitialData = {
  id: 0,
  activity_name: '',
  activity_image: '',
  category_id: 0,
  goal_id: 0,
  is_measurable: true,
  quantity: null,
  unit: 1,
  activity_time: 0,
  specific_time: {
    from: '',
    to: '',
  },
  activity_days: [],
  activity_notes: {},
  activity_dont: {},
  activity_references: [],
  activity_days_data: 0,
  is_active: true,
  error: {
    activity_name: '',
    category_id: '',
    goal_id: '',
    is_measurable: '',
    activity_time: '',
    quantity: '',
    activity_days: '',
  },
};

const LinkData = {
  id: 0,
  linkName: '',
  url: '',
  imageUrl: '',
  loading: false,
};

export const constructDrugData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((val) => {
      return {
        value: val,
        id: val?.id ?? 0,
        activity_name: val?.activity_name ?? '',
        is_active: val?.is_active ?? false,
        category_name: val?.category_name ?? '',
        created_at: val?.created_at ?? '',
        updated_at: val?.updated_at ?? '',
      };
    });
    return arr;
  }
};

export const useActivityTemplate = create((set, get) => ({
  activity: activityInitialData,
  activityTemplateListData: [],
  activityTemplateListCount: 0,
  loading: null,
  error: null,
  Link: [{ ...LinkData }],
  linkloading: false,

  isIamValidToSave: () => {
    const { activity } = get();
    const activityCopy = JSON.parse(JSON.stringify(activity));
    let isValid = true;
    const error = activityCopy?.error;
    // checking drugName
    if (!activity?.activity_name) {
      isValid = false;
      error.activity_name = 'Please enter your activity name';
    } else {
      error.activity_name = '';
    }
    // checking time
    if (!activity?.category_id) {
      isValid = false;
      error.category_id = 'Please select category type';
    } else {
      error.category_id = '';
    }
    // Quality
    if (!activity?.quantity && activity.is_measurable) {
      isValid = false;
      error.quantity = 'Please enter quantity';
    } else {
      error.quantity = '';
    }
    // checking when
    if (!activity?.goal_id) {
      isValid = false;
      error.goal_id = 'Please select goal type';
    } else {
      error.goal_id = '';
    }

    if (!activity?.activity_time) {
      isValid = false;
      error.activity_time = 'Please select activity time';
    } else {
      error.activity_time = '';
    }
    if (activity?.activity_days.length === 0) {
      isValid = false;
      error.activity_days = 'Please select activity days';
    } else {
      error.activity_days = '';
    }
    set({
      activity: {
        ...activity,
        error,
      },
    });

    return isValid;
  },
  addLink: () => {
    const { Link } = get();
    if (Link[Link.length - 1].linkName && Link[Link.length - 1].url) {
      set({ Link: [...Link, { ...LinkData, id: Link.length }] });
    } else {
      toast.error('Please fill out all fields to proceed ');
    }
  },

  handelLinkChange: (index, key, value) => {
    const { Link, getLinkPreview } = get();
    Link[index][key] = value;
    let timer;
    if (key === 'url') {
      Link[index].loading = true;
      if (timer) clearTimeout(timer);
      timer = setTimeout(async () => {
        if (/^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
          set({ Link });
          const image = await getLinkPreview(value);
          Link[index].imageUrl = image;
          Link[index].loading = false;
          set({ Link });
        }
      }, 1500);
    }
    set({ Link, linkloading: false });
  },
  handleLinkDelete: (index) => {
    const { Link } = get();
    Link.splice(index, 1);
    set({ Link });
  },

  getLinkPreview: async (link) => {
    try {
      return axios
        .post('https://api.linkpreview.net', {
          q: link,
          key: '02030c9c3f7d8a9a5df14c65dbca79ff',
        })
        .then((resp) => {
          set(() => ({
            linkloading: false,
          }));
          return resp.data.image;
        });
    } catch (error) {
      set({ loading: false });
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // GET ALL ACTIVITY TEMPLATE DATA
  getAllActivityTemplateData: async (search = '', limit = 10, offset = 0) => {
    try {
      set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getAllActivityTemplateData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplate/get-all-activity`,
            {
              search,
              offset,
              limit,
            },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        set(() => ({
          loading: false,
          activityTemplateListData:
            Array.isArray(data?.data) && data?.data?.length > 0
              ? constructDrugData(data?.data)
              : [],
          activityTemplateListCount: data?.count,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  activityStatusUpdate: async (payload) => {
    try {
      set({ loading: true });
      const payloadData = {
        activity_id: payload?.id ?? 0,
        status: payload?.status ?? true,
      };
      queryClient.invalidateQueries({
        queryKey: ['activity_status_update'],
      });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['activity_status_update', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplate/upsert-activity-status`,
            {
              ...payloadData,
            },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'Activity Status Updated Successfully',
        );
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  initialEditActivityData: async (activityId) => {
    const { activity } = get();
    try {
      queryClient.invalidateQueries({
        queryKey: ['initialEditDrugData'],
      });

      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['initialEditDrugData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplate/get-activity`,
            {
              id: activityId,
            },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        set(() => ({
          loading: false,
          activity:
            Object.keys(data.activityData).length > 0
              ? {
                  ...activity,
                  id: data?.activityData.id ?? null,
                  activity_name: data?.activityData?.activity_name ?? null,
                  activity_image: data?.activityData?.activity_image ?? null,
                  category_id: data?.activityData?.category_id ?? null,
                  goal_id: data?.activityData?.goal_id ?? null,
                  is_measurable: data?.activityData?.is_measurable ?? null,
                  quantity: data?.activityData?.quantity ?? null,
                  unit: data?.activityData?.unit ?? null,
                  activity_time: data?.activityData?.activity_time ?? null,
                  specific_time:
                    {
                      from: data?.activityData?.specific_time?.from ?? null,
                      to: data?.activityData?.specific_time?.to ?? null,
                    } ?? null,
                  activity_days:
                    data?.activityData?.activity_days?.map(Number) ?? null,
                  activity_notes: data?.activityData?.activity_notes ?? null,
                  activity_dont: data?.activityData?.activity_dont ?? null,
                  activity_references:
                    data?.activityData?.activity_references ?? null,
                  activity_days_data: data?.activity_days_data ?? null,
                }
              : [],
          Link: data?.activityData?.activity_references ?? [],
        }));
        return data?.activityData;
      }

      return set(() => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  handleActivityFormChange: (key, value) => {
    const { activity } = get();

    set({
      activity: {
        ...activity,
        [key]: value,
      },
    });
  },
  handleLinkUpdate: (key, value) => {
    set({
      Link: value,
    });
  },
  handleActivitySpecificChange: (key, value) => {
    const { activity } = get();
    const { specific_time } = activity;
    set({
      activity: {
        ...activity,
        specific_time: {
          ...specific_time,
          [key]: value,
        },
      },
    });
  },

  handleActivityNotesChange: (Key, editor) => {
    const { activity } = get();
    set({
      activity: {
        ...activity,
        [Key]: editor,
      },
    });
  },

  addEditActivityData: async () => {
    const { Link } = get();
    try {
      const { activity } = get();
      set({ loading: true });
      const payloadData = {
        id: activity.id || '',
        activity_name: activity?.activity_name ?? '',
        activity_image: activity?.activity_image ?? '',
        category_id: activity?.category_id ?? '',
        goal_id: activity?.goal_id ?? '',
        is_measurable: activity?.is_measurable ?? '',
        quantity: activity?.quantity ?? null,
        unit: activity?.unit ?? 1,
        activity_time: activity?.activity_time ?? '',
        specific_time:
          {
            from: activity?.specific_time.from ?? '',
            to: activity?.specific_time.to ?? '',
          } ?? null,
        activity_days: activity?.activity_days?.map(Number) ?? [],
        activity_notes: activity?.activity_notes ?? {},
        activity_dont: activity?.activity_dont ?? {},
        activity_references: Link[0]?.imageUrl?.length > 0 ? Link : [],
        is_active: true,
      };
      queryClient.invalidateQueries({
        queryKey: ['upsert_activity'],
      });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['upsert_activity'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplate/upsert-activity`,
            {
              ...payloadData,
            },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        if (data?.status?.message === 'Activity Name Already Exist') {
          toast.error(data?.status?.message);
          set({ loading: false });
          return 201;
        }
      }
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ??
            `Activity ${activity.id ? 'Updated' : 'Created'} Successfully`,
        );
        set({ loading: false });

        return 200;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },

  // UPDATE PROFILE PICTURE
  updateActivityPicture: (profile_pic_url) => {
    const { activity } = get();
    set({
      activity: {
        ...activity,
        activity_image: profile_pic_url,
      },
    });
  },

  clearActivity: () => {
    set({
      activity: JSON.parse(JSON.stringify(activityInitialData)),
      Link: [{ ...LinkData }],
    });
  },
  clearReference: () => {
    set({
      Link: [{ ...LinkData }],
    });
  },
  clearNotes: () => {
    set({
      activity: {
        ...get().activity,
        activity_notes: [],
      },
    });
  },
  clearDonts: () => {
    set({
      activity: {
        ...get().activity,
        activity_dont: [],
      },
    });
  },
  clearUnits: () => {
    set({
      activity: {
        ...get().activity,
        quantity: null,
        unit: 1,
      },
    });
  },
}));
