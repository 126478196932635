export const atomButton_style = {
  buttonSx: {
    borderRadius: '8px',
    '& .MuiLoadingButton-loadingIndicator': {
      color: 'primary.main',
    },
    '&.MuiLoadingButton-loading': {
      backgroundColor: 'primary.lighter',
    },
  },
};
