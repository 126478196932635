import { getDateFormatToString } from '@hc/dayjs';
import { backofficeRoutes } from '@hc/routes';
import { useAwarenessManagement } from '@hc/store/backoffice/awarenessManagement';
import {
  AwarenessCategoryIcon,
  CheckBox,
  DeleteIcon,
  EditIcon,
  Input,
  Label,
  Switch,
  UploadIcon
} from '@hc/ui/atoms';
import { TableSkeletonLoader } from '@hc/ui/components';
import { MasterTable } from '@hc/ui/components/backoffice';
import { AwarenessManagementTopContainer } from '@hc/ui/components/backoffice/awarenessManagementTopContainer';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { PlanManagementDrawer } from '@hc/ui/components/backoffice/planManagementDrawer';
import { handleUploadFile, queryClient } from '@hc/utils';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AwarenessManagement_style } from './style';

export default function AwarenessManagement(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [awarenessManagementDrawerOpen, setAwarenessManagementDrawerOpen] =
    useState(false);

  // Store Data

  const {
    awarenessManagementState,
    getAllAwarenessManagementData,
    clearAwarenessManagementData,
    awarenessManagemenStatusUpdate,
    isIamValidToSave,
    upsertAwarenessManagement,
    handleAwarenessManagementFormChange,
    updateAwarenessPicture,
    getAwarenessDataById,
    handleCategoryManagementFormChange,
    loading,
  } = useAwarenessManagement((state) => ({
    awarenessManagementState: state.awarenessManagementState,
    getAllAwarenessManagementData: state.getAllAwarenessManagementData,
    clearAwarenessManagementData: state.clearAwarenessManagementData,
    awarenessManagemenStatusUpdate: state.awarenessManagemenStatusUpdate,
    isIamValidToSave: state.isIamValidToSave,
    upsertAwarenessManagement: state.upsertAwarenessManagement,
    handleAwarenessManagementFormChange:
      state.handleAwarenessManagementFormChange,
    updateAwarenessPicture: state.updateAwarenessPicture,
    getAwarenessDataById: state.getAwarenessDataById,
    handleCategoryManagementFormChange:
      state.handleCategoryManagementFormChange,
    loading: state.loading,
  }));

  const { awarenessManagementData, awarenessManagement } =
    awarenessManagementState;

  // General Hooks

  const closeAwarenessManagementDrawer = () => {
    setAwarenessManagementDrawerOpen(false);
  };

  // searchValue IS THE PARAM
  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    queryClient.invalidateQueries({
      queryKey: ['getAllAwarenessManagementData'],
    });
    await getAllAwarenessManagementData(searchValue);
  };
  const handleAddClick = () => {
    clearAwarenessManagementData();
    setAwarenessManagementDrawerOpen(true);
  };

  const onStatusChange = async (id, status) => {
    const payload = {
      id,
      status: !status,
    };
    const response = await awarenessManagemenStatusUpdate(payload);
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllAwarenessManagementData'],
      });
      await getAllAwarenessManagementData(search);
    }
  };

  const onEdit = async (type, data) => {
    if (type === 'edit') {
      await getAwarenessDataById(data);
      setAwarenessManagementDrawerOpen(true);
    } else if (type === 'category') {
      handleCategoryManagementFormChange('awareness_id', data?.id);
      navigate(backofficeRoutes?.awarenessCategoryManagement, {
        state: { id: data?.id, name: data?.awareness_name },
      });
    }
  };

  const handleAwarenessPicUpload = async (key, e) => {
    const res = await handleUploadFile(e.target.files);
    if (
      res?.[0]?.status === 'ok' &&
      res?.[0]?.message === 'Upload Successfully!'
    ) {
      // UPDATE PROFILE PICTURE
      updateAwarenessPicture(key, res?.[0]?.downloadUrl);
      return toast.success('Awareness Picture Updated Successfully');
    }
    toast.error('Something went wrong please try again!');
  };

  const columns = [
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => (
        <Box
          sx={{ pl: 1, display: 'flex', gap: 1.8, cursor: 'pointer' }}
          onClick={() => onEdit('edit', params?.row)}
        >
          <EditIcon id="edit" style={{ cursor: 'pointer', color: 'grey' }} />
          <AwarenessCategoryIcon
            id="amount"
            onClick={() => onEdit('category', params?.row)}
          />
        </Box>
      ),
    },
    {
      field: 'awareness_name',
      headerName: 'Awareness Name',
      width: 300,
      renderCell: (params) => (
        <>
          <Typography
            sx={{
              color: '#0E1824',
              fontFamily: 'poppins,Roboto,-apple-system,sans-serif',
              fontWeight: 400,
              fontSize: '0.875rem',
              lineHeight: '1.43',
            }}
          >
            {params?.row?.awareness_name}
          </Typography>
          {params?.row?.high_priority === true && (
            <Typography
              sx={{
                fontSize: '12px',
                borderRadius: '6px',
                padding: '4px 8px',
                ml: 0.5,
              }}
              backgroundColor={`${
                params?.row?.high_priority === true ? '#E8FCF0' : '#ffffe6'
              }`}
              color={`${
                params?.row?.high_priority === true ? '#4CAF50' : '#FACC15'
              }`}
            >
              {params?.row?.high_priority === true ? 'High' : 'Normal'}
            </Typography>
          )}
        </>
      ),
    },
    {
      field: 'category_count',
      headerName: 'Category Mapped',
      width: 200,
    },
    {
      field: 'impression_count',
      headerName: 'Impression Count',
      width: 200,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'active_status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => (
        <Switch
          id="toggle1"
          checked={params?.row?.active_status}
          onChange={() =>
            onStatusChange(
              params?.row?.awareness_id,
              params?.row?.active_status,
            )
          }
          rootStyle={AwarenessManagement_style.switchSx}
        />
      ),
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },
  ];

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllAwarenessManagementData'],
    });
    await getAllAwarenessManagementData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllAwarenessManagementData'],
    });
    await getAllAwarenessManagementData(search, rowsPerPage, offset);
  };

  const initialData = async () => {
    await getAllAwarenessManagementData('');
  };

  // UPSERT EXPERT FUNCTION
  const upsertAwarenessManagementFnc = async () => {
    const result = isIamValidToSave();

    if (result) {
      const reponse = await upsertAwarenessManagement();
      if (reponse === 200) {
        queryClient.invalidateQueries({
          queryKey: ['getAllAwarenessManagementData'],
        });
        initialData();
        setAwarenessManagementDrawerOpen(false);
        clearAwarenessManagementData();
      }
    }
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AwarenessManagementTopContainer
              addMasterStyle={AwarenessManagement_style.addMasterSx}
              handleSearch={handleSearch}
              handleAddClick={handleAddClick}
              title="Awareness Management"
              count={awarenessManagementData?.count ?? '0'}
              showSearchField
              searchFieldPlaceholder="Search Awareness Name"
              showAddButton
              addButtonName="Add Awareness"
              searchValue={search}
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Box>
                <TableSkeletonLoader />
              </Box>
            ) : (
              <MasterTable
                columns={columns}
                data={
                  Array.isArray(awarenessManagementData?.data) &&
                  awarenessManagementData?.data?.length
                    ? awarenessManagementData?.data
                    : []
                }
                customTableStyle={AwarenessManagement_style.dataTableCustomSx}
                hideFooter
                rootStyle={{ height: '68vh' }}
              />
            )}
            <Stack
              flexDirection="row"
              justifyContent="end"
              sx={{ width: '100%' }}
            >
              <Pagination
                totalCount={awarenessManagementData?.count ?? 0}
                onLimitChange={onLimitChange}
                onOffsetchange={onOffsetchange}
                offset={offset}
                rowsPerPage={rowsPerPage}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* Add Plan Management Drawer */}
        <Box>
          <PlanManagementDrawer
            isUpdate={awarenessManagement?.id?.length > 0 ? true : false}
            drawerOpen={awarenessManagementDrawerOpen}
            loading={loading}
            header="Awareness Management"
            closeDrawer={closeAwarenessManagementDrawer}
            upsertUser={upsertAwarenessManagementFnc}
            // eslint-disable-next-line react/no-children-prop
            children={
              <Box sx={{ width: '400px' }}>
                <Grid>
                  <Grid pb={1.5} item md={12} lg={12} xl={12}>
                    <Label isRequired htmlFor="awareness_name">
                      Name
                    </Label>
                    <Input
                      id="awareness_name"
                      placeholder="Awareness Name"
                      isError={awarenessManagement?.error?.name?.length > 0}
                      errorMessage={awarenessManagement?.error?.name}
                      onChange={(event) =>
                        handleAwarenessManagementFormChange(
                          'name',
                          event?.target.value,
                        )
                      }
                      value={awarenessManagement?.name}
                    />
                  </Grid>
                  <Grid pb={1.5} item md={12} lg={12} xl={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                      <CheckBox
                        id="circle"
                        checked={awarenessManagement?.high_priority}
                        onChange={(e) => {
                          handleAwarenessManagementFormChange(
                            'high_priority',
                            e.target.checked,
                          );
                        }}
                      />
                      <Typography sx={{ cursor: 'pointer', fontSize: '14px' }}>
                        High Priority
                      </Typography>
                    </Box>
                  </Grid>

                  {/*  -------------AWARENESS IMAGE UPLOAD FIELD ----------- */}
                  <Box sx={AwarenessManagement_style.inputGroupSx}>
                    <Box sx={AwarenessManagement_style.LablewithBtnSx}>
                      <Label
                        rootStyle={AwarenessManagement_style.labelSx}
                        htmlFor="awarenessHighPriorityImage"
                        isRequired
                      >
                        Image (High Priority)
                      </Label>
                      {awarenessManagement?.high_priority_image?.length > 0 && (
                        <Box
                          id="delete"
                          sx={AwarenessManagement_style.FlexSx}
                          onClick={() =>
                            updateAwarenessPicture('high_priority_image', '')
                          }
                        >
                          <DeleteIcon />
                          <Typography
                            sx={AwarenessManagement_style.ImgaeRemoveSx}
                          >
                            &nbsp; Delete
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box sx={AwarenessManagement_style.uploadFileRootSx}>
                      {!awarenessManagement?.high_priority_image?.length >
                        0 && (
                        <label htmlFor="uplodebtn">
                          <Box
                            sx={AwarenessManagement_style.activeUploadFileSx}
                          >
                            <Box
                              component="label"
                              sx={{
                                ...AwarenessManagement_style.uploadIconSx,
                              }}
                            >
                              <UploadIcon />
                              <input
                                hidden
                                name="img"
                                accept="image/*"
                                multiple="multiple"
                                type="file"
                                id="uplodebtn"
                                onChange={(e) =>
                                  handleAwarenessPicUpload(
                                    'high_priority_image',
                                    e,
                                  )
                                }
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={AwarenessManagement_style.browseTextSx}
                              >
                                Click to browse and upload
                              </Typography>
                            </Box>
                          </Box>
                        </label>
                      )}

                      {awarenessManagement.high_priority_image?.length > 0 && (
                        <Box>
                          <img
                            src={awarenessManagement.high_priority_image}
                            alt="high_priority_awareness_image"
                            style={{
                              width: '400px',
                              height: '160px',
                              objectFit: 'cover',
                              borderRadius: '10px',
                            }}
                          />
                        </Box>
                      )}
                    </Box>

                    {awarenessManagement?.error?.high_priority_image && (
                      <Typography color="#F44F5A" mt={0.5} fontSize={12}>
                        {awarenessManagement?.error?.high_priority_image}
                      </Typography>
                    )}
                  </Box>

                  {/*  -------------AWARENESS IMAGE UPLOAD FIELD ----------- */}
                  <Box sx={AwarenessManagement_style.inputGroupSx}>
                    <Box sx={AwarenessManagement_style.LablewithBtnSx}>
                      <Label
                        rootStyle={AwarenessManagement_style.labelSx}
                        htmlFor="awarenessImage"
                        isRequired
                      >
                        Image
                      </Label>
                      {awarenessManagement?.image?.length > 0 && (
                        <Box
                          id="delete"
                          sx={AwarenessManagement_style.FlexSx}
                          onClick={() => updateAwarenessPicture('image', '')}
                        >
                          <DeleteIcon />
                          <Typography
                            sx={AwarenessManagement_style.ImgaeRemoveSx}
                          >
                            &nbsp; Delete
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box sx={AwarenessManagement_style.uploadFileRootSx}>
                      {!awarenessManagement?.image?.length > 0 && (
                        <label htmlFor="uplodebtn">
                          <Box
                            sx={AwarenessManagement_style.activeUploadFileSx}
                          >
                            <Box
                              component="label"
                              sx={{
                                ...AwarenessManagement_style.uploadIconSx,
                              }}
                            >
                              <UploadIcon />
                              <input
                                hidden
                                name="img"
                                accept="image/*"
                                multiple="multiple"
                                type="file"
                                id="uplodebtn"
                                onChange={(e) =>
                                  handleAwarenessPicUpload('image', e)
                                }
                              />
                            </Box>
                            <Box>
                              <Typography
                                sx={AwarenessManagement_style.browseTextSx}
                              >
                                Click to browse and upload
                              </Typography>
                            </Box>
                          </Box>
                        </label>
                      )}

                      {awarenessManagement.image?.length > 0 && (
                        <Box>
                          <img
                            src={awarenessManagement.image}
                            alt="awareness_image"
                            style={{
                              width: '400px',
                              height: '160px',
                              objectFit: 'cover',
                              borderRadius: '10px',
                            }}
                          />
                        </Box>
                      )}
                    </Box>

                    {awarenessManagement?.error?.image && (
                      <Typography color="#F44F5A" mt={0.5} fontSize={12}>
                        {awarenessManagement?.error?.image}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
            }
          />
        </Box>
      </Box>
    </Box>
  );
}

AwarenessManagement.propTypes = {
  className: PropTypes.string,
};
