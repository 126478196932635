export const sidebar_style = {
  rootSx: {
    borderRadius: '0%',
    // maxHeight: 'calc(100% - 60px)',
  },
  menuSx: {
    mx: '12px',
    fontSize: '14px',
    color: 'text.primary',
    fontWeight: 600,
    padding: '5px',
  },
  ListItemButtonSx: {
    pb: 1,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  iconSx: {
    display: 'flex',
    alignItems: 'center',
  },
  ListItemButtonSelected:{
    pb: 1,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor:"red !important"
  }
};
