export const addReferences_style = {
  boxRootSx: {
    pt: 1,
    width: '400px',
  },
  SaveButtonSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    width: '180px',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },

  LablewithBtnSx: { display: 'flex', justifyContent: 'space-between', pr: 0.8 },
  ImgaeRemoveSx: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#F44F5A',
    cursor: 'pointer',
  },
  FlexSx: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    px: 0.5,
  },
  cancleButtonSx: {
    border: '1px solid',
    width: '180px',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  inputGroupSx: { mb: 1.5 },
  imginputGroupSx: { mb: 1.5 },
  labelSx: {
    color: 'text.label',
    mb: 1,
  },
  inputFieldSx: {
    marginTop: '4px',
  },
  getlinkSx: {
    textAlign: 'center',

    color: 'primary.main',
  },
  expertField: {
    backgroundColor: '#F7F7F7',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: 'none',
      },
    },
  },
};
