/* eslint-disable no-sparse-arrays */
// import { envConfig } from '@hc/config';
import { envConfig } from '@hc/config';
import { endOfFnc, startOfFnc, subtractFnc } from '@hc/dayjs';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructSeekHelpTableData = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val) => {
      arr.push({
        value: val,
        id: val?.seek_help_id ?? '',
        userName: val?.seek_help_data?.name ?? '',
        mobileNumber:
          val?.seek_help_data?.country_code && val?.seek_help_data?.mobile_no
            ? `${val?.seek_help_data?.country_code} ${val?.seek_help_data?.mobile_no}`
            : '',
        emailId: val?.email_id ?? '',
        content: val?.seek_help_data?.content ?? '',
        status: val?.status ?? '',
        createdAt: val?.created_at ?? '',
        updatedAt: val?.updated_at ?? '',
      });
    });
    return arr;
  }
};

export const constructSeekHelpStatsCardData = (data) => {
  return [
    {
      count: data?.all,
      percentage: '',
      description: 'Total no. of Seek Help',
      color: '#007965',
    },
    {
      count: data?.open,
      percentage: '',
      description: 'Open Seek Help',
      color: '#FACC15',
    },
    {
      count: data?.closed,
      percentage: '',
      description: 'Closed Seek Help',
      color: '#4CAF50',
    },
  ];
};

export const constructStatusTrackData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((val) => {
      return {
        // light-yellow : #ffffe6
        // light-green : #E8FCF0
        // light-red : #FEEAEA
        backgroundColor:
          val?.status_id === 1
            ? '#ffffe6'
            : val?.status_id === 2
            ? '#E8FCF0'
            : '#FEEAEA',
        // yellow : #FACC15
        // green : #4CAF50
        // red : #F44F5A
        color:
          val?.status_id === 1
            ? '#FACC15'
            : val?.status_id === 2
            ? '#4CAF50'
            : '#F44F5A',
        title: val?.status_name ?? '',
        subTitle: val?.name ?? '',
        dateTime: val?.at_time ?? '',
        dateTimeFormat: 'DD MMM YYYY, hh:mm A',
      };
    });
    return arr;
  }
};

export const UseSeekHelp = create((set, get) => ({
  seekHelpState: {
    seekHelpStatsCardData: [],
    seekHelpListData: [],
    seekHelpCount: 0,
    tabIndex: 0,
    fromDate: '',
    toDate: '',
    search: '',
    statusType: 0,
    // Form state
    id: '',
    name: '',
    email: '',
    mobile: '',
    countryCode: '',
    attachmentURL: [],
    status: 0,
    seekHelpStatus: 0,
    error: {
      status: '',
    },
    seekHelpStatusTrackData: [],
  },
  loading: null,
  error: null,

  isIamValidToSave: () => {
    const { seekHelpState } = get();
    const seekHelpStateCopy = JSON.parse(JSON.stringify(seekHelpState));
    let isValid = true;
    const error = seekHelpStateCopy?.error;
    // checking status
    if (!seekHelpStateCopy?.status) {
      isValid = false;
      error.status = 'Please select status';
    } else {
      error.status = '';
    }

    set({
      seekHelpState: {
        ...seekHelpState,
        error,
      },
    });

    return isValid;
  },

  // GET ALL SEEK HELP DATA
  getAllSeekHelpData: async (search = '', limit = 10, offset = 0) => {
    const { seekHelpState } = get();
    const { fromDate, toDate, statusType } = seekHelpState;
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    const fromDateConst =
      typeof fromDate === 'object' ? startOfFnc(fromDate, 'day') : '';
    const toDateConst =
      typeof toDate === 'object' ? endOfFnc(toDate, 'day') : '';

    try {
      set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getAllSeekHelpData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/seek-helps/get/all?user_id=${tokenData?.id}&search=${search}&limit=${limit}&offset=${offset}&start_date=${fromDateConst}&end_date=${toDateConst}&status_type=${statusType}`,
            {},
            true,
          );

          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          loading: false,
          seekHelpState: {
            ...seekHelpState,
            seekHelpListData:
              Array.isArray(data?.results) && data?.results?.length > 0
                ? constructSeekHelpTableData(data?.results)
                : [],
            seekHelpStatsCardData: constructSeekHelpStatsCardData({
              all: data?.seek_help_count ?? 0,
              open: data?.open_seek_help_count ?? 0,
              closed: data?.closed_seek_help_count ?? 0,
            }),
            seekHelpCount: data?.seek_help_count ?? 0,
          },
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  // GET SEEK HELP DATA
  getSeekHelpData: async (id) => {
    const { seekHelpState } = get();
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getSeekHelpData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/seek-help/get?user_id=${tokenData?.id}&seek_help_id=${id}`,
            {},
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          seekHelpState: {
            ...seekHelpState,
            id: data?.id ?? '',
            name: data?.seek_help_data?.name ?? '',
            email: data?.email_id ?? '',
            mobile: data?.seek_help_data?.mobile_no ?? '',
            countryCode: data?.seek_help_data?.country_code ?? '',
            content: data?.seek_help_data?.content ?? '',
            attachmentURL: data?.seek_help_data?.url ?? [],
            status: data?.status ?? 0,
            seekHelpStatus: data?.status ?? 0,
          },
        }));
      }
      return status?.code;
    } catch (error) {
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  // UPDATE SEEK HELP DATA
  updateSeekHelpData: async () => {
    const { seekHelpState, clearDrawerData } = get();
    const { name, email, id } = seekHelpState;
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      const {
        data: { status },
      } = await queryClient.fetchQuery({
        queryKey: ['updateSeekHelpData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/seek-help/resolved-status/update`,
            {
              user_id: tokenData?.id ?? '',
              email_id: email,
              name,
              seek_help_id: id,
            },
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        toast.success(status?.message);
        clearDrawerData();
      }
      return status?.code;
    } catch (error) {
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  // GET SEEK HELP DATA
  getSeekHelpStatusTrackData: async (seek_help_id) => {
    const { seekHelpState } = get();
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getSeekHelpStatusTrackData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/seek-help/track-status?user_id=${tokenData?.id}&seek_help_id=${seek_help_id}`,
            {},
            true,
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          seekHelpState: {
            ...seekHelpState,
            seekHelpStatusTrackData:
              Array.isArray(data) && data?.length > 0
                ? constructStatusTrackData(data)
                : [],
          },
        }));
      }
      return status?.code;
    } catch (error) {
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  // Handle change function
  handleChange: (key, value) => {
    const { seekHelpState } = get();

    set(() => ({
      seekHelpState: {
        ...seekHelpState,
        [key]: value,
      },
    }));
  },

  // update from date, To date & Tab index
  updateFromAndToDateAndTab: (fromDate, toDate, tab) => {
    const { seekHelpState } = get();

    set(() => ({
      seekHelpState: {
        ...seekHelpState,
        fromDate,
        toDate,
        tabIndex: tab,
      },
    }));
  },
  // Update tab index
  updateTabIndex: (fromDatee, toDatee) => {
    const { seekHelpState } = get();
    if (
      startOfFnc(new Date(), 'day') === startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(), 'day') === endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        seekHelpState: {
          ...seekHelpState,
          tabIndex: 0,
        },
      }));
    } else if (
      startOfFnc(new Date(), 'week') === startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(), 'week') === endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        seekHelpState: {
          ...seekHelpState,
          tabIndex: 1,
        },
      }));
    } else if (
      startOfFnc(new Date(), 'month') === startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(), 'month') === endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        seekHelpState: {
          ...seekHelpState,
          tabIndex: 2,
        },
      }));
    } else if (
      startOfFnc(new Date(subtractFnc(new Date(), 1, 'week')), 'week') ===
        startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(subtractFnc(new Date(), 1, 'week')), 'week') ===
        endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        seekHelpState: {
          ...seekHelpState,
          tabIndex: 3,
        },
      }));
    } else if (
      startOfFnc(new Date(subtractFnc(new Date(), 1, 'month')), 'month') ===
        startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(subtractFnc(new Date(), 1, 'month')), 'month') ===
        endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        seekHelpState: {
          ...seekHelpState,
          tabIndex: 4,
        },
      }));
    } else {
      set(() => ({
        seekHelpState: {
          ...seekHelpState,
          tabIndex: 5,
        },
      }));
    }
  },

  clearFilter: () => {
    const { seekHelpState } = get();
    set(() => ({
      seekHelpState: {
        ...seekHelpState,
        fromDate: new Date(startOfFnc(new Date(), 'day')),
        toDate: new Date(endOfFnc(new Date(), 'day')),
        statusType: 0,
      },
    }));
  },
  clearDrawerData: () => {
    const { seekHelpState } = get();
    set(() => ({
      seekHelpState: {
        ...seekHelpState,
        id: '',
        name: '',
        email: '',
        mobile: '',
        countryCode: '',
        attachmentURL: [],
        status: 0,
        seekHelpStatus: 0,
        error: {
          status: '',
        },
      },
    }));
  },
}));
