/* eslint-disable react/jsx-boolean-value */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Switch as MuiSwitch } from '@mui/material';

const IOSSwitch = styled((props) => (
  <MuiSwitch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 28,
  height: 17,
  padding: 0,
  margin: '0px!important',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(11px)',
      opacity: 1,
      color: '#fff',
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
      },
    },
    '&.Mui-checked+.MuiSwitch-track': {
      opacity: 1,
    },
  },
  '&.MuiSwitch-switchBase.Mui-checked': {
    color: '#F69D5B',
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 13,
    height: 13,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    // backgroundColor: theme.palette.mode === '#5C6266' ? '#888888' : '#F69D5B',
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export function Switch(props) {
  const {
    onChange = () => false,
    checked,
    rootStyle = {},
    className = '',
    ...rest
  } = props;
  return (
    <IOSSwitch
      sx={{
        '& .MuiSwitch-track': {
          backgroundColor: 'text.hint',
          opacity: 1,
          border: 0,
        },
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          backgroundColor: '#F69D5B!important',
          opacity: 1,
        },
        m: 0.5,
        ...rootStyle,
      }}
      onChange={onChange}
      className={`${className}`}
      {...rest}
      checked={checked}
    />
  );
}

Switch.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  rootStyle: PropTypes.object,
  className: PropTypes.string,
};
