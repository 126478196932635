/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Table } from '@atoms';
import { Box } from '@mui/material';

export function MasterTable(props) {
  const {
    data = [],
    columns = [],
    className = '',
    customTableStyle,
    hideFooter,
    rootStyle,
    rowHeight,
    ...rest
  } = props;

  return (
    <Box>
      <Table
        rows={data}
        columns={columns}
        rootStyle={{ height: '75vh', ...rootStyle }}
        emptyState
        autoHeight={Object.keys(data).length >= 0 ? false : true}
        customTableStyle={customTableStyle}
        hideFooter={hideFooter}
        rowHeight={rowHeight}
      />
    </Box>
  );
}
