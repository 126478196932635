import { useViewPort } from '@hc/store';
import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from '../../../atoms';
import { logoutModal_style } from './style';

function LogoutModal(props) {
  const {
    isModalOpen,
    handleClose = () => {},
    handleDelete = () => {},
  } = props;

  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <Box sx={logoutModal_style.totalModalSx}>
        <Box
          sx={
            isMobilePort
              ? logoutModal_style.totalMobileBoxSx
              : logoutModal_style.totalBoxSx
          }
        >
          <Box mt={2}>
            <Typography sx={logoutModal_style.description}>
              {' '}
              Are you sure you want to Logout?
            </Typography>
            <Box
              mt={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <Button
                sx={logoutModal_style.closebuttonSx}
                onClick={handleClose}
              >
                CANCEL
              </Button>
              <Button
                sx={logoutModal_style.DeletebuttonSx}
                onClick={handleDelete}
              >
                LOGOUT
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

LogoutModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleDelete: PropTypes.func,
};

export { LogoutModal };
