import { backofficeRoutes } from '@hc/routes';
import { PageNotFound } from '@hc/ui/components';
import { RootLayout } from '@hc/ui/components/backoffice';
import ActivityTemplates from '@pages/activityTemplates';
import ErrorBoundary from '@pages/errorBoundary';
import Login from '@pages/login';
import DrugMaster from '@pages/masters/drugMaster';
import PlanTemplates from '@pages/planTemplates';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AwarenessCategoryManagement from '../pages/awarenessCategoryManagement';
import AwarenessManagement from '../pages/awarenessManagement';
import DrugAuditPage from '../pages/drugAudit';
import FeedsandBugs from '../pages/feedsandBugs';
import MasterManagement from '../pages/masters';
import OrganizationTemplate from '../pages/organization';
import ContractDetails from '../pages/organization/contractDetails';
import OrganizationMember from '../pages/organization/organizationMember';
import ParameterSetup from '../pages/parameters';
import Patient from '../pages/patinet';
import PaymentSetup from '../pages/paymentSetup';
import PlanManagement from '../pages/planManagement';
import PlanStatusPage from '../pages/planStatus';
import SeekHelp from '../pages/seekHelp';
import SettlementsPages from '../pages/settlements';
import TransactionsPages from '../pages/transactions';
import UserManagement from '../pages/userManagement';
import { PrivateRouter } from './privateRouter';

// import { PrivateRouter } from './privateRouter';

const pathSlicer = (path, sliceStart = 1) => path.slice(sliceStart);

const router = createBrowserRouter([
  {
    path: pathSlicer(backofficeRoutes?.login),
    element: (
      <PrivateRouter>
        <RootLayout showLayout={false}>
          <Login />
        </RootLayout>
      </PrivateRouter>
    ),
  },
  {
    path: '/',
    element: (
      <PrivateRouter>
        <RootLayout />
      </PrivateRouter>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: pathSlicer(backofficeRoutes?.userManagement),
        element: <UserManagement />,
      },
      {
        path: pathSlicer(backofficeRoutes?.organization),
        element: <OrganizationTemplate />,
      },
      {
        path: pathSlicer(backofficeRoutes?.organizationMember),
        element: <OrganizationMember />,
      },
      {
        path: pathSlicer(backofficeRoutes?.contractDetails),
        element: <ContractDetails />,
      },
      {
        path: pathSlicer(backofficeRoutes?.masterManagement),
        element: <MasterManagement />,
      },
      {
        path: pathSlicer(backofficeRoutes?.drugMaster),
        element: <DrugMaster />,
      },
      {
        path: pathSlicer(backofficeRoutes?.activity),
        element: <ActivityTemplates />,
      },
      {
        path: pathSlicer(backofficeRoutes?.plan),
        element: <PlanTemplates />,
      },
      {
        path: pathSlicer(backofficeRoutes?.paymentSetup),
        element: <PaymentSetup />,
      },
      {
        path: pathSlicer(backofficeRoutes?.transactions),
        element: <TransactionsPages />,
      },
      {
        path: pathSlicer(backofficeRoutes?.settlements),
        element: <SettlementsPages />,
      },
      {
        path: pathSlicer(backofficeRoutes?.planStatus),
        element: <PlanStatusPage />,
      },
      {
        path: pathSlicer(backofficeRoutes?.planManagement),
        element: <PlanManagement />,
      },
      {
        path: pathSlicer(backofficeRoutes?.awarenessManagement),
        element: <AwarenessManagement />,
      },
      {
        path: pathSlicer(backofficeRoutes?.awarenessCategoryManagement),
        element: <AwarenessCategoryManagement />,
      },

      {
        path: pathSlicer(backofficeRoutes?.patient),
        element: <Patient />,
      },
      {
        path: pathSlicer(backofficeRoutes?.feedsandBugs),
        element: <FeedsandBugs />,
      },
      {
        path: pathSlicer(backofficeRoutes?.parameter),
        element: <ParameterSetup />,
      },
      {
        path: pathSlicer(backofficeRoutes?.drugAudit),
        element: <DrugAuditPage />,
      },
      {
        path: pathSlicer(backofficeRoutes?.seekHelp),
        element: <SeekHelp />,
      },
    ],
  },

  {
    path: '*',
    errorElement: <ErrorBoundary />,
    element: <PageNotFound />,
  },
]);

function RouterApp() {
  return <RouterProvider router={router} />;
}

export default RouterApp;
