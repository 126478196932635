import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructDrugData = (data) => {
  const allergiesTableData = data.map((val) => ({
    value: val,
    id: val?.id ?? 0,
    allergy: val?.allergy ?? '',
    status: val?.is_active ?? false,
    updated_at: val?.updated_at ?? '',
    created_at: val?.created_at ?? '',
  }));
  return allergiesTableData;
};

export const useAllergies = create((set, get) => ({
  allergiesMasterData: [],
  allergiesMasterCount: 0,
  allergy_id: '',
  allergy_name: '',
  loading: false,
  error: {
    allergy_name: '',
  },

  // ------------- STORE METHODS ------------------
  getAllAllergies: async (search = '', limit = 10, offset = 0) => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getAllAllergies', search, offset],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backofficeMaster/get-all-allergy`,
            {
              search,
              limit,
              offset,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        set(() => ({
          allergiesMasterData:
            Array.isArray(data?.data?.allergy_data) &&
            data?.data?.allergy_data?.length > 0
              ? constructDrugData(data?.data?.allergy_data)
              : [],
          allergiesMasterCount: data?.data?.allergy_count,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!'
      );
    }
  },

  allergiesStatusUpdate: async (payload) => {
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/backofficeMaster/allergy-status-update`,
        payload,
        true
      );
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'Allergies Status Updated Successfully'
        );
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  addEditAllergyMaster: async () => {
    const { allergy_name, allergy_id: id } = get();
    const type = id ? 'Update' : 'Add';
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/backofficeMaster/allergy-upsert`,
        { id: id || '', allergy_name },
        true
      );
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? `Allergy Data ${type} Successfully`
        );
      }
      set({ loading: false });
      return data;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  handleChange: (value) => {
    set({ allergy_name: value });
  },
  isIamValidToSave: () => {
    const { allergy_name, error } = get();
    if (!allergy_name) {
      error.allergy_name = 'Please enter your allergies name';
    } else {
      error.allergy_name = '';
      return true;
    }
  },

  initialEditAllergies: (value) => {
    set({
      allergy_name: value.allergy ?? '',
      allergy_id: value.id ?? '',
    });
  },

  clearAllergiesData: () => {
    set({
      allergy_name: '',
      allergy_id: '',
    });
  },
}));
