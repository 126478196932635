export const user_management_style = {
  togglebuttoninactiveSX: {
    color: '#5C6266',
    border: '1px solid ',
    borderColor: 'transparent',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '8px',
    // padding: '5px 10px',
    backgroundColor: '#E7FCF9',
    '&:hover': {
      color: '#5C6266',
      backgroundColor: '#E7FCF9',
    },
  },

  togglebuttonactiveSX: {
    color: 'primary.main',
    backgroundColor: '#E7FCF9',
    borderColor: 'primary.main',
    border: '1px solid ',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    // padding: '5px 10px',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: '#E7FCF9',
    },
  },
  buttonactiveSx: {
    border: '2px solid ',
    color: '#fff',
    textTransform: 'capitalize',
    padding: '10px',
    borderRadius: '8px',
    bgcolor: 'primary.main',
    cursor: 'pointer',
  },
  buttoninactiveSx: {
    backgroundColor: '#fff',
    border: '2px solid #E3E3E3',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: '12px',
    padding: '10px',
    color: '#5C6266',
    cursor: 'pointer',
  },

  hcDoctorSx: {
    // position: 'absolute',
    // bottom: '-5px',
    // margin: '-3px 16px',
    // mx: 1,
    // my: -1,
    backgroundColor: 'common.white',
    borderRadius: '50px',
    border: '2px solid',
    borderColor: 'common.white',
    '&:hover': {
      backgroundColor: 'common.white',
      borderColor: 'common.white',
      border: '2px solid',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '18px',
    width: '18px',
  },
  profileSx: {
    fontSize: '16px',
    color: 'primary.main',
    backgroundColor: 'red',
    width: '40px',
    height: '40px',
    border: '2px solid',
    borderColor: 'common.white',
  },
  checkSecondDisabledStyle: {
    backgroundColor: '#007965',
    opacity: 0.5,
    backgroundImage: '#F0F0F0',
    'input:hover ~ &': {
      backgroundColor: '#F0F0F0',
    },
  },
  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: 'text.hint',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#007965',
      opacity: 1,
    },
  },
  dataTableCustomSx: {
    '& .MuiDataGrid-row': {
      borderBottom: '2px solid',
      borderColor: 'grey.400',
    },
    '& .MuiDataGrid-main': {
      border: '1px solid',
      borderColor: 'grey.400',
      bgcolor: '#FFFFFF',
      borderRadius: '6px',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid',
      borderColor: 'grey.400',
    },
  },
};
