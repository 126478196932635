import { UseDrugAudit } from '@hc/store/backoffice/drugAudit';
import {
  Button,
  DateRangePicker,
  Drawer,
  Label,
  ToggleButton
} from '@hc/ui/atoms';
import { queryClient } from '@hc/utils';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { drugAuditFilterDrawer_style } from './style';

function DrugAuditFilterDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    drugAuditDrawerOpen = false,
    closeDrugAuditDrawer = () => false,
    ...rest
  } = props;

  const {
    getAllDrugAuditData,
    drugAuditState,
    clearFilter,
    handleChange,
    updateTabIndex,
    loading,
  } = UseDrugAudit((state) => ({
    getAllDrugAuditData: state.getAllDrugAuditData,
    drugAuditState: state.drugAuditState,
    clearFilter: state.clearFilter,
    handleChange: state.handleChange,
    updateTabIndex: state.updateTabIndex,
    loading: state.loading,
  }));

  const { drugAuditType, fromDate, toDate, search } = drugAuditState;

  const handleDateChange = (key, value) => {
    if (key === 'fromDate') {
      handleChange(key, value);
    } else {
      const endDate = new Date(value);
      if (endDate > fromDate) {
        handleChange(key, value);
      } else {
        toast.error('Please select greater than from date');
      }
    }
  };
  const handleAppointmentTypeChange = (key, value) => {
    handleChange(key, value);
  };

  const HandleSubmit = async () => {
    if (fromDate !== '' || toDate !== '') {
      if (
        (typeof fromDate === 'object' && typeof toDate === 'object') ||
        (typeof fromDate === 'object' && typeof toDate === 'object')
      ) {
        const dateFrom = new Date(fromDate);
        const dateTo = new Date(toDate);
        if (dateFrom < dateTo) {
          queryClient.invalidateQueries({
            queryKey: ['drugAuditData'],
          });

          await getAllDrugAuditData(search);
          updateTabIndex(dateFrom, dateTo);
          closeDrugAuditDrawer();
        } else {
          toast.error('Please select greater than from date');
        }
      } else {
        toast.error('Please select from date / to date');
      }
    } else {
      queryClient.invalidateQueries({
        queryKey: ['drugAuditData'],
      });

      await getAllDrugAuditData(search);
      closeDrugAuditDrawer();
    }
  };

  const HandleDrawerClose = () => {
    clearFilter();
  };

  const DrugAuditType = [
    { value: 'All', name: 'All' },
    { value: 'New Appointment', name: 'New Appointment' },
    { value: 'Follow-up', name: 'Follow Up' },
    { value: 'Referral', name: 'Referral' },
  ];

  return (
    <Box
      sx={{
        ...drugAuditFilterDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {drugAuditDrawerOpen && (
        <Drawer
          show={drugAuditDrawerOpen}
          onCloseDrawer={closeDrugAuditDrawer}
          anchor="right"
          isCloseIconRequired
          header="Filter"
          headerStyle={drugAuditFilterDrawer_style.headerSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                position: 'absolute',
                inset: 'auto 0 0 0',
                mb: 3,
                px: 2,
              }}
            >
              <Button
                buttonStyle={drugAuditFilterDrawer_style.SaveButtonSx}
                // onClick={() => upsertDrugMaster()}
                loading={loading}
                onClick={() => HandleSubmit()}
              >
                Apply Filter
              </Button>
              <Button
                buttonStyle={drugAuditFilterDrawer_style.cancleButtonSx}
                onClick={() => HandleDrawerClose()}
                disabled={loading}
              >
                Reset
              </Button>
            </Box>
          }
        >
          <Box sx={drugAuditFilterDrawer_style.boxRootSx}>
            {/*  ---------------- Appointment Type Toggle ------------- */}
            <Box sx={drugAuditFilterDrawer_style.inputGroupSx}>
              <Label
                rootStyle={drugAuditFilterDrawer_style.labelSx}
                htmlFor="SelectType"
                // isRequired
              >
                Appointment Type
              </Label>
              <ToggleButton
                RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                options={DrugAuditType}
                value={drugAuditType}
                buttonactiveStyle={
                  drugAuditFilterDrawer_style.togglebuttonactiveSX
                }
                buttoninactiveStyle={
                  drugAuditFilterDrawer_style.togglebuttoninactiveSX
                }
                onChange={(e) => {
                  handleAppointmentTypeChange('drugAuditType', e);
                }}
              />
            </Box>

            {/*  ---------------- Appointment Start Date Field ------------- */}
            <Box sx={drugAuditFilterDrawer_style.inputGroupSx}>
              <Label
                rootStyle={drugAuditFilterDrawer_style.labelSx}
                htmlFor="From Date"
                // isRequired
              >
                From Date
              </Label>
              <DateRangePicker
                // datatestid="startDate"
                startDate={fromDate ?? ''}
                onChange={(val) => {
                  handleDateChange('fromDate', val);
                }}
                dateFormat="dd-MM-yyyy"
                customSx={{
                  backgroundColor: '#fff',
                  borderColor: '#c5c5c5',
                  width: '100%',
                }}
              />
            </Box>

            {/*  ---------------- Appointment End Date Field ------------- */}
            <Box sx={drugAuditFilterDrawer_style.inputGroupSx}>
              <Label
                rootStyle={drugAuditFilterDrawer_style.labelSx}
                htmlFor="To Date"
                // isRequired
              >
                To Date
              </Label>

              <DateRangePicker
                // datatestid="endDate"
                startDate={toDate ?? ''}
                onChange={(val) => {
                  handleDateChange('toDate', val);
                }}
                dateFormat="dd-MM-yyyy"
                customSx={{
                  backgroundColor: '#fff',
                  borderColor: '#c5c5c5',
                }}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

DrugAuditFilterDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  drugAuditDrawerOpen: PropTypes.func,
  closeDrugAuditDrawer: PropTypes.func,
};

export { DrugAuditFilterDrawer };

