import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { loader_style } from './style';

export function Loader(props) {
  const {
    rootStyle = {},
    circularProgressProps,
    size,
    className = '',
    value,
    ...rest
  } = props;
  return (
    <Box
      sx={{ ...loader_style.rootSx, ...rootStyle }}
      {...rest}
      className={`${className}`}
    >
      <CircularProgress
        value={value}
        color="success"
        size={size}
        {...circularProgressProps}
      />
    </Box>
  );
}
Loader.propTypes = {
  className: PropTypes.object,
  sx: PropTypes.object,
  value: PropTypes.number,
  rootStyle: PropTypes.object,
  circularProgressProps: PropTypes.any,
  size: PropTypes.any,
};
