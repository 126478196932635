/* eslint-disable no-sparse-arrays */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

const drugInitialData = {
  drugId: 0,
  drugStatus: true,
  drugName: '',
  time: 0,
  when: 0,
  frequency: 0,
  category: 0,
  error: {
    drugName: '',
    time: '',
    when: '',
    frequency: '',
    category: '',
  },
};

export const constructDrugData = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val) => {
      const obj = {
        value: val,
        id: val?.id ?? 0,
        drugName: val?.drug_name ?? '',
        status: val?.is_active ?? false,
        frequency: val?.master_frequency?.frequency ?? '',
        when: val?.master_when_type?.when ?? '',
        time: val?.master_time?.time ?? '',
        category: val?.master_drug_category?.drug_category_name ?? '',
      };
      arr.push(obj);
    });
  }
  return arr;
};

export const useDrugMaster = create((set, get) => ({
  drug: JSON.parse(JSON.stringify(drugInitialData)),
  drugMasterData: [],
  drugMasterCount: 0,
  loading: null,
  error: null,
  getAllDrugMasters: async (search = '', limit = 10, offset = 0) => {
    try {
      set({ loading: true });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['getAllDrugMaster'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/drugs/get/all`,
            {
              search,
              limit,
              offset,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        return set(() => ({
          loading: false,
          drugMasterData:
            Array.isArray(data?.data?.drugs_data) &&
            data?.data?.drugs_data?.length > 0
              ? constructDrugData(data?.data?.drugs_data)
              : [],
          drugMasterCount: data?.data?.drugs_count,
        }));
      }
      return set(() => ({
        loading: false,
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  addDrugMaster: async (payload, type) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      const payloadData = {
        user_id: tokenData?.id ?? '',
        drug_id: type === 'update' ? payload?.drugId : 0,
        is_active: type === 'update' ? payload?.drugStatus : true,
        drug_name: payload?.drugName ?? '',
        frequency_id: payload?.frequency ?? 0,
        when_type_id: payload?.when ?? 0,
        time_id: payload?.time ?? 0,
        category_id: payload?.category ?? 0,
      };
      queryClient.invalidateQueries({
        queryKey: ['drug_upsert'],
      });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['drug_upsert', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/drugs/upsert`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ??
            `Drug Data ${type === 'add' ? 'Created' : 'Updated'} Successfully`
        );
        set({ loading: false });
        return data;
      }
      set({ loading: false });
      return data;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  drugMasterStatusUpdate: async (payload) => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      const payloadData = {
        user_id: tokenData?.id ?? '',
        drug_id: payload?.id,
        status: payload?.is_active,
      };
      queryClient.invalidateQueries({
        queryKey: ['drug_status_update'],
      });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['drug_status_update', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/drugs/status/update`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'Drug Status Updated Successfully'
        );
        set({ loading: false });
        return data?.status?.code;
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  clearDrug: () => {
    set({
      drug: JSON.parse(JSON.stringify(drugInitialData)),
    });
  },
  updateDrugMasterError: (error) => {
    const { drug } = get();
    set({
      drug: {
        ...drug,
        error,
      },
    });
  },
  isIamValidToSave: () => {
    const { drug } = get();
    const drugCopy = JSON.parse(JSON.stringify(drug));
    let isValid = true;
    const error = drugCopy?.error;
    // checking drugName
    if (!drug?.drugName) {
      isValid = false;
      error.drugName = 'Please enter your drug name';
    } else {
      error.drugName = '';
    }
    // checking time
    if (drug?.time === 0) {
      isValid = false;
      error.time = 'Please select time';
    } else {
      error.time = '';
    }
    // checking when
    if (drug?.when === 0) {
      isValid = false;
      error.when = 'Please select when';
    } else {
      error.when = '';
    }
    // checking frequency
    if (drug?.frequency === 0) {
      isValid = false;
      error.frequency = 'Please select frequency';
    } else {
      error.frequency = '';
    }
    // checking category
    if (drug?.category === 0) {
      isValid = false;
      error.category = 'Please select category';
    } else {
      error.category = '';
    }
    set({
      drug: {
        ...drug,
        error,
      },
    });

    return isValid;
  },

  handleDrugFormChange: (key, value) => {
    const { drug } = get();

    set({
      drug: {
        ...drug,
        [key]: value,
      },
    });
  },

  initialEditDrugData: (id, value) => {
    const { drug } = get();
    set({
      drug: {
        ...drug,
        drugId: id ?? 0,
        drugStatus: value?.is_active ?? true,
        drugName: value?.drug_name ?? '',
        time: value?.master_time?.id ?? 0,
        when: value.master_when_type?.id ?? 0,
        frequency: value?.master_frequency?.id ?? 0,
        category: value?.master_drug_category?.id ?? 0,
      },
    });
  },
}));
