/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
import {
  BackArrowIcon,
  BackwardArrowIcon,
  Button,
  SearchField,
} from '@hc/ui/atoms';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { FilterTab } from '../../filterTab';
import { master_top_container_Style } from './style';

export function MasterTopContainer(props) {
  const {
    handleAddClick = () => {},
    handleSearch = () => {},
    handleSecondSearch = () => {},
    SecondsearchFieldPlaceholder = '',
    title = '',
    count = 0,
    isTitileShow = true,
    searchFieldPlaceholder = '',
    showSearchField = false,
    showSecondSearchField = false,
    addButtonName = '',
    showAddButton = false,
    addMasterStyle = {},
    isTabShow = false,
    currentTab,
    isBackButton = false,
    isMasterTitleShow = false,
    filterData,
  } = props;

  // General Hooks
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const handleSearchFnc = (newData) => {
    setSearch(newData);
    handleSearch(newData);
  };

  const handleSecondSearchFnc = (newData) => {
    setSearchValue(newData);
    handleSecondSearch(newData);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    currentTab(newValue);
  };
  // const filterList = ['By Admin', 'By Experts'];

  const filterList = [
    { name: 'By Admin', count: tabIndex === 0 ? count : -1 },
    { name: 'By Experts', count: tabIndex === 1 ? count : -1 },
  ];

  const navigate = useNavigate();

  return (
    <Box>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
            {isBackButton && (
              <BackwardArrowIcon
              id='backbtn'
                onClick={() => navigate(-1)}
                rootStyle={{ cursor: 'pointer' }}
              />
            )}
            {isTitileShow && (
              <Typography sx={master_top_container_Style.masterTextSx}>
                {`${title} (${count > 0 && count < 10 ? `0${count}` : count})`}
              </Typography>
            )}
            {isMasterTitleShow && (
              <Stack flexDirection="row">
                <Typography sx={master_top_container_Style.masterBadgeTextSx}>
                  {title}
                </Typography>
                <Box sx={master_top_container_Style.BadgeSx}>
                  {count > 0 && count < 10 ? `0${count}` : count}
                </Box>
              </Stack>
            )}
          </Box>

          {isTabShow && (
            <Box
              sx={{
                backgroundColor: '#EDEFF2',
                borderRadius: '10px',
              }}
            >
              <FilterTab
                filterList={filterData ?? filterList}
                handleChange={handleChange}
                value={tabIndex}
                count={count > 0 && count < 10 ? `0${count}` : count}
                showCount
                filterTabRootStyle={{ width: '200px' }}
              />
            </Box>
          )}
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {showSearchField && (
              <Grid item>
                <SearchField
                  id="search"
                  searchFieldStyle={master_top_container_Style.searchFieldSx}
                  placeholder={searchFieldPlaceholder ?? ''}
                  onSearch={search}
                  setOnSearch={handleSearchFnc}
                  startAdornment={
                    <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                      <BiSearch />
                    </IconButton>
                  }
                />
              </Grid>
            )}
            {showSecondSearchField && (
              <Grid item>
                <SearchField
                  id="search2"
                  searchFieldStyle={master_top_container_Style.searchFieldSx}
                  placeholder={SecondsearchFieldPlaceholder ?? ''}
                  onSearch={searchValue}
                  setOnSearch={handleSecondSearchFnc}
                  startAdornment={
                    <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                      <BiSearch />
                    </IconButton>
                  }
                />
              </Grid>
            )}
            {showAddButton && (
              <Grid item>
                <Button
                  onClick={() => handleAddClick('new', true)}
                  buttonStyle={{
                    ...master_top_container_Style.addMasterSx,
                    ...addMasterStyle,
                  }}
                >
                  {addButtonName}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

MasterTopContainer.propTypes = {
  handleAddClick: PropTypes.func,
  handleSearch: PropTypes.func,
  title: PropTypes.string,
  count: PropTypes.number,
  searchFieldPlaceholder: PropTypes.string,
  showSearchField: PropTypes.bool,
  addButtonName: PropTypes.string,
  showAddButton: PropTypes.bool,
};
