import { queryClient } from '@hc/utils';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { BackwardArrowIcon, Button, SearchField } from '../../../atoms';
import { awarenessManagementTopContainer_style } from './style';

function AwarenessManagementTopContainer(props) {
  const {
    className = '',
    handleAddClick = () => {},
    handleSearch = () => {},
    title = '',
    count = 0,
    searchFieldPlaceholder = '',
    addButtonName = '',
    addMasterStyle = {},
    rootStyle = {},
    searchValue = '',
    isBackButton = false,
    ...rest
  } = props;
  const navigate = useNavigate();

  const onBack = () => {
    queryClient.invalidateQueries({
      queryKey: ['getAllAwarenessManagementData'],
    });
    navigate(-1);
  };

  return (
    <Box
      sx={{
        ...awarenessManagementTopContainer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
            {isBackButton && (
              <BackwardArrowIcon
                onClick={() => onBack()}
                rootStyle={{ cursor: 'pointer' }}
              />
            )}
            <Typography sx={awarenessManagementTopContainer_style.masterTextSx}>
              {`${title} (${count > 0 && count < 10 ? `0${count}` : count})`}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <SearchField
                id="search"
                searchFieldStyle={
                  awarenessManagementTopContainer_style.searchFieldSx
                }
                placeholder={searchFieldPlaceholder ?? ''}
                onSearch={searchValue}
                setOnSearch={handleSearch}
                startAdornment={
                  <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                    <BiSearch />
                  </IconButton>
                }
              />
            </Grid>

            <Grid item>
              <Button
                onClick={() => handleAddClick()}
                buttonStyle={{
                  ...awarenessManagementTopContainer_style.addMasterSx,
                  ...addMasterStyle,
                }}
              >
                {addButtonName}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

AwarenessManagementTopContainer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  handleAddClick: PropTypes.func,
  handleSearch: PropTypes.func,
  title: PropTypes.string,
  count: PropTypes.number,
  searchFieldPlaceholder: PropTypes.string,
  addButtonName: PropTypes.string,
  searchValue: PropTypes.string,
};

export { AwarenessManagementTopContainer };
