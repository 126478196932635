export const filterTab_style = {
  rootSx: {
    minHeight: '20px',
  },
  tabButtonSx: {
    fontSize: '14px',
    fontWeight: 500,
    height: '20px',
    position: 'relative',
    zIndex: 1,
    color: '#3D3D3D',
    width: '29%',
    opacity: 1,
    borderRadius: '8px',
    '&.Mui-selected': {
      color: '#fff',
    },
  },
};
