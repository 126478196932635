import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const usePlanManagement = create((set, get) => ({
  planManagementState: {
    planManagementData: { data: [], count: '0' },
    planManagement: {
      id: '',
      plan_name: '',
      amount: null,
      plan_type: '',
      is_active: true,
      error: {
        plan_name: '',
        amount: '',
        plan_type: '',
      },
    },
  },
  loading: false,
  error: false,

  getAllPlanManagementData: async (searchValue, limit = 10, offset = 0) => {
    try {
      const { planManagementState } = get();
      set({ loading: true });

      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getAllPlanManagementData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/get-all-plan-management`,
            {
              search: searchValue ?? '',
              offset,
              limit,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          loading: false,
          planManagementState: {
            ...planManagementState,
            planManagementData: {
              data: data?.data ?? [],
              count: data?.count ?? '0',
            },
          },
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  planManagemenStatusUpdate: async (payload) => {
    try {
      set({ loading: true });
      const payloadData = {
        id: payload?.id,
        status: payload?.status,
      };
      queryClient.invalidateQueries({
        queryKey: ['planManagementStatusUpdate'],
      });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['planManagementStatusUpdate', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/plan-management-status-update`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(data?.status?.message ?? 'Status Updated Successfully');
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  upsertPlanManagement: async () => {
    try {
      const { planManagementState } = get();
      const { planManagement } = planManagementState;
      set({ loading: true });
      const payloadData = {
        id: planManagement?.id || '',
        plan_name: planManagement?.plan_name ?? '',
        amount: planManagement?.amount ?? 0,
        plan_type: planManagement?.plan_type ?? '',
        is_active: planManagement?.is_active ?? true,
      };

      queryClient.invalidateQueries({
        queryKey: ['upsertPlanManagement'],
      });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['upsertPlanManagement'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backoffice/upsert-plan-management`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        if (data?.status?.message === 'Plan Management Name Already Exist') {
          toast.error(data?.status?.message);
          set({ loading: false });
          return 201;
        }
        toast.success(
          data?.status?.message ??
            `Plan Management ${
              planManagement.id ? 'Updated' : 'Created'
            } Successfully`
        );
        set({ loading: false });
        return 200;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  isIamValidToSave: () => {
    const { planManagementState } = get();
    const { planManagement } = planManagementState;
    const planManagementCopy = JSON.parse(JSON.stringify(planManagement));

    let isValid = true;
    const error = planManagementCopy?.error;

    // checking plan name
    if (!planManagementCopy?.plan_name) {
      isValid = false;
      error.plan_name = 'Please enter plan name';
    } else {
      error.plan_name = '';
    }
    // checking amount
    if (
      !planManagementCopy?.amount ||
      planManagementCopy?.amount === null ||
      planManagementCopy?.amount === 0
    ) {
      isValid = false;
      error.amount = 'Please enter amount';
    } else {
      error.amount = '';
    }
    // checking plan type
    if (!planManagementCopy?.plan_type) {
      isValid = false;
      error.plan_type = 'Please select plan type';
    } else {
      error.plan_type = '';
    }

    set({
      planManagementState: {
        ...planManagementState,
        planManagement: {
          ...planManagement,
          error,
        },
      },
    });

    return isValid;
  },

  handlePlanManagementFormChange: (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    const { planManagementState } = get();
    const { planManagement } = planManagementState;
    set({
      planManagementState: {
        ...planManagementState,
        planManagement: {
          ...planManagement,
          [key]: value,
        },
      },
    });
  },

  clearPlanManagementData: () => {
    const { planManagementState } = get();
    set({
      planManagementState: {
        ...planManagementState,
        planManagement: {
          id: '',
          plan_name: '',
          amount: null,
          plan_type: '',
          is_active: true,
          error: {
            plan_name: '',
            amount: '',
            plan_type: '',
          },
        },
      },
    });
  },

  initialEditPlanManagementData: (data) => {
    const { planManagementState } = get();
    set({
      planManagementState: {
        ...planManagementState,
        planManagement: {
          id: data?.id || '',
          plan_name: data?.plan_name ?? '',
          amount: data?.amount ?? null,
          plan_type: data?.plan_type ?? '',
          is_active: data?.is_active ?? true,
          error: {
            plan_name: '',
            amount: '',
            plan_type: '',
          },
        },
      },
    });
  },
}));
