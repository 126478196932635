import { getDateFormatToString } from '@hc/dayjs';

import { backofficeRoutes } from '@hc/routes';
import { useOrganization } from '@hc/store';
import {
  DeleteIcon,
  EmailIcon,
  MapIcon,
  OrganizationIcon,
  PhoneIcon,
  RefreshIcon,
  Switch,
} from '@hc/ui/atoms';
import { AddMembersDrawer, TableSkeletonLoader } from '@hc/ui/components';
import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  capitalize,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { organizationMember_style } from './style';

export default function OrganizationMember(props) {
  const { className = '', ...rest } = props;

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [organizationDrawerOpen, setOrganizationDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const statusColor = {
    1: '#25C460',
    2: '#fff',
    3: '#88888',
    4: '#E4BC62',
  };

  // Store Data

  const {
    getAllOrganizationMembers,
    organizationState,
    organizationMemberStatusUpdate,
    deleteMember,
    resendInvite,
    loading,
  } = useOrganization((state) => ({
    resendInvite: state.resendInvite,
    deleteMember: state.deleteMember,
    getAllOrganizationMembers: state.getAllOrganizationMembers,
    organizationState: state.organizationState,
    organizationMemberStatusUpdate: state.organizationMemberStatusUpdate,
    loading: state.loading,
  }));

  const { members, organization } = organizationState;

  // General Hooks

  const closeOrganizationDrawer = () => {
    setOrganizationDrawerOpen(false);
  };

  const handleAddClick = () => {
    setOrganizationDrawerOpen(true);
  };
  // searchValue IS THE PARAM

  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    queryClient.invalidateQueries({
      queryKey: ['organization-members-all'],
    });
    await getAllOrganizationMembers(searchValue, rowsPerPage, offset);
  };

  const onStatusChange = async (data) => {
    queryClient.invalidateQueries({
      queryKey: ['organization_member_update'],
    });
    const response = await organizationMemberStatusUpdate(data);
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['organization-members-all'],
      });
      await getAllOrganizationMembers(search, rowsPerPage, offset);
    }
  };

  const initialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['organization-members-all'],
    });
    await getAllOrganizationMembers(search, rowsPerPage, offset);
  };

  const resendInviteBtn = async (params) => {
    queryClient.invalidateQueries({
      queryKey: ['user-resend-invite'],
    });
    await resendInvite(params);
  };

  const onDeleteMember = async (params) => {
    queryClient.invalidateQueries({
      queryKey: ['backoffice-members-delete'],
    });
    await deleteMember(params);
    initialData();
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1.8, cursor: 'pointer' }}>
          <DeleteIcon onClick={() => onDeleteMember(params?.row)} />
        </Box>
      ),
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 160,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            {capitalize(params.row?.fullName)}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 210,
    },
    {
      field: 'membershipCode',
      headerName: 'Mem. Code',
      width: 140,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 160,
    },

    {
      field: 'isActive',
      headerName: 'Unblock/Block',
      // type: 'number',
      width: 160,
      renderCell: (params) => (
        <Switch
          checked={params.row.isActive}
          onChange={() => onStatusChange(params)}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'status',
      width: 120,
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{
            color: statusColor[params.row.statusId],
            // backgroundColor: statusColor[params.row.statusId],
          }}
        >
          {params.row.status}
          {params.row.statusId === 4 && (
            <Tooltip title="Resend Invite">
              <IconButton
                color="secondary"
                onClick={() => resendInviteBtn(params.row)}
              >
                <RefreshIcon
                  rootStyle={{ color: statusColor[params.row.statusId] }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 180,
      valueGetter: (params) =>
        getDateFormatToString(params?.row?.createdAt, 'lll'),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updatedAt, 'lll'),
    },
  ];

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['organization-members-all'],
    });
    await getAllOrganizationMembers(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['organization-members-all'],
    });
    await getAllOrganizationMembers(search, rowsPerPage, offset);
  };

  useEffect(() => {
    if (organization?.name === '') {
      navigate(backofficeRoutes?.organization);
    } else {
      initialData();
    }
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MasterTopContainer
              addMasterStyle={organizationMember_style.addMasterSx}
              handleSearch={handleSearch}
              handleAddClick={handleAddClick}
              title="Organization Members"
              count={members?.membersCount ?? '0'}
              showSearchField
              searchFieldPlaceholder="Search by Name, Phone & Email"
              showAddButton
              addButtonName="Add Member"
              isBackButton
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              sx={organizationMember_style.topbarCardSx}
            >
              <Box sx={organizationMember_style.descAlignSx}>
                <OrganizationIcon rootStyle={{ color: '#597986' }} />
                <Typography sx={organizationMember_style.descSx}>
                  {organizationState?.organization?.name}
                </Typography>
              </Box>
              {organizationState?.organization?.address.length > 0 && (
                <Box sx={organizationMember_style.descAlignSx}>
                  <MapIcon />
                  <Typography sx={organizationMember_style.descSx}>
                    {organizationState?.organization?.address}
                  </Typography>
                </Box>
              )}
              <Box sx={organizationMember_style.descAlignSx}>
                <PhoneIcon rootStyle={{ color: '#597986' }} />
                <Typography sx={organizationMember_style.descSx}>
                  {`${organizationState?.organization?.mobileNumber?.mobileCode} ${organizationState?.organization?.mobileNumber?.mobile}`}
                </Typography>
              </Box>
              <Box sx={organizationMember_style.descAlignSx}>
                <EmailIcon
                  rootStyle={{
                    color: '#597986',
                    width: '16px',
                    height: '16px',
                  }}
                />
                <Typography sx={organizationMember_style.descSx}>
                  {organizationState?.organization?.email}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <Box>
                <TableSkeletonLoader />
              </Box>
            ) : (
              <Box>
                <MasterTable
                  columns={columns}
                  data={members?.membersData ?? []}
                  customTableStyle={organizationMember_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              </Box>
            )}
          </Grid>
          <Stack
            flexDirection="row"
            justifyContent="end"
            sx={{ width: '100%' }}
          >
            <Pagination
              totalCount={members?.membersCount}
              onLimitChange={onLimitChange}
              onOffsetchange={onOffsetchange}
              offset={offset}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Grid>

        {/* Add Plan Template Drawer */}
        <Box>
          <AddMembersDrawer
            memberDrawerOpen={organizationDrawerOpen}
            closeMemberDrawer={closeOrganizationDrawer}
          />
        </Box>
      </Box>
    </Box>
  );
}

OrganizationMember.propTypes = {
  className: PropTypes.string,
};
