import { SvgIcon } from '@mui/material';

export function DoctorImageIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 106,
    height: 96,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 106 96"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Group 5162" transform="translate(-39 -116)">
        <circle
          data-name="Ellipse 39431"
          cx={48}
          cy={48}
          r={48}
          transform="translate(48 116)"
          fill="#fff"
          opacity={0.5}
        />
        <circle
          data-name="Ellipse 39386"
          cx={40}
          cy={40}
          r={40}
          transform="translate(56 124)"
          fill="#fff"
          opacity={0.5}
        />
        <g
          data-name="Group 4752"
          transform="translate(7.766 -475.562)"
          fill="#fff"
        >
          <circle
            data-name="Ellipse 39405"
            cx={5}
            cy={5}
            r={5}
            transform="translate(41.234 675.562)"
            opacity={0.35}
          />
          <ellipse
            data-name="Ellipse 39406"
            cx={10}
            cy={10.5}
            rx={10}
            ry={10.5}
            transform="translate(31.234 625.562)"
            opacity={0.35}
          />
          <circle
            data-name="Ellipse 39407"
            cx={13.5}
            cy={13.5}
            r={13.5}
            transform="translate(110.234 595.562)"
            opacity={0.35}
          />
        </g>
        <g data-name="icons8-stethoscope (2)">
          <path
            data-name="Path 4313"
            d="M56.577 69.859h.11a16.463 16.463 0 0016.252-16.417v-3.623a1.647 1.647 0 10-3.294 0v3.624a13.124 13.124 0 01-12.958 13.122 12.766 12.766 0 01-9.279-3.789 12.994 12.994 0 01-3.9-9.334V53a1.647 1.647 0 00-3.294 0v.439a16.4 16.4 0 0016.363 16.42zm-14.66-21.084a16.368 16.368 0 0016.417-16.307V14.843A3.82 3.82 0 0054.49 11a1.647 1.647 0 100 3.294.519.519 0 01.549.549v17.625a13.123 13.123 0 01-26.245 0V14.843a.519.519 0 01.549-.549 1.647 1.647 0 100-3.294 3.82 3.82 0 00-3.843 3.843v17.625a16.368 16.368 0 0016.417 16.307z"
            transform="translate(75 134) translate(-25.5 -10.341)"
            fill="#454b54"
          />
          <path
            data-name="Path 4314"
            d="M68.047 14.357a2.229 2.229 0 002.2-2.306 2.15 2.15 0 00-.494-1.428 2.057 2.057 0 00-1.7-.824 1.618 1.618 0 00-1.653 1.648 1.974 1.974 0 00.11.6 1.393 1.393 0 00-.11.659 1.652 1.652 0 001.647 1.651zm13.836 0a1.618 1.618 0 001.647-1.647 2.756 2.756 0 00-.11-.659 2.335 2.335 0 00.11-.6A1.618 1.618 0 0081.884 9.8a2.215 2.215 0 00-2.2 2.251 2.15 2.15 0 00.494 1.428 1.97 1.97 0 001.706.878z"
            transform="translate(75 134) translate(-58.548 -9.8)"
            fill="#454b54"
          />
          <path
            data-name="Path 4315"
            d="M24.206 53a5.161 5.161 0 11-5.106 5.161A5.134 5.134 0 0124.206 53z"
            transform="translate(75 134) translate(21.64 -29.281)"
            fill="#fff"
          />
          <path
            data-name="Path 4316"
            d="M22.853 63.617a6.809 6.809 0 10-6.753-6.809 6.8 6.8 0 006.753 6.809zm0-10.322a3.514 3.514 0 11-3.459 3.514 3.492 3.492 0 013.459-3.515z"
            transform="translate(75 134) translate(22.993 -27.928)"
            fill="#454b54"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function AlarmClockIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 14,
    height: 16,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 14.049 16"
      {...rest}
    >
      <path
        d="M12.049 3a.585.585 0 100 1.171h.39v.81a7.032 7.032 0 105.944 2.464l.712-.713a.585.585 0 10-.828-.828l-.713.712a6.987 6.987 0 00-3.944-1.635v-.81H14A.585.585 0 1014 3zm.906 3.126a.585.585 0 00.142 0A5.831 5.831 0 0117.133 7.8a.586.586 0 00.062.062 5.858 5.858 0 11-4.239-1.739zm2.595 2.722a.585.585 0 00-.4.177l-1.821 1.821a1.169 1.169 0 10.829.827l1.82-1.82a.585.585 0 00-.425-1z"
        transform="translate(-6 -3)"
        fill="#5c6266"
      />
    </SvgIcon>
  );
}

export function SunRiseIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '22px',
    height: '12px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 22.857 12"
      {...rest}
    >
      <path
        data-name="Path 96905"
        d="M29 55.9a6.9 6.9 0 1113.793 0z"
        transform="translate(-6 -26) translate(-18.468 -18.468)"
        fill="#fcca3d"
      />
      <path
        data-name="Path 96906"
        d="M28.266 36.837H24.9a7.323 7.323 0 00-1.773-4.256l2.384-2.4a.571.571 0 000-.828.589.589 0 00-.828 0l-2.4 2.384a7.453 7.453 0 00-4.256-1.773v-3.373a.591.591 0 10-1.182 0v3.369a7.323 7.323 0 00-4.256 1.773l-2.4-2.384a.585.585 0 10-.828.828l2.384 2.384a7.453 7.453 0 00-1.773 4.256H6.591a.591.591 0 100 1.182h21.675a.581.581 0 00.591-.591.565.565 0 00-.591-.571zm-10.837-5.714a6.345 6.345 0 016.286 5.714H11.143a6.345 6.345 0 016.286-5.714z"
        fill="#444b54"
        transform="translate(-6 -26)"
      />
    </SvgIcon>
  );
}

export function SunSetIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '16px',
    height: '16px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        data-name="Path 96909"
        d="M19 27.126C19 22.629 22.331 19 26.458 19s7.458 3.629 7.458 8.126z"
        transform="translate(-16 -16) translate(-2.458 -2.458)"
        fill="#f46e67"
      />
      <path
        data-name="Path 96910"
        d="M31.5 24.5a.491.491 0 01-.5-.5 7 7 0 10-14 0 .5.5 0 11-1 0 8 8 0 1116 0 .491.491 0 01-.5.5zm0 2.5h-15a.5.5 0 010-1h15a.5.5 0 010 1zm-3.333 2.5h-8.334a.5.5 0 010-1h8.333a.5.5 0 010 1zM24.833 32h-1.666a.5.5 0 110-1h1.667a.5.5 0 110 1z"
        fill="#444b54"
        transform="translate(-16 -16)"
      />
    </SvgIcon>
  );
}

export function NoonIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '16px',
    height: '16px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 16"
      {...rest}
    >
      <g data-name="Group 105488">
        <path
          d="M11.84 3.966a.44.44 0 00-.047.012.381.381 0 00-.3.381v2.282a.38.38 0 10.759 0V4.358a.38.38 0 00-.415-.392zM6.432 6.2a.377.377 0 00-.3.268.383.383 0 00.107.386l1.616 1.619a.38.38 0 10.534-.535L6.776 6.32a.38.38 0 00-.308-.12zm10.768 0a.381.381 0 00-.225.119l-1.614 1.619a.38.38 0 10.534.535l1.613-1.617A.38.38 0 0017.2 6.2zm-5.325 1.963a3.805 3.805 0 103.795 3.805 3.8 3.8 0 00-3.795-3.805zm-7.7 3.425a.384.384 0 00.107.761h2.281a.381.381 0 100-.761H4.179zm12.9 0a.384.384 0 00.107.761h2.277a.381.381 0 100-.761h-2.378zm-9 3.758a.381.381 0 00-.225.119l-1.607 1.617a.38.38 0 10.534.535L8.389 16a.381.381 0 00-.273-.654zm7.471 0a.377.377 0 00-.3.268.383.383 0 00.107.386l1.613 1.617a.38.38 0 10.534-.535l-1.6-1.618a.379.379 0 00-.273-.119h-.071zM11.84 16.9a.441.441 0 00-.047.012.381.381 0 00-.3.381v2.283a.38.38 0 10.759 0V17.3a.38.38 0 00-.415-.392z"
          transform="translate(-5115 -18278) translate(5111.15 18274.035)"
          fill="#444b54"
        />
        <g
          data-name="Ellipse 129700"
          transform="translate(-5115 -18278) translate(5117.04 18280.041)"
          fill="#fcca3d"
          stroke="#444b54"
          strokeWidth={1}
        >
          <ellipse cx={5.96} cy={5.959} rx={5.96} ry={5.959} stroke="none" />
          <ellipse cx={5.96} cy={5.959} rx={5.46} ry={5.459} fill="none" />
        </g>
      </g>
    </SvgIcon>
  );
}

export function MoonIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '16px',
    height: '16px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16.561 16"
      {...rest}
    >
      <path
        data-name="Path 97964"
        d="M15.509 6A7.954 7.954 0 0120 13.3a8.141 8.141 0 01-8.141 8.141A7.94 7.94 0 014 14.983a7 7 0 005.9 3.088 7.3 7.3 0 007.3-7.3A7.041 7.041 0 0015.509 6z"
        transform="translate(-3.006 -5.003) translate(-.715 -.717)"
        fill="#ffde55"
      />
      <path
        data-name="Path 97965"
        d="M30.369 16.246l-1.123.561-.561 1.684-.561-1.684L27 16.246l1.123-.561.561-1.685.561 1.684z"
        transform="translate(-3.006 -5.003) translate(-17.258 -6.471)"
        fill="#ffde55"
      />
      <path
        data-name="Path 97966"
        d="M18.369 29.246l-1.123.561-.561 1.684-.561-1.684L15 29.246l1.123-.561.561-1.685.561 1.684z"
        transform="translate(-3.006 -5.003) translate(-8.627 -15.822)"
        fill="#ffde55"
      />
      <path
        data-name="Path 97967"
        d="M39.364 13.3a8.141 8.141 0 01-8.141 8.141c-.1 0-.2 0-.3-.006A8.136 8.136 0 0038.8 13.3a7.991 7.991 0 00-3.475-6.715A5.94 5.94 0 0034.872 6a7.954 7.954 0 014.491 7.3z"
        transform="translate(-3.006 -5.003) translate(-20.078 -.717)"
        fill="#ffcd00"
      />
      <path
        data-name="Path 97968"
        d="M33.965 16.246l-1.123.561-.561 1.684-.281-.842.281-.842 1.123-.561-1.123-.561-.281-.843.281-.842.561 1.684z"
        transform="translate(-3.006 -5.003) translate(-20.855 -6.471)"
        fill="#ffcd00"
      />
      <path
        data-name="Path 97969"
        d="M21.965 29.246l-1.123.561-.561 1.684-.281-.842.281-.842 1.123-.561-1.123-.561-.281-.843.281-.842.561 1.684z"
        transform="translate(-3.006 -5.003) translate(-12.223 -15.822)"
        fill="#ffcd00"
      />
      <path
        data-name="Path 97971"
        d="M26.153 15.78l1.019.509.525 1.574a.281.281 0 00.533 0l.525-1.574 1.018-.509a.281.281 0 000-.5l-1.019-.509-.525-1.574a.281.281 0 00-.533 0l-.525 1.574-1.019.509a.281.281 0 000 .5zm1.374-.561a.281.281 0 00.14-.163l.3-.887.3.885a.281.281 0 00.14.163l.621.31-.621.31a.281.281 0 00-.14.163l-.3.885-.3-.885a.281.281 0 00-.14-.163l-.621-.31z"
        transform="translate(-3.006 -5.003) translate(-16.537 -5.753)"
      />
      <path
        data-name="Path 97972"
        d="M14 28.527a.281.281 0 00.155.253l1.019.509.525 1.574a.281.281 0 00.533 0l.525-1.574 1.018-.509a.281.281 0 000-.5l-1.019-.509-.525-1.574a.281.281 0 00-.533 0l-.525 1.574-1.018.506a.281.281 0 00-.155.25zm1.529-.309a.281.281 0 00.14-.163l.3-.887.3.885a.281.281 0 00.14.163l.621.31-.621.309a.281.281 0 00-.14.163l-.3.887L15.67 29a.281.281 0 00-.14-.163l-.621-.31z"
        transform="translate(-3.006 -5.003) translate(-7.908 -15.103)"
      />
      <path
        data-name="Path 97975"
        d="M14.916 5.031a.281.281 0 00-.332.438 6.725 6.725 0 011.616 4.586 7.026 7.026 0 01-7.02 7.018 6.69 6.69 0 01-5.664-2.966.281.281 0 00-.505.214A8.222 8.222 0 0011.145 21a8.431 8.431 0 008.421-8.421 8.261 8.261 0 00-4.651-7.551zm-3.771 15.411a7.649 7.649 0 01-7.22-4.958 7.222 7.222 0 005.255 2.151 7.588 7.588 0 007.579-7.579 7.7 7.7 0 00-.971-3.862 7.76 7.76 0 013.217 6.389 7.869 7.869 0 01-7.86 7.86z"
        transform="translate(-3.006 -5.003)"
      />
    </SvgIcon>
  );
}

export function AnyTimeIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '18px',
    height: '16px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 18.293 16"
      {...rest}
    >
      <g data-name="icons8-time (1)">
        <g data-name="Group 106610">
          <path
            data-name="Path 100645"
            d="M73.105 77.582a4.291 4.291 0 114.305-4.291 4.3 4.3 0 01-4.305 4.291z"
            transform="translate(-2.135 -18) translate(2.135 18) translate(-63.952 -65.291)"
            fill="#fcca3d"
          />
          <path
            data-name="Path 100646"
            d="M63.349 24.211a.437.437 0 00-.611.073l-.465.582A7.993 7.993 0 0054.375 18a7.84 7.84 0 00-5.644 2.356.432.432 0 00.611.611 7.028 7.028 0 015.033-2.095 7.122 7.122 0 017.04 6.167l-.684-.538a.435.435 0 10-.538.684l1.455 1.164c.015.015.029.029.044.029a.014.014 0 01.015.015c.015.015.044.015.058.029a.109.109 0 00.073.015h.087a.184.184 0 00.087-.015c.015 0 .015 0 .029-.015s.044-.015.058-.015.015-.015.029-.015.044-.015.058-.029l.073-.073 1.164-1.455a.437.437 0 00-.074-.609z"
            transform="translate(-2.135 -18) translate(2.135 18) translate(-45.221 -18)"
            fill="#454b54"
          />
          <path
            data-name="Path 100647"
            d="M122.04 86.353l1.76-.015a.436.436 0 100-.873l-1.455.015-.029-2.444a.429.429 0 00-.436-.436.438.438 0 00-.436.436l.015 2.647a.471.471 0 00.422.7.523.523 0 00.159-.03z"
            transform="translate(-2.135 -18) translate(2.135 18) translate(-112.726 -77.902)"
            fill="#454b54"
          />
          <path
            data-name="Path 100648"
            d="M72.284 67.727a4.742 4.742 0 10-4.742 4.727 4.74 4.74 0 004.742-4.727zm-8.611 0a3.869 3.869 0 113.869 3.855 3.864 3.864 0 01-3.869-3.855z"
            transform="translate(-2.135 -18) translate(2.135 18) translate(-58.388 -59.727)"
            fill="#454b54"
          />
          <path
            data-name="Path 100649"
            d="M16.322 127.655a7.028 7.028 0 01-5.033 2.095 7.122 7.122 0 01-7.04-6.167l.684.538a.5.5 0 00.276.1.414.414 0 00.335-.16.437.437 0 00-.073-.611l-1.455-1.164a.318.318 0 00-.073-.044.014.014 0 01-.015-.015c-.015 0-.029-.015-.044-.015h-.042c-.015 0-.029-.015-.058-.015h-.1a.63.63 0 00-.073.015h-.017a.11.11 0 00-.058.029c-.015 0-.015.015-.029.015s-.029.015-.044.029l-.015.015c-.015.015-.044.029-.058.058l-1.164 1.455a.435.435 0 10.684.538l.465-.582a8.019 8.019 0 007.913 6.851 7.84 7.84 0 005.644-2.356.432.432 0 10-.611-.611z"
            transform="translate(-2.135 -18) translate(2.135 18) translate(-2.135 -114.622)"
            fill="#454b54"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function SpecificTimeIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '14px',
    height: '14px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 14 14.013"
      {...rest}
    >
      <path
        data-name="Path 100640"
        d="M20.312 14a6.312 6.312 0 106.312 6.312A6.312 6.312 0 0020.312 14z"
        transform="translate(-11 -11) translate(-2.621 -2.621)"
        fill="none"
      />
      <path
        data-name="Path 100641"
        d="M82 84.525A2.532 2.532 0 0184.525 82a2.483 2.483 0 011.7.669c.05.05-.088.126-.114.2s.088.164.126.215a.712.712 0 00.3.227c.076.013.139-.088.151-.05A2.519 2.519 0 1182 84.525z"
        transform="translate(-11 -11) translate(-62.037 -62.037)"
        fill="#fcca3d"
      />
      <path
        data-name="Path 100642"
        d="M94.938 87.3l-1.692 1.894-.606-.783a.378.378 0 10-.593.467l.884 1.136a.412.412 0 00.29.151h.013a.362.362 0 00.278-.126l1.906-2.146a2.675 2.675 0 00-.48-.593z"
        transform="translate(-11 -11) translate(-70.746 -66.668)"
        fill="#fff"
      />
      <g data-name="Group 106608">
        <path
          data-name="Path 100643"
          d="M17.691 24.381a6.691 6.691 0 116.691-6.691c0 .2-.013.4-.025.606a.375.375 0 01-.417.341.367.367 0 01-.341-.417c.013-.177.025-.366.025-.543a5.933 5.933 0 10-5.933 5.946c.189 0 .379-.013.568-.025a.382.382 0 01.417.341.39.39 0 01-.341.417c-.215.013-.43.025-.644.025z"
          transform="translate(-11 -11) translate(11 11) translate(-11 -11)"
          fill="#444b54"
        />
      </g>
      <g data-name="Group 106609">
        <path
          data-name="Path 100644"
          d="M58.418 30.418h-1.956a1.241 1.241 0 00-.821-.821v-3.218a.379.379 0 00-.757 0V29.6a1.26 1.26 0 00.379 2.462 1.239 1.239 0 001.2-.884h1.957a.379.379 0 000-.757zm-3.156.884a.5.5 0 11.5-.5.506.506 0 01-.5.498z"
          transform="translate(-11 -11) translate(16.428 12.894) translate(-54 -26)"
          fill="#444b54"
        />
      </g>
    </SvgIcon>
  );
}

export function MBFNotesIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '44px',
    height: '44px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106224" transform="translate(-48 -479)">
        <rect
          data-name="Rectangle 46375"
          width={44}
          height={44}
          rx={22}
          transform="translate(48 479)"
          fill="#e1fff3"
        />
        <path
          d="M8.5 6A2.513 2.513 0 006 8.5v15A2.513 2.513 0 008.5 26h10.556a.833.833 0 00.589-.244l.009-.009 6.1-6.1a.833.833 0 00.246-.591V8.5A2.513 2.513 0 0023.5 6zm0 1.667h15a.821.821 0 01.833.833v9.722h-3.611a2.513 2.513 0 00-2.5 2.5v3.611H8.5a.821.821 0 01-.833-.833v-15a.821.821 0 01.833-.833zm2.778 2.777a.833.833 0 100 1.667h9.444a.833.833 0 100-1.667zm0 3.889a.833.833 0 100 1.667H18.5a.833.833 0 100-1.667zm9.444 5.557h2.433l-3.266 3.266v-2.434a.821.821 0 01.833-.833z"
          transform="translate(54 485)"
          fill="#1fb476"
        />
      </g>
    </SvgIcon>
  );
}

export function MBFDontsIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '44px',
    height: '44px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106223" transform="translate(-134 -479)">
        <rect
          data-name="Rectangle 56145"
          width={44}
          height={44}
          rx={22}
          transform="translate(134 479)"
          fill="#fff6f0"
        />
        <path
          d="M15.636 0a2.4 2.4 0 00-2.386 2.386v.477h-3.1A2.159 2.159 0 008 5.011v13.841A2.159 2.159 0 0010.148 21h10.977a2.159 2.159 0 002.148-2.148V5.011a2.159 2.159 0 00-2.148-2.148h-3.1v-.477A2.4 2.4 0 0015.636 0zm0 1.432a.944.944 0 01.955.955V4.3h-1.909V2.386a.944.944 0 01.954-.954zM10.148 4.3h3.1v.716a.716.716 0 00.716.716h3.341a.716.716 0 00.716-.716V4.3h3.1a.705.705 0 01.716.716v13.836a.705.705 0 01-.716.716H10.148a.705.705 0 01-.716-.716V5.011a.705.705 0 01.716-.711zm5.477 3.331a.716.716 0 00-.7.726v4.773a.716.716 0 101.432 0V8.352a.716.716 0 00-.727-.726zm.011 7.647a.955.955 0 10.955.955.955.955 0 00-.955-.96z"
          transform="translate(140.364 490.5)"
          fill="#ff980e"
        />
      </g>
    </SvgIcon>
  );
}

export function MBFReferenceIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '44px',
    height: '44px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Group 106222" transform="translate(-220 -479)">
        <rect
          data-name="Rectangle 56146"
          width={44}
          height={44}
          rx={22}
          transform="translate(220 479)"
          fill="#def5fc"
        />
        <path
          data-name="icons8-repository (1)"
          d="M10.5 3A2.51 2.51 0 008 5.5v12.725a2.5 2.5 0 002.273 2.488v-1.374a1.139 1.139 0 01-.884-.886h12.474a.682.682 0 00.682-.682V5.5a2.51 2.51 0 00-2.5-2.5zm0 1.364h9.545A1.126 1.126 0 0121.181 5.5v11.589H9.364V5.5A1.126 1.126 0 0110.5 4.362zm1.136 1.819a.909.909 0 10.909.909.909.909 0 00-.909-.911zm0 3.636a.909.909 0 10.909.909.909.909 0 00-.909-.911zm0 3.636a.909.909 0 10.909.909.909.909 0 00-.909-.911zm-.455 5.909v2.954a.682.682 0 001.164.482l.882-.881.882.881a.681.681 0 001.164-.482v-2.956zm5 0v1.364h5.681a.682.682 0 100-1.364z"
          transform="translate(226.728 488.002)"
          fill="#1f91b4"
        />
      </g>
    </SvgIcon>
  );
}

export function HalfRoundIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 45,
    height: 62,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 62"
      {...rest}
      sx={rootSx}
    >
      <defs>
        <clipPath id="a">
          <rect
            data-name="Rectangle 46270"
            width={45}
            height={62}
            rx={7}
            transform="translate(4948 5494.183)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        data-name="Mask Group 6"
        transform="translate(-4948 -5494.183)"
        clipPath="url(#a)"
      >
        <ellipse
          data-name="Ellipse 129076"
          cx={32}
          cy={31}
          rx={32}
          ry={31}
          transform="translate(4951 5494.183)"
          fill="#fafafa"
        />
      </g>
    </SvgIcon>
  );
}
export function LogoIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 67.651,
    height: 69.685,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 67.651 69.685"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="Path 1"
        d="M64.945 46.911a25.738 25.738 0 01-33.116 11.262 23.236 23.236 0 003.459.272 23.61 23.61 0 10-5.588-46.48 25.836 25.836 0 0135.24 34.946zm-53.952-3.137A32.607 32.607 0 0142.257 1.955a32.068 32.068 0 015.945.575A34.464 34.464 0 0035.319 0a34.842 34.842 0 109.837 68.255 35.62 35.62 0 005.83-2.324 32.494 32.494 0 01-39.993-22.157zm20.476-22.282v8.57H22.9v8.99h8.567v8.567h8.99v-8.567h8.567v-8.99h-8.565v-8.57z"
        fill="#fff"
        transform="translate(-.452)"
      />
    </SvgIcon>
  );
}
export function WellnessClockIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '14px',
    height: '14px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      {...rest}
      sx={rootSx}
    >
      <defs>
        <clipPath id="a">
          <path
            data-name="Rectangle 46320"
            transform="translate(.082 .389)"
            fill="#6552cc"
            d="M0 0H14V14H0z"
          />
        </clipPath>
      </defs>
      <g
        data-name="icons8-clock 1"
        transform="translate(-.082 -.389)"
        clipPath="url(#a)"
      >
        <path
          data-name="Path 6955"
          d="M6.698 1.519a5.833 5.833 0 105.833 5.833 5.84 5.84 0 00-5.833-5.833zm0 .875A4.958 4.958 0 111.74 7.353a4.952 4.952 0 014.958-4.959zm-.444 1.452a.438.438 0 00-.431.444v3.208a.437.437 0 00.128.309L7.41 9.271a.438.438 0 10.619-.619l-1.33-1.33V4.29a.438.438 0 00-.444-.444z"
          fill="#6552cc"
          stroke="#6552cc"
          strokeWidth={0.2}
        />
      </g>
    </SvgIcon>
  );
}
export function Flag(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    ...rootStyle,
  };
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...rest} sx={rootSx}>
      <path
        data-name="Path 6953"
        d="M6.63 21.22a.787.787 0 01-.787-.787V1.843a.787.787 0 111.573 0v18.59a.787.787 0 01-.786.787z"
        fill="#25c460"
      />
      <path
        data-name="Path 6954"
        d="M15.512 10.4H6.63a.787.787 0 01-.787-.787V1.491A1.488 1.488 0 018.332.389l8.18 7.422a1.488 1.488 0 01-1 2.591zM7.417 8.828h7.874L7.417 1.683z"
        fill="#25c460"
      />
      <ellipse
        data-name="Ellipse 129102"
        cx={7.011}
        cy={1.753}
        rx={7.011}
        ry={1.753}
        transform="translate(0 19.864)"
        fill="#25c460"
      />
    </SvgIcon>
  );
}

export function AverageMembersIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 48,
    height: 48,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Group 105643" transform="translate(-302 -89)">
        <rect
          data-name="Rectangle 53935"
          width={48}
          height={48}
          rx={4}
          transform="translate(302 89)"
          fill="#fcf3e8"
        />
        <path
          d="M6.545 2a2.275 2.275 0 00-2.081 1.365A2.51 2.51 0 002 5.864a5.072 5.072 0 001.485 3.585l-.02-.021 4.2 4.577c.012.018.021.037.034.055a.682.682 0 00.193.191l.651.709a.682.682 0 00.281.188 8.751 8.751 0 001.249 4.728A4.63 4.63 0 0014.006 22a5.874 5.874 0 005.7-5.079 2.953 2.953 0 00-.665-5.83 2.927 2.927 0 00-2.214.939 3.113 3.113 0 000 4.031 2.811 2.811 0 001.484.856 4.363 4.363 0 01-4.309 3.72 3.127 3.127 0 01-2.8-1.514 7.526 7.526 0 01-1.021-3.977.682.682 0 00.27-.185l.672-.732a.682.682 0 00.171-.167c.013-.019.022-.039.035-.058l4.2-4.576-.02.021A5.072 5.072 0 0017 5.864a2.51 2.51 0 00-2.5-2.5h-.419a2.27 2.27 0 10.146 1.364h.273a1.126 1.126 0 011.136 1.136 3.706 3.706 0 01-1.086 2.622l-.02.021-3.33 3.623a1.531 1.531 0 00-1.2-.585H8.994a1.53 1.53 0 00-1.2.585L4.47 8.507l-.02-.021a3.706 3.706 0 01-1.086-2.622 1.121 1.121 0 01.96-1.119A2.272 2.272 0 106.545 2zm0 1.364a.909.909 0 11-.909.909.909.909 0 01.909-.909zm5.455 0a.909.909 0 11-.909.909.909.909 0 01.909-.909zm7.045 9.091a1.591 1.591 0 010 3.182 1.476 1.476 0 01-1.195-.481 1.753 1.753 0 010-2.219 1.476 1.476 0 011.195-.482zm-10.052.455h1.012c.2 0 .283.137.213.295l-.719.783-.719-.788c-.069-.154.02-.291.214-.291zm10.052.455a.682.682 0 10.682.682.682.682 0 00-.682-.683z"
          transform="translate(314 101)"
          fill="#ff980e"
        />
      </g>
    </SvgIcon>
  );
}

export function FamilyMembersIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 48,
    height: 48,
    ...rootStyle,
  };

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Group 105642" transform="translate(-302 -89)">
        <rect
          data-name="Rectangle 53933"
          width={48}
          height={48}
          rx={4}
          transform="translate(302 89)"
          fill="#e9ecfc"
        />
        <path
          d="M12.1 4.5a4.81 4.81 0 104.81 4.81A4.822 4.822 0 0012.1 4.5zm0 1.519A3.291 3.291 0 118.81 9.31a3.28 3.28 0 013.29-3.291zm7.089 5.316a3.015 3.015 0 00-2.277.967 3.2 3.2 0 000 4.142 3.163 3.163 0 004.553 0 3.2 3.2 0 000-4.142 3.015 3.015 0 00-2.275-.967zm0 1.519a1.4 1.4 0 011.141.457 1.682 1.682 0 010 2.124 1.4 1.4 0 01-1.141.457 1.4 1.4 0 01-1.141-.457 1.682 1.682 0 010-2.124 1.4 1.4 0 011.142-.457zM6.278 16.4A2.281 2.281 0 004 18.677v.607c0 2.925 3.558 5.216 8.1 5.216a12.53 12.53 0 002.692-.29 4.4 4.4 0 01-1.2-1.321 11.973 11.973 0 01-1.488.092c-4.066 0-6.582-1.919-6.582-3.7v-.607a.76.76 0 01.759-.759h8.379a3.265 3.265 0 011.745-.506h.115a4.087 4.087 0 01-.833-1.013zm10.127 2.025a2.29 2.29 0 00-2.278 2.275v.426a3 3 0 001.543 2.458 6.543 6.543 0 003.521.913 6.543 6.543 0 003.521-.913 3 3 0 001.543-2.458V20.7a2.29 2.29 0 00-2.278-2.278zm0 1.519h5.57a.748.748 0 01.759.759v.426a1.5 1.5 0 01-.841 1.178 5.12 5.12 0 01-2.7.674 5.12 5.12 0 01-2.7-.674 1.5 1.5 0 01-.841-1.178V20.7a.748.748 0 01.753-.757z"
          transform="translate(312 98.5)"
          fill="#4f66d8"
        />
      </g>
    </SvgIcon>
  );
}

export function IndividualMembersIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 48,
    height: 48,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Group 105644" transform="translate(-302 -89)">
        <rect
          data-name="Rectangle 53934"
          width={48}
          height={48}
          rx={4}
          transform="translate(302 89)"
          fill="#def5fc"
        />
        <path
          d="M15.857 4a5 5 0 104.91 5 4.967 4.967 0 00-4.91-5zm0 1.5A3.5 3.5 0 1112.419 9a3.458 3.458 0 013.438-3.5zM9.954 16A1.983 1.983 0 008 17.989v.761a4.663 4.663 0 002.606 4.031A10.89 10.89 0 0015.857 24a10.89 10.89 0 005.251-1.219A4.822 4.822 0 0023.6 19.5h.118v-1.511A1.984 1.984 0 0021.76 16zm0 1.5H21.76a.475.475 0 01.481.489v.761a3.047 3.047 0 01-1.841 2.719 9.644 9.644 0 01-4.543 1.031 9.644 9.644 0 01-4.539-1.031 3.047 3.047 0 01-1.845-2.719v-.761a.474.474 0 01.481-.489z"
          transform="translate(310.143 99)"
          fill="#1f91b4"
        />
      </g>
    </SvgIcon>
  );
}

export function InvitiesMembersIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 48,
    height: 48,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      {...rest}
      sx={rootSx}
    >
      <g data-name="Group 105641" transform="translate(-302 -89)">
        <rect
          data-name="Rectangle 53935"
          width={48}
          height={48}
          rx={4}
          transform="translate(302 89)"
          fill="#e1fff3"
        />
        <path
          d="M15.142 6a.833.833 0 00-.456.116l-9.463 5.628A2.5 2.5 0 004 13.892v8.5A3.624 3.624 0 007.611 26h15a3.624 3.624 0 003.611-3.611v-8.5A2.5 2.5 0 0025 11.745l-9.462-5.627A.833.833 0 0015.142 6zm-.03 1.8l9.037 5.373-9.037 4.4-9.038-4.4zM15.1 9.322a.833.833 0 00-.82.845v1.667h-1.669a.833.833 0 100 1.667h1.667v1.667a.833.833 0 101.667 0V13.5h1.667a.833.833 0 100-1.667h-1.667v-1.666a.833.833 0 00-.846-.845zm-9.433 5.511l9.08 4.417a.833.833 0 00.729 0l9.08-4.417v7.557a1.932 1.932 0 01-1.944 1.944h-15a1.932 1.932 0 01-1.945-1.944z"
          transform="translate(311 96.999)"
          fill="#1fb476"
        />
      </g>
    </SvgIcon>
  );
}

export function LightningIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 7,
    height: 10,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7 10"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M16.97 7.478a.374.374 0 00-.345-.228h-1.751l1.575-2.685A.375.375 0 0016.125 4h-3a.375.375 0 00-.328.194l-2.75 5a.375.375 0 00.328.556h1.694l-1.541 3.732a.375.375 0 00.617.4l5.75-6a.374.374 0 00.075-.404z"
        transform="translate(-10 -4)"
        fill="#999"
      />
    </SvgIcon>
  );
}
export function RunningIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 8.947,
    height: 10,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.947 10"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M14.1 6a1.052 1.052 0 101.053 1.052A1.052 1.052 0 0014.1 6zM9.738 7.841a.921.921 0 00-.707.331l-1.157 1.39a.395.395 0 10.606.506l1.143-1.373 1.477.181-1.265 1.758a1.04 1.04 0 00.3 1.527l.021.01a1.037 1.037 0 00.148.071l2.141 1.027.34 2.431a.395.395 0 00.778-.1v-2.892a.4.4 0 00-.188-.336l-.665-.411-.875-.552L12.889 9.9l.5.686a.4.4 0 00.307.147h1.841a.395.395 0 100-.789h-1.6l-.716-1.458-.015-.029-.028-.043a1.049 1.049 0 00-.934-.575c-.033 0-.065.007-.1.01-.116-.01-.224-.01-.315-.01zm-.05 4.686l-.275.831-2.058.271a.395.395 0 00.032.788h2.368a.4.4 0 00.37-.245l.689-1.1a21.472 21.472 0 01-1.126-.544z"
        transform="translate(-6.991 -6)"
        fill="#0e1824"
      />
    </SvgIcon>
  );
}
export function ActivityNotesEditIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g transform="translate(-48 -479)">
        <rect
          data-name="Rectangle 46375"
          width={44}
          height={44}
          rx={22}
          transform="translate(48 479)"
          fill="#e9f0ef"
        />
        <path
          d="M8.5 6A2.513 2.513 0 006 8.5v15A2.513 2.513 0 008.5 26h10.556a.833.833 0 00.589-.244l.009-.009 6.1-6.1a.833.833 0 00.246-.591V8.5A2.513 2.513 0 0023.5 6zm0 1.667h15a.821.821 0 01.833.833v9.722h-3.611a2.513 2.513 0 00-2.5 2.5v3.611H8.5a.821.821 0 01-.833-.833v-15a.821.821 0 01.833-.833zm2.778 2.777a.833.833 0 100 1.667h9.444a.833.833 0 100-1.667zm0 3.889a.833.833 0 100 1.667H18.5a.833.833 0 100-1.667zm9.444 5.557h2.433l-3.266 3.266v-2.434a.821.821 0 01.833-.833z"
          transform="translate(54 485)"
          fill="#5c6266"
        />
        <g data-name="Group 106352" transform="translate(3 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#007965"
          />
          <path
            d="M83.576 510.926a1.5 1.5 0 00-1.062.438l-4.747 4.747a.751.751 0 00-.191.325l-.636 2.228a.322.322 0 00.4.4l2.229-.636h0a.752.752 0 00.324-.191l4.747-4.748a1.5 1.5 0 00-1.064-2.563zm0 .64a.854.854 0 01.606.253h0a.853.853 0 010 1.213l-.416.416-1.213-1.214.416-.416a.855.855 0 01.607-.253zm-1.478 1.123l1.213 1.213-3.876 3.877a.109.109 0 01-.046.027l-1.671.478.477-1.668h0a.1.1 0 01.031-.05z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.4}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ActivityDontsEditIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g data-name="Dont's" transform="translate(-134 -479)">
        <rect
          data-name="Rectangle 56145"
          width={44}
          height={44}
          rx={22}
          transform="translate(134 479)"
          fill="#e9f0ef"
        />
        <path
          d="M15.636 0a2.4 2.4 0 00-2.386 2.386v.477h-3.1A2.159 2.159 0 008 5.011v13.841A2.159 2.159 0 0010.148 21h10.977a2.159 2.159 0 002.148-2.148V5.011a2.159 2.159 0 00-2.148-2.148h-3.1v-.477A2.4 2.4 0 0015.636 0zm0 1.432a.944.944 0 01.955.955V4.3h-1.909V2.386a.944.944 0 01.954-.954zM10.148 4.3h3.1v.716a.716.716 0 00.716.716h3.341a.716.716 0 00.716-.716V4.3h3.1a.705.705 0 01.716.716v13.836a.705.705 0 01-.716.716H10.148a.705.705 0 01-.716-.716V5.011a.705.705 0 01.716-.711zm5.477 3.331a.716.716 0 00-.7.726v4.773a.716.716 0 101.432 0V8.352a.716.716 0 00-.727-.726zm.011 7.647a.955.955 0 10.955.955.955.955 0 00-.955-.96z"
          transform="translate(140.364 490.5)"
          fill="#5c6266"
        />
        <g data-name="Group 106353" transform="translate(89 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#007965"
          />
          <path
            d="M83.542 510.851a1.5 1.5 0 00-1.062.438l-4.747 4.747a.751.751 0 00-.191.325l-.636 2.228a.322.322 0 00.4.4l2.229-.636h0a.752.752 0 00.324-.191l4.747-4.748a1.5 1.5 0 00-1.064-2.563zm0 .64a.854.854 0 01.606.253h0a.853.853 0 010 1.213l-.416.416-1.213-1.214.416-.416a.855.855 0 01.607-.253zm-1.478 1.123l1.213 1.213-3.876 3.877a.109.109 0 01-.046.027l-1.671.478.477-1.668h0a.1.1 0 01.031-.05z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.4}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function ActivityReffernceEditIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 44,
    height: 44,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 44 44"
      {...rest}
    >
      <g transform="translate(-220 -479)">
        <rect
          data-name="Rectangle 56146"
          width={44}
          height={44}
          rx={22}
          transform="translate(220 479)"
          fill="#e9f0ef"
        />
        <path
          data-name="icons8-repository (1)"
          d="M10.5 3A2.51 2.51 0 008 5.5v12.725a2.5 2.5 0 002.273 2.488v-1.374a1.139 1.139 0 01-.884-.886h12.474a.682.682 0 00.682-.682V5.5a2.51 2.51 0 00-2.5-2.5zm0 1.364h9.545A1.126 1.126 0 0121.181 5.5v11.589H9.364V5.5A1.126 1.126 0 0110.5 4.362zm1.136 1.819a.909.909 0 10.909.909.909.909 0 00-.909-.911zm0 3.636a.909.909 0 10.909.909.909.909 0 00-.909-.911zm0 3.636a.909.909 0 10.909.909.909.909 0 00-.909-.911zm-.455 5.909v2.954a.682.682 0 001.164.482l.882-.881.882.881a.681.681 0 001.164-.482v-2.956zm5 0v1.364h5.681a.682.682 0 100-1.364z"
          transform="translate(226.728 488.002)"
          fill="#5c6266"
        />
        <g data-name="Group 106354" transform="translate(175 -28)">
          <circle
            data-name="Ellipse 129853"
            cx={8}
            cy={8}
            r={8}
            transform="translate(73 507)"
            fill="#007965"
          />
          <path
            d="M83.542 510.926a1.5 1.5 0 00-1.062.438l-4.747 4.747a.751.751 0 00-.191.325l-.636 2.228a.322.322 0 00.4.4l2.229-.636h0a.752.752 0 00.324-.191l4.747-4.748a1.5 1.5 0 00-1.064-2.563zm0 .64a.854.854 0 01.606.253h0a.853.853 0 010 1.213l-.416.416-1.213-1.214.416-.416a.855.855 0 01.607-.253zm-1.478 1.123l1.213 1.213-3.876 3.877a.109.109 0 01-.046.027l-1.671.478.477-1.668h0a.1.1 0 01.031-.05z"
            fill="#fff"
            stroke="#fff"
            strokeWidth={0.4}
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export function PlanTemplatesIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 11.2,
    height: 14,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 11.2 14"
      {...rest}
    >
      <path
        d="M9.4 4A1.408 1.408 0 008 5.4v12.075a.525.525 0 101.05 0V5.4a.342.342 0 01.35-.35h8.4a.342.342 0 01.35.35v12.075a.525.525 0 101.05 0V5.4A1.408 1.408 0 0017.8 4zm1.225 2.275a.35.35 0 00-.35.35v1.4a.35.35 0 00.35.35h5.95a.35.35 0 00.35-.35v-1.4a.35.35 0 00-.35-.35zm.35 3.675a.525.525 0 100 1.05h5.25a.525.525 0 100-1.05zm0 2.1a.525.525 0 100 1.05h3.85a.525.525 0 100-1.05z"
        transform="translate(-8 -4)"
        fill="#0e1824"
      />
    </SvgIcon>
  );
}
export function PlanManagementIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 14.2,
    height: 12,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 14 12.999"
      {...rest}
    >
      <path
        d="M7.026 6a2.062 2.062 0 00-.6.09 2.036 2.036 0 00-.363.15 2.016 2.016 0 00-.816.8 1.953 1.953 0 00-.153.355 1.93 1.93 0 00-.05.189 2.027 2.027 0 00-.041.4v9.027A2.006 2.006 0 007.026 19h9.947A2.006 2.006 0 0019 17.013V7.986a2.027 2.027 0 00-.041-.4 1.932 1.932 0 00-.05-.189 1.953 1.953 0 00-.153-.355 2.016 2.016 0 00-.816-.8 2.036 2.036 0 00-.363-.15 2.243 2.243 0 00-.393-.079C17.112 6 17.044 6 16.974 6zm0 1.083a.552.552 0 11-.112.011.567.567 0 01.112-.011zm1.842 0a.542.542 0 11-.553.542.547.547 0 01.553-.542zm2.211 0h5.895a.567.567 0 01.112.011.541.541 0 01-.112 1.072h-5.895a.542.542 0 110-1.083zM6.105 9.25h11.79v7.764a.912.912 0 01-.921.9H7.026a.912.912 0 01-.921-.9zm5.888 1.077a.456.456 0 00-.453.458v.3a1.5 1.5 0 00-1.382 1.462 1.512 1.512 0 001.52 1.49h.829a.587.587 0 110 1.174h-1.142a.6.6 0 01-.526-.307.466.466 0 00-.625-.18.447.447 0 00-.183.613 1.528 1.528 0 001.335.776h.175v.254a.461.461 0 00.921 0v-.254h.046a1.49 1.49 0 100-2.979h-.829a.587.587 0 110-1.174h.248a.47.47 0 00.148 0h.194a.6.6 0 01.526.306.465.465 0 00.8.026.444.444 0 000-.457 1.507 1.507 0 00-1.141-.724v-.324a.447.447 0 00-.135-.326.465.465 0 00-.326-.134z"
        transform="translate(-5 -6)"
        fill="#0e1824"
      />
    </SvgIcon>
  );
}

export function DownloadsIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 13.989,
    height: 14,
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.989 14"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M12.983 5.973a.777.777 0 00-.766.788v7.45l-1-1a.777.777 0 10-1.1 1.1l2.332 2.332a.777.777 0 001.1 0l2.327-2.343a.777.777 0 10-1.1-1.1l-1 1V6.76a.777.777 0 00-.789-.788zm-4.651 4.285A2.343 2.343 0 006 12.589v5.052a2.343 2.343 0 002.332 2.332h9.326a2.343 2.343 0 002.332-2.332v-5.052a2.343 2.343 0 00-2.332-2.332h-1.166a.777.777 0 100 1.554h1.166a.766.766 0 01.777.777v5.052a.766.766 0 01-.777.777H8.332a.766.766 0 01-.777-.777v-5.051a.766.766 0 01.777-.777H9.5a.777.777 0 100-1.554z"
        transform="translate(-6 -5.972)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function MembersIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 22.154,
    height: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.154 24"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M12.154 5a4.308 4.308 0 104.308 4.308A4.308 4.308 0 0012.154 5zM22 13.615a4.308 4.308 0 114.308-4.308A4.313 4.313 0 0122 13.615zm0-6.769a2.462 2.462 0 102.462 2.462A2.464 2.464 0 0022 6.846zm-7.385 16V17a3.37 3.37 0 01.776-2.154H8.154A2.156 2.156 0 006 17v4.615a6.145 6.145 0 009.682 5.034 7.33 7.33 0 01-1.067-3.803zM22 29a6.161 6.161 0 01-6.154-6.154V17A2.156 2.156 0 0118 14.846h8A2.156 2.156 0 0128.154 17v5.846A6.161 6.161 0 0122 29zm-4-12.308a.309.309 0 00-.308.308v5.846a4.308 4.308 0 008.615 0V17a.309.309 0 00-.307-.308z"
        transform="translate(-6 -5)"
        fill="#007965"
        opacity={0.5}
      />
    </SvgIcon>
  );
}
export function SharedIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 24.6,
    height: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.6 24"
      {...rest}
      sx={rootSx}
    >
      <path
        d="M7.8 5A3.812 3.812 0 004 8.8v11.127a3.812 3.812 0 003.8 3.8h7.311a2.908 2.908 0 011.293-.538 4.066 4.066 0 01-.884-1.218H7.8a2.051 2.051 0 01-2.049-2.049v-8.474L15.29 16.6a.878.878 0 00.835 0l9.534-5.154v4.843a4.136 4.136 0 011.756 1.066V8.8A3.812 3.812 0 0023.61 5zm0 1.756h15.8A2.051 2.051 0 0125.659 8.8v.647l-9.951 5.379-9.952-5.374V8.8A2.051 2.051 0 017.8 6.756zm11.42 10.537a2.927 2.927 0 102.927 2.927 2.927 2.927 0 00-2.927-2.927zm5.268 0a2.941 2.941 0 00-1.856.662 4.088 4.088 0 010 4.53 2.927 2.927 0 101.856-5.192zm-7.622 7.024a1.757 1.757 0 00-1.753 1.872c0 .071.01.144.016.217C15.273 28.2 16.868 29 18.45 29h1.539c1.582 0 3.178-.8 3.322-2.594l.016-.217a1.758 1.758 0 00-1.754-1.872zm7.043 0a2.891 2.891 0 01.585 1.944l-.017.24a3.742 3.742 0 01-1.171 2.476 3.289 3.289 0 00.415.023h1.534c1.586 0 3.178-.8 3.325-2.593l.017-.217a1.76 1.76 0 00-1.756-1.873z"
        transform="translate(-4 -5)"
        fill="#6552cc"
        opacity={0.4}
      />
    </SvgIcon>
  );
}

export function UserClockIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 24,
    height: 24,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
      sx={rootSx}
    >
      <path
        data-name="icons8-user-clock (1)"
        d="M11.417 3v.007a5.546 5.546 0 00-.327 11.084.837.837 0 00.327.072h.031v-.007a5.575 5.575 0 000-11.15V3zm.015 1.678a3.9 3.9 0 11-3.907 3.9 3.9 3.9 0 013.907-3.9zm9.428 10.043A6.14 6.14 0 1027 20.86a6.14 6.14 0 00-6.14-6.139zM5.078 16.4A2.155 2.155 0 003 18.616v.849a5.184 5.184 0 002.665 4.48 10.491 10.491 0 005.428 1.38 11.505 11.505 0 003.7-.6 7.237 7.237 0 01-.726-1.513 10.218 10.218 0 01-2.971.435c-3.092 0-6.419-1.31-6.419-4.186v-.849c0-.3.185-.546.4-.546h9.145a7.242 7.242 0 01.988-1.666zm15.225.558a.558.558 0 01.558.558v3.344h2.791a.558.558 0 010 1.116H20.3a.558.558 0 01-.558-.558v-3.906a.558.558 0 01.558-.559z"
        transform="translate(-3 -3)"
        fill="#ff980e"
        opacity={0.5}
      />
    </SvgIcon>
  );
}
export function PdfIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 32,
    width: 32,
    color: '#F58634',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="22.57"
      height="28"
      viewBox="0 0 22.57 28"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-file-prescription"
        d="M11.174,4A3.178,3.178,0,0,0,8,7.15v21.7A3.178,3.178,0,0,0,11.174,32H27.4a3.178,3.178,0,0,0,3.174-3.15V14.15a1.046,1.046,0,0,0-.31-.742l-.011-.011L21.091,4.308A1.062,1.062,0,0,0,20.343,4Zm0,2.1h8.111v5.95a3.178,3.178,0,0,0,3.174,3.15h6V28.85A1.038,1.038,0,0,1,27.4,29.9H11.174a1.038,1.038,0,0,1-1.058-1.05V7.15A1.038,1.038,0,0,1,11.174,6.1ZM21.4,7.585,26.958,13.1h-4.5A1.038,1.038,0,0,1,21.4,12.05ZM13.113,13.8a.879.879,0,0,0-.882.875v6.65a.882.882,0,0,0,1.763,0V20.1h1.411a3.141,3.141,0,0,0,.365-.037L18.6,24.258l-1.981,2.874a.871.871,0,0,0,.23,1.217.884.884,0,0,0,1.225-.228l1.583-2.3,1.547,2.3a.884.884,0,0,0,1.225.228.871.871,0,0,0,.23-1.217l-1.93-2.863,1.93-2.8a.871.871,0,0,0-.23-1.217.885.885,0,0,0-1.225.228L19.672,22.7,17.42,19.363a3.122,3.122,0,0,0,1.16-2.413,3.166,3.166,0,0,0-3.174-3.15ZM14,15.55h1.411a1.4,1.4,0,1,1,0,2.8H14Z"
        transform="translate(-8 -4)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function EmailIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 20,
    height: 18,
    mt: 0.5,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.055 18.737"
      {...rest}
      sx={rootSx}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
      >
        <path
          id="icons8-mail"
          d="M7.25,8A3.262,3.262,0,0,0,4,11.25v9.5A3.262,3.262,0,0,0,7.25,24h13.5A3.262,3.262,0,0,0,24,20.75v-9.5A3.262,3.262,0,0,0,20.75,8Zm0,1.5h13.5a1.739,1.739,0,0,1,1.75,1.75V11.8L14,16.4,5.5,11.8V11.25A1.739,1.739,0,0,1,7.25,9.5ZM5.5,13.508l8.144,4.4a.75.75,0,0,0,.713,0l8.144-4.4V20.75a1.739,1.739,0,0,1-1.75,1.75H7.25A1.739,1.739,0,0,1,5.5,20.75Z"
          transform="translate(-4 -8)"
        />
      </svg>
    </SvgIcon>
  );
}
export function CameraDocIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 20,
    height: 18,
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.055 18.737"
      {...rest}
      sx={rootSx}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.75"
        height="19.75"
        viewBox="0 0 20.75 19.75"
      >
        <path
          id="icons8-camera"
          d="M12.236,5a2.252,2.252,0,0,0-1.943,1.116L9.194,8H7.25A3.262,3.262,0,0,0,4,11.25v9.5A3.262,3.262,0,0,0,7.25,24h13.5A3.262,3.262,0,0,0,24,20.75v-9.5A3.262,3.262,0,0,0,20.75,8H18.806l-1.1-1.884A2.252,2.252,0,0,0,15.764,5Zm0,1.5h3.527a.748.748,0,0,1,.648.372l1.316,2.256a.75.75,0,0,0,.647.372H20.75a1.739,1.739,0,0,1,1.75,1.75v9.5a1.739,1.739,0,0,1-1.75,1.75H7.25A1.739,1.739,0,0,1,5.5,20.75v-9.5A1.739,1.739,0,0,1,7.25,9.5H9.625a.75.75,0,0,0,.647-.372l1.316-2.256A.748.748,0,0,1,12.236,6.5ZM14,11a4.5,4.5,0,1,0,4.5,4.5A4.511,4.511,0,0,0,14,11Zm0,1.5a3,3,0,1,1-3,3A2.989,2.989,0,0,1,14,12.5Z"
          transform="translate(-3.625 -4.625)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.75"
        />
      </svg>
    </SvgIcon>
  );
}
export function DoctorBookAppointmentIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    height: 24,
    width: 24,
    color: '#F58634',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...rest}
      sx={rootSx}
    >
      <path
        id="icons8-schedule"
        d="M6.571,0a6.571,6.571,0,1,0,6.571,6.571A6.584,6.584,0,0,0,6.571,0Zm0,1.714A4.857,4.857,0,1,1,1.714,6.571,4.844,4.844,0,0,1,6.571,1.714ZM6.558,2.845a.857.857,0,0,0-.844.869V6.571a.857.857,0,0,0,.251.606L7.108,8.32A.857.857,0,1,0,8.32,7.108l-.892-.892v-2.5a.857.857,0,0,0-.871-.869Zm7.054.584a7.633,7.633,0,0,1,.537,1.714h6.137a2,2,0,0,1,2,2V8H14.148a7.692,7.692,0,0,1-.537,1.714h8.674V20.286a2,2,0,0,1-2,2H7.143a2,2,0,0,1-2-2V14.148a7.692,7.692,0,0,1-1.714-.537v6.674A3.719,3.719,0,0,0,7.143,24H20.286A3.719,3.719,0,0,0,24,20.286V7.143a3.719,3.719,0,0,0-3.714-3.714Zm.1,8.571a1.429,1.429,0,1,0,1.429,1.429A1.429,1.429,0,0,0,13.714,12Zm4.857,0A1.429,1.429,0,1,0,20,13.429,1.429,1.429,0,0,0,18.571,12ZM8.857,17.143a1.429,1.429,0,1,0,1.429,1.429A1.429,1.429,0,0,0,8.857,17.143Zm4.857,0a1.429,1.429,0,1,0,1.429,1.429A1.429,1.429,0,0,0,13.714,17.143Z"
        fill="#007965"
      />
    </SvgIcon>
  );
}
