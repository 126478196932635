/* eslint-disable no-unused-vars */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

export const useOrganizationNotification = create((set, get) => ({
  notificationState: {
    notificationData: [],
  },
  loading: null,
  error: null,
  getOrganizationNotification: async (id) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query

      const { status, data, error, isFetching } = await queryClient.fetchQuery({
        queryKey: ['/notifications/getAll'],
        queryFn: async () => {
          const { status, data, error, isFetching } = await httpRequest(
            'post',
            `${envConfig.api_url}/notifications/getAll`,
            {
              id,
            },
            true,
          );
          return data;
        },
        staleTime: Infinity,
      });

      set((state) => ({
        loading: false,
        notificationState: {
          ...state.notificationState,
          notificationData: data,
        },
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!',
      );
    }
  },
  softDeleteNotification: async (notification_id) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query

      const status = await queryClient.fetchQuery({
        queryKey: ['/notifications/status', notification_id],
        queryFn: async () => {
          const status = await httpRequest(
            'post',
            `${envConfig.api_url}/notifications/status`,
            {
              notification_id,
            },
            true,
          );
          return status;
        },
        staleTime: Infinity,
      });

      if (status?.status?.code === 200) {
        set({ loading: false });
        return status;
      }
      set({ loading: false });
    } catch (error) {
      log('error', error);
      set({ loading: false });
      return toast.error('Something went wrong please try again!');
    }
  },

  organizationNotificationUndo: async (notification_id) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status } = await queryClient.fetchQuery([
        '/notifications/undo',
        'post',
        { notification_id },
      ]);
      if (status?.code === 200) {
        set({ loading: false });
        return status;
      }
      set({ loading: false });
    } catch (error) {
      log('error', error);
      set({ loading: false });
      return toast.error('Something went wrong please try again!');
    }
  },
  organizationNotificationDelete: async (notification_id) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status } = await queryClient.fetchQuery([
        '/notifications/delete',
        'post',
        { notification_id },
      ]);
      if (status?.code === 200) {
        set({ loading: false });
        return status;
      }
      set({ loading: false });
    } catch (error) {
      log('error', error);
      set({ loading: false });
      return toast.error('Something went wrong please try again!');
    }
  },
}));
