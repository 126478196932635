import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { PriceDecreaseIcon, PriceIncreaseIcon } from '../../../atoms';
import { transactionsCard_style } from './style';

function TransactionsCard(props) {
  const {
    className = '',
    rootStyle = {},
    positive = true,
    percentage,
    description,
    amount,
    showPercentage,
    colorCode = '',
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...transactionsCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        pb={0.6}
        alignItems="center"
      >
        <Typography
          sx={{
            ...transactionsCard_style.amountFontSx,
            ...(colorCode && { color: colorCode }),
          }}
        >
          {amount}{' '}
        </Typography>
        {showPercentage && (
          <Box
            sx={{
              ...transactionsCard_style.percentageContainerSx,
              ...(positive
                ? { background: '#E8FCF0' }
                : { background: '#FEEAEA ' }),
            }}
          >
            {positive ? <PriceIncreaseIcon /> : <PriceDecreaseIcon />}
            <Typography
              sx={{
                ...transactionsCard_style.percentageFontSx,
                ...(positive ? { color: '#25C460' } : { color: '#F44F5A' }),
              }}
            >
              &nbsp; {percentage}
            </Typography>
          </Box>
        )}
      </Stack>
      <Typography sx={transactionsCard_style.cardDescriptionSx}>
        {description}
      </Typography>
    </Box>
  );
}

TransactionsCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { TransactionsCard };

