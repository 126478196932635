export const notification_card_style = {
  rootSx: {
    py: 2.1,
    px: 2.5,
    m: 1.2,
    borderRadius: '4px',
  },
  iconBackgroundSx: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: 'primaryTints.100',
  },
  iconExpertBackgroundSx: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    backgroundColor: 'primary.lighter',
  },
};
