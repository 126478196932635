import { useDrugMaster } from '@hc/store/backoffice/drugMaster';
import { EditIcon, Switch } from '@hc/ui/atoms';
import { TableSkeletonLoader } from '@hc/ui/components';
import {
  AddDrugDrawer,
  MasterTable,
  MasterTopContainer,
} from '@hc/ui/components/backoffice';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { Box, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { DrugMaster_Style } from './style';

export default function DrugMaster(props) {
  const { ...rest } = props;

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // Store Data
  const {
    drug,
    drugMasterData,
    isIamValidToSave,
    clearDrug,
    getAllDrugMasters,
    addDrugMaster,
    drugMasterStatusUpdate,
    initialEditDrugData,
    loading,
    drugMasterCount,
  } = useDrugMaster(
    (state) => ({
      loading: state.loading,
      drugMasterData: state.drugMasterData,
      isIamValidToSave: state.isIamValidToSave,
      getAllDrugMasters: state.getAllDrugMasters,
      clearDrug: state.clearDrug,
      addDrugMaster: state.addDrugMaster,
      drug: state.drug,
      drugMasterStatusUpdate: state.drugMasterStatusUpdate,
      initialEditDrugData: state.initialEditDrugData,
      drugMasterCount: state.drugMasterCount,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // General Hooks
  const [drugDrawerOpen, setDrugDrawerOpen] = useState(false);

  const closeDrugMasterDrawer = () => {
    setDrugDrawerOpen(false);
    clearDrug();
  };

  const initialData = async () => {
    await getAllDrugMasters('');
  };

  const upsertDrugMasterFnc = async () => {
    const result = isIamValidToSave();
    if (result) {
      const responseAdd = await addDrugMaster(
        drug,
        drug?.drugId > 0 ? 'update' : 'add',
      );
      if (responseAdd?.status?.code === 200) {
        setDrugDrawerOpen(false);
        queryClient.invalidateQueries({
          queryKey: ['getAllDrugMaster'],
        });
        initialData();
      }
      clearDrug();
    }
  };

  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    queryClient.invalidateQueries({
      queryKey: ['getAllDrugMaster'],
    });
    await getAllDrugMasters(searchValue);
  };

  const onStatusChange = async (id, val) => {
    const payload = {
      id: id ?? 0,
      is_active: !val?.is_active ?? true,
    };
    const response = await drugMasterStatusUpdate(payload);
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllDrugMaster'],
      });
      await getAllDrugMasters('');
    }
  };
  const handleAddClick = () => {
    clearDrug();
    setDrugDrawerOpen(true);
  };
  const onEdit = (id, val) => {
    initialEditDrugData(id, val);
    setDrugDrawerOpen(true);
  };

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllDrugMaster'],
    });
    await getAllDrugMasters(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllDrugMaster'],
    });
    await getAllDrugMasters(search, rowsPerPage, offset);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
      field: 'drugName',
      headerName: 'Drug Name',
      width: 250,
    },

    {
      field: 'when',
      headerName: 'When',
      width: 150,
    },
    {
      field: 'frequency',
      headerName: 'Frequency',
      width: 150,
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 150,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Active/Inactive',
      width: 150,
      renderCell: (params) => (
        <Switch
          id="status"
          checked={params?.row?.status}
          onChange={() => onStatusChange(params?.row?.id, params?.row?.value)}
          rootStyle={DrugMaster_Style.switchSx}
        />
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 150,

      renderCell: (params) => (
        <Box
          sx={{ pl: 1 }}
          onClick={() => onEdit(params?.row?.id, params?.row?.value)}
        >
          <EditIcon id='editDrug' style={{ cursor: 'pointer', color: '#007965' }} />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    initialData();
  }, []);

  return (
    <Box
      {...rest}
      px={2}
      py={2}
      sx={{
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: '#fff',
        borderRadius: '10px',
      }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MasterTopContainer
              handleSearch={handleSearch}
              handleAddClick={handleAddClick}
              title="Drugs"
              count={drugMasterCount}
              showSearchField
              searchFieldPlaceholder="Search drug by name"
              showAddButton
              addButtonName="Add Drug"
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Box>
                <TableSkeletonLoader />
              </Box>
            ) : (
              <Box>
                <MasterTable
                  columns={columns}
                  data={drugMasterData ?? []}
                  customTableStyle={DrugMaster_Style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              </Box>
            )}
            <Stack
              flexDirection="row"
              justifyContent="end"
              sx={{ width: '100%' }}
            >
              <Pagination
                totalCount={drugMasterCount}
                onLimitChange={onLimitChange}
                onOffsetchange={onOffsetchange}
                offset={offset}
                rowsPerPage={rowsPerPage}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* Add Drug Drawer */}

        <AddDrugDrawer
          drugDrawerOpen={drugDrawerOpen}
          closeDrugMasterDrawer={closeDrugMasterDrawer}
          upsertDrugMaster={upsertDrugMasterFnc}
          isUpdate={drug?.drugId > 0 ? true : false}
        />
      </Box>
    </Box>
  );
}

DrugMaster.propTypes = {
  className: PropTypes.string,
};
