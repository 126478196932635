/* eslint-disable react/display-name */
import { backofficeRoutes } from '@hc/routes';
import { localStorageKeys } from '@hc/utils/constants';
import { parseJwt } from '@hc/utils/helperFunctions';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function PrivateRouter(props) {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    // get the authToken from local storage
    const authToken = localStorage.getItem(localStorageKeys?.authToken);
    const data = parseJwt(authToken);
    if (!authToken) {
      if (location?.pathname !== backofficeRoutes.login) {
        navigate(backofficeRoutes.login);
      }
    }

    // If expire date is lesser than current date & time clear the auth token in local storage and redirect to login page
    if (authToken) {
      if (data?.exp && moment().unix() >= data?.exp) {
        localStorage.clear();
        navigate(backofficeRoutes.login);
      }
    }

    setShowComponent(true);
  }, [location]);

  if (showComponent) {
    return children;
  }
  return null;
}

export { PrivateRouter };

