import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructDrugData = (data) => {
  const allergiesTableData = data.map((val) => ({
    value: val,
    id: val?.id,
    role_name: val?.role_name ?? '',
    updated_at: val?.updated_at ?? '',
    created_at: val?.created_at ?? '',
  }));
  return allergiesTableData;
};

export const useRoles = create((set) => ({
  roleMasterData: [],
  roleCount: 0,
  loading: false,
  // ------------- STORE METHODS ------------------
  getAllRoles: async (search = '', limit = 10, offset = 0) => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getAllRoles', search, offset],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backofficeMaster/role/get/all?search=${search}&limit=${limit}&offset=${offset}`,
            {},
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        set(() => ({
          roleMasterData:
            Array.isArray(data?.data?.role_data) &&
            data?.data?.role_data?.length > 0
              ? constructDrugData(data?.data?.role_data)
              : [],
          roleCount: data?.data?.role_count,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!'
      );
    }
  },
}));
