import { Box } from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import PropTypes from 'prop-types';
import { pdfViewer_style } from './style';

export function PdfViewer(props) {
  const { url, rootStyle = {} } = props;
  // Create new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const pdfVersion = '3.5.141';
  const pdfWorkerUrl = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfVersion}/pdf.worker.js`;
  return (
    <Box
      sx={{
        ...pdfViewer_style.rootSx,
        ...rootStyle,
      }}
    >
      {url?.length > 0 && (
        <Worker workerUrl={pdfWorkerUrl}>
          <Viewer
            fileUrl={url}
            enableSmoothScroll
            plugins={[
              // Register plugins
              defaultLayoutPluginInstance,
            ]}
          />
        </Worker>
      )}
    </Box>
  );
}

PdfViewer.propTypes = {
  rootStyle: PropTypes.object,
};
