import { Box } from '@mui/material';
import { useSpecialities } from '@hc/store/backoffice/masters/Specialities';
import PropTypes from 'prop-types';
import { Button, Drawer, Input, Label } from '@hc/ui/atoms';
import { specialitiesDrawer_style } from './style';

function SpecialitiesDrawer(props) {
  const {
    drawerOpen = false,
    closeMasterDrawer = () => false,
    upsertMaster = () => {},
  } = props;

  const { handleChange, speciality_name, speciality_id } = useSpecialities(
    (state) => ({
      handleChange: state.handleChange,
      speciality_name: state.speciality_name,
      speciality_id: state.speciality_id,
    })
  );

  return (
    <Box>
      {drawerOpen && (
        <Drawer
          show={drawerOpen}
          onCloseDrawer={closeMasterDrawer}
          anchor="right"
          isCloseIconRequired
          header={`${speciality_id ? 'Update' : 'Add'} Specialities`}
          headerStyle={specialitiesDrawer_style.drawerHeadSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                buttonStyle={specialitiesDrawer_style.saveBtnSx}
                onClick={upsertMaster}
              >
                {speciality_id ? 'Update' : 'Add'}
              </Button>
              <Button
                buttonStyle={specialitiesDrawer_style.cancleBtnSx}
                onClick={closeMasterDrawer}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <Box sx={specialitiesDrawer_style.boxRootSx}>
            <Box sx={specialitiesDrawer_style.inputGroupSx}>
              <Label
                rootStyle={specialitiesDrawer_style.labelSx}
                htmlFor="Specialities"
                isRequired
              >
                Specialities
              </Label>
              <Input
                id="Specialities"
                textFieldStyle={specialitiesDrawer_style.inputFieldSx}
                onChange={(e) => handleChange(e.target.value)}
                fullWidth
                value={speciality_name}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

SpecialitiesDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
  closeMasterDrawer: PropTypes.func,
};

export { SpecialitiesDrawer };
