/* eslint-disable camelcase */
export const ContractDetails_style = {
  headerSx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  transactionStatusSx: {
    px: 0.2,
    width: 'min-content',
    py: 0.1,
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 400,
  },
  dataTableCustomSx: {
    '& .MuiDataGrid-row': {
      borderBottom: '2px solid',
      borderColor: 'grey.400',
    },
    '& .MuiDataGrid-main': {
      border: '1px solid',
      borderColor: 'grey.400',
      bgcolor: '#FFFFFF',
      borderRadius: '6px',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
    '& .MuiDataGrid-row:hover': {
      backgroundColor: '#EDEFF2',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderBottom: '1px solid',
      borderColor: 'grey.400',
    },
  },
  titleSx: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: 'medium',
  },
  daterangeSx: {
    width: '250px',
  },
  switchSx: {
    '& .MuiSwitch-track': {
      backgroundColor: 'text.hint',
      opacity: 1,
      border: 0,
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#007965',
      opacity: 1,
    },
  },
  addMasterSx: {
    width: 'fit-content',
  },
  topbarheadingSx: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0E1824',
    pb: 0.4,
  },
  topbarTitleSx: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#0E1824',
    pb: 0.4,
  },
  descSX: { fontSize: '12px', fontWeight: 400, color: '#0E1824', pb: 0.4 },
  topbarCardSx: {
    backgroundColor: '#fff',
    p: 2,
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
  },
};
