import { SvgIcon } from '@mui/material';

export function PriceDecreaseIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '16px',
    height: '7px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 7.276"
      {...rest}
    >
      <path
        d="M2.53 13.984a.545.545 0 00-.38.936l5.089 5.09a.545.545 0 00.771 0l3.977-3.977 4.136 4.136h-1.955a.545.545 0 100 1.091h3.272a.545.545 0 00.545-.545v-3.272a.545.545 0 10-1.091 0V19.4l-4.522-4.522a.545.545 0 00-.771 0l-3.976 3.975-4.7-4.7a.545.545 0 00-.395-.169z"
        transform="translate(-1.985 -13.984)"
        fill="#f44f5a"
      />
    </SvgIcon>
  );
}

export function OrganizationIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '15px',
    height: '14px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      data-name="Group 94297"
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 15 14"
      {...rest}
    >
      <path
        data-name="icons8-organization (3)"
        d="M3.5 3A1.53 1.53 0 002 4.556V17h15V7.667a1.54 1.54 0 00-1.5-1.556h-3V4.556A1.53 1.53 0 0011 3zM5 6.111h1.5v1.556H5zm3 0h1.5v1.556H8zm4.5 1.556h3v7.778h-3v-1.556H14v-1.556h-1.5v-1.555H14V9.222h-1.5zM5 9.222h1.5v1.556H5zm3 0h1.5v1.556H8zm-3 3.111h1.5v1.556H5zM3.5 4.556H11v10.888H3.5z"
        transform="translate(-2 -3)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function MapIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '12px',
    height: '12px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 12.269 12.584"
      {...rest}
    >
      <path
        d="M6.134.125a4.008 4.008 0 00-4 4 6.763 6.763 0 001.247 3.172 30.878 30.878 0 001.914 2.743 1.075 1.075 0 001.678 0 30.878 30.878 0 001.914-2.743 6.763 6.763 0 001.247-3.172 4.008 4.008 0 00-4-4zm0 1a2.993 2.993 0 013 3 7.013 7.013 0 01-1.1 2.652 30.142 30.142 0 01-1.85 2.648c-.033.043-.064.043-.1 0a30.141 30.141 0 01-1.849-2.65 7.013 7.013 0 01-1.101-2.65 2.993 2.993 0 013-3zm0 2a1 1 0 101 1 1 1 0 00-1-1zM2.043 8.459a1.168 1.168 0 00-1.066.693l-.743 1.666A1.166 1.166 0 001.3 12.459h9.665a1.167 1.167 0 001.069-1.641l-.74-1.667a1.168 1.168 0 00-1.066-.693H9.142q-.319.483-.691 1h1.777a.167.167 0 01.152.1l.74 1.667a.166.166 0 01-.152.234H1.302a.167.167 0 01-.152-.234l.741-1.667a.167.167 0 01.152-.1h1.776q-.374-.518-.691-1z"
        fill="#597986"
        stroke="#597986"
        strokeWidth={0.25}
      />
    </SvgIcon>
  );
}

export function UserSettingIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '15px',
    height: '16px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 15.628 16"
      {...rest}
    >
      <path
        d="M6.349 3a2.216 2.216 0 00-1.673.711 2.349 2.349 0 000 3.044 2.216 2.216 0 001.673.711 2.216 2.216 0 001.673-.711 2.349 2.349 0 000-3.044A2.216 2.216 0 006.349 3zm5.209 0a2.216 2.216 0 00-1.673.711 2.349 2.349 0 000 3.044 2.324 2.324 0 003.346 0 2.349 2.349 0 000-3.044A2.216 2.216 0 0011.558 3zM6.349 4.116a1.029 1.029 0 01.839.336 1.236 1.236 0 010 1.561 1.029 1.029 0 01-.839.336 1.029 1.029 0 01-.839-.336 1.236 1.236 0 010-1.561 1.029 1.029 0 01.839-.336zm5.209 0a1.029 1.029 0 01.839.336 1.236 1.236 0 010 1.561 1.029 1.029 0 01-.839.336 1.029 1.029 0 01-.839-.336 1.236 1.236 0 010-1.561 1.029 1.029 0 01.839-.336zM4.3 8.209A1.3 1.3 0 003 9.512v3.535a3.344 3.344 0 005.422 2.625 4.1 4.1 0 01-.6-.955 2.229 2.229 0 01-3.709-1.67V9.512a.186.186 0 01.187-.186h3.175a2.036 2.036 0 01.46-1.116zm5.209 0a1.3 1.3 0 00-1.3 1.3v3.535a3.353 3.353 0 002.02 3.073 4.825 4.825 0 01-.159-1.212c0-.065.007-.128.009-.192a2.225 2.225 0 01-.754-1.669V9.512a.186.186 0 01.186-.186H13.6a.186.186 0 01.186.186v.688a4.713 4.713 0 01.526-.094h.029a4.811 4.811 0 01.561-.036v-.558a1.3 1.3 0 00-1.3-1.3zm5.4 2.6a3.834 3.834 0 00-.641.054l-.129.533a1.488 1.488 0 01-1.868 1.078l-.448-.133a4.131 4.131 0 00-.634 1.169l.333.32a1.489 1.489 0 010 2.145l-.333.32a4.126 4.126 0 00.634 1.169l.448-.133a1.488 1.488 0 011.868 1.078l.129.533a3.848 3.848 0 001.282 0l.129-.533a1.488 1.488 0 011.868-1.078l.448.133a4.131 4.131 0 00.634-1.169l-.333-.32a1.489 1.489 0 010-2.145l.333-.32a4.126 4.126 0 00-.634-1.169l-.448.133a1.488 1.488 0 01-1.87-1.074l-.129-.533a3.834 3.834 0 00-.641-.053zm0 2.977a1.116 1.116 0 11-1.116 1.116 1.116 1.116 0 011.114-1.111z"
        transform="translate(-3 -3)"
        fill="#6f6f6f"
      />
    </SvgIcon>
  );
}

export function BondIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '16px',
    height: '14px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 14.4"
      {...rest}
    >
      <path
        d="M3.6 3a1.6 1.6 0 00-.309.031l-.025.006a1.6 1.6 0 00-.28.087h-.012A1.6 1.6 0 002 4.6v11.2a1.6 1.6 0 00.031.309c0 .008 0 .017.006.025a1.6 1.6 0 00.087.28v.012A1.6 1.6 0 003.6 17.4h12.8a1.6 1.6 0 00.309-.031l.025-.006A1.6 1.6 0 0018 15.8V4.6a1.6 1.6 0 00-.031-.309c0-.008 0-.017-.006-.025A1.6 1.6 0 0016.4 3zm1.6 1.6h9.6a1.6 1.6 0 001.6 1.6v8a1.6 1.6 0 00-1.6 1.6H5.2a1.6 1.6 0 00-1.6-1.6v-8a1.6 1.6 0 001.6-1.6zm4.572.8v.823a2.027 2.027 0 00-1.791 2.152c0 2.673 2.767 1.882 2.767 3.666 0 .893-.576.914-.694.914-.1 0-.746.036-.8-1.155H7.738a2.168 2.168 0 001.92 2.377V15h.8v-.823a2 2 0 001.8-2.147c0-2.565-2.767-2.075-2.767-3.648 0-.9.5-.93.6-.93.188 0 .619.168.655 1.148h1.508a2.178 2.178 0 00-1.681-2.355V5.4zM5.2 9.4V11h1.6V9.4zm8 0V11h1.6V9.4z"
        transform="translate(-2 -3)"
        fill="#6f6f6f"
      />
    </SvgIcon>
  );
}
export function AwarenessCategoryIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '16px',
    height: '16px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      sx={rootSx}
      {...rest}
    >
      <path
        d="M10.5 6A5.506 5.506 0 005 11.5v25c0 3.032 2.467 5.5 5.5 5.5h13.557a12.921 12.921 0 01-1.422-3.002H10.5a2.503 2.503 0 01-2.5-2.5v-20.5h32v7a13.011 13.011 0 013 1.77V11.5C43 8.468 40.533 6 37.5 6h-27zm3 14a1.5 1.5 0 100 3h2a1.5 1.5 0 100-3h-2zm8 0a1.5 1.5 0 100 3h8.498c1.54-.643 3.229-1 5.002-1 .302 0 .598.025.895.045A1.493 1.493 0 0034.5 20h-13zM35 24c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm-21.5 2a1.5 1.5 0 100 3h2a1.5 1.5 0 100-3h-2zm8 0a1.5 1.5 0 100 3h1.975a13.02 13.02 0 012.158-3H21.5zM35 27a1 1 0 011 1v6h6a1 1 0 010 2h-6v6a1 1 0 01-2 0v-6h-6a1 1 0 010-2h6v-6a1 1 0 011-1zm-21.5 5a1.5 1.5 0 100 3h2a1.5 1.5 0 100-3h-2zm8 0a1.5 1.5 0 100 3h.5c0-1.034.133-2.035.361-3H21.5z"
        fill="#6f6f6f"
      />
    </SvgIcon>
  );
}

export function ActivityCancleIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '24px',
    height: '24px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g data-name="Group 106376" transform="translate(-332 -452)">
        <path
          data-name="Rectangle 56227"
          transform="translate(332 452)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <path
          d="M18.259 6.97a.7.7 0 00-.492.217l-4.788 4.791-4.788-4.791a.705.705 0 10-1 1l4.788 4.791-4.785 4.788a.705.705 0 101 1l4.788-4.791 4.788 4.791a.705.705 0 101-1l-4.788-4.791 4.788-4.791a.705.705 0 00-.5-1.214z"
          transform="translate(331.02 451.03)"
          fill="#f44f5a"
        />
      </g>
    </SvgIcon>
  );
}

export function PriceIncreaseIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '16px',
    height: '7px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 7.273"
      {...rest}
    >
      <path
        d="M14.182 14a.545.545 0 100 1.091h1.956L12 19.229l-3.978-3.978a.546.546 0 00-.771 0L2.16 20.342a.545.545 0 10.771.771l4.705-4.705 3.978 3.978a.546.546 0 00.771 0l4.523-4.523v1.956a.545.545 0 101.091 0v-3.274a.545.545 0 00-.545-.545z"
        transform="translate(-2 -14)"
        fill="#25c460"
      />
    </SvgIcon>
  );
}

export function TransactionFilterIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '16px',
    height: '16px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        d="M7.111 6A1.121 1.121 0 006 7.111v1.373A3.335 3.335 0 007.271 11.1l4.5 3.515v6.714a.667.667 0 001.054.543l3.111-2.222a.667.667 0 00.28-.543v-4.488l4.5-3.515A3.335 3.335 0 0022 8.484V7.111A1.121 1.121 0 0020.889 6zm.222 1.333h13.334v1.151a2 2 0 01-.762 1.57l-4.759 3.713a.667.667 0 00-.257.526v4.475l-1.778 1.27v-5.745a.667.667 0 00-.257-.526L8.1 10.055a2 2 0 01-.762-1.57z"
        transform="translate(-6 -6)"
        fill="#007965"
      />
    </SvgIcon>
  );
}

export function TransactionIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '14px',
    height: '11px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 14 11"
      {...rest}
    >
      <path
        d="M4.5 8A1.508 1.508 0 003 9.5v6.333a1.456 1.456 0 00.02.19.5.5 0 00-.02.143V17.5A1.508 1.508 0 004.5 19h11a1.508 1.508 0 001.5-1.5v-1.333a.5.5 0 00-.02-.144 1.459 1.459 0 00.02-.189V9.5A1.508 1.508 0 0015.5 8h-3.833A2.132 2.132 0 0010 8.839 2.132 2.132 0 008.333 8zm0 1h3.833A1.159 1.159 0 019.5 10.167v6.707a2.891 2.891 0 00-1.658-.54H4.5a.492.492 0 01-.5-.5V9.5a.492.492 0 01.5-.5zm7.167 0H15.5a.492.492 0 01.5.5v6.333a.492.492 0 01-.5.5h-3.343a2.891 2.891 0 00-1.658.54v-6.706A1.159 1.159 0 0111.667 9zm1.667.667a.5.5 0 00-.5.5v.223a1.413 1.413 0 00-1.007 1.792 1.486 1.486 0 001.425.986h.331a.417.417 0 110 .833h-.57a.43.43 0 01-.4-.224.5.5 0 00-.754-.173.514.514 0 00-.122.654 1.425 1.425 0 001.1.729v.18a.5.5 0 101 0v-.192a1.416 1.416 0 001.157-1.562 1.463 1.463 0 00-1.473-1.246h-.343a.417.417 0 01-.417-.417.445.445 0 01.449-.417h.282a.382.382 0 01.4.224.5.5 0 00.751.174.522.522 0 00.125-.658 1.341 1.341 0 00-.928-.7v-.2a.5.5 0 00-.506-.506zM5.5 10a.5.5 0 100 1h2.333a.5.5 0 100-1zm0 2a.5.5 0 100 1h2.333a.5.5 0 100-1zm0 2a.5.5 0 100 1h1a.5.5 0 100-1zM4 17.24a1.469 1.469 0 00.5.093h3.342a1.9 1.9 0 011.44.667H4.5a.492.492 0 01-.5-.5zm12 0v.26a.492.492 0 01-.5.5h-4.783a1.9 1.9 0 011.441-.667H15.5a1.469 1.469 0 00.5-.093z"
        transform="translate(-3 -8)"
        fill="#0e1824"
      />
    </SvgIcon>
  );
}

export function AwarenessManagementIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '17px',
    height: '17px',
    color: '#0e1824',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 17.371 16.17"
      {...rest}
    >
      <path
        d="M18.851 5.3a1.448 1.448 0 00-.538.077l-8.225 2.8-3.911.3A2.367 2.367 0 004 10.834v3.257a2.367 2.367 0 002.178 2.351h.049-.573l.383 3.446a1.776 1.776 0 003.54-.2V16.7l.513.039 8.224 2.8a1.518 1.518 0 001.986-1.414v-3.86a1.713 1.713 0 000-3.177V6.8a1.522 1.522 0 00-1.274-1.48 1.467 1.467 0 00-.175-.02zm-.012 1.281a.2.2 0 01.173.218v11.326a.2.2 0 01-.284.2l-8.3-2.83a.643.643 0 00-.158-.033l-1.287-.1a.643.643 0 00-.692.642v3.685a.489.489 0 01-.974.054l-.448-4.013a.643.643 0 00-.592-.57 1.064 1.064 0 01-.99-1.069v-3.257a1.064 1.064 0 01.99-1.069l3.3-.253v4.023a.643.643 0 101.287 0V9.277L18.729 6.6a.259.259 0 01.11-.019z"
        transform="translate(-4 -5.299)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function PaymentSetupIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '14px',
    height: '14px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 14 14.35"
      {...rest}
    >
      <path
        d="M11 3a4.558 4.558 0 00-4.55 4.55A4.511 4.511 0 007.189 10h-.564a.525.525 0 100 1.05H8.2a.525.525 0 00.2 0h6.974a.525.525 0 100-1.05h-.559A4.528 4.528 0 0011 3zm0 1.05A3.48 3.48 0 0113.471 10h-2.045a.437.437 0 00.012-.105v-.26a1.265 1.265 0 00-.153-2.522H10.8a.394.394 0 110-.788h.46a.415.415 0 01.329.144.437.437 0 10.742-.463 1.278 1.278 0 00-.9-.54v-.26a.438.438 0 10-.875 0v.269a1.266 1.266 0 00.244 2.513h.481a.394.394 0 110 .787h-.621a.661.661 0 01-.441-.161.437.437 0 00-.724.491 1.443 1.443 0 001.064.539v.251a.437.437 0 00.012.105H8.545A3.454 3.454 0 017.5 7.55a3.492 3.492 0 013.5-3.5zm-5.425.35A1.577 1.577 0 004 5.975v7a1.577 1.577 0 001.575 1.575H8.55v1.75H7.325a.525.525 0 100 1.05h7.35a.525.525 0 100-1.05H13.45v-1.75h2.975A1.577 1.577 0 0018 12.975v-7A1.577 1.577 0 0016.425 4.4H15.2a5.242 5.242 0 01.615 1.05h.613a.526.526 0 01.525.525v7a.526.526 0 01-.525.525H5.575a.526.526 0 01-.525-.525v-7a.526.526 0 01.525-.525h.614A5.24 5.24 0 016.8 4.4zM9.6 14.55h2.8v1.75H9.6z"
        transform="translate(-4 -3)"
        fill="#0e1824"
      />
    </SvgIcon>
  );
}

export function AddReferenceIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '16px',
    height: '16px',
    color: '#6552cc',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        data-name="icons8-add (2)"
        d="M12 4a8 8 0 108 8 8.009 8.009 0 00-8-8zm3.4 8.6h-2.8v2.8a.6.6 0 01-1.2 0v-2.8H8.6a.6.6 0 110-1.2h2.8V8.6a.6.6 0 111.2 0v2.8h2.8a.6.6 0 010 1.2z"
        transform="translate(-4 -4)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
export function BackOfficeAddReferenceIcon(props) {
  const { rootStyle, ...rest } = props;

  const rootSx = {
    width: '16px',
    height: '16px',
    color: '#007965',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        data-name="icons8-add (2)"
        d="M12 4a8 8 0 108 8 8.009 8.009 0 00-8-8zm3.4 8.6h-2.8v2.8a.6.6 0 01-1.2 0v-2.8H8.6a.6.6 0 110-1.2h2.8V8.6a.6.6 0 111.2 0v2.8h2.8a.6.6 0 010 1.2z"
        transform="translate(-4 -4)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function PlanStatusIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '14px',
    height: '14px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 14 14"
      {...rest}
    >
      <path
        d="M11 4a7 7 0 107 7 7.008 7.008 0 00-7-7zm0 1.05A5.95 5.95 0 115.05 11 5.942 5.942 0 0111 5.05zm2.615 3.85a.525.525 0 00-.361.159l-3.129 3.129L8.746 10.8a.525.525 0 10-.746.746l1.75 1.75a.525.525 0 00.742 0L14 9.8a.525.525 0 00-.381-.9z"
        transform="translate(-4 -4)"
      />
    </SvgIcon>
  );
}

export function DrugAuditIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '13.974',
    height: '14',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.974 14"
      sx={rootSx}
      {...rest}
    >
      <path
        d="M14.742 4.857a4.105 4.105 0 00-2.912 1.2L6.06 11.83a4.119 4.119 0 005.825 5.825l3.006-3.006a.547.547 0 00.122-.122l2.642-2.642a4.2 4.2 0 00.352-5.424 3.173 3.173 0 00-.352-.4 4.107 4.107 0 00-2.913-1.204zm0 1.086a3.029 3.029 0 012.139 5.168l-2.316 2.316-4.278-4.277L12.6 6.833a3.016 3.016 0 012.142-.89zM9.514 9.922l4.279 4.278-2.681 2.68a3.017 3.017 0 01-4.279 0 3.017 3.017 0 010-4.279z"
        transform="translate(-4.858 -4.857)"
      />
    </SvgIcon>
  );
}

export function SettlementsIcon(props) {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: '14px',
    height: '8px',
    ...rootStyle,
  };
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 14 8.275"
      {...rest}
    >
      <path
        data-name="icons8-double-tick (1)"
        d="M12.953 10.985a.477.477 0 00-.325.152L7.212 16.8l-1.853-1.854a.477.477 0 10-.675.675l1.869 1.869-.585.611-3.154-3.155a.477.477 0 10-.675.675l3.5 3.5a.477.477 0 00.682-.007l.907-.948.955.955a.477.477 0 00.682-.007l7-7.316a.477.477 0 10-.69-.66L8.513 18.1l-.626-.626 5.431-5.674a.477.477 0 00-.365-.811z"
        transform="translate(-2 -10.984)"
        fill="#0e1824"
      />
    </SvgIcon>
  );
}
