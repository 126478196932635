/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useMasters = create((set, get) => ({
  mastersState: {
    activityUnits: [],
    goalTag: [],
    planDurationType: [],
  },
  loading: null,

  getActivityUnits: async () => {
    try {
      const { mastersState } = get();

      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['master-get-activity-unit'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/master/get-activity-unit`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      let resArr = [];

      for (const val of data) {
        let obj = {
          value: val?.id,
          label: val?.unit_name,
        };
        resArr.push(obj);
      }

      if (status?.code === 200) {
        set({
          loading: false,
          mastersState: {
            ...mastersState,
            activityUnits: resArr,
          },
        });
      }

      return data;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getGoalTag: async () => {
    try {
      const { mastersState } = get();

      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['get-mbf-goal-tag'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/master/get-mbf-goal-tag`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      let resArr = [];

      for (const val of data) {
        let obj = {
          value: val?.id,
          name: val?.goal_name,
        };
        resArr.push(obj);
      }

      if (status?.code === 200) {
        set({
          loading: false,
          mastersState: {
            ...mastersState,
            goalTag: resArr,
          },
        });
      }

      return data;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  getPlanDurationType: async (isDropDown) => {
    try {
      const { mastersState } = get();

      set({ loading: true });

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['get-mbf-plan-duration-type'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/master/get-mbf-plan-duration-type`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      let resArr = [];

      for (const val of data) {
        let obj = {};
        if (isDropDown) {
          obj = {
            value: val?.type,
            label: val?.type,
          };
        } else {
          obj = {
            value: val?.type,
            name: val?.type,
          };
        }

        resArr.push(obj);
      }

      if (status?.code === 200) {
        set({
          loading: false,
          mastersState: {
            ...mastersState,
            planDurationType: resArr,
          },
        });
      }

      return data;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
