import { Box, Grid, Stack, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { AddNewTierDrawer } from '@hc/ui/components/backoffice/addNewTierDrawer';
import { Button, EditIcon, Input, Table } from '@hc/ui/atoms';
import React, { useState } from 'react';
import { PaymentSetup_style } from './style';

export default function PaymentSetup(props) {
  const { className = '', ...rest } = props;
  const [drugDrawerOpen, setDrugDrawerOpen] = useState(false);

  const [edit, setEdit] = useState(false);

  const closeDrugMasterDrawer = () => {
    setDrugDrawerOpen(false);
  };

  const handelSave = () => {
    setEdit(!edit);
  };

  const columns = [
    {
      field: 'tier',
      headerName: 'Tier',
      width: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'consultation_fee',
      headerName: 'Consultation fee ',
      width: 220,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'follow_up_fee',
      headerName: 'Follow-up fee',
      width: 200,
      headerAlign: 'right',
      align: 'right',
    },

    {
      field: 'platform_fee',
      headerName: 'Platform fee',
      width: 200,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'No_of_doctors',
      headerName: 'No. of doctors',
      width: 200,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 200,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Box
          sx={{ pl: 1 }}
          // onClick={() => onEdit(params?.row?.id, params?.row?.value)}
        >
          <EditIcon style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      tier: 'Tier 1',
      consultation_fee: '1000',
      follow_up_fee: `1000`,
      platform_fee: `5%`,
      No_of_doctors: 20,
      action: true,
    },

    {
      id: 2,
      tier: 'Tier 2',
      consultation_fee: '1000',
      follow_up_fee: `1000`,
      platform_fee: `5%`,
      No_of_doctors: 20,
      action: true,
    },

    {
      id: 3,
      tier: 'Tier 3',
      consultation_fee: '1000',
      follow_up_fee: `1000`,
      platform_fee: `5%`,
      No_of_doctors: 20,
      action: true,
    },
  ];

  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Stack>
        <Stack flexDirection="row" justifyContent="space-between" mb={1}>
          <Box>
            <Typography sx={PaymentSetup_style.titleSx}>
              Payment tiers
            </Typography>
          </Box>
          <Box>
            <Button
              sx={PaymentSetup_style.addtireBtnSx}
              onClick={() => setDrugDrawerOpen(true)}
            >
              Add new tier
            </Button>
          </Box>
        </Stack>
        <Table
          customTableStyle={PaymentSetup_style.tableCustomSx}
          rows={rows}
          columns={columns}
          rootStyle={{ height: '36vh', width: '100%' }}
          emptyState
          pageSize={5}
          disableColumnMenu
          autoHeight={Object.keys(rows).length >= 0 ? false : true}
        />
      </Stack>
      <Stack sx={PaymentSetup_style.ContainerCard}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={PaymentSetup_style.titleSx}>Tax setup</Typography>
          {!edit && (
            <Stack flexDirection="row" gap={3}>
              <Button sx={PaymentSetup_style.cancleButtonSx}>Cancel</Button>
              <Button sx={PaymentSetup_style.saveButtonSx} onClick={handelSave}>
                Save
              </Button>
            </Stack>
          )}

          {edit && (
            <Box>
              <Button sx={PaymentSetup_style.saveButtonSx} onClick={handelSave}>
                Edit
              </Button>{' '}
            </Box>
          )}
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          <Typography sx={PaymentSetup_style.TurnonFontSx}>
            {' '}
            Turn ON to enable tax
          </Typography>

          <Switch />
          <Typography sx={PaymentSetup_style.disableFontSx}>
            Disabled
          </Typography>
        </Stack>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <Typography sx={PaymentSetup_style.gstFontSx}>
              CGST percentage
            </Typography>
            <Box>
              <Input sx={PaymentSetup_style.InputSx} endAdornment="%" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={PaymentSetup_style.gstFontSx}>
              SGST percentage
            </Typography>
            <Box>
              <Input sx={PaymentSetup_style.InputSx} endAdornment="%" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={PaymentSetup_style.gstFontSx}>Total GST</Typography>
            <Box>
              <Input sx={PaymentSetup_style.totalgstInputSx} />
            </Box>
          </Grid>
        </Grid>
      </Stack>
      <AddNewTierDrawer
        drugDrawerOpen={drugDrawerOpen}
        closeDrugMasterDrawer={closeDrugMasterDrawer}
      />
    </Box>
  );
}

PaymentSetup.propTypes = {
  className: PropTypes.string,
};
