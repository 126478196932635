import { Box } from '@mui/material';
import { useSymptoms } from '@hc/store//backoffice/masters/Symptoms';
import PropTypes from 'prop-types';
import { Button, Drawer, Input, Label } from '@hc/ui/atoms';
import { symptomsDrawer_style } from './style';

function SymptomsDrawer(props) {
  const {
    drawerOpen = false,
    closeMasterDrawer = () => false,
    upsertMaster = () => {},
  } = props;

  const { handleChange, symptoms, symptoms_id } = useSymptoms((state) => ({
    handleChange: state.handleChange,
    symptoms: state.symptoms,
    symptoms_id: state.symptoms_id,
  }));

  return (
    <Box>
      {drawerOpen && (
        <Drawer
          show={drawerOpen}
          onCloseDrawer={closeMasterDrawer}
          anchor="right"
          isCloseIconRequired
          header={`${symptoms_id ? 'Update' : 'Add'} Symptoms`}
          headerStyle={symptomsDrawer_style.drawerHeadSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                buttonStyle={symptomsDrawer_style.saveBtnSx}
                onClick={upsertMaster}
              >
                {symptoms_id ? 'Update' : 'Add'}
              </Button>
              <Button
                buttonStyle={symptomsDrawer_style.cancleBtnSx}
                onClick={closeMasterDrawer}
              >
                Cancel
              </Button>
            </Box>
          }
        >
          <Box sx={symptomsDrawer_style.boxRootSx}>
            <Box sx={symptomsDrawer_style.inputGroupSx}>
              <Label
                rootStyle={symptomsDrawer_style.labelSx}
                htmlFor="Symptoms"
                isRequired
              >
                Symptoms
              </Label>
              <Input
                id="Symptoms"
                textFieldStyle={symptomsDrawer_style.inputFieldSx}
                onChange={(e) => handleChange(e.target.value)}
                fullWidth
                value={symptoms}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

SymptomsDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
  closeMasterDrawer: PropTypes.func,
};

export { SymptomsDrawer };
