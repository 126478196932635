import { useFeedBackMaster } from '@hc/store/backoffice/feeds/feedbackMaster';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  AngryFaceEmoji,
  Button,
  CustomTextArea,
  Drawer,
  HappyFaceEmoji,
  HeartFaceEmoji,
  Input,
  Label,
  NeutralFaceEmoji,
  NotOkEmoji,
} from '../../../atoms';
import { feedbackDrawer_style } from './style';

function FeedBackDrawer(props) {
  const { drawerOpen = false, closeMasterDrawer = () => false } = props;
  const { feeds } = useFeedBackMaster((state) => ({
    feeds: state.feeds,
  }));
  // Emojis 😎😂
  const FeedEmoji = [
    { id: 0, icon: <AngryFaceEmoji />, percentage: 0 },
    { id: 1, icon: <NotOkEmoji />, percentage: 25 },
    { id: 2, icon: <NeutralFaceEmoji />, percentage: 50 },
    { id: 3, icon: <HappyFaceEmoji />, percentage: 75 },
    { id: 4, icon: <HeartFaceEmoji />, percentage: 100 },
  ];
  return (
    <Box>
      {drawerOpen && (
        <Drawer
          show={drawerOpen}
          onCloseDrawer={closeMasterDrawer}
          anchor="right"
          isCloseIconRequired
          header="FeedBack"
          headerStyle={feedbackDrawer_style.drawerHeadSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                buttonStyle={feedbackDrawer_style.saveBtnSx}
                onClick={closeMasterDrawer}
              >
                Close
              </Button>
            </Box>
          }
        >
          <Box sx={feedbackDrawer_style.boxRootSx}>
            <Box sx={feedbackDrawer_style.inputGroupSx}>
              <Label
                rootStyle={feedbackDrawer_style.labelSx}
                htmlFor="feedbackName"
                isRequired
              >
                Name
              </Label>
              <Input
                id="feedbackName"
                textFieldStyle={feedbackDrawer_style.inputFieldSx}
                value={feeds?.name ?? ''}
                fullWidth
                isReadOnly
              />
            </Box>
            <Box sx={feedbackDrawer_style.inputGroupSx}>
              <Typography sx={feedbackDrawer_style.title}>Rating</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {FeedEmoji.map((item, i) => (
                  <Box
                    id={item?.id}
                    key={i}
                    sx={{
                      ...feedbackDrawer_style.emojiContainer,
                      boxShadow: '0 0 3px #FDB408 , inset 0 0 3px #FDB408',
                      background:
                        feeds?.rating === item?.percentage ? '#FDB408' : '',
                    }}
                  >
                    {item.icon}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box sx={feedbackDrawer_style.inputGroupSx}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, py: 2 }}>
                Content
              </Typography>
              <CustomTextArea
                data_testId="content"
                textFieldStyle={feedbackDrawer_style.customtextSx}
                value={feeds?.content ?? ''}
                // disable
              />
            </Box>
            {Array.isArray(feeds?.url) && feeds?.url?.length > 0 ? (
              <Box sx={feedbackDrawer_style.inputGroupSx}>
                <Typography sx={feedbackDrawer_style.title}>
                  FeedBack Image
                </Typography>
                {feeds?.url.map((image, i) => (
                  <img
                    src={image}
                    alt="feeds"
                    key={i}
                    width="300px"
                    style={{ borderRadius: '10px' }}
                  />
                ))}
              </Box>
            ) : null}
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

FeedBackDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
};

export { FeedBackDrawer };
