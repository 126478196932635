/* eslint-disable prefer-const */
import { getDateFormatToString } from '@hc/dayjs';

import { backofficeRoutes } from '@hc/routes';
import { useOrganization } from '@hc/store';
import { DialogModal, ThreeDotsMenuIcon } from '@hc/ui/atoms';
import {
  AddContractDrawer,
  PdfViewer,
  TableSkeletonLoader,
} from '@hc/ui/components';
import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { ContractDetails_style } from './style';

export default function ContractDetails(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();
  const [MOUUrl, setMOUUrl] = useState('');
  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contractDrawerOpen, setcontractDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const open = Boolean(anchorEl);

  const statusColor = {
    Active: '#25C460',
    Expired: '#E4BC62',
  };

  // Store Data

  const {
    getAllContractData,
    viewAndDownloadMOU,
    organizationState,
    clearContractData,
    loading,
  } = useOrganization(
    (state) => ({
      viewAndDownloadMOU: state.viewAndDownloadMOU,
      organizationState: state.organizationState,
      clearContractData: state.clearContractData,
      getAllContractData: state.getAllContractData,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { organization } = organizationState;

  // General Hooks

  const closeContractDrawer = () => {
    setcontractDrawerOpen(false);
  };

  const handleAddClick = () => {
    clearContractData();
    setcontractDrawerOpen(true);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
  };

  const viewClick = async (params) => {
    const url = await viewAndDownloadMOU(params);
    setMOUUrl(url);
    setIsModalOpen(true);
    handleOnClose();
  };

  const downLoadClick = async (params) => {
    const url = await viewAndDownloadMOU(params);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click'));
    handleOnClose();
    return url;
  };

  // searchValue IS THE PARAM

  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    queryClient.invalidateQueries({
      queryKey: ['contracts-get'],
    });
    await getAllContractData(searchValue, rowsPerPage, offset);
  };

  const columns = [
    {
      field: 'contract_ID',
      headerName: 'Contract ID',
      width: 200,
    },
    {
      field: 'contract_Period',
      headerName: 'Contract Period',
      width: 150,
    },
    {
      field: 'purchased_On',
      headerName: 'Purchased On',
      width: 200,
      valueGetter: (params) =>
        getDateFormatToString(params.row.purchased_On, 'lll'),
    },
    {
      field: 'members',
      headerName: 'Members',
      width: 150,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
    },

    {
      field: 'is_active',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <Typography
          variant="body1"
          sx={{
            color: statusColor[params.row.is_active],
            // backgroundColor: statusColor[params.row.statusId],
          }}
        >
          {params.row.is_active}
        </Typography>
      ),
    },

    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1.8, cursor: 'pointer' }}>
          <IconButton
            id="more"
            disableRipple
            onClick={(e) => handleClick(e, params?.row)}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'basic-button' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <ThreeDotsMenuIcon />
          </IconButton>
          <Menu
            id="basic-button"
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleOnClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              id="ViewMOU"
              key="view"
              onClick={() => viewClick(params?.row)}
              onClose={handleOnClose}
            >
              View MOU
            </MenuItem>

            <MenuItem
              id="downloadMOU"
              onClick={() => downLoadClick(params?.row)}
              key="download"
              onClose={handleOnClose}
            >
              Download MOU
            </MenuItem>
          </Menu>
        </Box>
      ),
    },
  ];

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['contracts-get'],
    });
    await getAllContractData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['contracts-get'],
    });

    await getAllContractData(search, rowsPerPage, offset);
  };

  const initialData = async () => {
    await getAllContractData(search, rowsPerPage, offset);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (organization?.name === '') {
      navigate(backofficeRoutes?.organization);
    } else {
      queryClient.invalidateQueries({
        queryKey: ['contracts-get'],
      });
      initialData();
    }
  }, []);

  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{
        height: '90vh',
        overflowY: 'scroll',
        backgroundColor: '#F8F8F9',
      }}
    >
      {!contractDrawerOpen && (
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MasterTopContainer
                addMasterStyle={ContractDetails_style.addMasterSx}
                handleSearch={handleSearch}
                handleAddClick={handleAddClick}
                title="Contract Details"
                count={organizationState?.contractList?.length ?? '0'}
                showSearchField
                searchFieldPlaceholder="Search by Name, Phone & Email"
                showAddButton
                addButtonName="Add New Contract"
                isBackButton
              />
            </Grid>

            <Grid item xs={12}>
              <Stack sx={ContractDetails_style.topbarCardSx}>
                <Typography sx={ContractDetails_style.topbarheadingSx}>
                  Organization Details:
                </Typography>
                <Typography sx={ContractDetails_style.topbarTitleSx}>
                  {organization?.name ?? ''}
                </Typography>
                <Typography sx={ContractDetails_style.descSX}>
                  {organization?.address ?? ''}
                </Typography>
                <Typography sx={ContractDetails_style.descSX}>
                  {`${organization?.mobileNumber?.mobileCode} ${organization?.mobileNumber?.mobile} , ${organization?.email}`}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              {loading ? (
                <Box>
                  <TableSkeletonLoader />
                </Box>
              ) : (
                <Box>
                  <MasterTable
                    columns={columns}
                    data={organizationState?.contractList ?? []}
                    customTableStyle={ContractDetails_style.dataTableCustomSx}
                    hideFooter
                    rootStyle={{ height: '68vh' }}
                  />
                </Box>
              )}
            </Grid>
            <Stack
              flexDirection="row"
              justifyContent="end"
              sx={{ width: '100%' }}
            >
              <Pagination
                totalCount={organizationState?.contractList?.length}
                onLimitChange={onLimitChange}
                onOffsetchange={onOffsetchange}
                offset={offset}
                rowsPerPage={rowsPerPage}
              />
            </Stack>
          </Grid>

          {/* Add Plan Template Drawer */}
        </Box>
      )}
      {contractDrawerOpen && (
        <Box>
          <AddContractDrawer closeContractDrawer={closeContractDrawer} />
        </Box>
      )}
      <DialogModal
        isDialogOpened={isModalOpen}
        fullWidth
        handleCloseDialog={handleClose}
        title="MOU file"
        maxModalWidth="lg"
        content={<PdfViewer url={MOUUrl} />}
      />
    </Box>
  );
}

ContractDetails.propTypes = {
  className: PropTypes.string,
};
