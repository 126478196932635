export const SearchSelect_style = {
  autoListSx: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'border.main',
      borderRadius: '8px',
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: '10px !important',
      paddingBottom: '10px !important',
      paddingLeft: '14px !important',
      paddingRight: '14px !important',
      fontSize: '14px',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
    '& .MuiFormHelperText-root': {
      mx: 0,
    },
  },
  expertAutoList: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'border.main',
      borderRadius: '8px',
    },
    '& .MuiOutlinedInput-root': {
      paddingTop: '10px !important',
      paddingBottom: '10px !important',
      paddingLeft: '14px !important',
      paddingRight: '14px !important',
      fontSize: '14px',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'expert.main',
      },
    },
    '& .MuiFormHelperText-root': {
      mx: 0,
    },
  },
  optionListSx: {
    border: '1.5px solid',
    borderColor: 'border.main',
    boxShadow: '0px -5px 10px #0000000A',
    borderRadius: '8px',
    mt: 1,
  },
  circularSx: {
    color: '#239179!important',
  },
};
