import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructDrugData = (data) => {
  const allergiesTableData = data.map((val) => ({
    value: val,
    id: val?.id ?? 0,
    health_problem: val?.health_problem ?? '',
    status: val?.is_active ?? false,
    updated_at: val?.updated_at ?? '',
    created_at: val?.created_at ?? '',
  }));
  return allergiesTableData;
};

export const useHealthProblems = create((set, get) => ({
  healthProblemData: [],
  healthProblemCount: 0,
  health_problem_id: '',
  health_problem: '',
  loading: false,
  error: {
    health_problem: '',
  },

  // ------------- STORE METHODS ------------------
  getAllHealthProblems: async (search = '', limit = 10, offset = 0) => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getAllHealthProblems', search, offset],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/backofficeMaster/get-all-health-problem`,
            {
              search,
              limit,
              offset,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        set(() => ({
          healthProblemData:
            Array.isArray(data?.data?.health_problem_data) &&
            data?.data?.health_problem_data?.length > 0
              ? constructDrugData(data?.data?.health_problem_data)
              : [],
          healthProblemCount: data?.data?.health_problem_count,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!'
      );
    }
  },

  healthProblemsStatusUpdate: async (payload) => {
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/backofficeMaster/health-problem-status-update`,
        payload,
        true
      );
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'health problem Status Updated Successfully'
        );
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  addEditHealthProblems: async () => {
    const { health_problem, health_problem_id: id } = get();
    const type = id ? 'Update' : 'Add';
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/backofficeMaster/health-problem-upsert`,
        { id: id || '', health_problem },
        true
      );
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? `health problem Data ${type} Successfully`
        );
      }
      set({ loading: false });
      return data;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  handleChange: (value) => {
    set({ health_problem: value });
  },
  isIamValidToSave: () => {
    const { health_problem, error } = get();
    if (!health_problem) {
      error.health_problem = 'Please enter your health problem name';
    } else {
      error.health_problem = '';
      return true;
    }
  },

  initialEditHealthProblems: (value) => {
    set({
      health_problem: value.health_problem ?? '',
      health_problem_id: value.id ?? '',
    });
  },

  clearHealthProblems: () => {
    set({
      health_problem: '',
      health_problem_id: '',
    });
  },
}));
