import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { checkBox_style } from './style';

export const BpIcon = styled('span')(({ checkStyle }) => ({
  borderRadius: '50px',
  width: 16,
  height: 16,
  boxShadow: BpIcon
    ? 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)'
    : 'none',
  backgroundColor: '#f5f8fa',
  '.Mui-focusVisible &': {
    outline: '1px auto rgba(19,124,189,.6)',
    outlineOffset: 0,
  },
  ...checkStyle,
}));

export const BpCheckedIcon = styled(BpIcon)(({ checkSecondStyle }) => ({
  backgroundColor: '#007965',
  backgroundImage: '#007965',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    boxShadow: 'none',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#007965',
  },
  ...checkSecondStyle,
}));

export function CheckBox(props) {
  const {
    disableRipple = true,
    icon,
    checkStyle = {},
    checkSecondStyle = {},
    checkedIcon,
    checked = false,
    disabled = false,
    value,
    className = '',
    id,
    onChange = () => {},
    ...rest
  } = props;

  return (
    <Box sx={checkBox_style.rootSx} className={`${className}`} {...rest}>
      <Checkbox
        id={id}
        disabled={disabled}
        checked={checked}
        disableRipple={disableRipple}
        sx={{ ...checkBox_style.checkSx, ...checkStyle }}
        onChange={onChange}
        value={value}
        icon={<BpIcon checkStyle={checkStyle} />}
        checkedIcon={<BpCheckedIcon checkSecondStyle={checkSecondStyle} />}
      />
    </Box>
  );
}

CheckBox.propTypes = {
  checkSecondStyle: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.any,
  disableRipple: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.node,
  checked: PropTypes.bool,
  checkedIcon: PropTypes.node,
  checkStyle: PropTypes.object,
};
