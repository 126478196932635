import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructDrugData = (data) => {
  const allergiesTableData = data.map((val) => ({
    value: val,
    id: val.id,
    content: val?.bug_form_answer.content ?? '',
    name: val?.bug_form_answer.name ?? '',
    email: val?.bug_form_answer.email ?? '',
    updated_at: val?.updated_at ?? '',
    created_at: val?.created_at ?? '',
    module: val.module ?? '',
    status: val.master_bug_status.id,
    statusValue: val.master_bug_status.status,
  }));
  return allergiesTableData;
};

export const useBugReportMaster = create((set, get) => ({
  bugReportData: [],
  bugReportCount: 0,
  bug: {},
  id: '',
  status: 0,
  loading: false,

  // ------------- STORE METHODS ------------------
  getbugList: async (search = '', limit = 10, offset = 0) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getbugList', search, offset],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/user-report-bugs/get?user_id=${tokenData.id}&search=${search}&limit=${limit}&offset=${offset}`,
            {},
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        set(() => ({
          bugReportData:
            Array.isArray(data?.data?.bug_data) &&
            data?.data?.bug_data?.length > 0
              ? constructDrugData(data?.data?.bug_data)
              : [],
          bugReportCount: data?.data?.count,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!'
      );
    }
  },

  initialBugs: (id) => {
    const { bugReportData } = get();
    const [data] = bugReportData.filter((val) => val.id === id);
    set({
      bug: data.value.bug_form_answer,
      status: data.value.master_bug_status.id,
      id: data.value.id,
    });
  },

  updateBugStatus: async () => {
    const { status, id } = get();
    try {
      set({ loading: true });
      const { data } = await httpRequest(
        'post',
        `${envConfig.api_url}/backoffice/user-report-bug/status/update`,
        { bug_id: id, status },
        true
      );
      if (data?.status?.code === 200) {
        toast.success('status updates succesfully');
      }
      set(() => ({ loading: false }));
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!'
      );
    }
  },
  handleChange: (value) => {
    set({ status: value });
  },

  isIamValidToSave: () => {
    const { goal_name, error } = get();
    if (!goal_name) {
      error.goal_name = 'Please enter your Goal Category name';
    } else {
      error.goal_name = '';
      return true;
    }
  },

  clearGoalCategory: () => {
    set({
      goal_name: '',
      goal_id: '',
    });
  },
}));
