export const customRadioButton_style = {
  activeSx: {
    opacity: 1,
    color: 'orangeTints.600',
    fontSize: '12px',
  },
  nameActiveSx: {
    fontWeight: 500,
    color: 'orangeTints.600',
  },
  nameInactiveSx: {
    fontWeight: 500,
  },
  radioSx: {
    color: 'text.secondary',
    fontSize: '12px',
  },
  avataractiveSx: {
    fontSize: '12px',
    width: '40px',
    height: '40px',
    backgroundColor: 'orangeTints.50',
    border: '2px solid',
    borderColor: 'orangeTints.600',
    color: 'orangeTints.600',
    borderRadius: '50%',
  },
  avatarinactiveSx: {
    width: '40px',
    height: '40px',
    backgroundColor: 'orangeTints.50',
    border: '2px solid',
    borderColor: 'orangeTints.50',
    borderRadius: '50%',
    fontSize: '12px',
    color: 'text.secondary',
  },
};
