import { Avatar, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { profileCardCard_style } from './style';

export function ProfileCard(props) {
  const {
    name = '',
    src = '',
    mobileNumber = '',
    emailId = '',
    gender,
    age,
    profileName = '',
    expertCategory = '',
    rootStyle = {},
    className = '',
    ...rest
  } = props;

  return (
    <Box className={`${className}`} {...rest}>
      <Box sx={{ ...profileCardCard_style.rootSx, ...rootStyle }}>
        <Box sx={{ position: 'relative' }}>
          {/* <img src={HalfRound} alt="" style={halfRoundSx} /> */}
          <Box sx={{ p: 1, pb: '8px!important' }}>
            <Box sx={profileCardCard_style.appointmentCardSx}>
              <Box sx={profileCardCard_style.linkSx}>
                <Box sx={profileCardCard_style.doctornameSx}>
                  <Avatar
                    sx={profileCardCard_style.profileSx}
                    variant="rounded"
                    src={src}
                  >
                    {profileName}
                  </Avatar>
                  <Box sx={profileCardCard_style.relationSX}>
                    <Box
                      sx={{ display: 'flex', gap: '1', alignItems: 'center' }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: '600' }}>
                        {name}{' '}
                      </Typography>
                      {mobileNumber && (
                        <Typography
                          variant="body1"
                          sx={{ color: 'text.hint', fontSize: '12px', ml: 1 }}
                        >
                          {`(${mobileNumber})`}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      sx={{ display: 'flex', gap: '1', alignItems: 'center' }}
                    >
                      <Typography sx={{ color: 'text.hint', fontSize: '12px' }}>
                        {emailId}
                      </Typography>
                      {emailId && gender && (
                        <span
                          style={{
                            color: '#5C6266',
                            display: 'flex',
                            gap: '1',
                            alignItems: 'center',
                          }}
                        >
                          &nbsp;
                          <RiCheckboxBlankCircleFill
                            style={{ fontSize: '6px', ml: 1, mr: 0.5 }}
                          />
                          &nbsp;
                        </span>
                      )}
                      {expertCategory && (
                        <>
                          <span
                            style={{
                              color: '#5C6266',
                              display: 'flex',
                              gap: '1',
                              alignItems: 'center',
                            }}
                          >
                            &nbsp;
                            <RiCheckboxBlankCircleFill
                              style={{ fontSize: '6px', ml: 1, mr: 0.5 }}
                            />
                            &nbsp;
                          </span>

                          <Typography
                            sx={{ color: 'text.hint', fontSize: '12px' }}
                          >
                            {expertCategory}
                          </Typography>
                        </>
                      )}

                      {gender && (
                        <Typography
                          sx={{ color: 'text.hint', fontSize: '12px' }}
                        >
                          {age ? `${gender} / ${age}yrs` : `${gender}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
ProfileCard.propTypes = {
  name: PropTypes.string,
  mobileNumber: PropTypes.string,
  emailId: PropTypes.string,
  gender: PropTypes.string,
  profileName: PropTypes.string,
  expertCategory: PropTypes.string,
  age: PropTypes.string,
  className: PropTypes.object,
};
