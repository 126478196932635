/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const UseOrganizationData = create((set) => ({
  OrganizationData: [],
  getOrganizationData: async () => {
    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['OrganizationData'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/master/get-organization`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (Array.isArray(data) && data?.length > 0) {
        let unitDataCopy = [];
        const selection = [
          {
            value: "All",
            name: 'All Users',
            label: 'All Users',
          },
          {
            value: "user",
            name: 'Non-organization users',
            label: 'Non-organization users',
          },
        ];
        for (const object of selection) {
          unitDataCopy.push(object);
        }
        for(const obj of data){
          const object = {
            value: obj?.id ?? '',
            name: obj?.organization_name ?? '',
            label: obj?.organization_name ?? '',
          }
          unitDataCopy.push(object);
        }
        // unitDataCopy = data.map((val) => {
        //   return {
        //     value: val?.id ?? '',
        //     name: val?.organization_name ?? '',
        //     label: val?.organization_name ?? '',
        //   };
        // });

        set({
          OrganizationData: unitDataCopy,
        });
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
