import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const UsePlanStatusMasterData = create((set) => ({
  PlanStatusData: [],

  getPlanStatusData: async () => {
    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['PlanStatusData'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/master/get-plan-status`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });
      const defaultAry = { value: 0, name: 'All', label: 'All' };
      if (Array.isArray(data) && data?.length > 0) {
        const unitDataCopy = data.map((val) => {
          return {
            value: val?.id ?? '',
            name: val?.plan_status_name ?? '',
            label: val?.plan_status_name ?? '',
          };
        });

        set({
          PlanStatusData: [defaultAry, ...unitDataCopy],
        });
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
