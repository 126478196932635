import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const useActivityTimeMaster = create((set) => ({
  activityTimeData: [],
  getActivityTimeData: async () => {
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['ActivityTimeData'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/master/get-activity-time`,
            {},
            true
          );
          return data;
        },
        staleTime: Infinity,
      });

      if (Array.isArray(data) && data?.length > 0) {
        const unitDataCopy = data.map((val) => {
          return { value: val?.id ?? '', name: val?.time ?? '' };
        });
        set({
          loading: false,
          activityTimeData: unitDataCopy,
        });
      }
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
}));
