import { Box, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { searchField_style } from './style';

export function SearchField(props) {
  const {
    id = '',
    setOnSearch = () => {},
    onSearch = '',
    startAdornment,
    endAdornment,
    searchFieldStyle = {},
    searchInputStyle = {},
    placeholder = '',
  } = props;
  return (
    <Box className={searchField_style.searchBoxSx}>
      {/* Searchfield */}
      <TextField
        id={id}
        placeholder={placeholder}
        sx={{ ...searchField_style.searchFieldSx, ...searchFieldStyle }}
        variant="outlined"
        onChange={(e) => setOnSearch(e.target.value)}
        value={onSearch}
        fullWidth
        InputProps={{
          ...(startAdornment && {
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  ...searchField_style.searchInputSx,
                  ...searchInputStyle,
                }}
              >
                {startAdornment}
              </InputAdornment>
            ),
          }),

          ...(endAdornment && {
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ ...searchField_style.searchInputSx, ...searchInputStyle }}
              >
                {endAdornment}
              </InputAdornment>
            ),
          }),
        }}
      />
    </Box>
  );
}
SearchField.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.string,
  startAdornment: PropTypes.bool,
  endAdornment: PropTypes.bool,
  searchFieldStyle: PropTypes.object,
  setOnSearch: PropTypes.func,
  searchInputStyle: PropTypes.object,
};
