export const allergiesDrawer_style = {
  inputFieldSx: {
    marginTop: '4px',
  },
  drawerHeadSx: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#101010',
    textTransform: 'capitalize',
  },
  boxRootSx: {
    display: 'grid',
    gap: 2,
    pt: 1,
    width: '350px',
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },

  labelSx: {
    color: 'text.label',
  },
  saveBtnSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleBtnSx: {
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
};
