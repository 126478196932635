import { TickIcon } from '@atoms/icons';
import { SearchField, TransactionFilterIcon } from '@hc/ui/atoms';
import { FiltersDrawer } from '@hc/ui/components/backoffice/filtersDrawer';
import { FilterTab } from '@hc/ui/components/backoffice/filterTab';
import { TransactionsCard } from '@hc/ui/components/backoffice/transactionsCard';
import { TransactionsTable } from '@hc/ui/components/backoffice/transactionsTable';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BiChevronDown, BiSearch } from 'react-icons/bi';
import { TransactionsPages_style } from './style';

function createData(
  transaction_iD,
  date_time,
  Patient,
  Doctor,
  appointment_type,
  mode_of_payment,
  total_earnings,
  settelment_amount,
  platform_fee,
  razorpay_charges,
  tax_charges,
  transaction_status,
  settelment_status,
) {
  return {
    transaction_iD,
    date_time,
    Patient,
    Doctor,
    appointment_type,
    mode_of_payment,
    total_earnings,
    settelment_amount,
    platform_fee,
    razorpay_charges,
    tax_charges,
    transaction_status,
    settelment_status,
  };
}

export default function TransactionsPages(props) {
  const { className = '', ...rest } = props;

  const [search, setSearch] = useState('');
  const [drugDrawerOpen, setDrugDrawerOpen] = useState(false);

  const handleSearchFnc = (newData) => {
    setSearch(newData);
  };
  const closeDrugMasterDrawer = () => {
    setDrugDrawerOpen(false);
  };

  const selection = [
    {
      value: 'overall',
      label: 'Overall',
    },
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: 'this_Week',
      label: 'This Week',
    },
    {
      value: 'this_month',
      label: 'This Month',
    },
  ];

  const CardData = [
    {
      amount: '₹50,000',
      percentage: '+20%',
      description: 'Total earnings',
      color: '#007965',
    },
    {
      amount: '₹35,000',
      percentage: '+15%',
      description: 'Total Settlement amount',
      color: '#007965',
    },
    {
      amount: '₹10,000',
      percentage: '-15%',
      description: 'Total platform fee',
      color: '#007965',
    },
    {
      amount: '₹5000',
      percentage: '',
      description: 'Total earnings',
      color: '#FACC15',
    },
  ];
  const headCells = [
    {
      id: 'transaction_iD',
      numeric: false,
      disablePadding: true,
      label: 'Transaction ID',
      type: 'Normal',
    },
    {
      id: 'date_time',
      numeric: false,
      disablePadding: false,
      label: 'Date & Time',
      type: 'Normal',
    },
    {
      id: 'Patient',
      numeric: false,
      disablePadding: false,
      label: 'Patient',
      type: 'Normal',
    },
    {
      id: 'Doctor',
      numeric: false,
      disablePadding: false,
      label: 'Doctor',
      type: 'Normal',
    },

    {
      id: 'appointment_type',
      numeric: false,
      disablePadding: false,
      label: 'Appointment type',
      type: 'Normal',
    },
    {
      id: 'mode_of_payment',
      numeric: false,
      disablePadding: false,
      label: 'Mode of payment',
      type: 'Normal',
    },
    {
      id: 'total_earnings',
      numeric: true,
      disablePadding: false,
      label: 'Total earnings',
      type: 'HighLightedCell',
    },
    {
      id: 'settelment_amount',
      numeric: true,
      disablePadding: false,
      label: 'settelment amount',
      type: 'BoldCell',
    },
    {
      id: 'platform_fee',
      numeric: true,
      disablePadding: false,
      label: 'Platform fee',
      type: 'BoldCell',
    },
    {
      id: 'razorpay_charges',
      numeric: true,
      disablePadding: false,
      label: 'Razorpay charges',
      type: 'BoldCell',
    },
    {
      id: 'tax_charges',
      numeric: true,
      disablePadding: false,
      label: 'Tax charges',
      type: 'BoldCell',
    },
    {
      id: 'transaction_status',
      numeric: true,
      disablePadding: false,
      label: 'Transaction Status',
      type: 'StickyCell1',
    },
    {
      id: 'settelment_status',
      numeric: true,
      disablePadding: false,
      label: 'Settlement Status',
      type: 'StickyCell0',
    },
  ];

  const rows = [
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Refund',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'NA',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Refund',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Na',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
    createData(
      'Tx0002100345',
      '19/06/2023, 04:15 PM',
      `Roy Brewer`,
      `Dr. Varaprasad`,
      'app',
      'Credit card',
      400,
      800,
      500,
      400,
      100,
      'Success',
      'Failed',
    ),
  ];

  const filterList = ['All', 'Today', 'This week', 'This month'];

  const [select, setSelect] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSelect(newValue);
  };

  return (
    <Box
      className={`${className}`}
      {...rest}
      p={3}
      sx={{
        overflow: 'scroll',
        backgroundColor: '#F8F8F9',
        height: '92vh',
      }}
      // {height: '100vh'},
    >
      <Box>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {' '}
          <Typography sx={TransactionsPages_style.statsFont}>Stats</Typography>
          <Select
            value={select}
            MenuProps={{
              PaperProps: {
                sx: { ...TransactionsPages_style.selectPaperPropsSx },
              },
            }}
            IconComponent={BiChevronDown}
            sx={TransactionsPages_style.selectMenuSx}
          >
            {selection?.map((val, i) => (
              <MenuItem
                key={i}
                onClick={() => handleChange(val)}
                value={val?.value}
                sx={TransactionsPages_style.menuListSx}
              >
                {select === val?.value ? <TickIcon /> : null}
                &nbsp; {val?.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Grid container spacing={2}>
          {CardData.map((data, i) => (
            <Grid item xs={3} key={i} height="100%">
              {' '}
              <TransactionsCard
                percentage={data?.percentage?.slice(1)}
                description={data.description}
                amount={data.amount}
                positive={
                  Number(data?.percentage?.slice(0, -1)) > 0 ? true : false
                }
                showPercentage={data.percentage ? true : false}
                colorCode={data?.color ?? '#007965'}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={4}>
        <Typography sx={TransactionsPages_style.statsFont}>
          Transactions
        </Typography>
        <Stack flexDirection="row" justifyContent="space-between" mt={2}>
          <Box
            sx={{
              backgroundColor: '#EDEFF2',
              borderRadius: '10px',
            }}
          >
            <FilterTab
              filterList={filterList}
              handleChange={handleChange}
              value={select}
              toggleContainerSx={{
                justifyContent: '',
                width: '488px',
              }}
              filterTabRootStyle={TransactionsPages_style.filterTabRootSx}
            />{' '}
          </Box>

          <Stack flexDirection="row" alignItems="center" gap={2}>
            <SearchField
              searchFieldStyle={TransactionsPages_style.searchFieldSx}
              placeholder="Search Patient, Doctor"
              onSearch={search}
              setOnSearch={handleSearchFnc}
              startAdornment={
                <IconButton disableRipple sx={{ mt: 0.5, mr: -2.5 }}>
                  <BiSearch />
                </IconButton>
              }
            />

            <Box
              sx={TransactionsPages_style.filterContainerSx}
              onClick={() => setDrugDrawerOpen(true)}
            >
              <TransactionFilterIcon />
            </Box>
          </Stack>
        </Stack>
      </Box>

      <Box mt={3}>
        <TransactionsTable headCells={headCells} rows={rows} />
      </Box>
      <FiltersDrawer
        drugDrawerOpen={drugDrawerOpen}
        closeDrugMasterDrawer={closeDrugMasterDrawer}
      />
    </Box>
  );
}

TransactionsPages.propTypes = {
  className: PropTypes.string,
};
