/* eslint-disable no-sparse-arrays */
// import { envConfig } from '@hc/config';
import { envConfig } from '@hc/config';
import { endOfFnc, startOfFnc, subtractFnc } from '@hc/dayjs';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructDrugAuditTableData = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val) => {
      arr.push({
        value: val,
        id: val?.id ?? 0,
        appointment_type: val?.appointment_type ?? '',
        patient_details: val?.patient_details ?? '',
        doctor_name: val?.doctor_name
          ? val?.doctor_name
          : val?.doctor_first_name
          ? val?.doctor_first_name
          : '',
        chief_doctor_name: val?.chief_doctor_name ?? '',
        consultation_time: val?.consultation_time ?? '',
        status_name: val?.status_name ?? '',
        rx_audit_status_id: val?.rx_audit_status_id ?? 0,
      });
    });
    return arr;
  }
};

export const constructDrugAuditStatsCardData = (data) => {
  return [
    {
      count: data?.all,
      percentage: '',
      description: 'Total no. of Prescription',
      color: '#007965',
    },
    {
      count: data?.open,
      percentage: '',
      description: 'Open Prescription',
      color: '#FACC15',
    },
    {
      count: data?.approved,
      percentage: '',
      description: 'Prescription Approved',
      color: '#4CAF50',
    },
    {
      count: data?.raisedIssue,
      percentage: '',
      description: 'Prescription Discrepancy',
      color: '#F44F5A',
    },
  ];
};

export const constructStatusTrackData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((val) => {
      return {
        // light-yellow : #ffffe6
        // light-green : #E8FCF0
        // light-red : #FEEAEA
        backgroundColor:
          val?.status_id === 1
            ? '#ffffe6'
            : val?.status_id === 2
            ? '#E8FCF0'
            : '#FEEAEA',
        // yellow : #FACC15
        // green : #4CAF50
        // red : #F44F5A
        color:
          val?.status_id === 1
            ? '#FACC15'
            : val?.status_id === 2
            ? '#4CAF50'
            : '#F44F5A',
        title: val?.status_name ?? '',
        subTitle: val?.doctor_name ?? '',
        dateTime: val?.at_time ?? '',
        dateTimeFormat: 'DD MMM YYYY, hh:mm A',
      };
    });
    return arr;
  }
};

export const UseDrugAudit = create((set, get) => ({
  drugAuditState: {
    drugAuditStatsCardData: [],
    drugAuditListData: [],
    drugAuditStatusTrackData: [],
    drugAuditCount: 0,
    drugAuditType: 'All',
    tabIndex: 0,
    fromDate: '',
    toDate: '',
    search: '',
  },
  loading: null,
  error: null,

  isIamValidToSave: () => {
    const { activity } = get();
    const activityCopy = JSON.parse(JSON.stringify(activity));
    let isValid = true;
    const error = activityCopy?.error;
    // checking drugName
    if (!activity?.activity_name) {
      isValid = false;
      error.activity_name = 'Please enter your activity name';
    } else {
      error.activity_name = '';
    }
    // checking time
    if (!activity?.category_id) {
      isValid = false;
      error.category_id = 'Please select category type';
    } else {
      error.category_id = '';
    }
    // checking when
    if (!activity?.goal_id) {
      isValid = false;
      error.goal_id = 'Please select goal type';
    } else {
      error.goal_id = '';
    }

    if (!activity?.activity_time) {
      isValid = false;
      error.activity_time = 'Please select activity time';
    } else {
      error.activity_time = '';
    }
    set({
      activity: {
        ...activity,
        error,
      },
    });

    return isValid;
  },

  // GET ALL DRUG AUDIT DATA
  getAllDrugAuditData: async (search = '', limit = 10, offset = 0) => {
    const { drugAuditState } = get();
    const { drugAuditType, fromDate, toDate } = drugAuditState;
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    const fromDateConst =
      typeof fromDate === 'object' ? startOfFnc(fromDate, 'day') : '';
    const toDateConst =
      typeof toDate === 'object' ? endOfFnc(toDate, 'day') : '';

    try {
      set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['drugAuditData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/drug-audits/get?user_id=${tokenData?.id}&search=${search}&limit=${limit}&offset=${offset}&app_type_filter=${drugAuditType}&app_start_date=${fromDateConst}&app_end_date=${toDateConst}`,
            {},
            true,
          );

          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          loading: false,
          drugAuditState: {
            ...drugAuditState,
            drugAuditListData:
              Array.isArray(data?.results) && data?.results?.length > 0
                ? constructDrugAuditTableData(data?.results)
                : [],
            drugAuditStatsCardData: constructDrugAuditStatsCardData({
              all: data?.drug_audit_count ?? 0,
              open: data?.open_drug_audit_count ?? 0,
              approved: data?.approved_drug_audit_count ?? 0,
              raisedIssue: data?.issue_drug_audit_count ?? 0,
            }),
            drugAuditCount: data?.drug_audit_count ?? 0,
          },
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  // GET DRUG AUDIT STATUS TRACK DATA
  getDrugAuditStatusTrackData: async (id) => {
    const { drugAuditState } = get();
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);

    try {
      // set({ loading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['drugAuditStatusTrackData'],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/drug-audits/track-status?user_id=${tokenData?.id}&rx_audit_id=${id}`,
            {},
            true,
          );

          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          // loading: false,
          drugAuditState: {
            ...drugAuditState,
            drugAuditStatusTrackData:
              Array.isArray(data) && data?.length > 0
                ? constructStatusTrackData(data)
                : [],
          },
        }));
      }
      // set(() => ({ loading: false }));
    } catch (error) {
      // set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....',
      );
    }
  },

  handleChange: (key, value) => {
    const { drugAuditState } = get();

    set(() => ({
      drugAuditState: {
        ...drugAuditState,
        [key]: value,
      },
    }));
  },

  updateFromAndToDateAndTab: (fromDate, toDate, tab) => {
    const { drugAuditState } = get();

    set(() => ({
      drugAuditState: {
        ...drugAuditState,
        fromDate,
        toDate,
        tabIndex: tab,
      },
    }));
  },
  updateTabIndex: (fromDatee, toDatee) => {
    const { drugAuditState } = get();
    if (
      startOfFnc(new Date(), 'day') === startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(), 'day') === endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        drugAuditState: {
          ...drugAuditState,
          tabIndex: 0,
        },
      }));
    } else if (
      startOfFnc(new Date(), 'week') === startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(), 'week') === endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        drugAuditState: {
          ...drugAuditState,
          tabIndex: 1,
        },
      }));
    } else if (
      startOfFnc(new Date(), 'month') === startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(), 'month') === endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        drugAuditState: {
          ...drugAuditState,
          tabIndex: 2,
        },
      }));
    } else if (
      startOfFnc(new Date(subtractFnc(new Date(), 1, 'week')), 'week') ===
        startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(subtractFnc(new Date(), 1, 'week')), 'week') ===
        endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        drugAuditState: {
          ...drugAuditState,
          tabIndex: 3,
        },
      }));
    } else if (
      startOfFnc(new Date(subtractFnc(new Date(), 1, 'month')), 'month') ===
        startOfFnc(fromDatee, 'day') &&
      endOfFnc(new Date(subtractFnc(new Date(), 1, 'month')), 'month') ===
        endOfFnc(toDatee, 'day')
    ) {
      set(() => ({
        drugAuditState: {
          ...drugAuditState,
          tabIndex: 4,
        },
      }));
    } else {
      set(() => ({
        drugAuditState: {
          ...drugAuditState,
          tabIndex: 5,
        },
      }));
    }
  },

  clearFilter: () => {
    const { drugAuditState } = get();
    set(() => ({
      drugAuditState: {
        ...drugAuditState,
        drugAuditType: 'All',
        fromDate: '',
        toDate: '',
      },
    }));
  },
}));
