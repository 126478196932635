import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { httpRequest, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import create from 'zustand';

const typeConverter = (ary) => {
  let l = '';
  ary.map((val, i, a) => {
    if (a.length > 1 && a.length - 1 === i) {
      l = `${l.slice(0, -1)}&${val} `;
      return;
    }
    l += `${val},`;
  });
  return l.slice(0, -1);
};
export const constructPlanData = (data) => {
  if (Array.isArray(data) && data?.length > 0) {
    const arr = data.map((val) => {
      return {
        value: val,
        plan_name: val?.plan_name ?? '',
        id: val?.id ?? 0,
        category_data: typeConverter(val?.category_data) ?? '',
        created_at: val?.created_at ?? '',
        updated_at: val?.updated_at ?? '',
        is_active: val?.is_active ?? false,
      };
    });

    return arr;
  }
};

export const constructPlanDropDown = (data) => {
  const arr = [];
  if (Array.isArray(data) && data?.length > 0) {
    data.map((val) => {
      const obj = {
        value: val,
        label: val?.activity_name,
        activity_image: val?.activity_image,
        category_name: val?.category_name,
        category_id: val?.category_id,
        activity_name: val?.activity_name,
        activity_id: val?.id,
        id: val?.id,
      };
      arr.push(obj);
    });
  }
  return arr;
};

export const usePlanTemplate = create((set, get) => ({
  planTemplateState: {
    planTemplateListData: [],
    planTemplateListCount: '',
    ActivityDropDownData: [],
    activityCardData: [],
    plan: {
      id: '',
      plan_name: '',
      category_id: [],
      duration: null,
      duration_type: null,
      activity_data: [],
      is_active: true,
      status: 2,
      error: {
        plan_name: '',
        category_id: '',
        duration: '',
        duration_type: '',
      },
    },
  },
  loading: false,
  activityCardLoading: false,
  getPlanLoading: false,
  error: false,

  getAllPlanTemplateData: async (searchValue, limit = 10, offset = 0) => {
    try {
      const { planTemplateState } = get();
      set({ loading: true });

      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getAllPlanTemplateData'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/planTemplate/get-all-plan`,
            {
              search: searchValue ?? '',
              offset,
              limit,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          loading: false,
          planTemplateState: {
            ...planTemplateState,
            planTemplateListData:
              Array.isArray(data?.data) && data?.data?.length > 0
                ? constructPlanData(data?.data)
                : [],
            planTemplateListCount: data?.count,
          },
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  planStatusUpdate: async (payload) => {
    try {
      set({ loading: true });
      const payloadData = {
        plan_id: payload?.id ?? 0,
        status: payload?.status,
      };
      queryClient.invalidateQueries({
        queryKey: ['plan_status_update'],
      });

      const { data } = await queryClient.fetchQuery({
        queryKey: ['plan_status_update', payload],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/planTemplate/upsert-plan-status`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        toast.success(
          data?.status?.message ?? 'plan Status Updated Successfully'
        );
      }
      set({ loading: false });
      return data?.status?.code;
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  addPlanTemplate: async () => {
    try {
      const { planTemplateState } = get();
      const { activityCardData, plan } = planTemplateState;
      set({ loading: true });
      const payloadData = {
        id: plan?.id || '',
        plan_name: plan?.plan_name ?? '',
        category_id: plan?.category_id ?? '',
        duration: plan?.duration ?? '',
        duration_type: plan?.duration_type ?? '',
        activity_data: activityCardData.map((ary) => ary.activity_id) ?? [],
        is_active: plan?.is_active,
        status: plan?.status ?? '',
      };

      queryClient.invalidateQueries({
        queryKey: ['upsert_plan'],
      });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['upsert_plan'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/planTemplate/upsert-plan`,
            {
              ...payloadData,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (data?.status?.code === 200) {
        if (data?.status?.message === 'Plan Name Already Exist') {
          toast.error(data?.status?.message);
          set({ loading: false });
          return 201;
        }
        toast.success(
          data?.status?.message ??
            `Activity ${plan.id ? 'Updated' : 'Created'} Successfully`
        );
        set({ loading: false });
        return 200;
      }
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  isIamValidToSave: () => {
    const { planTemplateState } = get();
    const { plan } = planTemplateState;
    const planCopy = JSON.parse(JSON.stringify(plan));

    let isValid = true;
    const error = planCopy?.error;

    // checking plan_name
    if (!planCopy?.plan_name) {
      isValid = false;
      error.plan_name = 'Please enter plan name';
    } else {
      error.plan_name = '';
    }
    // checking category
    if (
      Array.isArray(planCopy?.category_id) &&
      planCopy?.category_id?.length === 0
    ) {
      isValid = false;
      error.category_id = 'Please select category';
    } else {
      error.category_id = '';
    }
    // checking duration
    if (
      planCopy?.duration === null ||
      planCopy?.duration === '' ||
      planCopy?.duration === 0
    ) {
      isValid = false;
      error.duration = 'Please enter valid duration';
    } else {
      error.duration = '';
    }
    // checking duration_type
    if (
      planCopy?.duration_type === null ||
      planCopy?.duration_type === '' ||
      planCopy?.duration_type === 0
    ) {
      isValid = false;
      error.duration_type = 'Please select duration type';
    } else {
      error.duration_type = '';
    }

    set({
      planTemplateState: {
        ...planTemplateState,
        plan: {
          ...plan,
          error,
        },
      },
    });

    return isValid;
  },

  getActivityList: async (category_id) => {
    const { planTemplateState } = get();
    try {
      set({ activityCardLoading: true });
      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getActivityList'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/activityTemplate/get-activity-by-category-id`,
            {
              search: '',
              category_id: category_id ?? [],
              goal_id: [],
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (status?.code === 200) {
        set(() => ({
          activityCardLoading: false,
          planTemplateState: {
            ...planTemplateState,
            ActivityDropDownData:
              Array.isArray(data?.activityData) &&
              data?.activityData?.length > 0
                ? constructPlanDropDown(data?.activityData)
                : [],
          },
        }));
      }
      set(() => ({ activityCardLoading: false }));
    } catch (error) {
      set({ activityCardLoading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  initialEditPlanData: async (id) => {
    const { planTemplateState, getActivityList } = get();
    try {
      set({ getPlanLoading: true });
      queryClient.invalidateQueries({
        queryKey: ['getIndividualPlan'],
      });

      const {
        data: { data, status },
      } = await queryClient.fetchQuery({
        queryKey: ['getIndividualPlan'],
        queryFn: async () => {
          const response = await httpRequest(
            'post',
            `${envConfig.api_url}/planTemplate/get-plan`,
            {
              id,
            },
            true
          );
          return response;
        },
        staleTime: Infinity,
      });

      if (status?.code === 200) {
        const categoryId = data.planData.category_data.map((val) => val.id);
        set(() => ({
          planTemplateState: {
            ...planTemplateState,
            activityCardData: data.activityData.map((val) => {
              return { ...val, label: val?.activity_name };
            }),
            plan:
              Object.keys(data.planData).length > 0
                ? {
                    id: data.planData.data.id,
                    activity_id: data.planData.data.id,
                    plan_name: data.planData.data.plan_name,
                    category_id: [...categoryId],
                    duration: data.planData.data.duration,
                    duration_type: data.planData.data.duration_type,
                    is_active: data.planData.data.is_active,
                    status: 2,
                    error: {
                      plan_name: '',
                      category_id: '',
                      duration: '',
                      duration_type: '',
                    },
                  }
                : {},
          },
          getPlanLoading: false,
        }));
        getActivityList();
        return data;
      }
      set({ getPlanLoading: false });
    } catch (error) {
      set({ getPlanLoading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          'some thing went wrong plesae try again!....'
      );
    }
  },

  handlePlanFormChange: (key, value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    const { planTemplateState } = get();
    const { plan } = planTemplateState;
    set({
      planTemplateState: {
        ...planTemplateState,
        plan: {
          ...plan,
          [key]: value,
        },
      },
    });
  },

  handlePlanCategoryChange: (value) => {
    const { planTemplateState } = get();
    const { plan } = planTemplateState;

    plan.category_id = value;

    set({
      planTemplateState: {
        ...planTemplateState,
        plan: {
          ...plan,
        },
      },
    });
  },
  addCardData: (options) => {
    const { planTemplateState } = get();
    const { activityCardData } = planTemplateState;
    activityCardData.push(options);

    set({
      planTemplateState: {
        ...planTemplateState,
        activityCardData,
      },
    });
  },
  removeCardItem: (id) => {
    const { planTemplateState } = get();
    const { activityCardData } = planTemplateState;
    const newarray = activityCardData.filter((val) => val?.activity_id !== id);
    set({
      planTemplateState: {
        ...planTemplateState,
        activityCardData: newarray,
      },
    });
  },

  deleteActivityData: (activityId) => {
    const { planTemplateState } = get();
    const { activityCardData } = planTemplateState;

    const newarray = activityCardData.filter(
      (y) => y.category_id !== activityId
    );

    set({
      planTemplateState: {
        ...planTemplateState,
        activityCardData: newarray,
      },
    });
  },

  clearActivityCardData: () => {
    const { planTemplateState } = get();
    set({
      planTemplateState: {
        ...planTemplateState,
        activityCardData: [],
      },
    });
  },

  clearPlanTemplateData: () => {
    const { planTemplateState } = get();
    set({
      planTemplateState: {
        ...planTemplateState,
        plan: {
          id: '',
          plan_name: '',
          category_id: [],
          duration: null,
          duration_type: null,
          activity_data: [],
          is_active: true,
          status: 2,
          error: {
            plan_name: '',
            category_id: '',
            duration: '',
            duration_type: '',
          },
        },
      },
    });
  },
}));
