export const logoutModal_style = {
  description: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#0F0B11',
    textAlign: 'center',
  },
  closebuttonSx: {
    mr: 2,
    maxWidth: '120px',
    bgcolor: 'white',
    color: 'primary.main',
    borderRadius: '8px',
    '&:hover': {
      color: 'white',
    },
    border: '2px solid #007965',
  },
  DeletebuttonSx: {
    ml: 2,
    maxWidth: '120px',
    bgcolor: '#F44F5A',
    color: 'white',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: '#F44F5A',
    },
    '&:focus': {
      bgcolor: '#F44F5A',
    },
    border: '2px solid #F44F5A',
  },

  totalModalSx: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: '0px solid',
    boxShadow: 24,
    backgrounColor: '#FFF',
    p: 3,
    outline: '0px',
  },
  totalBoxSx: {
    backgroundColor: 'common.white',
    px: 6,
    pt: 2,
    borderRadius: '8px',
    width: 400,
    minWidth: '426px',
    minHeight: '190px',
    margin: '0px auto',
  },
  totalMobileBoxSx: {
    backgroundColor: 'common.white',
    px: 6,
    pt: 2,
    borderRadius: '8px',
    minHeight: '190px',
    margin: '0px auto',
  },

  centerIconSx: {
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
  },
};
