import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import {
  Button,
  DateRangePicker,
  Drawer,
  Label,
  ToggleButton,
} from '@hc/ui/atoms';
import { useState } from 'react';
import { filtersDrawer_style } from './style';

function FiltersDrawer(props) {
  const {
    className = '',
    rootStyle = {},
    drugDrawerOpen = false,
    closeDrugMasterDrawer = () => false,
    ...rest
  } = props;
  const [component, setComponent] = useState(0);

  const planChange = () => {};

  const HandleSubmit = () => {
    setComponent(0);
  };

  const HandleDrawerClose = () => {
    closeDrugMasterDrawer();
  };

  const AppointmentType = [
    { value: 1, name: 'All' },

    { value: 2, name: 'New' },

    { value: 3, name: 'Follow-up' },
  ];

  const Modeofpayment = [
    { value: 1, name: 'All' },
    { value: 2, name: 'UPI' },
    { value: 3, name: 'Credit card' },
    { value: 4, name: 'Debit card' },
    { value: 5, name: 'Net banking' },
  ];

  const Status = [
    { value: 1, name: 'All' },
    { value: 2, name: 'Success' },
    { value: 3, name: 'Failed' },
    { value: 4, name: 'Refund' },
    { value: 5, name: 'Processing' },
  ];

  return (
    <Box
      sx={{
        ...filtersDrawer_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {drugDrawerOpen && (
        <Drawer
          show={drugDrawerOpen}
          onCloseDrawer={HandleDrawerClose}
          anchor="right"
          isCloseIconRequired
          header="Filters"
          headerStyle={filtersDrawer_style.headerSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                position: 'absolute',
                inset: 'auto 0 0 0',
                mb: 3,
                px: 2,
              }}
            >
              <Button
                buttonStyle={filtersDrawer_style.SaveButtonSx}
                // onClick={() => upsertDrugMaster()}
                onClick={() => HandleSubmit()}
              >
                Apply
              </Button>
              <Button
                buttonStyle={filtersDrawer_style.cancleButtonSx}
                onClick={closeDrugMasterDrawer}
              >
                Reset
              </Button>
            </Box>
          }
        >
          {component === 0 && (
            <Box sx={filtersDrawer_style.boxRootSx}>
              {/* ------------ACTIVITY NAME INPUT----------- */}
              <Box sx={filtersDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={filtersDrawer_style.labelSx}
                  htmlFor="ActivityName"
                  isRequired
                >
                  Date range
                </Label>
                <Box>
                  <DateRangePicker
                  // value={value}
                  // onChange={(newValue) => setValue(newValue)}
                  />
                </Box>
              </Box>

              {/*  ----------------ACTIVITY TYPE DROPDOWN ------------- */}
              <Box sx={filtersDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={filtersDrawer_style.labelSx}
                  htmlFor="SelectType"
                  isRequired
                >
                  Appointment type
                </Label>
                <ToggleButton
                  RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                  options={AppointmentType}
                  // value={}
                  buttonactiveStyle={filtersDrawer_style.togglebuttonactiveSX}
                  buttoninactiveStyle={
                    filtersDrawer_style.togglebuttoninactiveSX
                  }
                  onChange={(e) => {
                    planChange('AppointmentType', e);
                  }}
                />
              </Box>

              {/* ----------TIME SELCTION TOGGLE------------------ */}

              <Box sx={filtersDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={filtersDrawer_style.labelSx}
                  htmlFor="SelectType"
                  isRequired
                >
                  Mode of payment
                </Label>
                <ToggleButton
                  RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                  options={Modeofpayment}
                  // value={}
                  buttonactiveStyle={filtersDrawer_style.togglebuttonactiveSX}
                  buttoninactiveStyle={
                    filtersDrawer_style.togglebuttoninactiveSX
                  }
                  onChange={(e) => {
                    planChange('Modeofpayment', e);
                  }}
                />
              </Box>
              {/* ------------------------------- */}
              <Box sx={filtersDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={filtersDrawer_style.labelSx}
                  htmlFor="SelectType"
                  isRequired
                >
                  Status
                </Label>
                <ToggleButton
                  RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                  options={Status}
                  // value={}
                  buttonactiveStyle={filtersDrawer_style.togglebuttonactiveSX}
                  buttoninactiveStyle={
                    filtersDrawer_style.togglebuttoninactiveSX
                  }
                  onChange={(e) => {
                    planChange('Status', e);
                  }}
                />
              </Box>
            </Box>
          )}
        </Drawer>
      )}
    </Box>
  );
}

FiltersDrawer.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { FiltersDrawer };
