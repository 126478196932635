export const multiSelectToggleButton_style = {
  buttonactiveSx: {
    border: '2px solid #EE8438',
    color: '#fff',
    textTransform: 'capitalize',
    padding: '10px',
    borderRadius: '8px',
    bgcolor: '#EE8438',
    cursor: 'pointer',
  },
  buttoninactiveSx: {
    backgroundColor: '#fff',
    border: '2px solid #E3E3E3',
    borderRadius: '8px',
    textTransform: 'capitalize',
    fontSize: '12px',
    padding: '10px',
    color: '#5C6266',
    cursor: 'pointer',
  },
};
