/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
import { DashboardIcon } from '@atoms';
import { backofficeRoutes } from '@hc/routes';
import { useRouting } from '@hc/store';
import { localStorageKeys } from '@hc/utils/constants';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Layout } from '../layout';

import {
  AwarenessManagementIcon,
  DrugAuditIcon,
  FeedBackandBugIcon,
  OrganizationIcon,
  ParameterSetupIcon,
  PaymentSetupIcon,
  PlanManagementIcon,
  PlanStatusIcon,
  PlanTemplatesIcon,
  RunningIcon,
  SettlementsIcon,
  TransactionIcon,
  UserProfileIcon
} from '../../../atoms';

function RootLayout(props) {
  const {
    className = '',
    showLayout = true,
    children,
    backgroundStyle,
    rootStyle,
    ...rest
  } = props;

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const route = useRouting((state) => state.route);

  const sideNav = [
    {
      icon: <UserProfileIcon />,
      navItem: 'User Management',
      path: backofficeRoutes?.userManagement,
    },
    {
      icon: <OrganizationIcon />,
      navItem: 'Organization Management',
      path: backofficeRoutes?.organization,
    },
    {
      icon: <DashboardIcon />,
      navItem: 'Master Management',
      path: backofficeRoutes?.masterManagement,
    },
    {
      icon: <UserProfileIcon />,
      navItem: 'Patient Management',
      path: backofficeRoutes?.patient,
    },
    {
      icon: <RunningIcon rootStyle={{ width: '16px', height: '16px' }} />,
      navItem: 'Activity Template',
      path: backofficeRoutes?.activity,
    },
    {
      icon: <PlanTemplatesIcon />,
      navItem: 'Plan Template',
      path: backofficeRoutes?.plan,
    },
    {
      icon: <TransactionIcon />,
      navItem: 'Transaction',
      path: backofficeRoutes?.transactions,
    },
    {
      icon: <PaymentSetupIcon />,
      navItem: 'Payment Setup',
      path: backofficeRoutes?.paymentSetup,
    },

    {
      icon: <SettlementsIcon />,
      navItem: 'Settlement',
      path: backofficeRoutes?.settlements,
    },
    {
      icon: <PlanStatusIcon />,
      navItem: 'Plan Status',
      path: backofficeRoutes?.planStatus,
    },
    {
      icon: <PlanManagementIcon />,
      navItem: 'Plans Management',
      path: backofficeRoutes?.planManagement,
    },
    {
      icon: <AwarenessManagementIcon />,
      navItem: 'Awareness Management',
      path: backofficeRoutes?.awarenessManagement,
    },
    {
      icon: <FeedBackandBugIcon />,
      navItem: 'FeedBacks & Bugs',
      path: backofficeRoutes?.feedsandBugs,
    },
    {
      icon: <ParameterSetupIcon />,
      navItem: 'Parameter Setup',
      path: backofficeRoutes?.parameter,
    },
    {
      icon: <DrugAuditIcon />,
      navItem: 'Drug Audit',
      path: backofficeRoutes?.drugAudit,
    },
    {
      icon: <RunningIcon rootStyle={{ width: '16px', height: '16px' }} />,
      navItem: 'Seek Help',
      path: backofficeRoutes?.seekHelp,
    },
  ];

  useEffect(() => {
    if (searchParams.get('token')?.length > 0) {
      localStorage.clear();
      localStorage.setItem(
        localStorageKeys.authToken,
        searchParams.get('token'),
      );
    }
  }, []);

  useEffect(() => {
    if (route !== null) {
      navigate(route);
      setTimeout(() => {
        useRouting.setState({ route: null });
      }, 1000);
    }
  }, [route]);

  return (
    <Box mt={6} className={`${className}`} {...rest}>
      <Toaster />
      {!showLayout && children}
      {showLayout && (
        <Box>
          <Layout navItems={sideNav} />
        </Box>
      )}
    </Box>
  );
}

export { RootLayout };

RootLayout.propTypes = {
  className: PropTypes.string,
  showLayout: PropTypes.object,
  children: PropTypes.object,
  backgroundStyle: PropTypes.object,
  rootStyle: PropTypes.object,
};
