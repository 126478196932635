/* eslint-disable no-unused-vars */
import { localStorageKeys } from '@hc/utils';
import { create } from 'zustand';

export const useViewPort = create((set, get) => ({
  isMobilePort: null,
  homeTabIndex: '0',

  setValue: async (key, value) => {
    try {
      set(() => ({
        [key]: value,
      }));
      if (key === 'homeTabIndex') {
        sessionStorage.setItem(localStorageKeys.doctorResPage, value);
      }
    } catch (err) {
      return set((state) => ({ state, loading: false, error: err }));
    }
  },
}));
