export const dialog_style = {
  titleRootSx: {
    fontSize: 14,
    fontWeight: 500,
  },
  dialogRootSx: {},
  rootSx: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeOutsx: {
    width: '30px',
    height: '30px',
    position: 'fixed',
    borderRadius: '50%',
    backgroundColor: '#fff',
    padding: '15px',
    mt: '-20px',
    ml: '40px',
  },
  contentSx: {},
};
