import { getDateFormatToString } from '@hc/dayjs';
import { useCategoryMaster } from '@hc/store/backoffice/categoryMaster';
import { useDurationMaster } from '@hc/store/backoffice/durationTypeMaster';
import { usePlanTemplate } from '@hc/store/backoffice/planTemplate';
import { EditIcon, Switch } from '@hc/ui/atoms';
import { TableSkeletonLoader } from '@hc/ui/components';
import { MasterTable, MasterTopContainer } from '@hc/ui/components/backoffice';
import { AddPlanDrawer } from '@hc/ui/components/backoffice/addPlanDrawer';
import { Pagination } from '@hc/ui/components/backoffice/pagination';
import { queryClient } from '@hc/utils';
import { Box, Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { PlanTemplates_style } from './style';

export default function PlanTemplates(props) {
  const { className = '', ...rest } = props;

  const [search, setSearch] = useState('');
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [planTemplateDrawerOpen, setPlanTemplateDrawerOpen] = useState(false);

  // Store Data

  const {
    getAllPlanTemplateData,
    planStatusUpdate,
    initialEditPlanData,
    planTemplateState,
    clearPlanTemplateData,
    loading,
  } = usePlanTemplate((state) => ({
    getAllPlanTemplateData: state.getAllPlanTemplateData,
    planStatusUpdate: state.planStatusUpdate,
    initialEditPlanData: state.initialEditPlanData,
    planTemplateState: state.planTemplateState,
    clearPlanTemplateData: state.clearPlanTemplateData,
    loading: state.loading,
  }));

  const { planTemplateListData, planTemplateListCount, plan } =
    planTemplateState;

  const { getCategoryData } = useCategoryMaster((state) => ({
    getCategoryData: state.getCategoryData,
  }));

  const { getDurationData } = useDurationMaster((state) => ({
    getDurationData: state.getDurationData,
  }));

  // General Hooks

  const closePlanTemplateDrawer = () => {
    setPlanTemplateDrawerOpen(false);
  };
  // searchValue IS THE PARAM

  const handleSearch = async (searchValue) => {
    setSearch(searchValue);
    queryClient.invalidateQueries({
      queryKey: ['getAllPlanTemplateData'],
    });
    await getAllPlanTemplateData(searchValue);
    setOffset(0);
  };
  const handleAddClick = () => {
    clearPlanTemplateData();
    setPlanTemplateDrawerOpen(true);
  };

  const onStatusChange = async (id, status) => {
    const payload = {
      id,
      status: !status,
    };
    const response = await planStatusUpdate(payload);
    if (response === 200) {
      queryClient.invalidateQueries({
        queryKey: ['getAllPlanTemplateData'],
      });
      await getAllPlanTemplateData(search, rowsPerPage, offset);
    }
  };

  const onEdit = (id) => {
    initialEditPlanData(id);
    setPlanTemplateDrawerOpen(true);
  };

  const columns = [
    {
      field: 'plan_name',
      headerName: 'Plan Name',
      width: 300,
    },
    {
      field: 'category_data',
      headerName: 'Type',
      width: 200,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 270,
      valueGetter: (params) =>
        getDateFormatToString(params.row.created_at, 'lll'),
    },

    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 250,
      valueGetter: (params) =>
        getDateFormatToString(params.row.updated_at, 'lll'),
    },

    {
      field: 'is_active',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <Switch
          id="status1"
          checked={params?.row?.is_active}
          onChange={() =>
            onStatusChange(params?.row?.id, params?.row?.is_active)
          }
          rootStyle={PlanTemplates_style.switchSx}
        />
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ pl: 1 }} onClick={() => onEdit(params?.row?.id)}>
          <EditIcon id='edit1' style={{ cursor: 'pointer', color: 'grey' }} />
        </Box>
      ),
    },
  ];

  const onLimitChange = async (limit, offset) => {
    setRowsPerPage(limit);
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllPlanTemplateData'],
    });
    await getAllPlanTemplateData(search, limit, offset);
  };

  const onOffsetchange = async (offset) => {
    setOffset(offset);
    queryClient.invalidateQueries({
      queryKey: ['getAllPlanTemplateData'],
    });
    await getAllPlanTemplateData(search, rowsPerPage, offset);
  };

  const initialData = async () => {
    await getAllPlanTemplateData('');
    getCategoryData();
    getDurationData();
  };

  useEffect(() => {
    initialData();
  }, []);
  return (
    <Box
      className={`${className}`}
      {...rest}
      px={3}
      py={3}
      sx={{ height: '100vh', overflowY: 'scroll', backgroundColor: '#F8F8F9' }}
    >
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MasterTopContainer
              addMasterStyle={PlanTemplates_style.addMasterSx}
              handleSearch={handleSearch}
              handleAddClick={handleAddClick}
              title="Plan templates"
              count={planTemplateListCount ?? '0'}
              showSearchField
              searchFieldPlaceholder="Search Plan Name"
              showAddButton
              addButtonName="Add Plan Template"
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Box>
                <TableSkeletonLoader />
              </Box>
            ) : (
              <Box>
                <MasterTable
                  columns={columns}
                  data={planTemplateListData ?? []}
                  customTableStyle={PlanTemplates_style.dataTableCustomSx}
                  hideFooter
                  rootStyle={{ height: '68vh' }}
                />
              </Box>
            )}
          </Grid>
          <Stack
            flexDirection="row"
            justifyContent="end"
            sx={{ width: '100%' }}
          >
            <Pagination
              totalCount={planTemplateListCount}
              onLimitChange={onLimitChange}
              onOffsetchange={onOffsetchange}
              offset={offset}
              rowsPerPage={rowsPerPage}
            />
          </Stack>
        </Grid>

        {/* Add Plan Template Drawer */}
        <Box>
          <AddPlanDrawer
            planTemplateDrawerOpen={planTemplateDrawerOpen}
            closePlanTemplateDrawer={closePlanTemplateDrawer}
            isUpdate={plan?.id > 0 ? true : false}
          />
        </Box>
      </Box>
    </Box>
  );
}

PlanTemplates.propTypes = {
  className: PropTypes.string,
};
