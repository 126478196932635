import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { tagsToggleButton_style } from './style';
import { DonetickIcon } from '../../../atoms';

function TagsToggleButton(props) {
  const {
    valueChange,
    value,
    data,
    options = [],
    isError = false,
    errorMessage = '',
    buttonactiveStyle = {},
    buttoninactiveStyle = {},
    RootStyle = {},
    dataTestId,
    ...rest
  } = props;

  return (
    <>
      <Box gap={1} sx={{ my: 0.5, display: 'flex', ...RootStyle }} {...rest}>
        {options?.map((val, i) => (
          <Box
          id={`${dataTestId}${i + 1}`}
            key={i}
            sx={{
              display: 'grid',
              placeItems: 'center',
              gap: 0.5,
            }}
          >
            <Button
              onClick={() => valueChange(val.value)}
              size="small"
              sx={
                val.value === value
                  ? {
                      ...tagsToggleButton_style.buttonactiveSx,
                      ...buttonactiveStyle,
                    }
                  : {
                      ...tagsToggleButton_style.buttoninactiveSx,
                      ...buttoninactiveStyle,
                    }
              }
            >
              <Box display="flex" alignItems="center">
                {value === val.value ? <DonetickIcon /> : ''}
                <Typography fontSize="14px" ml={0.3}>
                  {val.name}
                </Typography>{' '}
              </Box>
            </Button>
          </Box>
        ))}
      </Box>
      {isError && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </>
  );
}

TagsToggleButton.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { TagsToggleButton };
