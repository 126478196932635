import { useBugReportMaster } from '@hc/store/backoffice/feeds/bugReportMaster';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  Button,
  CustomTextArea,
  Drawer,
  Dropdown,
  Input,
  Label,
} from '../../../atoms';
import { bugreportDrawer_style } from './style';

function BugreportDrawer(props) {
  const {
    drawerOpen = false,
    closeMasterDrawer = () => false,
    upsertMaster,
  } = props;
  const { bug, handleChange, status } = useBugReportMaster((state) => ({
    bug: state.bug,
    handleChange: state.handleChange,
    status: state.status,
  }));

  const colorStatus = [
    { label: 'Pending', name: 'Pending', value: 1 },
    { label: 'Inprogress', name: 'Inprogress', value: 2 },
    { label: 'Completed', name: 'Completed', value: 3 },
    { label: 'Deployed', name: 'Deployed', value: 4 },
  ];
  return (
    <Box
      sx={{
        ...bugreportDrawer_style.rootSx,
      }}
    >
      {drawerOpen && (
        <Drawer
          show={drawerOpen}
          onCloseDrawer={closeMasterDrawer}
          anchor="right"
          isCloseIconRequired
          header="Bugs"
          headerStyle={bugreportDrawer_style.drawerHeadSx}
          footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                buttonStyle={bugreportDrawer_style.saveBtnSx}
                onClick={upsertMaster}
              >
                update
              </Button>
              <Button
                buttonStyle={bugreportDrawer_style.cancleBtnSx}
                onClick={closeMasterDrawer}
              >
                Close
              </Button>
            </Box>
          }
        >
          <Box sx={bugreportDrawer_style.boxRootSx}>
            <Box sx={bugreportDrawer_style.inputGroupSx}>
              <Label
                rootStyle={bugreportDrawer_style.labelSx}
                htmlFor="bugCreatorName"
                isRequired
              >
                Name
              </Label>
              <Input
                id="bugCreatorName"
                textFieldStyle={bugreportDrawer_style.inputFieldSx}
                value={bug?.name ?? ''}
                fullWidth
                isReadOnly
              />
            </Box>

            <Box sx={bugreportDrawer_style.inputGroupSx}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500, py: 2 }}>
                Content
              </Typography>
              <CustomTextArea
                data_testId="content"
                textFieldStyle={bugreportDrawer_style.customtextSx}
                value={bug?.content ?? ''}
                // disable
              />
            </Box>

            {Array.isArray(bug?.url) && bug?.url?.length > 0 ? (
              <Box sx={bugreportDrawer_style.inputGroupSx}>
                <Typography sx={bugreportDrawer_style.title}>
                  Bug Image
                </Typography>
                {bug?.url.map((image, i) => (
                  <img
                    src={image}
                    alt="bug"
                    key={i}
                    width="300px"
                    style={{ borderRadius: '10px' }}
                  />
                ))}
              </Box>
            ) : null}

            <Box sx={bugreportDrawer_style.inputGroupSx}>
              <Typography sx={bugreportDrawer_style.title}>Status</Typography>
              <Dropdown
                datatestid="status"
                selectOption={colorStatus}
                placeholder="Status"
                value={status}
                onChange={(value) => {
                  handleChange(value?.target?.value);
                }}
              />
            </Box>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

BugreportDrawer.propTypes = {
  drawerOpen: PropTypes.bool,
};

export { BugreportDrawer };
