import {
  withScope,
  captureMessage,
  captureException,
  captureEvent,
} from '@sentry/react';

export const log = (type, payload) => {
  if (type === 'info') {
    withScope((scope) => {
      scope.setLevel('info');
      captureMessage(payload);
    });
  } else if (type === 'error') {
    withScope((scope) => {
      scope.setLevel('error');
      captureException(payload);
    });
  } else if (type === 'event') {
    const event = {
      message: payload.eventName,
      level: 'info',
      extra: payload.extraData,
    };
    captureEvent(event);
  }
};
