export const inputBox_style = {
  textFieldSx: {
    opacity: 1,
    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 600,
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
    '& .MuiFormHelperText-root': {
      mx: 0,
    },
    '& input::placeholder': {
      fontSize: '14px',
      fontWeight:400,
    },
  },
};
