/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { multiSelectToggleButton_style } from './style';

function MultiSelectToggleButton(props) {
  const {
    className = '',
    rootStyle = {},
    buttons = [],
    disabled = false,
    isError = false,
    handleButton = () => {},
    errorMessage = '',
    buttonactiveStyle = {},
    buttoninactiveStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...multiSelectToggleButton_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        gap={1}
        sx={{
          my: 0.5,
          display: 'flex',
          flexWrap: 'wrap',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {buttons?.map((val, i) => (
          <Box
            id={`expType_${i}`}
            key={i}
            onClick={() => !disabled && handleButton(val.value)}
            display="flex"
            sx={
              val.selected
                ? {
                    ...multiSelectToggleButton_style.buttonactiveSx,
                    ...buttonactiveStyle,
                  }
                : {
                    ...multiSelectToggleButton_style.buttoninactiveSx,
                    ...buttoninactiveStyle,
                  }
            }
          >
            <Typography fontSize={12}>{val?.name ? val?.name : ''}</Typography>

            {val?.icon && val?.selected && (
              <Box sx={{ padding: '0px 0px 0px 10px', marginTop: '-5px' }}>
                {val?.icon}
              </Box>
            )}
          </Box>
        ))}
      </Box>
      {isError && (
        <Typography sx={{ mt: 0.5 }} variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

MultiSelectToggleButton.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  buttons: PropTypes.array,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  handleButton: PropTypes.func,
  errorMessage: PropTypes.string,
  buttonactiveStyle: PropTypes.object,
  buttoninactiveStyle: PropTypes.object,
};

export { MultiSelectToggleButton };
