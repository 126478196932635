import PropTypes from 'prop-types';
import { Box, Stack, Typography, IconButton } from '@mui/material';
import DefaultImg from '@assets/DefaultImg.svg';
import MindImg from '@assets/MindImg.svg';
import BodyImg from '@assets/BodyImg.svg';
import FoodImg from '@assets/FoodImg.svg';
import { ActivityCancleIcon } from '../../../atoms';
import { activitySelectCard_style } from './style';

function ActivitySelectCard(props) {
  const {
    className = '',
    rootStyle = {},
    activityName = 'Jogging',
    activityType = 'body',
    image,
    selectable = true,
    onClose = () => {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...activitySelectCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          border: '1px solid #E2E2E2',
          borderRadius: '8px',
          px: 1,
          py: 1,
        }}
      >
        <Stack flexDirection="row" alignItems="center">
          <Box sx={activitySelectCard_style.cardContentSx}>
            <Box>
              <img
                style={{
                  objectFit: image?.includes('https') ? 'cover' : 'contain',
                  width: '40px',
                  height: '40px',
                  borderRadius: '4px',
                }}
                src={
                  image?.includes('https')
                    ? image
                    : activityType === 'Mind'
                    ? MindImg
                    : activityType === 'Body'
                    ? BodyImg
                    : activityType === 'Food'
                    ? FoodImg
                    : DefaultImg
                }
                alt=""
              />
            </Box>
            <Box sx={activitySelectCard_style.relationSX}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '500',
                  textAlign: 'left',
                  fontSize: '14px',
                  color: '#0E1824',
                }}
              >
                {activityName}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'left',
                  fontSize: '12px',
                  color: '#888888',
                }}
              >
                {activityType}
              </Typography>
            </Box>
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          {selectable && (
            <IconButton onClick={onClose}>
              <ActivityCancleIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

ActivitySelectCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ActivitySelectCard };
