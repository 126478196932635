export const addMembersDrawer_style = {
  inputFieldSx: {
    marginTop: '4px',
  },
  boxRootSx: {
    display: 'grid',
    gap: 2,
    pt: 1,
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },

  rootchildrenSx: {
    textAlign: 'center',
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: '8px',
    mr: 1,
  },

  togglebuttoninactiveSX: {
    color: '#5C6266',
    border: '1px solid ',
    borderColor: 'transparent',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '8px',
    padding: '5px 10px',
    backgroundColor: '#E7FCF9',
    '&:hover': {
      color: '#5C6266',
      backgroundColor: '#E7FCF9',
    },
  },
  togglebuttonactiveSX: {
    color: 'primary.main',
    backgroundColor: '#E7FCF9',
    borderColor: 'primary.main',
    border: '1px solid ',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    padding: '5px 10px',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: '#E7FCF9',
    },
  },
  UploadbtnSx: {
    bgcolor: 'primary.main',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    width: '150px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  SaveButtonSx: {
    bgcolor: 'primary.main',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleButtonSx: {
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  genderSx: {
    display: 'flex',
    justifyContent: 'start',
  },
  selectAvailabilityButtonSx: {
    backgroundColor: 'primary.main',
    color: '#fff',
  },
  unSelectAvailabilityButtonSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'primaryTints.A100',
  },
  buttonSx: {
    color: 'primary.main',
    border: '2px solid',
    fontSize: '14px',
    fontWeight: 'medium',
    borderColor: 'primary.main',
    height: '40px',
    width: '128px',
    borderRadius: '4px',
    textTransform: 'capitalize',
  },
  addMemberSx: {
    textTransform: 'capitalize',
    height: '40px',
    width: '124px',
    borderRadius: '4px',
  },
  labelSx: {
    color: 'text.label',
  },
  linkWrapSx: {
    display: 'flex',
    gap: 0.8,
    alignItems: 'center',
  },
  linkSx: {
    color: '#2675D2',
    fontSize: '14px',
    fontWeight: 'normal',
  },
};
