import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addDonts_style } from './style';
import { Button } from '../../../atoms';

function AddDonts(props) {
  const {
    className = '',
    rootStyle = {},
    value,
    handleChanges = () => {},
    HandleSubmit = () => {},
    closeDontsDrawer = () => {},
    boxRootStyle,
    isDontShowFooter,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...addDonts_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{ ...addDonts_style.boxRootSx, ...boxRootStyle }}
        height="68vh"
        pb={4}
      >
        <ReactQuill theme="snow" value={value} onChange={handleChanges} />
      </Box>
      {!isDontShowFooter && (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 8,
            position: 'fixed',
            inset: 'auto',
          }}
        >
          <Button
            buttonStyle={addDonts_style.SaveButtonSx}
            onClick={HandleSubmit}
          >
            Save
          </Button>
          <Button
            buttonStyle={addDonts_style.cancleButtonSx}
            onClick={closeDontsDrawer}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
}

AddDonts.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { AddDonts };
