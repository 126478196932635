import { useDrugMaster } from '@hc/store/backoffice/drugMaster';
import { useMasterDrugCategory } from '@hc/store/doctor/masterDrugCategory';
import { useMasterFrequency } from '@hc/store/doctor/masterFrequency';
import { useMasterTime } from '@hc/store/doctor/masterTime';
import { useMasterWhen } from '@hc/store/doctor/masterWhen';
import { Button, Drawer, Dropdown, Input, Label } from '@hc/ui/atoms';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { addDrugDrawer_style } from './style';

export function AddDrugDrawer(props) {
  const {
    className = '',
    drugDrawerOpen = false,
    closeDrugMasterDrawer = () => false,
    upsertDrugMaster = () => false,
    isUpdate = false,
    ...rest
  } = props;

  const { drug, handleDrugFormChange, loading } = useDrugMaster((state) => ({
    drug: state.drug,
    handleDrugFormChange: state.handleDrugFormChange,
    loading: state.loading,
  }));

  const { drugName, time, when, frequency, category, error } = drug;

  const { getMasterTimeData, masterTimeData } = useMasterTime((state) => ({
    getMasterTimeData: state.getMasterTimeData,
    masterTimeData: state.masterTimeData,
  }));

  const { getMasterWhenData, masterWhenData } = useMasterWhen((state) => ({
    getMasterWhenData: state.getMasterWhenData,
    masterWhenData: state.masterWhenData,
  }));

  const { getMasterFrequencyData, masterFrequencyData } = useMasterFrequency(
    (state) => ({
      getMasterFrequencyData: state.getMasterFrequencyData,
      masterFrequencyData: state.masterFrequencyData,
    })
  );

  const { masterDrugCategoryData, getMasterDrugCategoryData } =
    useMasterDrugCategory((state) => ({
      masterDrugCategoryData: state.masterDrugCategoryData,
      getMasterDrugCategoryData: state.getMasterDrugCategoryData,
    }));

  const handleChange = (key, value) => {
    // Handle drug Function Change
    handleDrugFormChange(key, value);
  };

  const initialMaterData = async () => {
    await getMasterTimeData();
    await getMasterWhenData();
    await getMasterFrequencyData();
    await getMasterDrugCategoryData();
  };

  useEffect(() => {
    initialMaterData();
  }, []);

  return (
    <Box className={`${className}`} {...rest}>
      <Box>
        {drugDrawerOpen && (
          <Drawer
            show={drugDrawerOpen}
            onCloseDrawer={closeDrugMasterDrawer}
            anchor="right"
            isCloseIconRequired
            header="Drug Master"
            headerStyle={{
              fontSize: '16px',
              fontWeight: 600,
              color: '#101010',
              textTransform: 'capitalize',
            }}
            footerStyle={{ borderTop: '0px', py: 2.8, px: 2 }}
            footer={
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  loading={loading}
                  buttonStyle={{
                    bgcolor: 'primary.main',
                    textTransform: 'capitalize',
                    color: '#fff',
                    borderRadius: '8px',
                    '&:hover': {
                      bgcolor: 'primary.main',
                    },
                  }}
                  onClick={() => upsertDrugMaster()}
                >
                  {isUpdate ? 'Update' : 'Save'}
                </Button>
                <Button
                  disabled={loading === true ? true : false}
                  buttonStyle={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    textTransform: 'capitalize',
                    borderRadius: '8px',
                    bgcolor: '#fff',
                    '&:hover': {
                      bgcolor: 'primaryTints.200',
                    },
                  }}
                  onClick={closeDrugMasterDrawer}
                >
                  Cancel
                </Button>
              </Box>
            }
          >
            <Box sx={addDrugDrawer_style.boxRootSx}>
              <Box sx={addDrugDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addDrugDrawer_style.labelSx}
                  htmlFor="drugName"
                  isRequired
                >
                  Name
                </Label>
                <Input
                  id="drugName"
                  textFieldStyle={addDrugDrawer_style.inputFieldSx}
                  onChange={(e) => {
                    handleChange('drugName', e.target.value, 20);
                  }}
                  isError={error?.drugName?.length > 0}
                  errorMessage={error?.drugName}
                  value={drugName}
                  fullWidth
                />
              </Box>

              <Box sx={addDrugDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addDrugDrawer_style.labelSx}
                  htmlFor="time"
                  isRequired
                >
                  Time
                </Label>
                <Dropdown
                  selectOption={
                    Array.isArray(masterTimeData) && masterTimeData?.length > 0
                      ? masterTimeData
                      : []
                  }
                  datatestid="time"
                  value={time}
                  onChange={(value) => {
                    handleChange('time', value?.target?.value);
                  }}
                  isError={error?.time}
                  helperText={error?.time}
                />
              </Box>
              <Box sx={addDrugDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addDrugDrawer_style.labelSx}
                  htmlFor="when"
                  isRequired
                >
                  When
                </Label>
                <Dropdown
                  selectOption={
                    Array.isArray(masterWhenData) && masterWhenData?.length > 0
                      ? masterWhenData
                      : []
                  }
                  datatestid="when"
                  value={when}
                  onChange={(value) => {
                    handleChange('when', value?.target?.value);
                  }}
                  isError={error?.when}
                  helperText={error?.when}
                />
              </Box>
              <Box sx={addDrugDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addDrugDrawer_style.labelSx}
                  htmlFor="frequency"
                  isRequired
                >
                  Frequency
                </Label>
                <Dropdown
                  selectOption={
                    Array.isArray(masterFrequencyData) &&
                    masterFrequencyData?.length > 0
                      ? masterFrequencyData
                      : []
                  }
                  datatestid="frequency"
                  value={frequency}
                  onChange={(value) => {
                    handleChange('frequency', value?.target?.value);
                  }}
                  isError={error?.frequency}
                  helperText={error?.frequency}
                />
              </Box>

              <Box sx={addDrugDrawer_style.inputGroupSx}>
                <Label
                  rootStyle={addDrugDrawer_style.labelSx}
                  htmlFor="category"
                  isRequired
                >
                  Category
                </Label>
                <Dropdown
                  selectOption={
                    Array.isArray(masterDrugCategoryData) &&
                    masterDrugCategoryData?.length > 0
                      ? masterDrugCategoryData
                      : []
                  }
                  datatestid="category"
                  value={category}
                  onChange={(value) => {
                    handleChange('category', value?.target?.value);
                  }}
                  isError={error?.category}
                  helperText={error?.category}
                />
              </Box>
            </Box>
          </Drawer>
        )}
      </Box>
    </Box>
  );
}

AddDrugDrawer.propTypes = {
  drugDrawerOpen: PropTypes.bool,
  isUpdate: PropTypes.bool,
  closeDrugMasterDrawer: PropTypes.func,
  upsertDrugMaster: PropTypes.func,
  className: PropTypes.node,
  sx: PropTypes.object,
};
