import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient
} from '@hc/utils';
import toast from 'react-hot-toast';
import create from 'zustand';

export const constructDrugData = (data) => {
  const allergiesTableData = data.map((val, i) => ({
    value: val,
    id: i,
    content: val?.feedback_form_answer.content ?? '',
    name: val?.feedback_form_answer.name ?? '',
    email: val?.feedback_form_answer.email ?? '',
    rating: val?.feedback_form_answer.rating ?? '',
    updated_at: val?.updated_at ?? '',
    created_at: val?.created_at ?? '',
    module: val.module ?? '',
  }));
  return allergiesTableData;
};

export const useFeedBackMaster = create((set, get) => ({
  feedBackData: [],
  feedBackCount: 0,
  feeds: {},
  loading: false,

  // ------------- STORE METHODS ------------------
  getFeedbacks: async (search = '', limit = 10, offset = 0) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    try {
      set({ loading: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['getFeedbacks', search, offset],
        queryFn: async () => {
          const response = await httpRequest(
            'get',
            `${envConfig.api_url}/backoffice/user-feedback/get?user_id=${tokenData.id}&search=${search}&limit=${limit}&offset=${offset}`,
            {},
            true
          );
          return response;
        },
        staleTime: Infinity,
      });
      if (data?.status?.code === 200) {
        set(() => ({
          feedBackData:
            Array.isArray(data?.data?.feedback_data) &&
            data?.data?.feedback_data?.length > 0
              ? constructDrugData(data?.data?.feedback_data)
              : [],
          feedBackCount: data?.data?.count,
        }));
      }
      set(() => ({ loading: false }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.data?.status?.message ??
          error?.message ??
          '❌ Some thing went wrong plesae try again!'
      );
    }
  },

  initialFeeds: (id) => {
    const { feedBackData } = get();
    const [data] = feedBackData.filter((val) => val.id === id);
    set({ feeds: data.value.feedback_form_answer });
  },

  clearGoalCategory: () => {
    set({
      goal_name: '',
      goal_id: '',
    });
  },
}));
